import classname from 'classnames';
import PropTypes from 'prop-types';

import './index.scss';

import CheckedCheckbox from './../../../assets/images/check.svg';
import UncheckedCheckbox from './../../../assets/images/uncheck.svg';

/**
 * To switch for checkbox version turn "disabled" to false
 */
const AuthCheckbox = ({ isChecked, onCheck, label, disabled }) => (
  <div className={classname('auth-checkbox', { disabled })}>
    <img alt="" src={isChecked ? CheckedCheckbox : UncheckedCheckbox} onClick={onCheck} />
    {label}
  </div>
);

export default AuthCheckbox;

AuthCheckbox.propTypes = {
  isChecked: PropTypes.bool,
  onCheck: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  disable: PropTypes.bool
};

AuthCheckbox.defaultProps = {
  onCheck: () => {},
  disable: false,
  isChecked: false
};
