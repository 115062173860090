import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Progress from './../progress';
import Tooltip from './tooltip';
import InfoMarker from './info-marker';
import InfoMessage from './info-message';

import { PROGRESS_TYPES } from './../../../constants/configuration.constants';

import {
  isLimitRestricted,
  getSecondaryPercent,
  getPercent,
  getAmount,
  getInfoMessageText
} from './helper';
import './index.scss';
import { configurationActions } from '../../../actions/configuration.action';
import { useTooltipCoordinates } from '../../../hooks';
import Portal from '../portal';

function ProgressWithTooltip({
  limit,
  spent,
  lowerLimit,
  progressType,
  currencySymbol,
  description,
  inactive
}) {
  const { t } = useTranslation();

  const [percent, setPercent] = useState(0);
  const [secondaryPercent, setSecondaryPercent] = useState(null);
  const [isLimitRestrictionVisible, setIsLimitRestrictionVisible] = useState(false);
  const [isInfoMessageVisible, setIsInfoMessageVisible] = useState(false);
  const [isMessageVisible, setMessageVisible] = useState(false);

  let timer = useRef(null);

  useEffect(() => {
    setPercent(getPercent(limit, spent));
  }, [limit, spent]);

  useEffect(() => {
    setIsLimitRestrictionVisible(isLimitRestricted(lowerLimit, limit, spent));
  }, [limit, spent, lowerLimit]);

  useEffect(() => {
    setSecondaryPercent(
      isLimitRestrictionVisible ? getSecondaryPercent(limit, lowerLimit, spent) : null
    );
  }, [isLimitRestrictionVisible, limit, lowerLimit, spent]);

  useEffect(() => {
    if (isInfoMessageVisible) {
      timer = setTimeout(() => {
        setMessageVisible(isInfoMessageVisible);
      }, 250);
    } else {
      clearTimeout(timer);
      setMessageVisible(isInfoMessageVisible);
    }

    return () => clearTimeout(timer);
  }, [isInfoMessageVisible]);

  const { tooltipCoords, handleHover } = useTooltipCoordinates(setIsInfoMessageVisible);

  const handleMarkerHoverOut = () => {
    setIsInfoMessageVisible(false);
  };

  return (
    <div className="progress--with__tooltip">
      <Tooltip
        percent={percent}
        amount={getAmount(currencySymbol || '$', spent)}
        description={description}
      />
      <Progress percent={percent} secondaryPercent={secondaryPercent} />
      {isLimitRestrictionVisible ? (
        <InfoMarker
          inactive={inactive}
          percent={secondaryPercent}
          onHoverOn={handleHover}
          onHoverOut={handleMarkerHoverOut}
        />
      ) : null}
      {isMessageVisible ? (
        <Portal
          component={InfoMessage}
          nameOfClass="info-message-js"
          text={t(`card.progressTooltips.${getInfoMessageText(progressType)}`)}
          tooltipCoords={tooltipCoords}
        />
      ) : null}
    </div>
  );
}

ProgressWithTooltip.propsType = {
  limit: PropTypes.number,
  spent: PropTypes.number,
  lowerLimit: PropTypes.number,
  progressType: PropTypes.string,
  description: PropTypes.string,
  inactive: PropTypes.bool
};

ProgressWithTooltip.defaultProps = {
  limit: 0,
  spent: 0,
  lowerLimit: null,
  progressType: PROGRESS_TYPES.DEFAULT,
  description: '',
  inactive: false
};

export default connect()(ProgressWithTooltip);
