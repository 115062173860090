export const authConstants = {
  LOGIN_USER: 'LOGIN_USER',
  LOGOUT: 'LOGOUT_USER',
  LOADING_USER_INFO: 'LOADING_USER_INFO',
  UPDATE_AGREEMENT: 'UPDATE_AGREEMENT'
};

export const TOKEN_TYPES = {
  BEARER: 'bearer',
  TWO_FACTOR: '2fa'
};

export const TEMPO_TOKEN_KEY = 'tempo_token';
export const TEMPO_DEVICE_ID_KEY = 'tempo_device_id';
export const EMAIL_TO_DEVICE_ID_MAP_KEY = 'email-to-device_id-map';

export const TWO_FACTOR_CODE_LENGTH = 6;
export const MOBILE_VERIFICATION_CODE_LENGTH = 6;

export const REDIRECT_AFTER_LOGIN_PATH_KEY = 'redirect_to_path_after_login';

export const LOCALE_MONTHS = {
  ES: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre',
  PT: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'
};

export const LOCALE_SHORT_MONTHS = {
  ES: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic',
  PT: 'Jan_Fev_Mar_Abr_Mai_Jun_Jul_Ago_Set_Out_Nov_Dez'
};

export const LOCALE_SHORT_DAYS = {
  PT: 'dom_seg_ter_qua_qui_sex_sab'
};
