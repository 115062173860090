import classNames from 'classnames';

import './index.scss';

const Input = ({
  disabled,
  invalid,
  Icon,
  blockStyles,
  className,
  withoutDisabledStyles,
  ...rest
}) => (
  <div
    className={classNames('default-input__wrapper', className, {
      disabled: withoutDisabledStyles ? null : disabled,
      invalid
    })}
    style={blockStyles}
  >
    <input disabled={disabled} {...rest} />
    {Icon ? <Icon className="default-input__icon" /> : null}
    {invalid ? <span>{invalid}</span> : null}
  </div>
);

export default Input;
