import classNames from 'classnames';
import PropTypes from 'prop-types';

import Loader from '../loader';

import { useActivationResend } from './hooks';

import './index.scss';

const ActivationResend = ({ userId }) => {
  const { status, handleClick, getLabel } = useActivationResend({ userId });
  const { pending, resolved } = status;

  return (
    <div className="resend-link">
      <div onClick={handleClick} className="resend-link__text-wrapper">
        <span
          className={classNames('resend-link__text', {
            pending,
            resolved
          })}
        >
          {getLabel()}
        </span>
        {pending && <Loader small />}
      </div>
    </div>
  );
};

export default ActivationResend;

ActivationResend.propTypes = {
  userId: PropTypes.string.isRequired
};
