import classNames from 'classnames';

import './index.scss';

const Content = ({ tagsInstance, option, idx, descriptionStyle, withoutOptions }) => (
  <div key={idx} className="data-group-content">
    {withoutOptions ? null : (
      <div className="data-group-content__label">
        <div>
          <span>{option}</span>
        </div>
      </div>
    )}
    <div className="data-group-content-data">
      {tagsInstance.map((e, idx) => (
        <div key={idx} className="data-group-content-data__description" style={descriptionStyle}>
          {e.label}
        </div>
      ))}
    </div>
  </div>
);

const DataGroup = ({ idx, styles, title, withoutOptions, children }) => (
  <div
    key={idx}
    className={classNames('data-group', {
      'without-options': withoutOptions
    })}
    style={styles}
  >
    <div className="data-group__title">{title}</div>
    <div className="data-group-description">{children}</div>
  </div>
);

DataGroup.Content = Content;

export default DataGroup;
