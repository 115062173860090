import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import './i18n';

import App from './app/App';

import store from './store';

import reportWebVitals from './reportWebVitals';
import { connectAnalytics } from './analytics';

import './index.scss';

connectAnalytics();

render(
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
  document.getElementById('root')
);

reportWebVitals();
