import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { prefix } from '../../../utils/classnames-utils';

import './index.scss';

const Divider = ({ vertical, main, ...props }) => {
  const baseClass = 'divider';
  const addPrefix = prefix(baseClass);
  const classes = classNames(baseClass, props.className, {
    [addPrefix('__vertical')]: vertical,
    [addPrefix('__horizontal')]: !vertical,
    [addPrefix('--main')]: main
  });
  return <div {...props} className={classes} />;
};

Divider.propsType = {
  vertical: PropTypes.bool,
  main: PropTypes.bool
};

Divider.defaultProps = {
  vertical: false,
  main: false
};

export default memo(Divider);
