import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import BoxInfo from '../box-info';
import { useRenderErrorBlock } from './hooks';
import { BOX_ERROR, BOX_INFO, BOX_SUCCESS } from '../../../constants/box-info.constants';

const RenderSuccessPasswordBlock = t => (
  <BoxInfo
    type={BOX_SUCCESS}
    description={`${t('success.description')} `}
    message={t('success.message')}
  />
);

const RenderErrorPasswordBlock = (passwordErrors, t) => {
  const {
    entriesFromPasswordErrors,
    chooseTagWrapper,
    message,
    composedString
  } = useRenderErrorBlock(passwordErrors, t);
  const { doNotMatch, regularError } = passwordErrors;

  if (doNotMatch || regularError) return <BoxInfo type={BOX_ERROR} message={message} />;

  return (
    <BoxInfo type={BOX_ERROR}>
      <b>{t('error.description')}</b> {t('error.message.atLeast')}
      {entriesFromPasswordErrors.map(([key, value], idx) =>
        idx === entriesFromPasswordErrors.length - 1
          ? composedString(key, value, idx)
          : chooseTagWrapper(key, value, idx)
      )}
    </BoxInfo>
  );
};

const renderInfoPasswordBlock = t => (
  <BoxInfo type={BOX_INFO} description={`${t('info.description')} `} message={t('info.message')} />
);

const PasswordInfoBox = ({ isDirty, passwordErrors }) => {
  const { t } = useTranslation('password-info-box');
  if (!isDirty) return renderInfoPasswordBlock(t);
  return passwordErrors
    ? RenderErrorPasswordBlock(passwordErrors, t)
    : RenderSuccessPasswordBlock(t);
};

PasswordInfoBox.propsType = {
  isDirty: PropTypes.bool,
  passwordErrors: PropTypes.object
};

PasswordInfoBox.defaultProps = {
  isDirty: false,
  passwordErrors: null
};

export default PasswordInfoBox;
