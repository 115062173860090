import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';

import appReducer from '../reducers';

import { authConstants } from '../constants/auth.constants';

const middlewares = [thunkMiddleware];

const rootReducer = (state, action) => {
  if (action.type === authConstants.LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const store = createStore(rootReducer, applyMiddleware(...middlewares));

export default store;
