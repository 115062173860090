export const completeColors = {
  circleStoke: '#23DCA2',
  circleFill: '#23DCA2',
  pathStoke: '#fff'
};

export const activeColors = {
  circleStoke: '#23DCA2',
  circleFill: '#fff',
  pathStoke: '#23DCA2'
};

export const pendingColors = {
  circleStoke: '#EEEEEE',
  circleFill: '#fff',
  pathStoke: '#EEEEEE'
};
