import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useClickOutsideElement } from '../../../hooks';

import { ScrollableArea } from '../../scrollable-area';
import arrowsImg from '../../../assets/images/chevron-menu.svg';
import unexpectedCurrencyFlag from '../../../assets/images/currency/default-flag.svg';

import './index.scss';

const CurrencyDropdownSecondary = ({
  currencies,
  selectedCurrency,
  onCurrencySelection,
  isBank,
  style,
  disabled
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isSingleCurrencyAvailable, setIsSingleCurrencyAvailable] = useState(true);

  const onClickOutside = () => {
    setIsDropdownVisible(false);
  };

  const dropdownRef = useRef();
  useClickOutsideElement(dropdownRef, onClickOutside);

  useEffect(() => {
    setIsSingleCurrencyAvailable(currencies.length <= 1);
  }, [currencies]);

  const handleHeaderClick = () => {
    if (!isSingleCurrencyAvailable) setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <div
      className="currency-dropdown__secondary"
      style={isBank ? { paddingLeft: 10, ...style } : style}
      ref={dropdownRef}
      onClick={() => !disabled && handleHeaderClick()}
    >
      {selectedCurrency.flagImg ? (
        <img className="dropdown-flag__img" alt="" src={selectedCurrency.flagImg} />
      ) : null}
      <span>{isBank ? selectedCurrency.name : selectedCurrency.code}</span>
      {!isSingleCurrencyAvailable ? <img alt="" src={arrowsImg} /> : null}
      {isDropdownVisible ? (
        <DropdownContainer
          isBank={isBank}
          currencies={currencies}
          onCurrencyClick={onCurrencySelection}
        />
      ) : null}
    </div>
  );
};

const DropdownContainer = ({ isBank, currencies, onCurrencyClick }) => (
  <div className="dropdown__container">
    <ScrollableArea styles={{ width: '100%', maxHeight: 250 }}>
      {currencies.map(item => (
        <div
          key={isBank ? item.key : item.id}
          className="currency-dropdown__secondary"
          style={isBank ? { paddingLeft: 0 } : null}
          onClick={() => onCurrencyClick(item)}
        >
          {isBank ? null : (
            <img
              className="dropdown-flag__img"
              alt=""
              src={item.flagImg || unexpectedCurrencyFlag}
            />
          )}
          <span>{isBank ? item.name : item.code}</span>
        </div>
      ))}
    </ScrollableArea>
  </div>
);

CurrencyDropdownSecondary.propsType = {
  isBank: PropTypes.bool,
  currencies: PropTypes.array,
  selectedCurrency: PropTypes.object.isRequired,
  onCurrencySelection: PropTypes.func
};
CurrencyDropdownSecondary.defaultProps = {
  isBank: false,
  currencies: [],
  selectedCurrency: {
    flagImg: null,
    code: '',
    id: 0
  },
  onCurrencySelection: () => {}
};

export default CurrencyDropdownSecondary;
