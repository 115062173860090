import { companyConstants } from '../constants/company.constants';
import helper from './helpers/company';
import {
  getAvailableCurrencies,
  getDefaultCurrency,
  prepareCurrencies
} from '../services/currency.service';

const INITIAL_STATE = {
  isCompanyInfoLoaded: false,
  companyId: null,
  companyLegalName: null,
  companyName: null,
  companyAddress: null,
  companyWalletBilling: null,
  billingPeriods: [],
  currentBillingPeriod: helper.prepareBillingPeriod(),
  selectedBillingPeriod: helper.prepareBillingPeriod(),
  departments: [],
  admins: [],
  superAdmins: [],
  billingContacts: [],
  supportedCurrenciesIds: [],
  defaultCurrency: null,
  currencies: [],
  availableCurrencies: [],
  transactions: [],
  integrations: [],
  totalPages: 0,
  currentPage: 0,
  companyCurrency: null
};

const getPreparedTransactions = ({ action, state }) =>
  action.transactions
    ? action.transactions.map(data => helper.prepareCompanyTransactions(data, state))
    : [];

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case companyConstants.LOADING_COMPANY_INFO:
      return { ...state, isCompanyInfoLoaded: action.loaded };

    case companyConstants.SET_COMPANY_INFO:
      const companyId = action.company.company_id;
      const companyName = action.company.company_name;
      const companyLegalName = action.company.company_legal_name;
      const hq = action.company.HQ;
      const companyAddress = helper.prepareCompanyAddress(action.company.address);

      const selectedBillingPeriod = helper.prepareBillingPeriod(
        action.company.billing,
        action.company.company_wallet_billing,
        action.company.company_credit_billing
      );

      const updatedCompanyState = {
        ...state,
        companyId,
        companyLegalName,
        companyName,
        companyAddress,
        selectedBillingPeriod,
        hq,
        companyWalletBilling: action.company.company_wallet_billing || null,
        companyCurrency: action.company.company_currency
      };

      const supportedCurrenciesIds = action.company.supported_currencies;
      if (state.currencies.length) {
        updatedCompanyState.supportedCurrenciesIds = supportedCurrenciesIds;
        updatedCompanyState.availableCurrencies = getAvailableCurrencies(
          state.currencies,
          supportedCurrenciesIds
        );
        updatedCompanyState.defaultCurrency = getDefaultCurrency(state.currencies);
      } else {
        updatedCompanyState.supportedCurrenciesIds = supportedCurrenciesIds;
      }

      return { ...updatedCompanyState };

    case companyConstants.SET_CURRENCIES:
      const preparedCurrencies = prepareCurrencies(action.currencies);
      if (state.supportedCurrenciesIds.length) {
        return {
          ...state,
          currencies: preparedCurrencies,
          availableCurrencies: getAvailableCurrencies(
            preparedCurrencies,
            state.supportedCurrenciesIds
          ),
          defaultCurrency: getDefaultCurrency(preparedCurrencies)
        };
      } else {
        return { ...state, currencies: preparedCurrencies };
      }

    case companyConstants.SET_BILLING_PERIOD:
      return {
        ...state,
        selectedBillingPeriod: helper.prepareBillingPeriod(action.period)
      };

    case companyConstants.SET_BILLING_PERIODS:
      const period = action.periods[action.periods.length - 1];
      let currentBillingPeriod = helper.prepareBillingPeriod(period);

      return {
        ...state,
        billingPeriods: helper.prepareBillingPeriods(action.periods),
        currentBillingPeriod
      };

    case companyConstants.SET_DEPARTMENTS:
      const departments = action.departments.map(department => ({
        id: department.department_id,
        label: department.name
      }));
      return { ...state, ...{ departments } };

    case companyConstants.CREATE_DEPARTMENT:
      let updatedDepartments = state.departments.slice();
      updatedDepartments.push({
        id: action.id,
        label: action.name
      });
      return { ...state, departments: updatedDepartments };

    case companyConstants.SET_ADMINS:
      return { ...state, admins: helper.prepareAdmins(action.admins) };

    case companyConstants.SET_SUPER_ADMINS:
      return {
        ...state,
        superAdmins: helper.prepareSuperAdmins(action.superAdmins)
      };

    case companyConstants.SET_BILLING_CONTACTS:
      return {
        ...state,
        billingContacts: helper.prepareBillingContacts(action.contacts)
      };

    case companyConstants.ADD_BILLING_CONTACT:
      let newBillingContacts = state.billingContacts.slice();
      newBillingContacts.push({
        email: action.email,
        id: action.id
      });
      return {
        ...state,
        billingContacts: newBillingContacts
      };

    case companyConstants.DELETE_BILLING_CONTACT:
      const updatedBillingContacts = state.billingContacts.filter(
        element => element.id !== action.id
      );
      return {
        ...state,
        billingContacts: updatedBillingContacts
      };

    case companyConstants.SET_COMPANY_TRANSACTIONS:
      const { setNewTransactionsList, totalPages, currentPage } = action;
      const actionTransactions = getPreparedTransactions({ action, state });

      const transactionsList = setNewTransactionsList
        ? actionTransactions
        : state.transactions.concat(actionTransactions);

      return {
        ...state,
        transactions: transactionsList,
        totalPages,
        currentPage
      };

    case companyConstants.SET_SYSTEM_INTEGRATIONS:
      return {
        ...state,
        integrations: action.integrations
      };

    default:
      return state;
  }
}
