import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { useClickOutsideElement } from '../../../hooks';

import triangleFilled from '../../../assets/images/triangle-filled.svg';

import './index.scss';

const SortBy = ({ sortOptions, sortFunc, sortTitle, localSort, isLocalSort, defaultSortIndex }) => {
  const [isSortSuggestionsVisible, setSortSuggestionsVisibility] = useState(false);
  const [activeSortIndex, setActiveSortIndex] = useState(defaultSortIndex);
  const { t } = useTranslation('translation');

  const handleClick = async index => {
    await setActiveSortIndex(index);
    !isLocalSort ? sortFunc(index) : localSort(index);
  };

  const SortSection = () => (
    <>
      <span>{sortTitle}</span>
      <span className="selected-option">{t(sortOptions[activeSortIndex.toString()])}</span>
      <img src={triangleFilled} alt="" />
    </>
  );

  const SortSuggestions = () => {
    const dropdown = useRef(null);
    useClickOutsideElement(dropdown, () => setSortSuggestionsVisibility(false));

    return isSortSuggestionsVisible ? (
      <div className="sort-suggestions" ref={dropdown}>
        {Object.entries(sortOptions).map(([idx, option]) => (
          <span
            key={idx}
            className={classnames({ active: activeSortIndex === +idx })}
            onClick={() => handleClick(+idx)}
          >
            {t(option)}
          </span>
        ))}
      </div>
    ) : null;
  };

  return (
    <div
      className={classnames('sort-by', {
        active: isSortSuggestionsVisible
      })}
      onClick={() => setSortSuggestionsVisibility(!isSortSuggestionsVisible)}
    >
      <SortSection />
      <SortSuggestions />
    </div>
  );
};

export default SortBy;

SortBy.propTypes = {
  sortOptions: PropTypes.object.isRequired,
  sortFunc: PropTypes.func.isRequired,
  sortTitle: PropTypes.string.isRequired
};
