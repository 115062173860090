import { useDispatch } from 'react-redux';

import { createTaxPayer, checkTaxPayerStatus } from '../../../services';

import { satConstants } from '../../../constants/sat.constants';

const {
  SAT_STATUSES: { VALID, INVALID, ERROR }
} = satConstants;

export const useSATform = isDisplayedInModal => {
  const dispatch = useDispatch();

  const checkSatStatus = async satId => {
    const { status, rfc } = await checkTaxPayerStatus(satId);

    const isSuccess = status === VALID;

    if (status === INVALID || status === ERROR) throw { message: 'error.rfcPassword' };
    if (!isSuccess) return checkSatStatus(satId);

    isDisplayedInModal &&
      dispatch({
        type: satConstants.IS_SAT_CONNECTED,
        payload: isSuccess
      });

    return { rfc };
  };

  const connectToSat = async credentials => {
    try {
      const taxPayer = await createTaxPayer(credentials);
      await checkSatStatus(taxPayer?.id);
    } catch (error) {
      throw error;
    }
  };

  return { connectToSat };
};

export const useSATvalidators = () => {
  return { handleRfcValidation };
};
