import { NavLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Divider, RoundedButton } from '../index';

import logo from '../../../assets/images/logo.svg';

import { INDEX_ROUTE } from '../../../constants/routes.constants';

import './index.scss';

const PaymentConfirmation = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = () => history.push(INDEX_ROUTE);

  return (
    <div className="payment-confirmation">
      <header className="payment-confirmation__header">
        <NavLink to={'/'}>
          <img className="header__logo" src={logo} alt="" />
        </NavLink>
      </header>
      <div className="payment-confirmation-body">
        <span className="payment-confirmation-body__message">
          {t('paymentConfirmation.message')}
        </span>
        <Divider />
        <div className="payment-confirmation-body__button-container">
          <RoundedButton children={t('paymentConfirmation.buttonLabel')} onClick={handleClick} />
        </div>
      </div>
    </div>
  );
};

export default PaymentConfirmation;
