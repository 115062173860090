import * as COUNTRIES_DATA from '../assets/json/countries';

export const COUNTRIES = COUNTRIES_DATA.default.map(country => ({
  name: country[0],
  alpha2Code: country[1],
  mobileCountryCode: country[2]
}));

export const PREFERRED_COUNTRIES_CODES = ['ae', 'sg', 'mx'];

export const getCountriesSortedByPreference = () => {
  let countries = COUNTRIES.slice();
  let sortedArray = [];
  for (let i = 0; i < countries.length; i++) {
    for (let y = 0; y < PREFERRED_COUNTRIES_CODES.length; y++) {
      if (countries[i].alpha2Code === PREFERRED_COUNTRIES_CODES[y]) {
        sortedArray.push(countries[i]);
        countries.splice(i, 1);
        i--;
      }
    }
  }
  return sortedArray.concat(countries);
};

export const getCountryByAlpha2Code = code => {
  return COUNTRIES.find(country => {
    return country.alpha2Code === code;
  });
};

export const getCountryByNumericCode = code => {
  return COUNTRIES.find(country => country.mobileCountryCode === code.toString() || null);
};
