import axios from 'axios';
import { API_URL } from './../constants/common.constants';
import { handleRequestError } from './handlers/error-handlers';
import { getDeviceId } from '../utils/auth-client';
import { getAuthorizationForRequest } from '../utils/network-util';

export const reportsService = {
  getReportsUsers,
  getTransactionsByDates
};

function getReportsUsers(from = '10/01/2019', to = '11/30/2019') {
  let params = {
    device_id: getDeviceId()
  };
  if (from && to) {
    params['from'] = from;
    params['to'] = to;
  }
  return axios
    .get(`${API_URL}/users`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: getAuthorizationForRequest()
      },
      params
    })
    .catch(handleRequestError);
}

function getTransactionsByDates(startAt, endAt) {
  let params = {
    device_id: getDeviceId(),
    start_at: '',
    end_at: ''
  };
  if (startAt && endAt) {
    params['start_at'] = startAt;
    params['end_at'] = endAt;
  }
  return axios
    .get(`${API_URL}/api/reports/company-transaction`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: getAuthorizationForRequest()
      },
      params
    })
    .catch(handleRequestError);
}
