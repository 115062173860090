import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ScrollableArea } from '../../scrollable-area';
import { ReactComponent as SearchIcon } from './../../../assets/images/search-icon.svg';

import { useClickOutsideElement } from '../../../hooks';
import { useSearchAutocomplete } from './hooks';

import './index.scss';

const SearchAutocomplete = ({ suggestions, onClick: callback, handleChange }) => {
  const {
    isSuggestionListVisible,
    activeSuggestion,
    filteredSuggestions,
    onChange,
    onClick,
    userInput,
    suggestionsListRef,
    onClickOutside
  } = useSearchAutocomplete({ suggestions, callback, handleChange });

  useClickOutsideElement(suggestionsListRef, onClickOutside);

  const SuggestionsList = () => (
    <ul className="suggestions" ref={suggestionsListRef}>
      <ScrollableArea autoHide={false} styles={{ width: '100%', maxHeight: 96 }}>
        {filteredSuggestions.map(({ id, label }, index) => (
          <li
            className={classNames({ 'suggestion-active': index === activeSuggestion })}
            key={id}
            onClick={e => onClick(e, id)}
          >
            {label}
          </li>
        ))}
      </ScrollableArea>
    </ul>
  );

  return (
    <div className="search-autocomplete">
      <SearchIcon className="search-icon" />
      <input type="text" onChange={onChange} value={userInput} />
      {isSuggestionListVisible ? <SuggestionsList /> : null}
    </div>
  );
};

export default SearchAutocomplete;

SearchAutocomplete.propTypes = {
  suggestions: PropTypes.instanceOf(Array),
  parentOnClick: PropTypes.func
};
SearchAutocomplete.defaultProps = {
  suggestions: []
};
