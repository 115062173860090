import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { RoundedButton } from '../../../index';

import { TRANSLATION } from '../../../../../constants/locales';

import './index.scss';

const LoadMore = ({ item, getItems }) => {
  const { t } = useTranslation(TRANSLATION);
  const [isLoading, setIsLoading] = useState(false);

  const {
    bulkId,
    pagination: { currentPage, totalPages, itemsPerPage },
    numberOfBulkPayments
  } = item;

  const handleClick = () =>
    getItems({
      id: bulkId,
      page: currentPage + 1,
      setIsLoading
    });

  const visibleItems =
    totalPages === currentPage ? numberOfBulkPayments : currentPage * itemsPerPage;

  const LoadMoreButton = ({ disabled, isLoading, label, handleClick }) => (
    <RoundedButton
      type="bright"
      className="load-more__button"
      onClick={handleClick}
      disabled={disabled}
      isLoading={isLoading}
    >
      {label}
    </RoundedButton>
  );

  const loadMoreInfo = (
    <span className="load-more__info">
      {t('loadMoreInfo', {
        visibleItems,
        totalItems: item.numberOfBulkPayments
      })}
    </span>
  );

  return (
    <div className="load-more">
      <LoadMoreButton
        handleClick={handleClick}
        disabled={isLoading || totalPages === currentPage}
        isLoading={isLoading}
        label={t('loadMore')}
      />
      {loadMoreInfo}
    </div>
  );
};

export default LoadMore;

LoadMore.propTypes = {
  item: PropTypes.object.isRequired,
  getItems: PropTypes.func.isRequired
};
