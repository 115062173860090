const NOTIFICATION_TYPES = {
  ALERT: 'ALERT',
  ADD_USER: 'ADD_USER',
  CREDIT_LIMIT: 'CREDIT_LIMIT',
  REMINDER: 'REMINDER'
};

const ACTION_TYPES = {
  READ: 'READ',
  ACCEPT: 'ACCEPT',
  REJECT: 'REJECT'
};

export default {
  NOTIFICATION_TYPES,
  ACTION_TYPES
};
