import classNames from 'classnames';

import { ReactComponent as Logo } from './../../../assets/images/logo.svg';
import { ReactComponent as Visa } from '../../../assets/images/cards/visa-light.svg';

import { isPhysicalCard } from '../../../utils/cards-util';

import './index.scss';

const SmallCard = ({ cardType, cardName, overlay = null }) => (
  <div
    className={classNames('small-card', {
      virtual: !isPhysicalCard(cardType)
    })}
  >
    <Logo className="card-logo" />
    <span className="card-type">{isPhysicalCard(cardType) ? 'Physical' : 'Virtual'}</span>
    <span className="card-name">{cardName}</span>
    <Visa className="card-brand" />
    {overlay}
  </div>
);

export default SmallCard;
