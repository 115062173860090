import PropTypes from 'prop-types';

const Checkbox = ({ isChecked, handleClick, className }) => (
  <svg
    className={className}
    onClick={handleClick}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.75"
      y="1.87939"
      width="20.5"
      height="20.5"
      rx="3.25"
      stroke="#1A1A1A"
      strokeWidth="1.5"
    />
    {isChecked ? (
      <path
        d="M17.3332 8.66675L9.99984 16.0001L6.6665 12.6667"
        stroke="#1A1A1A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ) : null}
  </svg>
);

export default Checkbox;

Checkbox.propTypes = {
  isChecked: PropTypes.bool,
  handleClick: PropTypes.func,
  className: PropTypes.string.isRequired
};

Checkbox.defaultProps = {
  isChecked: false,
  handleClick: () => {}
};
