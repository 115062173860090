import { useState } from 'react';

import { SendPaymentContext } from '../';

const INITIAL_EMAILS_CONTEXT = {
  payerEmails: null,
  beneficiaryEmails: null,
  isReceiveEmailChecked: false
};

const SendPaymentState = props => {
  const [optionsState, setOptionsState] = useState(null);
  const [bulkDataContext, setBulkDataContext] = useState(null);
  const [fileContext, setFileContext] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [amountFieldData, setAmountFieldData] = useState(null);
  const [sendInvoiceData, setSendInvoiceData] = useState(null);
  const [emailsContext, setEmailsContext] = useState(INITIAL_EMAILS_CONTEXT);
  const [paymentInfo, setPaymentInfo] = useState(null);

  const clearBulkPaymentsContext = () => {
    setOptionsState(null);
    setBulkDataContext(null);
    setFileContext(null);
  };

  const clearSinglePaymentContext = clearPaymentInfo => {
    setEmailsContext(INITIAL_EMAILS_CONTEXT);
    setAmountFieldData(null);
    setPaymentDetails(null);
    clearPaymentInfo && setPaymentInfo(null);
  };

  return (
    <SendPaymentContext.Provider
      value={{
        optionsState,
        setOptionsState,
        bulkDataContext,
        setBulkDataContext,
        fileContext,
        setFileContext,
        clearBulkPaymentsContext,
        paymentDetails,
        setPaymentDetails,
        amountFieldData,
        setAmountFieldData,
        clearSinglePaymentContext,
        sendInvoiceData,
        setSendInvoiceData,
        emailsContext,
        setEmailsContext,
        paymentInfo,
        setPaymentInfo
      }}
    >
      {props.children}
    </SendPaymentContext.Provider>
  );
};

export default SendPaymentState;
