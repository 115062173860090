import PropTypes from 'prop-types';

import { CARD_STATUSES } from '../../../constants/cards.constants';

import { getCardStatusLabel } from '../../../utils/cards-util';

import './index.scss';

const ArchivedCardOverlay = ({ status, t }) => (
  <div className="archived-card-overlay">
    <div
      className={`archived-card-overlay__background ${
        status === CARD_STATUSES.SUSPENDED || status === CARD_STATUSES.EXPIRED ? 'suspended' : ''
      }`.trim()}
    />
    <span className="archived-card-overlay__status">{getCardStatusLabel(status, t)}</span>
  </div>
);

export default ArchivedCardOverlay;

ArchivedCardOverlay.propTypes = {
  status: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};
