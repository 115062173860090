import React from 'react';
import classNames from 'classnames';

import './index.scss';

const Input = props => (
  <div
    className={classNames('group-boxes__simple-input', {
      secondary: props.secondary
    })}
  >
    <input
      autoFocus={true}
      value={props.value}
      onChange={event => props.onTextChange(event.target.value)}
      onKeyPress={event => {
        if (event.key === 'Enter') {
          props.onAdding();
        }
      }}
      onBlur={() => props.onAdding()}
    />
  </div>
);

export default Input;
