import { memo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import RingContent from './content';
import Portal from '../portal';

import { useTooltipContent } from './content/hooks';

import {
  getRadius,
  getNormalizedRadius,
  getCircumference,
  getStrokeDashoffset,
  getDefaultDiameter,
  getDefaultStroke
} from './helper';

import './index.scss';

const ProgressRing = ({
  sideLength,
  borderLength,
  progress,
  secondaryProgress,
  thirdProgress,
  inaccessibleLimitProgress,
  title,
  description,
  subDesc,
  onHoverStateChange,
  currencySymbol,
  creditSpent,
  walletSpent,
  remainingBalance,
  isCreditsTooltipVisible
}) => {
  const { t } = useTranslation();

  const [totalProgress, setTotalProgress] = useState(0);
  const [ringClassModifier, setRingClassModifier] = useState('default');
  const [stroke, setStroke] = useState(0);
  const [diameter, setDiameter] = useState(0);
  const [radius, setRadius] = useState(0);
  const [normalizedRadius, setNormalizedRadius] = useState(0);
  const [circumference, setCircumference] = useState(0);
  const [prevPrevStrokeDashoffset, setPrevPrevStrokeDashoffset] = useState(0);
  const [prevStrokeDashoffset, setPrevStrokeDashoffset] = useState(0);
  const [strokeDashoffset, setStrokeDashoffset] = useState(0);
  const [secondaryStrokeDashoffset, setSecondaryStrokeDashoffset] = useState(0);
  const [popupCoords, setPopupCoords] = useState({
    left: null,
    top: null
  });
  const [isSpentTooltipVisible, setIsSpentTooltipVisible] = useState(false);

  const ringRef = useRef(null);

  useEffect(() => {
    // set default diameter and stroke based on a parent dimensions,
    // if diameter and stroke are not provided with props

    const initialDiameter =
      sideLength || getDefaultDiameter(ringRef.current.clientWidth, ringRef.current.clientHeight);
    setDiameter(initialDiameter);
    setRingClassModifier('');

    if (!borderLength) {
      setStroke(getDefaultStroke(initialDiameter));
    }
  }, []);

  useEffect(() => {
    let total = progress >= 0 ? progress : 0;
    total += secondaryProgress >= 0 ? secondaryProgress : 0;
    total += thirdProgress >= 0 ? thirdProgress : 0;
    setTotalProgress(total);
  }, [progress, secondaryProgress, thirdProgress]);

  useEffect(() => {
    if (sideLength) setDiameter(sideLength);
  }, [sideLength]);

  useEffect(() => {
    if (borderLength) setStroke(borderLength);
  }, [borderLength]);

  useEffect(() => {
    setRadius(getRadius(diameter));
  }, [diameter]);

  useEffect(() => {
    const R = radius < stroke ? stroke : radius;
    setNormalizedRadius(getNormalizedRadius(R, stroke));
  }, [radius, stroke]);

  useEffect(() => {
    setCircumference(getCircumference(normalizedRadius));
  }, [normalizedRadius]);

  useEffect(() => {
    setPrevPrevStrokeDashoffset(getStrokeDashoffset(circumference, inaccessibleLimitProgress));
  }, [circumference, inaccessibleLimitProgress]);

  useEffect(() => {
    setPrevStrokeDashoffset(getStrokeDashoffset(circumference, totalProgress));
  }, [circumference, totalProgress]);

  useEffect(() => {
    setStrokeDashoffset(getStrokeDashoffset(circumference, secondaryProgress + progress));
  }, [circumference, secondaryProgress, progress]);

  useEffect(() => {
    setSecondaryStrokeDashoffset(getStrokeDashoffset(circumference, progress));
  }, [circumference, progress]);

  useEffect(() => {
    setIsSpentTooltipVisible(
      popupCoords.left !== null && popupCoords.top !== null && !isCreditsTooltipVisible
    );
  }, [popupCoords, isCreditsTooltipVisible]);

  const handleHoverOut = ({ relatedTarget }) => {
    const tooltipContent = 'spent-resources__tooltip--content';

    if (relatedTarget.className === tooltipContent) return;

    setPopupCoords(prev => ({
      ...prev,
      left: null,
      top: null
    }));
  };

  const handleMove = ({ clientX, clientY }) =>
    setPopupCoords(prev => ({
      ...prev,
      left: clientX,
      top: clientY
    }));

  return (
    <div
      ref={ringRef}
      className={`progress-ring ${ringClassModifier}`}
      style={ringClassModifier ? null : { width: diameter, height: diameter }}
      onMouseLeave={handleHoverOut}
      onMouseMove={handleMove}
    >
      {diameter ? (
        <>
          <RingContent
            diameter={diameter}
            stroke={stroke}
            circumference={circumference}
            prevPrevStrokeDashoffset={prevPrevStrokeDashoffset}
            prevStrokeDashoffset={prevStrokeDashoffset}
            strokeDashoffset={strokeDashoffset}
            secondaryStrokeDashoffset={secondaryStrokeDashoffset}
            radius={radius}
            normalizedRadius={normalizedRadius}
            title={title}
            description={description}
            subDesc={subDesc}
            onHoverStateChange={onHoverStateChange}
            currencySymbol={currencySymbol}
            creditSpent={creditSpent}
            walletSpent={walletSpent}
            remainingBalance={remainingBalance}
          />
        </>
      ) : null}
      {isSpentTooltipVisible && (
        <Portal
          component={SpentDescriptionTooltip}
          nameOfClass="resource-dashboard-js"
          creditSpent={creditSpent}
          walletSpent={walletSpent}
          currencySymbol={currencySymbol}
          remainingBalance={remainingBalance}
          handleHoverOut={handleHoverOut}
          style={popupCoords}
          t={t}
        />
      )}
    </div>
  );
};

const SpentDescriptionTooltip = ({ style, handleHoverOut, ...props }) => {
  const { TOOLTIP_DATA } = useTooltipContent(props);

  return (
    <div className="spent-resources__tooltip" style={style}>
      <div className="spent-resources__tooltip--content" onMouseLeave={handleHoverOut}>
        {TOOLTIP_DATA.map(({ markerClass, label, amount }, idx) => (
          <div key={idx} className="resources-description__row">
            <div className={markerClass} />
            <div className="info">
              <span className="info__label">{label}</span>
              <span className="info__value">{amount}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

ProgressRing.propsType = {
  sideLength: PropTypes.number,
  borderLength: PropTypes.number,
  progress: PropTypes.number,
  secondaryProgress: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  subDesc: PropTypes.string,
  onHoverStateChange: PropTypes.func,
  isCreditsTooltipVisible: PropTypes.bool
};

export default memo(ProgressRing);
