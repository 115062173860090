import { memo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as Download } from '../../../assets/images/download.svg';
import Loader from '../loader';

import './index.scss';

const getColumns = (
  t,
  isMerchantAmountNeeded,
  //TODO CP-142 remove isAmountNeeded prop after feature will be done
  isAmountNeeded,
  equivalentAmountHeader,
  isUsEquivalentNeeded,
  isLocalCurrencyEquivalentNeeded,
  defaultCurrencyCode,
  localCurrencyCode
) => {
  const columns = [
    t('reports.transactions.table.header.card'),
    t('reports.transactions.table.header.date'),
    t('reports.transactions.table.header.description'),
    t('reports.transactions.table.header.category'),
    t('reports.transactions.table.header.country'),
    t('reports.transactions.table.header.merchantName'),
    t('reports.transactions.table.header.user'),
    t('reports.transactions.table.header.department'),
    t('reports.transactions.table.header.last4'),
    t('reports.transactions.table.header.tags'),
    t('reports.transactions.table.header.type'),
    t('cardDetails.transactions.columns.header.status')
  ];
  if (isMerchantAmountNeeded) {
    columns.push(t('reports.transactions.table.header.merchantCurrency'));
    columns.push(t('reports.transactions.table.header.merchantAmount'));
  }
  if (isLocalCurrencyEquivalentNeeded) {
    localCurrencyCode && localCurrencyCode !== defaultCurrencyCode
      ? columns.push(defaultCurrencyCode, localCurrencyCode)
      : columns.push(localCurrencyCode);
  } else {
    //TODO CP-142 remove condition after feature will be done
    if (isAmountNeeded) {
      columns.push(t('reports.transactions.table.header.currency'));
      columns.push(t('reports.transactions.table.header.amount'));
    }
  }
  if (equivalentAmountHeader) {
    columns.push(equivalentAmountHeader);
  }
  if (isUsEquivalentNeeded) {
    columns.push(t('reports.transactions.table.header.usAmount'));
  }

  return columns;
};

const makeDataForDownload = (
  transactions,
  isMerchantAmountNeeded,
  //TODO CP-142 remove isAmountNeeded prop after feature will be done
  isAmountNeeded,
  equivalentAmountHeader,
  isUsEquivalentNeeded,
  isLocalCurrencyEquivalentNeeded
) =>
  transactions.map(transaction => {
    const transactionRow = [
      transaction.card.name,
      moment(transaction.transaction.createdAt).format('MMM D YYYY') || '-',
      transaction.transaction.description,
      transaction.merchant.category,
      transaction.merchant.country,
      transaction.merchant.name,
      transaction.user.name,
      transaction.user.department,
      transaction.card.last4,
      formattedTags(transaction.card.tags),
      transaction.transaction.type || '-',
      transaction.transaction.state
    ];

    if (isMerchantAmountNeeded) {
      transactionRow.push(formattedCurrency(transaction.merchant));
      transactionRow.push(formattedAmount(transaction.merchant));
    }
    if (isLocalCurrencyEquivalentNeeded) {
      transaction.transaction.cardCurrency?.code &&
      transaction.transaction.cardCurrency.code !== transaction.transaction.currency.code
        ? transactionRow.push(
            transaction.transaction.amount.toString(),
            transaction.transaction.cardAmount.toString()
          )
        : transactionRow.push(transaction.transaction.amount.toString());
    } else {
      //TODO CP-142 remove condition after feature will be done
      if (isAmountNeeded) {
        transactionRow.push(formattedCurrency(transaction.transaction));
        transactionRow.push(formattedAmount(transaction.transaction));
      }
    }
    if (equivalentAmountHeader) {
      transactionRow.push(transaction.transaction.hqAmount.toString());
    }
    if (isUsEquivalentNeeded) {
      transactionRow.push(transaction.transaction.usdEquivalent.toString());
    }

    return transactionRow;
  });

const formattedTags = tags => (tags.length ? tags.join(' ') : '-');

const formattedCurrency = ({ currency }) =>
  (typeof currency === 'string' ? currency : currency.code).toUpperCase();

const formattedAmount = transaction => {
  let value = '-';
  if (transaction.hasOwnProperty('amount')) {
    value = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    })
      .format(transaction.amount)
      .replace('$', '')
      .replace(',', '');
  }
  return value;
};

const downloadTransactions = (
  event,
  transactions,
  t,
  isMerchantAmountNeeded,
  //TODO CP-142 remove isAmountNeeded prop after feature will be done
  isAmountNeeded,
  isLocalCurrencyEquivalentNeeded,
  equivalentAmountHeader,
  isUsEquivalentNeeded,
  defaultCurrencyCode,
  localCurrencyCode
) => {
  event.preventDefault();
  const data = makeDataForDownload(
    transactions,
    isMerchantAmountNeeded,
    //TODO CP-142 remove isAmountNeeded prop after feature will be done
    isAmountNeeded,
    equivalentAmountHeader,
    isUsEquivalentNeeded,
    isLocalCurrencyEquivalentNeeded
  );

  let file = getColumns(
    t,
    isMerchantAmountNeeded,
    //TODO CP-142 remove isAmountNeeded prop after feature will be done
    isAmountNeeded,
    equivalentAmountHeader,
    isUsEquivalentNeeded,
    isLocalCurrencyEquivalentNeeded,
    defaultCurrencyCode,
    localCurrencyCode
  )
    .join(',')
    .concat('\n');
  data.forEach(row => {
    row = row.map(cell => {
      if (cell?.replace(/ /g, '').match(/[\s,"]/)) {
        return '"' + cell.replace(/"/g, '""') + '"';
      }
      return cell;
    });
    file = file.concat('', row.join(',').concat('\n'));
  });

  const universalBOM = '\uFEFF';
  const blob = new Blob([universalBOM, file], {
    type: 'data:text/csv;charset=utf-8'
  });
  let hiddenElement = document.createElement('a');
  hiddenElement.href = URL.createObjectURL(blob);
  hiddenElement.target = '_blank';
  hiddenElement.download = `${t('download.labels.transactions')}.csv`;
  hiddenElement.click();
  return new Promise(resolve => resolve());
};

const DownloadTransactions = ({
  transactions,
  onDownloadClick,
  isMerchantAmountNeeded,
  //TODO CP-142 remove isAmountNeeded prop after feature will be done
  isAmountNeeded,
  isLocalCurrencyEquivalentNeeded,
  isUsEquivalentNeeded,
  equivalentAmountHeader,
  downloadFromServer,
  areTransactionsLoading
}) => {
  const { t } = useTranslation();
  const { defaultCurrency } = useSelector(({ company }) => company);
  const { card } = useSelector(({ cards }) => cards);

  const localCurrencyCode = card?.currency?.code;
  const defaultCurrencyCode = defaultCurrency.code;

  const downloadTransactionsDocument = event =>
    downloadTransactions(
      event,
      transactions,
      t,
      isMerchantAmountNeeded,
      //TODO CP-142 remove isAmountNeeded prop after feature will be done
      isAmountNeeded,
      isLocalCurrencyEquivalentNeeded,
      equivalentAmountHeader,
      isUsEquivalentNeeded,
      defaultCurrencyCode,
      localCurrencyCode
    );

  return transactions.length || downloadFromServer ? (
    <div className="download-transactions-wrapper">
      <div
        className={classNames('download-transactions', {
          pending: areTransactionsLoading
        })}
        onClick={event => {
          if (areTransactionsLoading) return;

          downloadFromServer
            ? onDownloadClick()
            : downloadTransactionsDocument(event).then(() => onDownloadClick && onDownloadClick());
        }}
      >
        {areTransactionsLoading ? (
          <Loader small />
        ) : (
          <Download className="download-transactions--icon" />
        )}
        <span>{t('charts.transactions.download')}</span>
      </div>
    </div>
  ) : null;
};

export default memo(DownloadTransactions);
