import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { getAmount } from '../../../utils/currency-util';

import './index.scss';

const AvailableBox = ({ total, big, medium, small, value, available, currencySymbol }) => {
  const { t } = useTranslation();

  const boxTitle = total ? 'available.total' : 'available.available';

  return (
    <div
      className={classNames('available-box', {
        total: total,
        'available-box--big': big,
        'available-box--medium': medium,
        small: small
      })}
    >
      <span className="available-box__total">{t(boxTitle)}</span>

      {value !== undefined && available ? (
        <span className="available-box__value">{getAmount(currencySymbol, value)}</span>
      ) : (
        <span>- -</span>
      )}
    </div>
  );
};

export default AvailableBox;

AvailableBox.propTypes = {
  total: PropTypes.bool,
  big: PropTypes.bool,
  medium: PropTypes.bool,
  small: PropTypes.bool,
  value: PropTypes.number.isRequired,
  available: PropTypes.bool,
  currencySymbol: PropTypes.string
};

AvailableBox.defaultProps = {
  total: false,
  big: false,
  medium: false,
  small: false,
  available: true,
  currencySymbol: '$'
};
