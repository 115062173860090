export const notificationBannerConstants = {
  OVERDUE_PARTIALLY_SUSPENDED: 'overdue_partially_suspended',
  OVERDUE_FULLY_SUSPENDED: 'overdue_fully_suspended',
  OVERDUE_EXCEPTION: 'overdue_exception',
  DUE_TOMORROW: 'due_tomorrow',
  PRODUCT_BANNER: 'product_banner',
  POLICY_BANNER: 'Latefeepolicy_banner',

  ACTION_TYPES: {
    CANCELED: 'Canceled',
    READ_NOW: 'Read Now'
  }
};
