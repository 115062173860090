import { useState, useEffect, memo, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { amplitudeInstance } from '../../../analytics/amplitude';

import { ScrollableArea } from '../../scrollable-area';
import triangle from './../../../assets/images/triangle.svg';

import { useTargetDevice } from '../../../hooks';

import PrevNext from '../prev-next';
import { companyActions } from '../../../actions/company.action';
import { useClickOutsideElement } from '../../../hooks';

import {
  CARD_DETAILS_ID_ROUTE,
  CARDS_ROUTE,
  INDEX_ROUTE,
  USERS_ROUTE
} from '../../../constants/routes.constants';

import './index.scss';

const parseBillingDate = (date, language) => moment(date).locale(language).format('MMMM YYYY');

const BillingHeader = ({
  selectedBillingPeriod,
  billingPeriods,
  isCompanyInfoLoaded,
  availablePeriods,
  amplitudeKey,
  ...props
}) => {
  const {
    t,
    i18n: { language }
  } = useTranslation();

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [nextPeriod, setNextPeriod] = useState(null);
  const [prevPeriod, setPrevPeriod] = useState(null);

  useEffect(() => {
    const selectedIndex = billingPeriods.findIndex(
      period => period.id === selectedBillingPeriod.id
    );

    if (availablePeriods !== undefined) {
      const availableIds = availablePeriods ? availablePeriods.map(p => p.company_billing_id) : [];
      billingPeriods.forEach(period => {
        const ind = availableIds.findIndex(d => d === period.id);
        period.disabled = ind === -1;
      });
    } else {
      billingPeriods.forEach(period => (period.disabled = false));
    }

    setNextPeriod(getNextPeriod(selectedIndex, billingPeriods));
    setPrevPeriod(getPrevPeriod(selectedIndex, billingPeriods));
    return () => {
      setLoading(false);
    };
  }, [selectedBillingPeriod, availablePeriods]);

  const billingInfo = useRef();

  const { isTargetDevice } = useTargetDevice();

  const getNextPeriod = (selectedIndex, billingPeriods) => {
    if (selectedIndex < billingPeriods.length - 1 && !isLoading) {
      const nextPeriod = billingPeriods[selectedIndex + 1];
      return nextPeriod.disabled ? null : nextPeriod;
    }

    return null;
  };

  const getPrevPeriod = (selectedIndex, billingPeriods) => {
    if (selectedIndex > 0 && !isLoading) {
      const prevPeriod = billingPeriods[selectedIndex - 1];
      return prevPeriod.disabled ? null : prevPeriod;
    }

    return null;
  };

  const getAmplitudeBillingParameter = amplitudeKey =>
    ({
      [INDEX_ROUTE]: 'Dashboard',
      [CARDS_ROUTE]: 'Cards',
      [CARD_DETAILS_ID_ROUTE]: 'Card details',
      [USERS_ROUTE]: 'Users'
    }[amplitudeKey]);

  const selectPeriod = period => {
    if (isDropdownVisible) setIsDropdownVisible(false);
    if (period) {
      setLoading(true);
      props.dispatch(companyActions.selectBillingPeriod(period));

      amplitudeInstance.logEvent('Billing_Period_Button_Clicked');
    }
  };

  const closeBillingInfoDropdown = () => setIsDropdownVisible(false);
  useClickOutsideElement(billingInfo, closeBillingInfoDropdown);

  return (
    <>
      <div className="billing-header">
        <div className="billing-header__column">
          {selectedBillingPeriod.id && selectedBillingPeriod.date ? (
            <>
              {billingPeriods.length ? (
                <PrevNext
                  nextDisabled={nextPeriod === null}
                  prevDisabled={prevPeriod === null}
                  onPrevClick={() => selectPeriod(prevPeriod)}
                  onNextClick={() => selectPeriod(nextPeriod)}
                />
              ) : null}
              <div className="billing-info">
                <div className="billing-info__label">
                  <span>{t('billingHeader.title')}</span>
                </div>
                <div
                  className="billing-info__date"
                  ref={billingInfo}
                  onClick={() => {
                    if (isCompanyInfoLoaded) {
                      setIsDropdownVisible(!isDropdownVisible);
                    }
                  }}
                >
                  <span>{parseBillingDate(selectedBillingPeriod.date, language)}</span>
                  <img src={triangle} alt="" className={isDropdownVisible ? 'active' : ''} />
                </div>
                {billingPeriods.length && isDropdownVisible && !isLoading ? (
                  <div
                    className={classNames('dropdown', {
                      'common-scroll': !isTargetDevice
                    })}
                  >
                    <ScrollableArea styles={{ width: '100%', maxHeight: 300, minHeight: 50 }}>
                      {billingPeriods.map((period, index) => {
                        const isPeriodSelected = selectedBillingPeriod.id === period.id;
                        return (
                          <span
                            key={index}
                            className={`item 
                            ${isPeriodSelected ? 'selected' : ''}
                            ${period.disabled ? 'disabled' : ''}
                          `}
                            onClick={e => {
                              e.preventDefault();
                              if (!isLoading) {
                                if (!isPeriodSelected && !period.disabled) {
                                  setLoading(true);
                                  props.dispatch(companyActions.selectBillingPeriod(period));

                                  amplitudeInstance.logEvent('Billing_Period_Selected', {
                                    source: getAmplitudeBillingParameter(amplitudeKey)
                                  });
                                }
                                setIsDropdownVisible(!isDropdownVisible);
                              }
                            }}
                          >
                            {parseBillingDate(period.start_date, language)}
                          </span>
                        );
                      })}
                    </ScrollableArea>
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  let { selectedBillingPeriod, billingPeriods, isCompanyInfoLoaded } = state.company;

  return {
    selectedBillingPeriod,
    billingPeriods,
    isCompanyInfoLoaded
  };
};

export default memo(connect(mapStateToProps)(BillingHeader));
