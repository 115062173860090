import AsyncSelect from 'react-select/async/dist/react-select.esm';
import PropTypes from 'prop-types';

import { InputInfoContent, Label } from '../';
import { ScrollableArea } from '../../scrollable-area';

import { useTargetDevice } from '../../../hooks';

import colors from '../../../styles/_colors.module.scss';

const CustomMenu = props => {
  const { isTargetDevice } = useTargetDevice();

  return (
    <>
      {isTargetDevice ? (
        <ScrollableArea
          styles={{
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            maxHeight: '200px',
            boxSizing: 'border-box'
          }}
        >
          {props.children}
        </ScrollableArea>
      ) : (
        <div
          style={{
            maxHeight: '200px',
            overflowY: 'auto'
          }}
        >
          {props.children}
        </div>
      )}
    </>
  );
};

const getStyles = ({ touched, error, singleValueStyles, withInputOpacity, disabled }) => {
  const isValidate = true;
  const isDisabled = false;

  const border =
    isValidate && !isDisabled
      ? `1px solid ${!touched ? colors.bg100 : error ? colors.error600 : colors.bg100}`
      : `1px solid ${colors.bg100}`;

  return {
    control: provided => ({
      ...provided,
      border,
      backgroundColor: colors.bg100,
      borderRadius: '5px',
      opacity: withInputOpacity && disabled ? 0.6 : 1,
      boxShadow: 'none',
      // margin: '8px 0',

      '&:hover': {
        border
      },

      ' > div': {
        minHeight: 41,
        paddingLeft: '15px'
      }
    }),
    input: provided => ({
      ...provided,
      boxSizing: 'border-box',
      margin: 0,
      height: 20,
      fontFamily: 'PublicSansSemiBold',
      fontSize: 14,
      color: colors.text800,
      marginBottom: 4
    }),
    singleValue: provided => ({
      ...provided,
      color: colors.text800,
      position: 'static',
      transform: 'none',
      lineHeight: '16px',
      fontFamily: 'PublicSansSemiBold',
      marginLeft: 0,
      ...singleValueStyles
    }),
    placeholder: provided => ({
      ...provided,
      width: '93%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 14,
      whiteSpace: 'nowrap',
      fontFamily: 'PublicSansSemiBold'
    }),
    menuList: provided => ({
      ...provided,
      padding: '10px',
      fontSize: 12,

      '[class$="Group"]:first-of-type': {
        paddingTop: '0px',
        borderBottom: `1px dotted ${colors.bg500}`
      }
    }),
    dropdownIndicator: provided => {
      return {
        ...provided,

        '&:hover': {
          'svg path': {
            stroke: colors.text600
          }
        }
      };
    },
    option: (provided, { isSelected }) => {
      const styles = {
        ...provided,
        width: 'auto',
        padding: '8px 8px',

        '&:hover': {
          cursor: 'pointer'
        }
      };

      if (isSelected) {
        styles.backgroundColor = 'rgba(0, 5, 132)';
      }

      return styles;
    }
  };
};

const components = {
  ClearIndicator: null,
  IndicatorSeparator: null,
  DropdownIndicator: null
};

const CustomAsyncSelect = ({
  handleChange,
  handleLoadOptions,
  label,
  value = { label: 'MOCk_label', value: 'MOCK_value' },
  styles,
  placeholder,
  isDisabled,
  keyProp,
  singleValueStyles,
  withInputOpacity,
  infoContent,
  className
}) => {
  keyProp = keyProp ? { key: keyProp } : null;

  return (
    <div className={className} style={styles}>
      {label ? <Label label={label} className="input-label" disabled={isDisabled} /> : null}
      {infoContent ? (
        <InputInfoContent infoContent={infoContent} />
      ) : (
        <AsyncSelect
          {...keyProp}
          captureMenuScroll={false}
          isValidate
          hideIndicator
          isClearable
          isDisabled={isDisabled}
          styles={getStyles({ singleValueStyles, withInputOpacity, disabled: isDisabled })}
          loadOptions={handleLoadOptions}
          defaultOptions
          loadingMessage={() => null}
          noOptionsMessage={() => null}
          value={value}
          placeholder={placeholder}
          components={{
            MenuList: props => CustomMenu(props),
            ...components
          }}
          onChange={handleChange}
        />
      )}
    </div>
  );
};

export default CustomAsyncSelect;

CustomAsyncSelect.propTypes = {
  handleChange: PropTypes.func,
  handleLoadOptions: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.object,
  styles: PropTypes.object,
  placeholder: PropTypes.string,
  singleValueStyles: PropTypes.object
};

CustomAsyncSelect.defaultProps = {
  label: null,
  styles: null,
  placeholder: '',
  singleValueStyles: null
};
