const SET_CREDITS_TYPES = 'SET_CREDITS_TYPES';
const SET_STATEMENT_STATUSES = 'SET_STATEMENT_STATUSES';
const SET_ATTACHMENTS_TYPES = 'SET_ATTACHMENTS_TYPES';
const SET_CURRENT_STATEMENT = 'SET_CURRENT_STATEMENT';
const SET_STATEMENTS = 'SET_STATEMENTS';

const EXPORT_MENU_ITEM_TYPES = {
  INVOICE: 1,
  TRANSACTIONS: 2,
  STATEMENTS: 3
};

export const statementsConstants = {
  SET_CREDITS_TYPES,
  SET_STATEMENT_STATUSES,
  SET_ATTACHMENTS_TYPES,
  SET_CURRENT_STATEMENT,
  SET_STATEMENTS,
  EXPORT_MENU_ITEM_TYPES
};
