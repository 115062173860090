import { useState } from 'react';
import PropTypes from 'prop-types';

import { Loader } from '../../../../components/shared';
import { ReactComponent as DownloadFileIcon } from '../../../../assets/images/file-download.svg';

const DownloadFile = ({ handleDetailsDownload, transactionId }) => {
  const [isFileDownloading, setIsFileDownloading] = useState(false);

  return isFileDownloading ? (
    <Loader
      small
      style={{
        top: 2,
        right: 1
      }}
    />
  ) : (
    <DownloadFileIcon
      onClick={() => {
        setIsFileDownloading(true);
        handleDetailsDownload(transactionId, setIsFileDownloading);
      }}
    />
  );
};

export default DownloadFile;

DownloadFile.propTypes = {
  handleDetailsDownload: PropTypes.func,
  transactionId: PropTypes.string
};

DownloadFile.defaultProps = {
  handleDetailsDownload: () => {}
};
