import axios from 'axios';

import { API_URL, API_URL_V2 } from '../constants/common.constants';
import { handleRequestError } from './handlers/error-handlers';
import { getDeviceId } from '../utils/auth-client';
import { _DEFAULT_CONFIG } from './config';

export const integrationsService = {
  getSystemIntegrations,
  getIntegrations,
  postIntegrations,
  exportIntegrations
};

async function getSystemIntegrations() {
  const deviceId = await getDeviceId();
  const params = {
    device_id: deviceId
  };
  return axios
    .get(`${API_URL}/integrations/get-system-integrations`, {
      ...(await _DEFAULT_CONFIG()),
      params
    })
    .catch(handleRequestError);
}

async function getIntegrations(platformName) {
  const params = {
    platformName
  };
  return axios
    .get(`${API_URL_V2}/integrations`, {
      ...(await _DEFAULT_CONFIG()),
      params
    });
}

async function postIntegrations(platformName) {
  const data = {
    platformName
  };
  return axios
    .post(`${API_URL_V2}/integrations`, data, {
      ...(await _DEFAULT_CONFIG())
    });
}

async function exportIntegrations(platformName, fromDate, toDate) {
  const data = {
    platformName,
    fromDate,
    toDate
  };
  return axios
    .post(`${API_URL_V2}/integrations/exports`, data, {
      ...(await _DEFAULT_CONFIG())
    });
}
