import store from '../../store';

import { RESTRICTED_CLABES_LIST, RESTRICTED_RECIPIENTS_LIST } from './constants';
import * as COMPANY_CLABE_NUMBERS from '../../assets/json/company-clabe.json';

/**
 * issue with date format ( one day less )
 */
export const getFormattedDeliveryDate = date => date.replace('T00:00:00+00:00', '');

export const getIsPaymentConceptMXN = () => {
  const { hq: customerHQ } = store.getState().company;
  const requiredLocation = 'Mexico';

  return requiredLocation === customerHQ;
};

export const getIsRecipientRestricted = (value, isClabe, companyId) => {
  if (!value) return;

  let restrictedList = RESTRICTED_RECIPIENTS_LIST;

  if (isClabe) {
    const clabeNumbers = COMPANY_CLABE_NUMBERS.default.find(c => c.companyId === companyId);
    restrictedList = clabeNumbers
      ? [...clabeNumbers?.clabes, ...RESTRICTED_CLABES_LIST]
      : RESTRICTED_CLABES_LIST;
  }

  return restrictedList.some(i => i.toUpperCase() === value.trim().toUpperCase());
};
