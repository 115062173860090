import { statementsConstants } from '../constants/statements.constants';
import {
  prepareCreditsTypes,
  prepareStatementStatuses,
  prepareCurrentStatement,
  prepareStatement,
  attachmentsTypes
} from './helpers/statements';

const INITIAL_STATE = {
  creditsTypes: [],
  statuses: [],
  attachmentsTypes: null,
  currentStatement: null,
  statements: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case statementsConstants.SET_CREDITS_TYPES:
      return {
        ...state,
        creditsTypes: prepareCreditsTypes(action.creditsTypes)
      };
    case statementsConstants.SET_STATEMENT_STATUSES:
      return {
        ...state,
        statuses: prepareStatementStatuses(action.statuses)
      };
    case statementsConstants.SET_ATTACHMENTS_TYPES:
      return {
        ...state,
        attachmentsTypes: attachmentsTypes(action.creditsTypes)
      };
    case statementsConstants.SET_CURRENT_STATEMENT:
      return {
        ...state,
        currentStatement: prepareCurrentStatement(action.statement, state.creditsTypes)
      };
    case statementsConstants.SET_STATEMENTS:
      return {
        ...state,
        statements: action.data.statements.map(statement => {
          const statementCurrency = action.data.currencies.find(
            currency => currency.id === statement.currency_id
          );

          return prepareStatement(
            statement,
            state.creditsTypes,
            state.statuses,
            action.data.currencies,
            // TODO PLAT-548 remove action.data.featureFlag condition after feature is done
            action.data.featureFlag ? statementCurrency : action.data.defaultCurrency,
            action.data.defaultCurrency
          );
        })
      };
    default:
      return state;
  }
}
