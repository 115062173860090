import { ReactComponent as ReloadIcon } from '../../../../assets/images/reupload.svg';
import { ReactComponent as SuccessUploadIcon } from '../../../../assets/images/success-upload.svg';

const OperationIcon = ({ hasResponseErrors, uploadDocument, clientError, ...props }) => (
  <div
    {...props}
    style={{ position: 'relative', width: 48, cursor: hasResponseErrors && 'pointer' }}
  >
    {hasResponseErrors || clientError ? (
      <ReloadIcon onClick={uploadDocument} style={{ cursor: 'pointer' }} />
    ) : (
      <SuccessUploadIcon />
    )}
  </div>
);

export default OperationIcon;
