import { lazy, Suspense, useEffect } from 'react';

import Loader from '../components/shared/loader';
import PrivateRoute from '../components/authorization/private-route-common';

import { INDEX_ROUTE } from '../constants/routes.constants';

import './App.scss';

const loadAuthenticatedApp = () => import('../authenticated-app');
const loadUnAuthenticatedApp = () => import('../containers/authorization/signin');

const AuthenticatedApp = lazy(loadAuthenticatedApp);
const UnAuthenticatedApp = lazy(loadUnAuthenticatedApp);

const App = () => {
  /**
   * pre-load the authenticated side in the background while the user filling out the login form.
   */
  useEffect(() => {
    loadAuthenticatedApp();
  }, []);

  return (
    <Suspense fallback={<Loader centered />}>
      <PrivateRoute
        path={INDEX_ROUTE}
        component={AuthenticatedApp}
        authComponent={UnAuthenticatedApp}
      />
    </Suspense>
  );
};

export default App;
