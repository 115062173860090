import classNames from 'classnames';
import Button from '../button';

import './index.scss';

const PendingCardSubRow = ({ card, isRowInActive, t, onActivationCardEvent }) => {
  const state = card.shippingAddress?.state ? `, ${card.shippingAddress.state}` : '';
  const city = card.shippingAddress?.city ? `, ${card.shippingAddress.city}` : '';
  const postalCode = card.shippingAddress?.postal_code
    ? `, ${card.shippingAddress.postal_code}`
    : '';
  const shippingAddress = card.shippingAddress?.line1
    ? `${card.shippingAddress.line1}${city}${state}${postalCode}`
    : null;

  return (
    <>
      <div
        className={classNames('pending-card-row-info', {
          inactive: isRowInActive
        })}
      >
        <span>{t('cards.physCards.onItsWay')}</span>
        {shippingAddress ? (
          <span>
            {t('cards.physCards.sentTo')} {shippingAddress}
          </span>
        ) : null}
      </div>
      <div>
        {!isRowInActive ? (
          <Button className="button outline" onClick={onActivationCardEvent}>
            {t('card.actions.activate')}
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default PendingCardSubRow;
