import classNames from 'classnames';
import PropTypes from 'prop-types';

import './index.scss';

const NavigationHeader = ({ style, className, children }) => (
  <div className={classNames('navigation-header', className)} style={style}>
    {children}
  </div>
);

export default NavigationHeader;

NavigationHeader.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string
};
