import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';

const AddDepartmentRow = ({ value, onAddClick }) => {
  const { t } = useTranslation();

  return (
    <div className="add-department-row">
      <div className="add-department-row__left-column">
        <span className="description">
          {t('departments.noDepartmentMatch')}
        </span>
        <span className="value">{value}</span>
      </div>
      <div className="add-department-row__right-column">
        <span onClick={onAddClick}>{t('departments.addDepartment')}</span>
      </div>
    </div>
  );
};

export default AddDepartmentRow;
