import { reportsConstants } from './../constants/reports.constants';
import * as _ from 'lodash';
import { getCurrencyById } from '../services/currency.service';

const INITIAL_STATE = {
  transactions: [],
  hqName: ''
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case reportsConstants.SET_REPORTS_INFO:
      const formattedInfo = action.info.map(data =>
        prepareReportsTransactions(data, action.companyState)
      );
      const transactions = _.sortBy(formattedInfo, [info => info.transaction.createdAt]);
      const hqName = action.hqName || state.hqName;
      return { ...state, transactions, hqName };
    default:
      return state;
  }
}

function prepareReportsTransactions(rawData, companyState = {}) {
  return {
    user: {
      name: `${rawData.first_name} ${rawData.last_name}`,
      department: rawData.department_name
    },
    card: {
      id: rawData.card_id,
      name: rawData.card_name,
      last4: rawData.last4_digits,
      tags: rawData.tags,
      currency: getCurrencyById(companyState, rawData.currency_id)
    },
    merchant: {
      name: rawData.cleaned_merchant_name,
      baseName: rawData.merchant_name,
      country: rawData.merchant_country,
      category: rawData.merchant_category,
      amount: rawData.merchant_amount,
      currency: rawData.merchant_currency
    },
    transaction: {
      id: rawData.id,
      state: rawData.transaction_state,
      type: rawData.type,
      amount: rawData.amount,
      hqAmount: rawData.hq_amount,
      currency: companyState.defaultCurrency,
      cardCurrencyAmount: rawData.card_currency_amount || null,
      cardCurrency: getCurrencyById(companyState, rawData.currency_id),
      createdAt: rawData.created_at,
      description: rawData.description,
      usdEquivalent: rawData.usd_equivalent
    }
  };
}
