import { userConstants } from '../constants/user.constants';

// available limit for card/user shown on ui
export const getAvailableLimitByUserType = (
  defaultAvailable,
  currentAvailable,
  companyAvailable,
  userType,
  rateInfo
) => {
  let availableLimit = currentAvailable;

  if (companyAvailable && _isLimitsDependsOnUserType(userType)) {
    if (defaultAvailable !== null) {
      availableLimit =
        defaultAvailable > companyAvailable
          ? rateInfo
            ? parseInt(companyAvailable * rateInfo.exchangeRate)
            : companyAvailable
          : currentAvailable;
    } else {
      availableLimit = Math.min(currentAvailable, companyAvailable);
    }
  }

  return _validateAvailableLimit(availableLimit);
};

// lower limit for progress-bar on ui (if needed)
export const getLowerLimitByUserType = (
  defaultAvailable,
  currentAvailable,
  currentSpent,
  companyAvailable,
  userType,
  rateInfo
) => {
  let available = defaultAvailable === null ? currentAvailable : defaultAvailable;
  if (_isLimitsDependsOnUserType(userType) && companyAvailable < available) {
    const cAvailable = rateInfo
      ? parseInt(companyAvailable * rateInfo.exchangeRate)
      : companyAvailable;
    return _validateLowerLimit(currentSpent + cAvailable);
  }
  return null;
};

const _validateAvailableLimit = limit => (limit > 0 ? limit : 0);
const _validateLowerLimit = limit => (limit > 0 ? limit : 0);

const _isLimitsDependsOnUserType = userType =>
  userType === userConstants.USER_TYPES.SUPER_ADMIN ||
  userType === userConstants.USER_TYPES.ADMIN ||
  userType === userConstants.USER_TYPES.USER;
