import axios from 'axios';

import { getAuthorizationForRequest } from '../../utils/network-util';

export const _DEFAULT_CONFIG = async cancelToken => {
  const cancelTokenOption = () =>
    cancelToken
      ? {
          cancelToken: new axios.CancelToken(c => (cancelToken.cancelToken = c))
        }
      : null;

  return {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      crossDomain: true,
      mode: 'no-cors',
      Authorization: await getAuthorizationForRequest()
    },
    ...cancelTokenOption()
  };
};
