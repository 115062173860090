import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { ChosenFile } from '../file-uploader';
import { ReactComponent as NewFileIcon } from '../../../assets/images/new-file.svg';

import { CreditLineIncreaseContext } from '../../../context';

import './index.scss';

const DocumentsUploader = ({ onError }) => {
  const { t } = useTranslation();
  const fileInput = useRef(null);
  const { setFiles, files, dropFiles } = useContext(CreditLineIncreaseContext);

  const [isDragOver, setIsDragOver] = useState(false);

  const prepareFiles = files =>
    [...files].reduce(
      (data, file, index) => [
        ...data,
        {
          id: `${index}-${Date.now()}`,
          file: file,
          fileName: file.name,
          error: file.error
        }
      ],
      []
    );

  const [selectedFiles, setSelectedFiles] = useState(files && prepareFiles(files));

  useEffect(() => {
    const isErrorSelectedFile = selectedFiles?.some(file => file.error === true);
    const emptySelectedFiles = !selectedFiles.length;

    isErrorSelectedFile ? onError(t('translation:uploader.error.notAcceptable')) : onError(null);

    emptySelectedFiles && dropFiles();
  }, [selectedFiles]);

  const acceptableFiles = ['application/pdf'];

  const checkIsAcceptable = file => acceptableFiles.includes(file.type);

  const selectFiles = files => {
    [...files].forEach(file => {
      const isAcceptable = checkIsAcceptable(file);
      if (!isAcceptable) {
        file.error = true;
      } else {
        setFiles(prev => [...prev, file]);
      }
    });

    const preparedFiles = prepareFiles(files);
    setSelectedFiles(selectedFiles => [...selectedFiles, ...preparedFiles]);
  };

  const handleDragOver = e => {
    e.preventDefault();
    !isDragOver && setIsDragOver(true);
  };

  const handleDragLeave = e => {
    e.preventDefault();
    isDragOver && setIsDragOver(false);
  };

  const handleDrop = e => {
    e.preventDefault();
    onError(null);
    setIsDragOver(false);

    selectFiles(e.dataTransfer?.files);

    e.target.value = null;
  };

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleFileSelect = ({ target }) => {
    selectFiles(target.files);
    target.value = null;
  };

  const handleFileDelete = id => {
    setSelectedFiles(selectedFiles => selectedFiles.filter(file => file.id !== id));
  };

  return (
    <div
      className={classnames('documents-uploader', { 'drag-over': isDragOver })}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className="documents-uploader__files">
        {selectedFiles &&
          selectedFiles.map(file => (
            <ChosenFile key={file.id} file={file} handleFileDelete={handleFileDelete} />
          ))}
      </div>

      <div className="documents-uploader__input">
        <input
          type="file"
          accept={acceptableFiles.join(', ')}
          ref={fileInput}
          hidden
          multiple
          onChange={handleFileSelect}
        />

        <button className="documents-uploader__button" onClick={handleClick}>
          <span>
            <NewFileIcon className="documents-uploader__icon" />
            {t('uploader.label.chooseFile')}
          </span>
        </button>

        <span className="documents-uploader__hint">{t('uploader.description.dropHere')}</span>
        <span className="documents-uploader__accepted-files">{t('uploader.acceptedFiles')}</span>
      </div>
    </div>
  );
};

export default DocumentsUploader;
