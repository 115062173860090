import { configurationConstants } from '../constants/configuration.constants';

import { configurationService } from './../services';

export const configurationActions = {
  getUserStatuses,
  setOverlayVisibility
};

function getUserStatuses() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return configurationService
        .getUserStatuses()
        .then(response => {
          if (response.data.status.is_success) {
            dispatch({
              type: configurationConstants.SET_USER_STATUSES,
              statuses: response.data.data
            });
            resolve();
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function setOverlayVisibility(isOverlayVisible) {
  return dispatch => {
    dispatch({
      type: configurationConstants.SET_OVERLAY_VISIBILITY,
      isOverlayVisible
    });
  };
}
