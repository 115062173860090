import React from 'react';
import './index.scss';

const DepartmentRow = ({ value, isSelected, onSelection, ...props }) => (
  <span
    className={`department-row ${isSelected ? 'selected' : ''}`}
    onClick={onSelection}
    {...props}
  >
    {value}
  </span>
);

export default DepartmentRow;
