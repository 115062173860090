import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Loader from '../loader';
import { OperationIcon, CancelIcon } from '../../../containers/send-payment/internal/icons';
import { ReactComponent as FilePlusIcon } from '../../../assets/images/statements/file-plus.svg';
import RoundedButton from '../button-rounded';

import './index.scss';

const UploadFile = ({
  description,
  isUploading,
  handleDrop,
  error,
  fileName,
  highlighted,
  setHighlighted,
  getValidFile,
  selectFile,
  data,
  clearData,
  hasResponseErrors
}) => {
  const { t } = useTranslation('translation');
  const fileInput = useRef(null);

  const onDragEnter = e => {
    e.preventDefault();
    if (!highlighted) setHighlighted(true);
  };

  const handleDragLeave = e => {
    e.preventDefault();
    setHighlighted(false);
  };

  const handleDragOver = e => e.preventDefault();

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleFileSelect = async ({ target }) => {
    const file = getValidFile({ files: target.files });

    if (file) selectFile(file);
    target.value = null;
  };

  const InputForDownload = () => (
    <input type="file" ref={fileInput} hidden multiple onChange={handleFileSelect} />
  );

  const DefaultContent = () => (
    <>
      <FilePlusIcon className="upload-section__icon" />
      <RoundedButton onClick={handleClick} className="upload-section__button">
        {t('uploadFile.label')}
      </RoundedButton>
      {description ? <span className="upload-section__description">{description}</span> : null}
      <InputForDownload />
    </>
  );

  const UploadingContent = () => (
    <div className="loader">
      <Loader centered />
    </div>
  );
  const Error = () => <span className="error-message">{t(error)}</span>;
  const OperationContent = ({ hasResponseErrors, uploadDocument, clientError }) => (
    <>
      <OperationIcon
        className="operation-icon"
        hasResponseErrors={hasResponseErrors}
        uploadDocument={uploadDocument}
        clientError={clientError}
      />
      {hasResponseErrors || clientError ? (
        <RoundedButton onClick={uploadDocument} className="upload-section__button-reupload">
          {t('uploadFile.reupload')}
        </RoundedButton>
      ) : null}
      {clientError ? <Error>{clientError}</Error> : null}
      {!clientError && !hasResponseErrors ? (
        <span className="operation-message">
          {fileName} <CancelIcon className="cancel-icon" onClick={clearData} />
        </span>
      ) : null}
      <InputForDownload />
    </>
  );

  const Content = ({ clientError }) => {
    if (isUploading) return <UploadingContent />;
    if (data || clientError)
      return (
        <OperationContent
          hasResponseErrors={hasResponseErrors}
          uploadDocument={handleClick}
          clientError={clientError}
        />
      );
    return <DefaultContent />;
  };

  return (
    <section
      className={classNames('upload-section', {
        highlighted,
        uploading: isUploading,
        error: !isUploading && (error || hasResponseErrors),
        'with-error-message': !!error && typeof error === 'string',
        'with-data': data
      })}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragEnter={onDragEnter}
      onDragLeave={handleDragLeave}
    >
      <Content clientError={error} />
    </section>
  );
};

export default UploadFile;

UploadFile.propType = {
  description: PropTypes.string,
  isUploading: PropTypes.bool.isRequired,
  handleDrop: PropTypes.func,
  error: PropTypes.string,
  fileName: PropTypes.string,
  highlighted: PropTypes.bool,
  setHighlighted: PropTypes.func,
  getValidFile: PropTypes.func,
  selectFile: PropTypes.func,
  data: PropTypes.bool,
  clearData: PropTypes.func
};

UploadFile.defaultProps = {
  handleDrop: () => {},
  setHighlighted: () => {},
  getValidFile: () => {},
  selectFile: () => {},
  clearData: () => {},
  error: null,
  fileName: '',
  data: null
};
