import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import variables from './../../../styles/_variables.scss';
import getNumberWithCommas from '../../../utils/string-service';
import { ReactComponent as InfoCircleIcon } from './../../../assets/images/info-circle.svg';

import { ReactComponent as EditPen } from '../../../assets/images/edit-pen.svg';

import { getAmount } from '../../../utils/currency-util';

import './index.scss';

const limitInfo = {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  paddingBottom: 2,
  color: '#000000'
};

const limitInfoLabel = {
  display: 'flex',
  alignItems: 'center',
  fontFamily: variables.fontRegular,
  fontSize: '12px',
  lineHeight: '18px',
  paddingBottom: 3
};

const limitInfoData = {
  fontFamily: variables.fontBold,
  fontSize: '16px',
  lineHeight: 1.13,
  maxWidth: '120px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSizeAdjust: 0.58
};

const limitInfoDateLarge = {
  fontSize: '22px',
  lineHeight: '26px'
};

const LimitInfo = ({
  limit = 0,
  totalLimit = 0,
  credits = 0,
  large,
  title,
  isEmpty = false,
  editable = false,
  available = true,
  handleLimitUpdate,
  handleLimitChange,
  currencySymbol = '$',
  exchangeData = null,
  isTotalCredits = false,
  isEditPen = false
}) => {
  const { t } = useTranslation();

  const dataStyles = large ? { ...limitInfoData, ...limitInfoDateLarge } : limitInfoData;

  const formatLimitString = limit => limit.replace(/[^0-9.]/g, '');
  const creditsAmount = credits ? getAmount(currencySymbol, credits) : credits;
  const isCreditsAvailable = !!creditsAmount || isTotalCredits;

  const CREDITS_TIME_DELAY = 250;
  let creditsAmountTimeout = null;

  const [isCreditsOverlayVisible, toggleCreditsOverlayVisible] = useState(false);
  const [isExchangeTooltipVisible, setIsExchangeTooltipVisible] = useState(false);

  const handleMouseOver = () => {
    creditsAmountTimeout = setTimeout(() => {
      if (!isCreditsOverlayVisible) {
        toggleCreditsOverlayVisible(!isCreditsOverlayVisible);
      }
    }, CREDITS_TIME_DELAY);
  };

  const handleMouseOut = () => {
    clearTimeout(creditsAmountTimeout);
    if (isCreditsOverlayVisible) {
      toggleCreditsOverlayVisible(!isCreditsOverlayVisible);
    }
  };

  const handleExchangeDataMouseOver = () => {
    setIsExchangeTooltipVisible(true);
  };

  const handleExchangeDataMouseOut = () => {
    setIsExchangeTooltipVisible(false);
  };

  return available && !isEmpty ? (
    <div className="limit-info" style={limitInfo}>
      <div className="limit-header--row">
        {isCreditsAvailable ? (
          <span className="limit-info__credits" style={limitInfoLabel}>
            <span>{t('limitInfo.limitAndCredits')}</span>
            <InfoCircleIcon
              style={{ marginLeft: 4, cursor: 'pointer' }}
              onMouseEnter={handleMouseOver}
              onMouseLeave={handleMouseOut}
            />
          </span>
        ) : (
          <>
            <span style={limitInfoLabel}>{title || t('limitInfo.limit')}</span>

            {exchangeData ? (
              <ExchangeData
                isTooltipVisible={isExchangeTooltipVisible}
                rate={exchangeData.rate}
                date={exchangeData.date}
                onMarkerHoverOn={handleExchangeDataMouseOver}
                onMarkerHoverOut={handleExchangeDataMouseOut}
              />
            ) : null}
          </>
        )}
        {isEditPen ? <EditPen className="edit-pen" /> : null}
      </div>
      {editable ? (
        <div className="limit-info--editable">
          <span>{currencySymbol}</span>
          <input
            type="text"
            value={getNumberWithCommas(totalLimit)}
            className={'limit-info__input'}
            autoFocus
            onChange={event => {
              handleLimitChange(formatLimitString(event.target.value));
            }}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                handleLimitUpdate(formatLimitString(event.target.value));
              }
            }}
            onBlur={event => {
              event.preventDefault();
              handleLimitUpdate(formatLimitString(event.target.value));
            }}
          />
        </div>
      ) : (
        <span className="limit-info__value" style={dataStyles}>
          {getAmount(currencySymbol, totalLimit)}
        </span>
      )}

      {isCreditsOverlayVisible && (
        <div className="limit-credits-info">
          <div className="limit-credits-info__item">
            <span className="bold">{t('limitInfo.limit')}: </span>
            <span>{getAmount(currencySymbol, limit)}</span>
          </div>
          <div className="limit-credits-info__item">
            <span className="bold">{t('limitInfo.credits')}: </span>
            <span>{getAmount(currencySymbol, credits)}</span>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="limit-info" style={limitInfo}>
      <span style={limitInfoLabel}>{title || t('limitInfo.limit')}</span>
      <span style={dataStyles}>- -</span>
    </div>
  );
};

const ExchangeData = ({ isTooltipVisible, rate, date, onMarkerHoverOn, onMarkerHoverOut }) => (
  <div className="exchange-data">
    {isTooltipVisible ? (
      <div className="exchange-data__tooltip">
        <div>
          <span className="rate">{rate}</span>
          <span className="date">{date}</span>
        </div>
      </div>
    ) : null}
    <InfoCircleIcon
      className="exchange-data__info--icon"
      onMouseEnter={onMarkerHoverOn}
      onMouseLeave={onMarkerHoverOut}
    />
  </div>
);

export default LimitInfo;
