import { companyConstants } from './../constants/company.constants';
import { companyService, integrationsService } from './../services';
import { getEmailValidationError } from './../services/validation-service';
import { getObjWithoutField } from '../utils/object-util';

export const companyActions = {
  getCompanyInfo,
  getCurrencies,
  getSystemIntegrations,
  getBillingPeriods,
  selectBillingPeriod,
  getDepartments,
  createDepartment,
  getSuperAdmins,
  getAdmins,
  getBillingContacts,
  addBillingContact,
  deleteBillingContact
};

function getCompanyInfo(billingId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: companyConstants.LOADING_COMPANY_INFO,
        loaded: false
      });
      return companyService
        .getCompanyInfo(billingId)
        .then(response => {
          if (response.data.status.is_success) {
            const company = getObjWithoutField(response.data, 'status');
            dispatch({
              type: companyConstants.SET_COMPANY_INFO,
              company
            });
            //todo: delete temporary dispatch later
            // todo: after refactoring components
            // todo: to use company reducer instead
            dispatch({
              type: 'TEMP_SET_COMPANY_INFO_TO_USER',
              company
            });
            dispatch({
              type: companyConstants.LOADING_COMPANY_INFO,
              loaded: true
            });
            resolve(company.supported_currencies);
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getCurrencies() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return companyService
        .getCurrencies()
        .then(response => {
          if (response.data.status.is_success) {
            dispatch({
              type: companyConstants.SET_CURRENCIES,
              currencies: response.data.supported_currencies
            });
            resolve(response.data.supported_currencies);
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getSystemIntegrations() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return integrationsService
        .getSystemIntegrations()
        .then(response => {
          if (response.data.status.is_success) {
            dispatch({
              type: companyConstants.SET_SYSTEM_INTEGRATIONS,
              integrations: response.data.data
            });
            resolve();
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getBillingPeriods() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return companyService
        .getBillingPeriods()
        .then(response => {
          if (response.data.status.is_success) {
            dispatch({
              type: companyConstants.SET_BILLING_PERIODS,
              periods: response.data.data
            });
            resolve();
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function selectBillingPeriod(period) {
  return dispatch => {
    dispatch({
      type: companyConstants.SET_BILLING_PERIOD,
      period
    });
  };
}

function getDepartments() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return companyService
        .getDepartments()
        .then(response => {
          if (response.data.status.is_success) {
            dispatch({
              type: companyConstants.SET_DEPARTMENTS,
              departments: response.data.data
            });
            resolve();
          } else {
            reject(response.data.status.error_message);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function createDepartment(name) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return companyService
        .createDepartment(name)
        .then(response => {
          if (response.status) {
            if (response.status.is_success) {
              dispatch({
                type: companyConstants.CREATE_DEPARTMENT,
                id: response.data.department_id,
                name: name
              });
              resolve(response.data.department_id);
            } else {
              reject(response.status.error_message);
            }
          } else {
            reject('');
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getSuperAdmins() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return companyService
        .getSuperAdmins()
        .then(response => {
          if (response.data.status.is_success) {
            dispatch({
              type: companyConstants.SET_SUPER_ADMINS,
              superAdmins: response.data.data
            });
            resolve();
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getAdmins() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return companyService
        .getAdmins()
        .then(response => {
          if (response.data.status.is_success) {
            dispatch({
              type: companyConstants.SET_ADMINS,
              admins: response.data.data
            });
            resolve();
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getBillingContacts() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return companyService
        .getBillingContacts()
        .then(response => {
          if (response.data.status.is_success) {
            dispatch({
              type: companyConstants.SET_BILLING_CONTACTS,
              contacts: response.data.data
            });
            resolve();
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function addBillingContact(email) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!getEmailValidationError(email)) {
        return companyService
          .addBillingContact(email)
          .then(response => {
            if (response.status.is_success) {
              dispatch({
                type: companyConstants.ADD_BILLING_CONTACT,
                id: response.contact_id,
                email: email
              });
              resolve();
            } else {
              reject();
            }
          })
          .catch(error => {
            reject(error);
          });
      } else {
        reject();
      }
    });
  };
}

function deleteBillingContact(id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return companyService
        .deleteBillingContact(id)
        .then(response => {
          if (response.status.is_success) {
            dispatch({
              type: companyConstants.DELETE_BILLING_CONTACT,
              id: id
            });
            resolve();
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}
