import classNames from 'classnames';
import PropTypes from 'prop-types';

import { prefix } from '../../../../utils/classnames-utils';

const ShowJsEntity = ({ baseClass, animated, buttonId, children }) => {
  const addPrefix = prefix(baseClass);
  return (
    <>
      {/** rendered is shown with ShowJs */}
      <div
        className={classNames(baseClass, addPrefix('_show-data'), {
          animated
        })}
      />
      <button id={buttonId} />
      {/** rendered when data is hidden */}
      <div className={classNames(baseClass, addPrefix('_mask'))}>{children}</div>
    </>
  );
};

export default ShowJsEntity;

ShowJsEntity.propTypes = {
  baseClass: PropTypes.string.isRequired,
  animated: PropTypes.bool.isRequired,
  buttonId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
};
