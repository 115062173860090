import React, { Component } from 'react';

import {
  getCountriesSortedByPreference,
  PREFERRED_COUNTRIES_CODES,
  getCountryByNumericCode,
  getCountryByAlpha2Code
} from '../../../services/country-service';

import { ScrollableArea } from '../../scrollable-area';
import triangleImg from './../../../assets/images/mobile-input-triangle.svg';

import './index.scss';

const COUNTRIES = getCountriesSortedByPreference();

const getDefaultSelectedCountry = ({ numericCode, defaultCountryCode }) => {
  const defaultCountry = numericCode
    ? getCountryByNumericCode(numericCode)
    : getCountryByAlpha2Code(defaultCountryCode || COUNTRIES[0].alpha2Code);
  return defaultCountry || COUNTRIES[0];
};

class MobileInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: getDefaultSelectedCountry(props),
      mobileNumber: props.mobileNumber || '',
      isDropdownVisible: false,
      isEventListenerAdded: false
    };

    this.numberInput = React.createRef();
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.addOutsideClickListener = this.addOutsideClickListener.bind(this);
    this.removeOutsideClickListener = this.removeOutsideClickListener.bind(this);
    this.handleClickInside = this.handleClickInside.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    if (this.props.listenerByDefault && !this.state.isEventListenerAdded) {
      this.addOutsideClickListener();
    }
  }

  componentWillUnmount() {
    this.removeOutsideClickListener();
  }

  addOutsideClickListener() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.setState({ isEventListenerAdded: true });
  }

  removeOutsideClickListener() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    this.setState({ isEventListenerAdded: false });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.emitMobileChangeEvent();
    }
  }

  handleClickInside() {
    if (!this.state.isEventListenerAdded) {
      this.addOutsideClickListener();
    }
  }

  emitMobileChangeEvent() {
    this.setDropdownVisibility(false);
    this.removeOutsideClickListener();
    this.numberInput.current.blur();
    this.props.onMobileChange(this.state.selectedCountry, this.state.mobileNumber);
  }

  setDropdownVisibility(isVisible) {
    this.setState({ isDropdownVisible: isVisible });
  }

  handleCountrySelection(country) {
    this.setState({ selectedCountry: country });
    this.setDropdownVisibility(false);
  }

  handleMobileInput(mobile) {
    this.setState({ mobileNumber: mobile });
  }

  render() {
    return (
      <div
        ref={this.setWrapperRef}
        className="mobile-input__wrapper"
        onClick={this.handleClickInside}
      >
        <div className="mobile-input">
          <div
            className="selected-code-block"
            onClick={() => this.setDropdownVisibility(!this.state.isDropdownVisible)}
          >
            <span>{`+${this.state.selectedCountry.mobileCountryCode}`}</span>
            <img alt="" src={triangleImg} />
          </div>
          <input
            ref={this.numberInput}
            value={this.state.mobileNumber}
            onChange={event => this.handleMobileInput(event.target.value)}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                this.emitMobileChangeEvent(event.target.value);
              }
            }}
          />
        </div>
        {this.state.isDropdownVisible ? (
          <MobileDropdown
            countries={COUNTRIES}
            selectedCountry={this.state.selectedCountry}
            onCountrySelection={country => this.handleCountrySelection(country)}
          />
        ) : null}
      </div>
    );
  }
}

const MobileDropdown = ({ countries, selectedCountry, onCountrySelection }) => (
  <div className="mobile-dropdown">
    <ScrollableArea
      styles={{
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        maxHeight: '16.5rem',
        boxSizing: 'border-box'
      }}
    >
      {countries.map((country, index) => (
        <div key={index}>
          <DropdownElement
            country={country}
            isSelected={country.alpha2Code === selectedCountry.alpha2Code}
            onClick={() => onCountrySelection(country)}
          />
          {index === PREFERRED_COUNTRIES_CODES.length - 1 ? <div className="divider" /> : null}
        </div>
      ))}
    </ScrollableArea>
  </div>
);

const DropdownElement = ({ country, isSelected, ...props }) => (
  <div className="country" {...props}>
    <span className={`code ${isSelected ? 'selected' : ''}`}>
      {`+${country.mobileCountryCode}`}
    </span>
    <span className={`name ${isSelected ? 'selected' : ''}`}>{country.name}</span>
  </div>
);

export default MobileInput;
