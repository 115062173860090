export const MERCHANT_CATEGORIES_DATA = [
  'agricultural_services',
  'contracted_services',
  'airlines',
  'car_rental_agencies',
  'hotels_motels_resorts',
  'transportation_services',
  'utility_services',
  'retail_outlet_services',
  'clothing_stores',
  'miscellaneous_stores',
  'business_services',
  'professional_services_membership_organizations',
  'government_services'
];
