import { useTranslation } from 'react-i18next';

import ProgressWithTooltip from '../progress-tooltip';
import LimitInfo from '../../dashboard/limit-info';
import AvailableBox from '../available-box';

import { getAvailableAmount, getCreditsAmount } from '../../../utils/spending-util';

import './index.scss';

function SpendingHeader({ profile, credits, currency }) {
  const { t } = useTranslation();
  const creditsAmount = getCreditsAmount(credits);

  return (
    <div className="spending-header">
      <div className="spending-header__spending--block">
        <div className="spending-header__spending">
          <span>{t('spendingHeader.title')}</span>
        </div>
        <div className="spending-header__progress">
          <ProgressWithTooltip
            currencySymbol={currency.symbol}
            limit={profile.totalLimit}
            spent={profile.spent}
          />
        </div>
      </div>
      <div className="billing-info_wrapper">
        <div className="available-box__wrapper">
          <AvailableBox
            total
            big
            value={profile.available}
            currencySymbol={currency.symbol}
          />
        </div>

        <div className="spending-header__limits">
          <LimitInfo
            limit={profile.limit}
            totalLimit={profile.totalLimit}
            credits={creditsAmount}
            large
            currencySymbol={currency.symbol}
            isTotalCredits={true}
          />
        </div>
      </div>
    </div>
  );
}

export default SpendingHeader;
