function getNumberWithCommas(number) {
  if (number !== null) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}

export function getNumberWithoutCommas(number) {
  if (number !== null) {
    return number.toString().replace(/\,/g, '');
  }
}

export function getNumbersFromString(string) {
  return string.toString().replace(/\D/g, '');
}

export const getFractionalNumbersFromString = string => string.toString().replace(/[^0-9.]/g, '');

export function cleanMobileNumber(mobile) {
  return mobile.toString().replace(/[- ()]/g, '');
}

export const isCharactersSumValid = (values, maxLength) => {
  let stringsLength = values.length
    ? values.reduce((accumulator, nextValue) => accumulator + nextValue.length, 0)
    : 0;
  return !(stringsLength > maxLength);
};

export const getPreparedLimit = ({ limit, prevExchangeRate, exchangeRate }) => {
  const selectedCurrencyLimit = (getNumberWithoutCommas(limit) / prevExchangeRate) * exchangeRate;
  const roundedSelectedCurrencyLimit = Math.floor(selectedCurrencyLimit);

  return {
    roundedSelectedCurrencyLimit: getNumberWithCommas(roundedSelectedCurrencyLimit),
    selectedCurrencyLimit
  };
};

export const getStringWithoutSpaces = value => value.toString().replace(/ /g, '');

export default getNumberWithCommas;
