import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

import { useMarkerPosition } from '../hooks';

import { getValidatedPercent, getElementsSizes, getPosition, DEFAULT_POSITION } from './helper';

import './index.scss';

const Tooltip = ({ percent, amount, description }) => {
  const [bodyWidth, setBodyWidth] = useState(0);
  const [pointerWidth, setPointerWidth] = useState(0);
  const [position, setPosition] = useState(DEFAULT_POSITION);
  const [elementsSizes, setElementsSizes] = useState(null);

  const { containerRef, containerWidth } = useMarkerPosition();

  const bodyRef = useCallback(node => {
    if (node !== null) {
      if (node.getBoundingClientRect().width !== bodyWidth) {
        setBodyWidth(node.getBoundingClientRect().width);
      }
    }
  });

  const pointerRef = useCallback(node => {
    if (node !== null) {
      setPointerWidth(node.getBoundingClientRect().width);
    }
  }, []);

  useEffect(() => {
    const newSizes = getElementsSizes(
      getValidatedPercent(percent),
      containerWidth,
      bodyWidth,
      pointerWidth
    );

    if (!_.isEqual(newSizes, elementsSizes)) {
      setElementsSizes(newSizes);
    }
  }, [percent, amount, description, containerWidth, bodyWidth, pointerWidth]);

  useEffect(() => {
    if (elementsSizes) {
      setPosition(getPosition(elementsSizes));
    }
  }, [elementsSizes]);

  return (
    <div
      ref={containerRef}
      className={`progress-tooltip__container ${position.containerClassModifier}`}
    >
      <div ref={bodyRef} className="body" style={{ marginLeft: position.bodyMargin }}>
        <span className="description">{description}</span>
        <span className="amount">{amount}</span>
      </div>
      <div className="pointer" ref={pointerRef} style={{ left: position.pointerMargin }} />
    </div>
  );
};

Tooltip.propsType = {
  percent: PropTypes.number,
  amount: PropTypes.string,
  description: PropTypes.string
};

Tooltip.defaultProps = {
  percent: 0,
  amount: '$0',
  description: ''
};

export default Tooltip;
