import React from 'react';
import applePayIcon from '../../../assets/images/apple-pay/apple-pay.png';
import applePayIcon2x from '../../../assets/images/apple-pay/apple-pay@2x.png';
import applePayIcon3x from '../../../assets/images/apple-pay/apple-pay@3x.png';

const ApplePayIcon = ({ className }) => {
  return (
    <img
      className={className}
      src={applePayIcon}
      srcSet={`
      ${applePayIcon2x} 2x,
      ${applePayIcon3x} 3x
    `}
      alt=""
    />
  );
};

export default ApplePayIcon;
