const isSmartlookAvailable = process.env.REACT_APP_IS_SMARTLOOK_ENABLED === 'true';

const identify = (id, data) => {
  isSmartlookAvailable && window.smartlook('identify', id, data);
};

const connectSmartlook = () => {
  if (isSmartlookAvailable) {
    window.smartlook ||
      (function (d) {
        let o = (window.smartlook = function () {
            o.api.push(arguments);
          }),
          h = d.getElementsByTagName('head')[0];
        let c = d.createElement('script');
        o.api = [];
        c.async = true;
        c.type = 'text/javascript';
        c.charset = 'utf-8';
        c.src = 'https://rec.smartlook.com/recorder.js';
        h.appendChild(c);
      })(document);
    smartlook('init', '1ba2768f822e7e5f6600f95607023c68a3faadef');
  }
};

export { identify, connectSmartlook };
