import { DownloadFile } from '../internal';

import store from '../../../store';
import { amplitudeInstance } from '../../../analytics/amplitude';

import { depositsActions } from '../../../actions/deposits.action';

export const prepareBulkTransactions = (transactions, handleDetailsDownload) =>
  transactions.map(
    ({
      createdAt,
      amount,
      from,
      to,
      payer,
      status,
      description,
      usdEquivalent,
      currencyCode,
      amountValue,
      transactionId
    }) => {
      let bulkTransactionFormatted = {
        createdAt,
        amount,
        from,
        to,
        payer,
        status,
        downloadFile: (
          <DownloadFile
            handleDetailsDownload={handleDetailsDownload}
            transactionId={transactionId}
          />
        )
      };

      let bulkTransactionProto = Object.create({
        description,
        usdEquivalent,
        currencyCode,
        amountValue,
        transactionId
      });

      return Object.assign(bulkTransactionProto, bulkTransactionFormatted);
    }
  );

export const getPreparedTransactions = ({ transactions, handleDetailsDownload }) =>
  transactions.map(props => {
    const {
      createdAt,
      amount,
      from,
      to,
      payer,
      status,
      description,
      usdEquivalent,
      currencyCode,
      amountValue,
      transactionId,
      bulkTransactions,
      fileName,
      bulkTotalAmount,
      bulkId,
      bulkCount,
      ...rest
    } = props;

    let transactionFormatted = {
      createdAt,
      amount,
      from,
      to,
      payer,
      status,
      downloadFile: (
        <DownloadFile handleDetailsDownload={handleDetailsDownload} transactionId={transactionId} />
      )
    };

    const bulk = bulkId ? { bulk: [] } : null;

    let transactionProto = Object.create({
      description,
      usdEquivalent,
      currencyCode,
      amountValue,
      transactionId,
      areDetailsLoading: false,
      numberOfBulkPayments: bulkCount,
      bulkId,
      ...bulk
    });

    return Object.assign(transactionProto, transactionFormatted);
  });

export const handleDetailsDownload = (paymentId, setIsFileDownloading) =>
  store
    .dispatch(depositsActions.downloadPaymentFile({ paymentId }))
    .then(() => amplitudeInstance.logEvent('Download_As_PDF_Button_Clicked'))
    .finally(() => setIsFileDownloading(false));
