const ChurnZeroAppKey = process.env.REACT_APP_CHURNZERO_APP_KEY;

const identify = (ExternalAccountId, ExternalContactId) => {
  ChurnZeroAppKey && window.ChurnZero.push(['setContact', ExternalAccountId, ExternalContactId]);
};

const connectChurnZero = () => {
  if (ChurnZeroAppKey) {
    window.ChurnZero = window.ChurnZero || [];
    (function(a,b,c,d){
      let cz=a.createElement(b);
      cz.type=c;
      cz.async=true;
      cz.src=d;
      let s=a.getElementsByTagName(b)[0];
      s.parentNode.insertBefore(cz, s);
    })(document, "script",'text/javascript', 'https://tribal.us1app.churnzero.net/churnzero.js');

    ChurnZero.push(['setAppKey', ChurnZeroAppKey]);
  }
};

export { connectChurnZero, identify };
