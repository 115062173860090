import store from '../store';
import { LOGIN_ROUTE } from '../constants/routes.constants';
import { authConstants } from '../constants/auth.constants';

const { sessionStorage, location } = window;

export const isAuthorized = () => !!sessionStorage.getItem('token');

export const logout = history => {
  sessionStorage.clear();

  history &&
    // TODO: CP-160 remove getItem after feature will be done
    !!window.localStorage.getItem('clear_store_after_logout') &&
    store.dispatch({
      type: authConstants.LOGOUT
    });

  // TODO: CP-160 remove removeItem after feature will be done
  window.localStorage.removeItem('clear_store_after_logout');

  history ? history.push(LOGIN_ROUTE) : (location.href = LOGIN_ROUTE);
};

export const getDeviceId = () => sessionStorage.getItem('device_id') || null;
