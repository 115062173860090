import * as _ from 'lodash';

import { INCREDIT_TYPES } from '../../constants/company.constants';
import {
  getCurrencyById,
  getUnsupportedCurrencyByCode
} from '../../services/currency.service';

const _prepareIncreditType = rawIncreditType => {
  switch (rawIncreditType) {
    case 0:
      return INCREDIT_TYPES.INCREDIT_TYPE_ZERO;
    case 1:
      return INCREDIT_TYPES.INCREDIT_TYPE_ONE;
    default:
      return null;
  }
};

const _prepareBillingIncredits = rawIncredits => {
  let incredits = [];
  if (rawIncredits && Array.isArray(rawIncredits) && rawIncredits.length) {
    incredits = rawIncredits.map(rawCredit => ({
      amount: rawCredit.amount || 0,
      date: rawCredit.date || null,
      type: _prepareIncreditType(rawCredit.incredit_type_id)
    }));
  }
  return incredits;
};

const prepareCompanyAddress = rawAddress => {
  let address = null;
  if (
    rawAddress &&
    typeof rawAddress === 'object' &&
    !Array.isArray(rawAddress)
  ) {
    address = {
      id: rawAddress.id || null,
      city: rawAddress.city || null,
      country: rawAddress.country || null,
      state: rawAddress.state || null,
      postalCode: rawAddress.postal_code || null,
      line1: rawAddress.line1 || null,
      line2: rawAddress.line2 || null
    };
  }
  return address;
};

const prepareBillingPeriod = (companyBilling, walletBilling, creditBilling) => {
  const period = {
    id: null,
    limit: 0,
    totalLimit: 0,
    spent: 0,
    walletSpent: 0,
    creditSpent: 0,
    available: 0,
    incredits: _prepareBillingIncredits(),
    date: null,
    start_date: null,
    end_date: null
  };

  if (
    companyBilling &&
    typeof companyBilling === 'object' &&
    !Array.isArray(companyBilling)
  ) {
    period.id = companyBilling.id || null;
    period.limit = companyBilling.limit || 0;
    period.totalLimit = companyBilling.total_limit || 0;
    period.spent = companyBilling.spent || 0;
    period.available = companyBilling.available || 0;
    period.incredits = _prepareBillingIncredits(companyBilling.incredits);
    period.date = companyBilling.start_date || null;
    period.start_date = companyBilling.start_date || null;
    period.end_date = companyBilling.end_date || null;
  }
  if (walletBilling) period.walletSpent = walletBilling.spent || 0;
  if (creditBilling) period.creditSpent = creditBilling.spent || 0;

  return period;
};

const prepareBillingPeriods = rawPeriods => {
  let periods = [];

  if (rawPeriods && Array.isArray(rawPeriods) && rawPeriods.length) {
    periods = _.sortBy(rawPeriods, ['start_date'])
      .reverse()
      .map(period => prepareBillingPeriod(period));
  }

  return periods;
};

function prepareSuperAdmins(rawData) {
  return rawData.map(superAdmin => ({
    id: superAdmin.id,
    email: superAdmin.email
  }));
}

function prepareAdmins(rawData) {
  return rawData.map(admin => ({
    id: admin.id,
    email: admin.email
  }));
}

function prepareBillingContacts(rawData) {
  return rawData.map(contact => ({
    id: contact.id,
    email: contact.email
  }));
}

function prepareCompanyTransactions(rawData, companyState) {
  return {
    user: {
      name: `${rawData.first_name} ${rawData.last_name}`,
      department: rawData.department_name
    },
    card: {
      id: rawData.card_id,
      name: rawData.card_name,
      last4: rawData.last4_digits,
      tags: rawData.tags
    },
    merchant: {
      name: rawData.cleaned_merchant_name,
      baseName: rawData.merchant_name,
      country: rawData.merchant_country,
      category: rawData.merchant_category,
      amount: rawData.merchant_amount,
      currency: getUnsupportedCurrencyByCode(rawData.merchant_currency)
    },
    transaction: {
      id: rawData.id,
      state: rawData.transaction_state,
      type: rawData.type,
      amount: rawData.amount,
      currency: companyState.defaultCurrency,
      cardCurrencyAmount: rawData.card_currency_amount || null,
      cardCurrency: getCurrencyById(companyState, rawData.currency_id),
      createdAt: rawData.created_at,
      description: rawData.description
    }
  };
}

export default {
  prepareCompanyAddress,
  prepareBillingPeriod,
  prepareBillingPeriods,
  prepareSuperAdmins,
  prepareAdmins,
  prepareBillingContacts,
  prepareCompanyTransactions
};
