import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { SearchInput } from './internal';
import { ScrollableArea } from '../../scrollable-area';
import Divider from '../divider';

import { useModalSearch } from './hooks';
import { useTargetDevice } from '../../../hooks';

import './index.scss';

/**
 * @param dropdownData - dropdown list of items
 */
const SearchModal = ({ data, title, closeModal, onItemClick, withPortal }) => {
  const { t } = useTranslation('translation');
  const { isTargetDevice } = useTargetDevice();
  let dropdownData = Object.entries(data).sort((a, b) =>
    a[0] === b[0] ? 0 : a[0] < b[0] ? -1 : 1
  );

  const SearchItem = ({ item }) => {
    const [code, description] = item;
    const handleItemClick = () => {
      onItemClick(item);
      closeModal();
    };
    return (
      <div
        className={classNames('search-item', {
          simplebar: isTargetDevice
        })}
        onClick={handleItemClick}
      >
        <span className="search-item__label">{code}</span>
        <span className="search-item__description">{description}</span>
      </div>
    );
  };

  const SearchItemsList = ({ data }) => {
    const renderItems = () => data.map((item, idx) => <SearchItem key={idx} item={item} />);

    return data.length ? renderItems() : null;
  };

  const SearchListSection = ({ data }) => (
    <div
      className={classNames('search-modal__items-section', {
        'common-scroll': !isTargetDevice
      })}
    >
      <ScrollableArea styles={{ maxHeight: '49rem' }}>
        <SearchItemsList data={data} />
      </ScrollableArea>
    </div>
  );

  const Modal = () => {
    const { setEnteredText, enteredText, data } = useModalSearch({ dropdownData });

    return (
      <div
        className={classNames('search-modal', {
          portal: withPortal
        })}
      >
        <div className="search-modal__title">{title ? t(title) : null}</div>
        <SearchInput t={t} enteredText={enteredText} setEnteredText={setEnteredText} />
        <Divider className="search-modal__divider" />
        <SearchListSection data={data} />
      </div>
    );
  };

  return <Modal />;
};

export default SearchModal;

SearchModal.propTypes = {
  title: PropTypes.string,
  dropdownData: PropTypes.array,
  withPortal: PropTypes.bool,
  closeModal: PropTypes.func,
  onItemClick: PropTypes.func
};

SearchModal.defaultProps = {
  withPortal: false,
  closeModal: () => {},
  onItemClick: () => {}
};
