import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  MIN_PERCENT,
  getValidatedPercent,
  getValidatedSecondaryPercent,
  getFilledBarClass,
  getFilledBarStyle,
  getReversedBarStyle
} from './helper';
import './index.scss';

const Progress = props => {
  const [percent, setPercent] = useState(MIN_PERCENT);
  const [secondaryPercent, setSecondaryPercent] = useState(MIN_PERCENT);

  useEffect(() => {
    setPercent(getValidatedPercent(props.percent));
  }, [props.percent]);

  useEffect(() => {
    setSecondaryPercent(getValidatedSecondaryPercent(props.secondaryPercent));
  }, [props.secondaryPercent]);

  return (
    <div className="progress-bar">
      <div
        style={getFilledBarStyle(percent)}
        className={getFilledBarClass(percent)}
      />
      {secondaryPercent ? (
        <ReversedBar secondaryPercent={secondaryPercent} />
      ) : null}
    </div>
  );
};

const ReversedBar = ({ secondaryPercent }) => (
  <div style={getReversedBarStyle(secondaryPercent)} className="reversed">
    <div className="marker">
      <div className="marker-bg" />
    </div>
    <div className="strokes-area" />
  </div>
);

Progress.propsType = {
  percent: PropTypes.number.isRequired,
  secondaryPercent: PropTypes.number
};

Progress.defaultProps = {
  percent: MIN_PERCENT,
  secondaryPercent: MIN_PERCENT
};

export default Progress;
