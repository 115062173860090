import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

import alertImg from './../../../../assets/images/alert-circle.svg';
import alertImgHidden from './../../../../assets/images/alert-circle-hidden.svg';
import alertImgHover from './../../../../assets/images/alert-circle-hover.svg';

import { useMarkerPosition } from '../hooks';

import {
  DEFAULT_POSITION,
  getElementsSizes,
  getPosition,
  MIN_PERCENT,
  getValidatedPercent
} from './helper';

import './index.scss';

const InfoMarker = ({ percent, inactive, onHoverOn, onHoverOut }) => {
  const [elementsSizes, setElementsSizes] = useState(null);
  const [position, setPosition] = useState(DEFAULT_POSITION);
  const [isHoverActive, setHover] = useState(false);
  const imageRef = useRef(null);

  const { containerWidth, containerRef } = useMarkerPosition();

  useEffect(() => {
    const imageWidth = imageRef.current.width;
    const newSizes = getElementsSizes(imageWidth, containerWidth, getValidatedPercent(percent));

    if (!_.isEqual(newSizes, elementsSizes)) {
      setElementsSizes(newSizes);
    }
  }, [percent, containerWidth]);

  useEffect(() => {
    if (elementsSizes) {
      setPosition(getPosition(elementsSizes));
    }
  }, [elementsSizes]);

  const getImage = () => {
    let src = alertImg;
    if (inactive && isHoverActive) {
      src = alertImgHover;
    } else if (inactive) {
      src = alertImgHidden;
    }
    return src;
  };

  const hoverOutHandler = () => {
    setHover(false);
    onHoverOut();
  };

  return (
    <div ref={containerRef} className={`progress-info-marker ${position.alignment}`}>
      <img
        style={{ left: position.margin }}
        ref={imageRef}
        alt=""
        src={getImage()}
        onMouseOver={e => {
          setHover(true);
          onHoverOn(e);
        }}
        onWheel={() => hoverOutHandler()}
        onMouseLeave={() => hoverOutHandler()}
      />
    </div>
  );
};

InfoMarker.propsType = {
  percent: PropTypes.number.isRequired,
  inactive: PropTypes.bool,
  onHoverOn: PropTypes.func,
  onHoverOut: PropTypes.func
};

InfoMarker.defaultProps = {
  percent: MIN_PERCENT,
  inactive: false
};

export default InfoMarker;
