import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {useFlags} from "launchdarkly-react-client-sdk";

import { ScrollableArea } from '../../../scrollable-area';
import { Loader, Divider } from '../../';
import ItemsList from '../items-list';

import { useTargetDevice } from '../../../../hooks';

import { dropdownSearchConstants } from '../../../../constants/dropdown-search.constants';

import './index.scss';

const {
  TYPE: { NEW_RECIPIENT }
} = dropdownSearchConstants;

const Content = ({
  items,
  isNewItemVisible,
  enteredText,
  listTitle,
  onItemCreation,
  onItemSelection,
  contentType,
  isLoading,
  withFullMatchMark,
  selectedItemLabel,
  isCreateNewItemEnabled
}) => {
  const { t } = useTranslation('send-payment');
  const { isTargetDevice } = useTargetDevice();
  const { bannerMigrationTo20 } = useFlags();

  const isNewRecipientType = contentType === NEW_RECIPIENT;

  const CommonNewItemRow = () => (
    <div className="new-item__row">
      <span>{enteredText.trim()}</span>
      <button onClick={onItemCreation}>{t('recipientSelection.createNew')}</button>
    </div>
  );

  const NewRecipientRow = ({ isCreateNewItemEnabled }) => (
    <div className={classNames('new-recipient__row', { disabled: bannerMigrationTo20 ? true : !isCreateNewItemEnabled })}>
      <button onClick={onItemCreation} disabled={bannerMigrationTo20}>{t('recipientSelection.createNewRecipient')}</button>
    </div>
  );

  const NewItemRow = () =>
    isNewRecipientType ? (
      <NewRecipientRow isCreateNewItemEnabled={isCreateNewItemEnabled} />
    ) : (
      <CommonNewItemRow />
    );

  return (
    <>
      {items.length || isNewItemVisible ? (
        <div
          className={classNames('dropdown-search__content', {
            simplebar: isTargetDevice,
            'no-margin-top': isLoading
          })}
        >
          <ScrollableArea styles={{ width: '100%', maxHeight: '22em' }}>
            {isNewItemVisible ? <NewItemRow /> : null}
            {isLoading ? (
              <div className="dropdown-search__content-loader">
                <Loader centered />
              </div>
            ) : null}
            {items.length && isNewItemVisible ? <Divider /> : null}
            <div
              tabIndex="0"
              id="dropdown-search-focus-area-js"
              style={{
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '22em',
                outline: 'none'
              }}
            >
              {items.length && listTitle ? (
                <span className="search-item__title">{listTitle}</span>
              ) : null}
              {items.length ? (
                <ItemsList
                  items={items}
                  enteredText={enteredText.trim()}
                  onItemSelection={onItemSelection}
                  withFullMatchMark={withFullMatchMark}
                  selectedItemLabel={selectedItemLabel}
                />
              ) : null}
            </div>
          </ScrollableArea>
        </div>
      ) : null}
    </>
  );
};

export default Content;
