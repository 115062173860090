import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import moment from 'moment';

import { ExchangeDataTooltip, Portal } from '../components/shared';
import { ReactComponent as InfoCircleIcon } from '../assets/images/info-circle.svg';

import { getCoordinates } from '../utils/get-el-coordinates';
import { makeCancelable } from './helper';
import { getCancelToken } from '../utils/get-cancel-token';
import { getToken } from '../utils/token-utils';

export const usePreventTab = (allowedElementsIds = []) => {
  const allowedElementsIdsRef = useRef(allowedElementsIds);
  const focusedElementIndex = useRef(
    allowedElementsIdsRef.current.length ? allowedElementsIdsRef.current.length - 1 : null
  );

  useEffect(() => {
    window.addEventListener('keydown', keyPressHandler);
    return () => {
      window.removeEventListener('keydown', keyPressHandler);
    };
  }, []);

  useEffect(() => {
    allowedElementsIdsRef.current = allowedElementsIds;
  }, [allowedElementsIds]);

  const keyPressHandler = event => {
    if (event.code === 'Tab') {
      event.preventDefault();
      const allowedElsIds = allowedElementsIdsRef.current;
      if (allowedElsIds.length) {
        const newIndex =
          focusedElementIndex.current < allowedElsIds.length - 1
            ? focusedElementIndex.current + 1
            : 0;
        const elementToFocus = document.getElementById(allowedElsIds[newIndex]);
        if (elementToFocus) {
          elementToFocus.focus();
          focusedElementIndex.current = newIndex;
        }
      }
    }
  };
};

export const useNodeRemovalObserver = (
  observedNode,
  removableNode,
  isRemovableNodeVisible,
  onNodeRemoval
) => {
  const observedNodeRef = useRef(null);
  const removableNodeRef = useRef(null);
  const isRemovableNodeVisibleRef = useRef(false);
  const onNodeRemovalCallback = useRef(() => {});

  useEffect(() => {
    observedNodeRef.current = observedNode;
  }, [observedNode]);

  useEffect(() => {
    removableNodeRef.current = removableNode;
  }, [removableNode]);

  useEffect(() => {
    isRemovableNodeVisibleRef.current = isRemovableNodeVisible;
    if (isRemovableNodeVisible) {
      const observer = new MutationObserver(handleObservedNodeMutation);
      const observerConfig = { childList: true };
      observer.observe(observedNodeRef.current, observerConfig);
    }
  }, [isRemovableNodeVisible]);

  useEffect(() => {
    onNodeRemovalCallback.current = onNodeRemoval;
  }, [onNodeRemoval]);

  const handleObservedNodeMutation = function (mutationsList, observer) {
    if (isRemovableNodeVisibleRef.current) {
      for (let mutation of mutationsList) {
        if (mutation.removedNodes && mutation.removedNodes.length) {
          for (let node of mutation.removedNodes) {
            if (node === removableNodeRef.current) {
              onNodeRemovalCallback.current();
            }
          }
        }
      }
    } else {
      observer.disconnect();
    }
  };
};

/**
 *
 * @param elementRef element node which have to use "outside click" event
 * @param callback function which will be called by "outside click"
 * @param isPortal If the Portal component is using then document node will be picked.
 */
export const useClickOutsideElement = (elementRef, callback, isPortal) => {
  const rootDocument = document.getElementById('root');
  const documentInstance = isPortal ? document : rootDocument;

  const handleClickOutside = e => {
    if (elementRef.current && !elementRef.current.contains(e.target)) {
      callback();
    }
  };
  useEffect(() => {
    documentInstance.addEventListener('click', handleClickOutside);
    return () => {
      documentInstance.removeEventListener('click', handleClickOutside);
    };
  });
};

export const useTargetDevice = () => {
  const { userAgent } = window.navigator;
  const targetDevices = new RegExp(/Windows|X11/i);
  const isTargetDevice = targetDevices.test(userAgent);

  return { isTargetDevice };
};

export const useTooltipCoordinates = setIsTooltipVisible => {
  const [tooltipCoords, setTooltipCoords] = useState({
    top: null,
    left: null
  });

  const handleHover = async ({ target: ref }) => {
    const instanceCoords = getCoordinates(ref);
    await setTooltipCoords({ ...tooltipCoords, ...instanceCoords });
    await setIsTooltipVisible(true);
  };

  return { tooltipCoords, handleHover };
};

export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    didMount.current ? func() : (didMount.current = true);
  }, deps);
};

export const useCancellablePromise = () => {
  const promises = useRef([]);

  // promises and requests will be canceled automatically if component will unmount
  useEffect(() => handlePromisesClear, []);

  function handlePromisesClear() {
    promises.current.length &&
      promises.current.forEach(p => {
        // promise canceling
        p.cancel();
        // request cancelling
        p.requestCancel.cancelToken();
      });
    promises.current = [];
  }

  function cancellablePromise({ dispatch, action }) {
    // cancelToken will be attended to promiseCancelToken object via service config
    let promiseCancelToken = {};
    // attaching promiseCancelToken object to action
    // action must have the last argument as 'promiseCancelToken', that will be sent to the service
    const promise = dispatch(action.call(null, promiseCancelToken));
    const cPromise = makeCancelable(promise);

    cPromise.requestCancel = promiseCancelToken;
    // new promises will be pushed to the promises ref
    promises.current.push(cPromise);
    return cPromise.promise;
  }

  return { handlePromisesClear, cancellablePromise };
};

export const useFileUploader = ({ file: rawFile, uploadApiEndpoint, onUploadFinish }) => {
  const [progress, setProgress] = useState(0);
  const [isUploadError, setIsUploadError] = useState(false);

  const file = useMemo(() => rawFile, [rawFile.id]);
  const { token, handleCancel, handleCancelError } = useMemo(() => getCancelToken(file.id), [file]);

  useEffect(() => {
    if (!file.isUploaded) {
      upload(file);

      return () => handleCancel();
    }
  }, [file, file.isUploaded]);

  const upload = () => {
    const formData = new FormData();
    formData.append('file', file.file);

    setIsUploadError(false);
    setProgress(0);

    return uploadApiEndpoint?.(formData, token, updateLoadingProgress)
      .then(({ documents }) => {
        if (documents[0].isUploaded) {
          onUploadFinish(file.id, documents[0]);
        } else {
          setIsUploadError(true);
        }
      })
      .catch(err => {
        !handleCancelError(err) && setIsUploadError(true);
      });
  };

  const updateLoadingProgress = ({ loaded, total }) =>
    setProgress(Math.round((100 * loaded) / total));

  return {
    upload,
    isUploadError,
    progress
  };
};

/**
 * child ref width should be 100%
 */
export const useHasScrollbar = (childRef, parentRef) => {
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useEffect(() => {
    if (childRef.current?.offsetWidth && parentRef.current?.offsetWidth) {
      let widthScrollbar = parentRef.current.offsetWidth - childRef.current.offsetWidth;
      setScrollbarWidth(widthScrollbar);
    }
  }, [childRef.current, parentRef.current]);

  const tabDeviceMaxWidth = 1023;
  const hasScrollbar = !!scrollbarWidth || window.innerWidth < tabDeviceMaxWidth;

  return { scrollbarWidth, hasScrollbar };
};

export const useScrollTop = ({ instance, scrollableInstance, dependencies = [] }) => {
  const { isTargetDevice } = useTargetDevice();

  const instanceQuery = isTargetDevice
    ? `#${instance} div.simplebar-content-wrapper`
    : scrollableInstance
    ? `#${instance} ${scrollableInstance}`
    : `#${instance}`;

  useEffect(() => {
    let instance = document.querySelector(instanceQuery);
    instance && (instance.scrollTop = 0);
  }, [...dependencies]);
};

export const useRemoveTooltipVisibility = ({
  instance,
  dependencies = [],
  removeTooltipVisibility,
  condition = true
}) => {
  const { isTargetDevice } = useTargetDevice();

  const instanceQuery = isTargetDevice
    ? `#${instance} div.simplebar-content-wrapper`
    : `#${instance}`;

  useEffect(() => {
    if (!instance) return;
    instance = document.querySelector(instanceQuery);
    if (condition && instance) instance.addEventListener('scroll', removeTooltipVisibility);

    return () => instance?.removeEventListener('scroll', removeTooltipVisibility);
  }, [...dependencies]);
};

export const useExchangeTooltip = ({ rateInfo, scrollInstanceId }) => {
  const [isExchangeTooltipVisible, setIsExchangeTooltipVisible] = useState(false);
  const { tooltipCoords, handleHover: handleExchangeDataHover } = useTooltipCoordinates(
    setIsExchangeTooltipVisible
  );

  const handleExchangeDataMouseOut = () => setIsExchangeTooltipVisible(false);

  useRemoveTooltipVisibility({
    dependencies: [isExchangeTooltipVisible],
    condition: isExchangeTooltipVisible,
    removeTooltipVisibility: handleExchangeDataMouseOut,
    instance: scrollInstanceId
  });

  const RateInfoIcon = ({ className }) =>
    rateInfo ? (
      <InfoCircleIcon
        className={className}
        onMouseEnter={handleExchangeDataHover}
        onMouseLeave={handleExchangeDataMouseOut}
      />
    ) : null;

  const ExchangeTooltip = () =>
    isExchangeTooltipVisible &&
    rateInfo && (
      <Portal
        component={ExchangeDataTooltip}
        nameOfClass="exchange-data-tooltip-js"
        lastUpdate={`${moment(rateInfo.date).format('MMM DD, h:mm A')} UTC`}
        formattedExchangeRate={`1 USD = ${rateInfo.rate} ${rateInfo.currency}`}
        tooltipCoords={tooltipCoords}
      />
    );

  return { RateInfoIcon, ExchangeTooltip };
};

export const useCompanyRestriction = () => {
  const { companyName } = useSelector(({ company }) => company);
  const { NODE_ENV, REACT_APP_ENVIRONMENT } = process.env;

  const isProductionEnv = NODE_ENV === 'production';
  const isProdEnv = isProductionEnv && REACT_APP_ENVIRONMENT !== 'staging';
  const companyList = ['Tribal Credit', 'Aingel', 'exposit', 'Tribal'];
  const isCompanyExistsInList = companyList.includes(companyName);
  const isRestrictedByCompany = !isCompanyExistsInList && isProdEnv;

  return { isRestrictedByCompany };
};

/**
 * Right now we don't use this hook. It will be needed in the future, for launchdarkly implementation in authentication
 * */
export const useLaunchDarkly = ({ withoutClientIdentification } = {}) => {
  const launchDarklyClient = useLDClient();
  const flags = useFlags();

  useEffect(() => {
    if (withoutClientIdentification) return;
    const token = getToken();

    const previousUser = launchDarklyClient.getUser();

    const newUser = {
      key: token.userId,
      secondary: token.customerId,
      email: token.unique_name,
      custom: {
        companyId: token.customerId
      }
    };

    launchDarklyClient.alias(newUser, previousUser);
  }, []);

  return { launchDarklyClient, flags };
};

export const useCheckbox = () => {
  const [isChecked, setIsChecked] = useState(false);
  const toggleCheckbox = () => setIsChecked(!isChecked);

  return { isChecked, toggleCheckbox };
};
