import { invoicesConstants } from '../constants/invoices.constants';
import { satConstants } from '../constants/sat.constants';

const INITIAL_STATE = {
  unpaidInvoices: [],
  isSATConnected: false,
  firstPage: 0,
  lastPage: 0,
  nextPage: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case invoicesConstants.SET_UNPAID_INVOICES:
      return {
        ...state,
        unpaidInvoices: action.payload.setNewList
          ? action.payload.unpaidInvoices
          : [...state.unpaidInvoices, ...action.payload.unpaidInvoices],
        firstPage: action.payload.firstPage,
        lastPage: action.payload.lastPage,
        nextPage: action.payload.nextPage
      };
    case satConstants.IS_SAT_CONNECTED:
      return { ...state, isSATConnected: action.payload };
    default:
      return state;
  }
}
