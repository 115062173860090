/**
 * Auth Routes
 */
export const INDEX_ROUTE = '/';
export const LOGIN_ROUTE = '/login';
export const MAINTENANCE_ROUTE = '/maintenance';
export const PASSWORD_RECOVER_ROUTE = '/password-recover'; // njsscan-ignore: node_password
export const RESET_PASSWORD_ROUTE = '/reset-password'; // njsscan-ignore: node_password
export const SET_PASSWORD_ROUTE = '/set-password'; // njsscan-ignore: node_password
export const TWO_FACTOR_AUTH_ROUTE = '/two-factor-auth';
export const EMAIL_UPDATED_ROUTE = '/email-updated';
export const COMPLETE_REGISTER_ROUTE = '/complete-register';
/**
 * Private Routes
 */
export const CARDS_ROUTE = '/cards';
export const CARDS_ADD_ROUTE = '/cards/add';
export const CARD_DETAILS_ID_ROUTE = '/cards/details/:id';
export const CARD_DETAILS_ROUTE = '/cards/details';
export const USERS_ROUTE = '/users'; // njsscan-ignore: node_username
export const USERS_ADD_ROUTE = '/users/add'; // njsscan-ignore: node_username
export const USERS_UPDATE_ROUTE = '/users/update'; // njsscan-ignore: node_username
export const USERS_UPDATE_ID_ROUTE = '/users/update/:id'; // njsscan-ignore: node_username
export const SETTINGS_ROUTE = '/settings';
export const NOTIFICATIONS_ROUTE = '/notifications';
export const STATEMENTS_ROUTE = '/statements';
export const WALLET_ROUTE = '/wallet';
export const PENDING_RECIPIENTS_ROUTE = '/pending-recipients';
export const REPORTS_ROUTE = '/reports';
export const INTEGRATIONS_ROUTE = '/integrations';
export const NOT_FOUND_ROUTE = '/not-found';
export const OOPS_404_ROUTE = '/404';
export const SEND_PAYMENT_ROUTE = '/send-payment';
export const INSIGHTS_ROUTE = '/insights';
export const REWARDS_ROUTE = '/rewards';
export const INVOICES_ROUTE = '/invoices';
export const SUCCESS_ROUTE = '/success';
export const CREDIT_LINE_INCREASE = '/credit-line-increase';
export const PAYMENT_CONFIRMATION = '/payment-confirmation';
export const APPROVAL_REQUEST_ROUTE = '/send-payment/approval-request';
