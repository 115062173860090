import { useEffect, useRef, useState } from 'react';

import { useClickOutsideElement } from '../../../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { depositsActions } from '../../../../actions/deposits.action';
import { useTranslation } from 'react-i18next';
import { getFlagImgByCurrencyCode } from '../../../../services/currency.service';
import { paymentTypes } from './utils';

const {
  local: { STP, SPEI },
  international: { CURRENCY_CLOUD, SWAP }
} = paymentTypes;

export const useClickOutside = ({ closeRightModal }) => {
  const rightModalRef = useRef();
  useClickOutsideElement(rightModalRef, closeRightModal, true);

  return { rightModalRef };
};

export const isLocalPayment = transactionType => {
  const international = [CURRENCY_CLOUD, SWAP];
  const local = [SPEI, STP];
  return local.includes(transactionType?.toLowerCase());
};

export const useGetPaymentInfo = ({ transactionId, currencyCode }) => {
  const { t } = useTranslation('send-payment');
  const companyName = useSelector(state => state.company.companyName);
  const dispatch = useDispatch();
  const [transactionInfo, setTransactionInfo] = useState(null);
  const [isGetTransactionInfoLoading, setGetTransactionInfoLoading] = useState(false);
  const [hasPaymentInfoError, setPaymentInfoError] = useState(false);

  useEffect(() => {
    if (transactionId) {
      setGetTransactionInfoLoading(true);
      dispatch(depositsActions.getPaymentInfo({ paymentId: transactionId }))
        .then(response => {
          response.isLocalPayment = isLocalPayment(response?.integration_type);
          response.flagImg = getFlagImgByCurrencyCode(currencyCode);
          if (
            response &&
            (!response?.message || !response?.message?.length) &&
            companyName &&
            !response.isLocalPayment
          ) {
            response.message = `${t('confirmPayment.from')} ${companyName}`;
          }

          if (!response.status.is_success) setPaymentInfoError(true);
          setTransactionInfo(response);
        })
        .then(() => setGetTransactionInfoLoading(false))
        .catch(() => setPaymentInfoError(true));
    }
  }, [transactionId]);

  return { transactionInfo, isGetTransactionInfoLoading, hasPaymentInfoError };
};
