import axios from 'axios';

import { API_URL } from '../constants/common.constants';
import { handleRequestError } from './handlers/error-handlers';
import { getDeviceId } from '../utils/auth-client';
import { _DEFAULT_CONFIG } from './config';

export const configurationService = {
  getUserStatuses
};

async function getUserStatuses() {
  const deviceId = await getDeviceId();
  return axios
    .get(`${API_URL}/api/users/user-status`, {
      ...(await _DEFAULT_CONFIG()),
      params: { device_id: deviceId }
    })
    .catch(handleRequestError);
}
