import axios from 'axios';

import { API_URL } from '../constants/common.constants';
import { userConstants } from '../constants/user.constants';
import { handleRequestError } from './handlers/error-handlers';
import { getDeviceId } from '../utils/auth-client';
import { getAuthorizationForRequest } from '../utils/network-util';
import { _DEFAULT_CONFIG } from './config';

export const userService = {
  getUserInfo,
  getSystemLanguages,
  updateUserLanguage,
  getTopMerchants,
  getMostActiveUsers,
  getUsers,
  updateUserProfile,
  updateUserFirstName,
  updateUserLastName,
  updateUserSecondLastName,
  updateUserMobile,
  verifyUserMobile,
  updateUserEmail,
  updateUserTitle,
  updateUserDepartment,
  updateUserWalletAvailability,
  updateUserLimit,
  updateUserStatus,
  resendPendingMobile,
  resendPendingEmail,
  resetPendingMobile,
  resetPendingEmail,
  addNewUser,
  changePassword,
  getTrustedDevices,
  removeTrustedDevice,
  removeAllTrustedDevices
};

async function getUserInfo(billingId, userId) {
  const deviceId = await getDeviceId();
  let params = {
    device_id: deviceId
  };
  if (billingId) params['billing_id'] = billingId;
  if (userId) params['user_id'] = userId;
  return axios
    .get(`${API_URL}/api/users/user-info`, {
      ...(await _DEFAULT_CONFIG()),
      params
    })
    .catch(handleRequestError);
}

async function getSystemLanguages() {
  const deviceId = await getDeviceId();

  return axios
    .get(`${API_URL}/get-system-languages`, {
      ...(await _DEFAULT_CONFIG()),
      params: { device_id: deviceId }
    })
    .catch(handleRequestError);
}

function getTopMerchants(billingId) {
  let params = { device_id: getDeviceId() };
  if (billingId) params['billing_id'] = billingId;
  return axios
    .get(`${API_URL}/api/transactions/top-merchant`, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: getAuthorizationForRequest()
      },
      params
    })
    .catch(handleRequestError);
}

function getMostActiveUsers(billingId) {
  let params = { device_id: getDeviceId() };
  if (billingId) params['billing_id'] = billingId;
  return axios
    .get(`${API_URL}/api/users/most-active-users`, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: getAuthorizationForRequest()
      },
      params
    })
    .catch(handleRequestError);
}

let cancelGetUsers;
async function getUsers({ billingId, page, sortIndex, searchString }, cancelToken) {
  (searchString || searchString === '') && cancelGetUsers?.cancelToken?.();
  cancelGetUsers = cancelToken;

  let params = {
    device_id: getDeviceId(),
    page: page ? page : 1,
    SortBy: sortIndex ? sortIndex : 1,
    search_str: searchString ? searchString : null
  };

  if (billingId) params['billing_id'] = billingId;
  return axios
    .get(`${API_URL}/api/cards/cards`, {
      ...(await _DEFAULT_CONFIG(cancelToken)),
      params
    })
    .catch(handleRequestError);
}

function updateUserProfile(payload, userId) {
  const flags = userConstants.UPDATE_SELECTED_USER_FLAGS;
  const data = {
    device_id: getDeviceId(),
    new_data: {}
  };

  if (userId) data['user_id'] = userId;
  let enablePhysicalCardUrlParams = null;

  for (const item in payload) {
    switch (item) {
      case flags.FIRST_NAME:
        data.new_data.first_name = payload[item];
        break;
      case flags.LAST_NAME:
        data.new_data.last_name = payload[item];
        break;
      case flags.DEPARTMENT:
        data.new_data.department_id = payload[item].id;
        break;
      case flags.TITLE:
        data.new_data.title = payload[item];
        break;
      case flags.EMAIL:
        data.new_data.email = payload[item];
        break;
      case flags.MOBILE:
        data.new_data.mobile_code = payload[item].code;
        data.new_data.mobile_number = payload[item].number;
        break;
      case flags.WALLET:
        data.new_data.enable_wallets = payload[item];
        break;
      case flags.ADVANCED_CONTROLS:
        data.new_data.BlockedMerchants = payload[item].restrictedMerchantCategoriesKeys;
        data.new_data.TransactionLimit = payload[item].transactionLimit;
        break;
      case flags.PHYS_CARDS: {
        const deviceId = getDeviceId();
        let urlParams = `?device_id=${deviceId}&isEnabled=${payload[item]}`;
        if (userId) {
          urlParams += `&user_id=${userId}`;
        }
        enablePhysicalCardUrlParams = urlParams;
        break;
      }
      case flags.TYPE:
        data.new_data.user_type_id = payload[item];
        break;
      case flags.LIMIT:
        data.new_data.limit = Number.parseInt(payload[item], 10);
        break;
      case flags.STATE:
        data.new_data.user_state = payload[item];
        break;
      default:
        break;
    }
  }

  if (enablePhysicalCardUrlParams) {
    return axios
      .post(
        `${API_URL}/api/users/update-enable-physical-Card${enablePhysicalCardUrlParams}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            crossDomain: true,
            mode: 'no-cors',
            Authorization: getAuthorizationForRequest()
          }
        }
      )
      .then(response => response.data)
      .catch(handleRequestError);
  } else {
    return axios
      .patch(`${API_URL}/api/users/update-user-profile`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          crossDomain: true,
          mode: 'no-cors',
          Authorization: getAuthorizationForRequest()
        }
      })
      .then(response => response.data)
      .catch(handleRequestError);
  }
}

function updateUserFirstName(firstName, userId) {
  const deviceId = getDeviceId();
  let urlParams = `?device_id=${deviceId}&first_name=${firstName}`;
  if (userId) {
    urlParams += `&user_id=${userId}`;
  }

  return axios
    .post(
      `${API_URL}/api/users/update-first-name${urlParams}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          crossDomain: true,
          mode: 'no-cors',
          Authorization: getAuthorizationForRequest()
        }
      }
    )
    .then(response => response.data)
    .catch(handleRequestError);
}

function updateUserLanguage(languageId) {
  const deviceId = getDeviceId();
  let urlParams = `?device_id=${deviceId}&languageId=${languageId}`;

  return axios
    .post(
      `${API_URL}/update-user-language${urlParams}`,
      {
        languageId
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          crossDomain: true,
          mode: 'no-cors',
          Authorization: getAuthorizationForRequest()
        }
      }
    )
    .then(response => response.data)
    .catch(handleRequestError);
}

function updateUserLastName(lastName, userId) {
  const deviceId = getDeviceId();
  let urlParams = `?device_id=${deviceId}&last_name=${lastName}`;
  if (userId) {
    urlParams += `&user_id=${userId}`;
  }

  return axios
    .post(
      `${API_URL}/api/users/update-last-name${urlParams}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          crossDomain: true,
          mode: 'no-cors',
          Authorization: getAuthorizationForRequest()
        }
      }
    )
    .then(response => response.data)
    .catch(handleRequestError);
}

function updateUserSecondLastName(secondLastName, userId) {
  const deviceId = getDeviceId();
  let urlParams = `?device_id=${deviceId}&second_last_name=${secondLastName}`;
  if (userId) {
    urlParams += `&user_id=${userId}`;
  }

  return axios
    .post(
      `${API_URL}/api/users/update-mexican-user-info${urlParams}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          crossDomain: true,
          mode: 'no-cors',
          Authorization: getAuthorizationForRequest()
        }
      }
    )
    .then(response => response.data)
    .catch(handleRequestError);
}

function updateUserMobile(code, number, userId) {
  let data = {
    device_id: getDeviceId(),
    mobile_code: code,
    mobile_number: number
  };

  if (userId) {
    data.user_id = userId;
  }

  return axios
    .post(`${API_URL}/api/users/update-mobile`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: getAuthorizationForRequest()
      }
    })
    .then(response => response.data)
    .catch(handleRequestError);
}

function verifyUserMobile(codeId, code) {
  let data = {
    code_id: codeId,
    code
  };
  const deviceId = getDeviceId();

  return axios
    .post(`${API_URL}/api/users/verify-mobile?device_id=${deviceId}`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: getAuthorizationForRequest()
      }
    })
    .then(response => response.data)
    .catch(handleRequestError);
}

function updateUserEmail(email, userId) {
  let data = {
    device_id: getDeviceId(),
    email
  };

  if (userId) {
    data.user_id = userId;
  }

  return axios
    .post(`${API_URL}/api/users/update-email`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: getAuthorizationForRequest()
      }
    })
    .then(response => response.data)
    .catch(handleRequestError);
}

function updateUserTitle(title, userId) {
  const deviceId = getDeviceId();
  let urlParams = `?device_id=${deviceId}&title=${title}`;
  if (userId) {
    urlParams += `&user_id=${userId}`;
  }

  return axios
    .post(
      `${API_URL}/api/users/update-title${urlParams}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          crossDomain: true,
          mode: 'no-cors',
          Authorization: getAuthorizationForRequest()
        }
      }
    )
    .then(response => response.data)
    .catch(handleRequestError);
}

function updateUserDepartment(departmentId, userId) {
  const deviceId = getDeviceId();
  let urlParams = `?device_id=${deviceId}&department_id=${departmentId}`;
  if (userId) {
    urlParams += `&user_id=${userId}`;
  }

  return axios
    .post(
      `${API_URL}/api/users/update-department${urlParams}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          crossDomain: true,
          mode: 'no-cors',
          Authorization: getAuthorizationForRequest()
        }
      }
    )
    .then(response => response.data)
    .catch(handleRequestError);
}

function updateUserWalletAvailability(isEnabled, userId) {
  const deviceId = getDeviceId();
  let urlParams = `?device_id=${deviceId}`;
  return axios
    .post(
      `${API_URL}/api/users/update-enable-wallets${urlParams}`,
      {
        user_id: userId,
        enable_wallets: isEnabled
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          crossDomain: true,
          mode: 'no-cors',
          Authorization: getAuthorizationForRequest()
        }
      }
    )
    .then(response => response.data)
    .catch(handleRequestError);
}

function updateUserLimit(userId, limit) {
  const urlParams = userId
    ? `?user_id=${userId}&limit=${limit}&device_id=${getDeviceId()}`
    : `?limit=${limit}&device_id=${getDeviceId()}`;
  return fetch(`${API_URL}/api/users/update-limit${urlParams}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: getAuthorizationForRequest()
    }
  });
}

function updateUserStatus(id, statusId) {
  return fetch(
    `${API_URL}/api/users/update-status?user_id=${id}&status_id=${statusId}&device_id=${getDeviceId()}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: getAuthorizationForRequest()
      }
    }
  );
}

function resendPendingMobile(codeId, userId) {
  let data = {
    code_id: codeId
  };

  if (userId) {
    data.user_id = userId;
  }

  return axios
    .post(`${API_URL}/api/users/resend-mobile-code`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: getAuthorizationForRequest()
      }
    })
    .then(response => response.data)
    .catch(handleRequestError);
}

function resendPendingEmail(email, userId) {
  let data = {
    device_id: getDeviceId(),
    email
  };

  if (userId) {
    data.user_id = userId;
  }

  return axios
    .post(`${API_URL}/api/users/resend-email-confirmation`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: getAuthorizationForRequest()
      }
    })
    .then(response => response.data)
    .catch(handleRequestError);
}

function resetPendingMobile(userId) {
  return axios
    .put(
      `${API_URL}/api/users/remove-pending-mobiles?user_id=${userId}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          crossDomain: true,
          mode: 'no-cors',
          Authorization: getAuthorizationForRequest()
        }
      }
    )
    .then(response => response.data)
    .catch(handleRequestError);
}

function resetPendingEmail(userId) {
  return axios
    .put(
      `${API_URL}/api/users/remove-pending-mails?user_id=${userId}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          crossDomain: true,
          mode: 'no-cors',
          Authorization: getAuthorizationForRequest()
        }
      }
    )
    .then(response => response.data)
    .catch(handleRequestError);
}

function addNewUser(user) {
  return axios
    .post(`${API_URL}/api/users/add-user`, user, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: getAuthorizationForRequest()
      }
    })
    .then(response => response.data)
    .catch(handleRequestError);
}

function getTrustedDevices() {
  return axios
    .get(`${API_URL}/api/settings/trusted-devices`, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: getAuthorizationForRequest()
      },
      params: { device_id: getDeviceId() }
    })
    .catch(handleRequestError);
}

function removeTrustedDevice(targetId) {
  const deviceId = getDeviceId();
  return axios
    .post(
      `${API_URL}/api/settings/remove-device?device_id=${deviceId}&target_id=${targetId}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          crossDomain: true,
          mode: 'no-cors',
          Authorization: getAuthorizationForRequest()
        }
      }
    )
    .then(response => response.data)
    .catch(handleRequestError);
}

function removeAllTrustedDevices() {
  const deviceId = getDeviceId();
  return axios
    .post(
      `${API_URL}/api/settings/remove-all-devices?device_id=${deviceId}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          crossDomain: true,
          mode: 'no-cors',
          Authorization: getAuthorizationForRequest()
        }
      }
    )
    .then(response => response.data)
    .catch(handleRequestError);
}

function changePassword(oldPassword, newPassword, deviceId) {
  const data = {
    old_password: oldPassword,
    new_password: newPassword
  };

  return axios
    .post(`${API_URL}/api/users/change-password?device_id=${deviceId}`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: getAuthorizationForRequest()
      }
    })
    .then(response => response.data)
    .catch(handleRequestError);
}
