import classNames from 'classnames';
import PropTypes from 'prop-types';

import './index.scss';

const NavItem = ({ label, isSelected }) => (
  <div
    className={classNames('nav-item', {
      selected: isSelected
    })}
  >
    <span className="nav-item__label">{label}</span>
  </div>
);

export default NavItem;

NavItem.propTypes = {
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool
};

NavItem.defaultProps = {
  isSelected: true
};
