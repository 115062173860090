import classNames from 'classnames';
import PropTypes from 'prop-types';

import './index.scss';

const NotificationButton = ({ type, label, src, ...rest }) => (
  <a
    className={classNames('notification-button', {
      'product-banner': type === 'product_banner'
    })}
    target="_blank"
    href={src}
    {...rest}
  >
    {label}
  </a>
);

export default NotificationButton;

NotificationButton.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired
};
