import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';

import { useTargetDevice } from '../../hooks';

import 'simplebar/dist/simplebar.min.css';

export const ScrollableArea = ({ styles, className, children, ...rest }) => {
  const { isTargetDevice } = useTargetDevice();

  return !isTargetDevice ? (
    <>{children}</>
  ) : (
    <SimpleBar
      {...rest}
      className={className}
      style={{ height: '100%', overflowX: 'hidden', ...styles }}
    >
      {children}
    </SimpleBar>
  );
};

ScrollableArea.propTypes = {
  styles: PropTypes.object,
  className: PropTypes.string
};

ScrollableArea.defaultProps = {
  styles: null,
  className: null
};
