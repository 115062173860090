import _ from 'lodash';
import moment from 'moment';

import { walletConstants } from '../constants/wallet.constants';
import { walletService } from '../services';

import { getCitiesAction } from './helpers/wallet.helper';
import { depositsConstants } from '../constants/deposits.constants';
import { formatTransactions } from '../reducers/deposits';

function getPurposeCodes({ currency, bankAccountCountry }) {
  return () => {
    return new Promise((resolve, reject) => {
      return walletService
        .getPurposeCodes({ currency, bankAccountCountry })
        .then(response => {
          if (response.data.status.is_success) {
            resolve(response?.data);
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getBeneficiaries() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return walletService
        .getBeneficiaries()
        .then(response => {
          if (response.data?.status?.is_success) {
            dispatch({
              type: walletConstants.SET_BENEFICIARIES,
              beneficiaries: response.data.beneficiary_info || []
            });
            resolve();
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function approvedDeclinedRecipient(id, isActive) {
  return () => {
    return new Promise((resolve, reject) => {
      return walletService
        .approvedDeclinedRecipient(id, isActive)
        .then(({ data }) => {
          if (data && data.status.is_success) {
            resolve(data);
          } else {
            reject(data.status.error_message || '');
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getBeneficiaryDetails(id, cancelToken) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return walletService
        .getBeneficiaryDetails(id, cancelToken)
        .then(({ data }) => {
          if (data && data.status.is_success) {
            dispatch({
              type: walletConstants.SET_BENEFICIARY_DETAILS,
              details: data
            });
            resolve(data);
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getPaymentDetails(id, cancelToken) {
  return () => {
    return new Promise((resolve, reject) => {
      return walletService
        .getPaymentDetails(id, cancelToken)
        .then(({ data }) => {
          if (data && data.status.is_success) {
            resolve(data);
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function createBeneficiary(beneficiary, companyId) {
  return () => {
    return new Promise((resolve, reject) => {
      return walletService
        .createBeneficiary(beneficiary, companyId)
        .then(({ data }) => {
          if (data && data.status.is_success) {
            resolve(data);
          } else {
            reject(data.status.error_message || '');
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function updateBeneficiary(beneficiary, companyId) {
  return () => {
    return new Promise((resolve, reject) => {
      return walletService
        .updateBeneficiary(beneficiary, companyId)
        .then(({ data }) => {
          if (data && data.status.is_success) {
            resolve(data);
          } else {
            reject(data.status.error_message || '');
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function createPayment(data, { companyId = "", isSatPayment = false }) {
  return () => {
    return new Promise((resolve, reject) => {
      return walletService
        .createPayment(data, companyId, isSatPayment)
        .then(({ data }) => {
          if (data) {
            if (data.status.is_success) {
              resolve();
            } else {
              reject(data.status);
            }
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function createBulkPayments(data, arcusPayment) {
  return () => {
    return new Promise((resolve, reject) => {
      return walletService
        .createBulkPayments(data, arcusPayment)
        .then(({ data }) => {
          if (data) {
            if (data.status.is_success) {
              resolve();
            } else {
              reject(data.status);
            }
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getBankDetails(id, currencyId, countryId) {
  return () => {
    return new Promise((resolve, reject) => {
      return walletService
        .getBankDetails(id, currencyId, countryId)
        .then(({ data }) => {
          if (data && data.status.is_success) {
            resolve(data);
          } else {
            reject(data.status.error_message || '');
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getCountries() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return walletService
        .getCountries()
        .then(({ data }) => {
          if (data && data.status.is_success) {
            dispatch({
              type: walletConstants.SET_RECIPIENT_COUNTRIES,
              countriesInfo: data.countries_info
            });
            resolve(data);
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getBanks() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return walletService
        .getBanks()
        .then(({ data }) => {
          if (data && data.status.is_success) {
            dispatch({
              type: walletConstants.SET_BANKS,
              banks: data.banks
            });
            resolve(data);
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getCities(countryId, stateId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(getCitiesAction({}));
      return walletService
        .getCities(countryId, stateId)
        .then(({ data }) => {
          if (data && data.status.is_success) {
            dispatch(getCitiesAction({ citiesInfo: data.cities_info }));
            resolve(data);
          } else if (data?.status?.error_message === 'NotExistGetResult') {
            dispatch(getCitiesAction({ isCityState: true, citiesInfo: data.cities_info }));
            resolve();
          } else reject();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getStates(countryId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return walletService
        .getStates(countryId)
        .then(({ data }) => {
          if (data && data.status.is_success) {
            dispatch({
              type: walletConstants.SET_RECIPIENT_STATES,
              statesInfo: data.states_info
            });
            resolve(data);
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getRate(beneficiaryId) {
  return () => {
    return new Promise((resolve, reject) => {
      return walletService
        .getRate(beneficiaryId)
        .then(({ data }) => {
          if (data && data.status.is_success) {
            resolve(data);
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getBalanceInfo(billingId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return walletService
        .getBalanceInfo(billingId)
        .then(({ data }) => {
          if (data && data.status.is_success) {
            dispatch({
              type: walletConstants.SET_BALANCE,
              companyBalance: data.company_balance,
              userBalance: data.user_balance
            });
            resolve(data);
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

const getCurrencies = cancelToken => dispatch =>
  new Promise((resolve, reject) =>
    walletService
      .getCurrencies(cancelToken)
      .then(({ data }) => {
        if (data && data.status && data.status.is_success === true) {
          dispatch({
            type: walletConstants.SET_WALLET_CURRENCIES,
            currencies: data.currencies_info
          });
          resolve(data.currencies_info);
        } else {
          reject();
        }
      })
      .catch(error => {
        reject(error);
      })
  );

const getWalletFeatures = () => dispatch =>
  new Promise((resolve, reject) =>
    walletService
      .getWalletFeatures()
      .then(({ data }) => {
        if (data && data.status && data.status.is_success === true) {
          dispatch({
            type: walletConstants.SET_WALLET_FEATURES,
            features: data.features
          });
          resolve();
        } else {
          reject();
        }
      })
      .catch(error => {
        reject(error);
      })
  );

function acceptPaymentAgreement() {
  return dispatch =>
    new Promise((resolve, reject) =>
      walletService
        .acceptPaymentAgreement()
        .then(({ data }) => {
          const isSuccess = _.get(data, 'status.is_success', false);
          if (isSuccess) {
            dispatch({
              type: walletConstants.ACCEPT_PAYMENT_AGREEMENT
            });
            resolve();
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        })
    );
}

function uploadBulkPaymentFile({ file, fileName }, arcusPayment) {
  return () =>
    new Promise((resolve, reject) =>
      walletService
        .uploadBulkPaymentFile({ file }, arcusPayment)
        .then(response => {
          if (response?.status !== 200) return reject();
          const { data } = response;
          const isSuccess = _.get(data, 'status.is_success', false);
          if (isSuccess) {
            const {
              bulk_payment_info: bulkPaymentInfo,
              bulk_payments: bulkPayment,
              file_errors: fileErrors,
              file_id: fileId,
              is_available_valid: isAvailableValid,
              delivery_date: deliveryDate,
              date,
              rate,
              payment_type: paymentType,
              wire_fee: wireFee,
              bulk_id: bulkId
            } = data;

            return resolve({
              bulkPaymentInfo,
              bulkPayment,
              fileErrors,
              fileId,
              isAvailableValid,
              rateInfo: { date, rate },
              deliveryDate: moment(deliveryDate).format('MMM DD, YYYY'),
              paymentType: paymentType,
              wireFee,
              fileName,
              bulkId
            });
          } else {
            const emptyFileCondition = !data.file_errors && !data.bulk_payments && !isSuccess;
            const commonMessage =
              data?.status?.error_message || 'Operation failure. Please try again';
            const emptyFileErrorCode = 'translation:uploadFile.errors.emptyFile';

            reject(emptyFileCondition ? emptyFileErrorCode : commonMessage);
          }
        })
        .catch(error => {
          reject(error);
        })
    );
}

function downloadPaymentFile({ fileId, fileName, bulkId }) {
  return () =>
    new Promise((resolve, reject) =>
      walletService
        .downloadPaymentFile({ fileId, fileName, bulkId })
        .then(response => {
          if (response.status === 200 && response?.data?.status?.is_success) {
            resolve(response.data);
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        })
    );
}

function getRecurringEmails() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) =>
      walletService
        .getRecurringEmails()
        .then(response => {
          if (response.status === 200 && response?.data?.status?.is_success) {
            let recurringEmails = response.data.emails.filter(Boolean);
            const email = getState().user.user.email;
            !recurringEmails.includes(email) && recurringEmails.unshift(email);
            dispatch({
              type: walletConstants.SET_RECURRING_EMAILS,
              recurringEmails: recurringEmails || []
            });
            resolve(response.data);
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        })
    );
}

function postRecurringEmails({ recurringEmails }) {
  return () =>
    new Promise((resolve, reject) =>
      walletService
        .postRecurringEmails({ recurringEmails })
        .then(response => {
          if (response.status === 200 && response?.data?.status?.is_success) {
            resolve(response.data);
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        })
    );
}

function addBeneficiary(beneficiary) {
  return dispatch => {
    dispatch({
      type: walletConstants.ADD_BENEFICIARY,
      beneficiary
    });
  };
}

const getConversion = async (data, companyId) => {
  try {
    return await walletService.getConversion(data, companyId);
  } catch (e) {
    throw Error(e);
  }
}

function getBulkPayments({ bulkId, page }) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) =>
      walletService
        .getBulkPayments({ bulkId, page })
        .then(response => {
          if (response.status === 200 && response?.data?.status?.is_success) {
            const itemsPerPage = _.get(response, 'data.paging.items_per_page');
            const totalPages = _.get(response, 'data.paging.total_pages');
            const currentPage = _.get(response, 'data.paging.current_page');

            const preparedTransactions = response.data.data.map(transaction => ({
              ...formatTransactions({
                paymentInstance: transaction,
                paymentStatuses: getState().deposits.paymentStatuses
              })
            }));

            const preparedData = {
              preparedTransactions,
              pagination: {
                currentPage,
                totalPages,
                itemsPerPage
              }
            };

            dispatch({
              type: depositsConstants.CACHE_BULK_TRANSACTIONS,
              bulkId,
              page,
              preparedData
            });

            resolve(preparedData);
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        })
    );
}

export const walletActions = {
  getBeneficiaries,
  getBeneficiaryDetails,
  getPaymentDetails,
  createBeneficiary,
  updateBeneficiary,
  createPayment,
  createBulkPayments,
  getBankDetails,
  getCountries,
  getBanks,
  getCities,
  getStates,
  getRate,
  getConversion,
  getBalanceInfo,
  getCurrencies,
  getWalletFeatures,
  acceptPaymentAgreement,
  getPurposeCodes,
  uploadBulkPaymentFile,
  downloadPaymentFile,
  getRecurringEmails,
  postRecurringEmails,
  addBeneficiary,
  getBulkPayments,
  approvedDeclinedRecipient
};
