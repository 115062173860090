export const companyConstants = {
  SET_COMPANY_INFO: 'SET_COMPANY_INFO',
  LOADING_COMPANY_INFO: 'LOADING_COMPANY_INFO',
  SET_CURRENCIES: 'SET_CURRENCIES',
  SET_BILLING_PERIOD: 'SET_BILLING_PERIOD',
  SET_BILLING_PERIODS: 'SET_BILLING_PERIODS',
  SET_DEPARTMENTS: 'SET_DEPARTMENTS',
  CREATE_DEPARTMENT: 'CREATE_DEPARTMENT',

  SET_ADMINS: 'SET_ADMINS',

  SET_SUPER_ADMINS: 'SET_SUPER_ADMINS',

  SET_BILLING_CONTACTS: 'SET_BILLING_CONTACTS',
  ADD_BILLING_CONTACT: 'ADD_BILLING_CONTACT',
  DELETE_BILLING_CONTACT: 'DELETE_BILLING_CONTACT',
  SET_COMPANY_TRANSACTIONS: 'SET_COMPANY_TRANSACTIONS',

  SET_SYSTEM_INTEGRATIONS: 'SET_SYSTEM_INTEGRATIONS'
};

export const INCREDIT_TYPES = {
  INCREDIT_TYPE_ZERO: 'INCREDIT_TYPE_ZERO',
  INCREDIT_TYPE_ONE: 'INCREDIT_TYPE_ONE'
};
