import AddDepartmentRow from '../add-department-row';
import DepartmentRow from '../department-row';

import { ScrollableArea } from '../../../scrollable-area';

import './index.scss';

const Dropdown = props => {
  return (
    <div className="departments-dropdown__content">
      <ScrollableArea styles={{ width: '100%', maxHeight: 250, minHeight: 35 }}>
        {props.isAddDepartmentVisible ? (
          <AddDepartmentRow
            value={props.enteredDepartmentText}
            onAddClick={() => props.onCreation(props.enteredDepartmentText)}
          />
        ) : null}
        {props.data.map((el, index) => (
          <DepartmentRow
            key={index}
            value={el.label}
            isSelected={el.id === props.selectedDepartment.id}
            onSelection={event => props.onSelection(el)}
          />
        ))}
      </ScrollableArea>
    </div>
  );
};

export default Dropdown;
