import {
  getEmailValidationError,
  getMobileNumberValidationError,
  getPasswordValidationError,
  getPasswordValidationErrors
} from '../../services/validation-service';
import {
  EMAIL_TO_DEVICE_ID_MAP_KEY,
  TEMPO_DEVICE_ID_KEY,
  TEMPO_TOKEN_KEY
} from '../../constants/auth.constants';

export function getLoginValidationError(email, password) {
  const emailError = getEmailValidationError(email);
  return emailError ? emailError : getPasswordValidationError(password);
}

export function getRegistrationValidationError(password, mobileNumber) {
  const passwordErrors = getPasswordValidationErrors(password);
  let hasPasswordsErrors = false;
  for (let [key, value] of Object.entries(passwordErrors)) {
    if (value) {
      hasPasswordsErrors = true;
      break;
    }
  }
  return hasPasswordsErrors
    ? {
        isDirty: true,
        passwordError: passwordErrors,
        mobileError: null
      }
    : {
        isDirty: false,
        passwordError: null,
        mobileError: getMobileNumberValidationError(mobileNumber)
      };
}

export function setTemporaryAuthData(data) {
  window.sessionStorage.setItem(TEMPO_TOKEN_KEY, data.access_token);
  window.sessionStorage.setItem(TEMPO_DEVICE_ID_KEY, data.device_id);
}

export function cleanTemporaryAuthData() {
  window.sessionStorage.removeItem('tempo_token');
  window.sessionStorage.removeItem('tempo_device_id');
}

export function setAuthDataToLocalStorage(data, rememberMe) {
  window.sessionStorage.setItem('token', data.access_token);
  window.sessionStorage.setItem('device_id', data.device_id);

  if (rememberMe) {
    const { email } = data.profile;
    const rawDeviceIdMap = window.localStorage.getItem(EMAIL_TO_DEVICE_ID_MAP_KEY);
    const deviceIdMap = rawDeviceIdMap ? JSON.parse(rawDeviceIdMap) : {};
    deviceIdMap[email] = data.device_id;
    window.localStorage.setItem(EMAIL_TO_DEVICE_ID_MAP_KEY, JSON.stringify(deviceIdMap));
  }
}
