import PropTypes from 'prop-types';

import { ReactComponent as SearchLoopIcon } from '../../../../../assets/images/search-loop-icon.svg';

import './index.scss';

const SearchInput = ({ t, setEnteredText, enteredText }) => {
  const handleChange = ({ target }) => {
    const { value } = target;
    setEnteredText(value);
  };

  return (
    <div className="search">
      <div className="icon-container">
        <SearchLoopIcon className="search-loop-icon" />
      </div>
      <input
        className="search-input"
        onChange={handleChange}
        type="text"
        placeholder={t('searchModal.placeholder')}
        value={enteredText}
      />
    </div>
  );
};

export default SearchInput;

SearchInput.propTypes = {
  t: PropTypes.func.isRequired,
  enteredText: PropTypes.string,
  setEnteredText: PropTypes.func.isRequired
};

SearchInput.defaultProps = {
  enteredText: ''
};
