import { withLDProvider } from 'launchdarkly-react-client-sdk';

import { getToken } from './token-utils';

export const getAuthenticatedApp = component => {
  const token = getToken();

  const getUser = token =>
    token
      ? {
          key: token.userId,
          secondary: token.customerId,
          email: token.unique_name,
          custom: {
            companyId: token.customerId
          }
        }
      : null;

  const user = getUser(token);

  return user
    ? withLDProvider({ clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID, user })(
        component
      )
    : component;
};
