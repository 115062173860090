import * as _ from 'lodash';
import { cardsService } from '../services';

import { getNumberWithoutCommas } from '../utils/string-service';
import {
  getCardNameValidationError,
  getCardLimitValidationError,
  getCardHolderNameValidationError
} from '../services/validation-service';
import { getCurrencyById } from '../services/currency.service';

import { userConstants } from '../constants/user.constants';
import {
  cardsConstants,
  UPDATE_FLAGS,
  CARD_USAGE_OPTIONS,
  MINIMAL_CARD_LIMIT,
  CARD_STATUSES,
  CARD_REQUEST_STATUS_ID,
  CARD_TYPES_IDS,
  CARD_TYPES
} from '../constants/cards.constants';
import {
  transformCardStatusForRequest,
  transformCardTypeForRequest
} from '../constants/map.constants';
import { getDeviceId } from '../utils/auth-client';
import { isPhysicalCard } from '../utils/cards-util';

export const cardsActions = {
  createCard,
  getCards,
  getCardDetails,
  getCardRate,
  getCardTransactions,
  clearActiveCard,
  updateCardStatus,
  updateCard,
  reportCard,
  getPanInfo,
  updateCardVisibility,
  getCardsTypes,
  updateCardLimit,
  updateUserCardLimit,
  updateUserCardStatus,
  sortCardsBy,
  getCardPin,
  updateShippingAddress,
  hideShippingAddressUpdate
};

function createCard(card) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      if (!_getCardCreationValidationError(card)) {
        const formattedCard = _transformCardForCreation(card);
        return cardsService
          .createCard(formattedCard)
          .then(async response => {
            if (!response.status.is_success) {
              // TODO: change parameters below instead of nulls to actual data from response
              // TODO: when spacegene will implement this
              reject(_constructCardCreationError(null, null, null, response.status.error_message));
            } else {
              // in order to get currency by received from server id
              // we acquiring the data from company reducer
              const receivedCard = response.data;
              const typeId = receivedCard?.type_id;
              const isPhysicalCard = typeId === CARD_TYPES_IDS.PHYSICAL;
              let shippingAddressAvailableForUpdate = null;

              if (isPhysicalCard) {
                shippingAddressAvailableForUpdate = await getShippingAddressAvailableForUpdate({
                  cardId: receivedCard.id
                });
              }

              receivedCard.shippingAddressAvailableForUpdate = shippingAddressAvailableForUpdate;
              receivedCard.currency = getCurrencyById(getState().company, receivedCard.currency_id);

              dispatch({ type: cardsConstants.INCREMENT_CARDS_COUNT });
              dispatch({
                type: cardsConstants.ADD_AND_SET_ACTIVE_CARD,
                card: receivedCard
              });

              if (card?.type === CARD_TYPES.PHYSICAL) {
                dispatch({
                  type: userConstants.UPDATE_IS_PHYSICAL_CARD_ALLOWED,
                  IsPhysicalCardAllowed: false
                });
              }

              resolve(response.data);
            }
          })
          .catch(error => {
            reject(_constructCardCreationError(null, null, null, error));
          });
      } else {
        reject(_getCardCreationValidationError(card));
      }
    });
  };
}

function updateCard(actionFlag, payload) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const validationError = _getCardUpdateValidationError(actionFlag, payload);
      if (!validationError) {
        const newCard = _transformCardForUpdate(actionFlag, payload);
        return cardsService
          .updateCard(newCard)
          .then(async response => {
            if (!response.status.is_success) {
              reject(response.status.error_message);
            } else {
              // in order to get currency by received from server id
              // we acquiring the data from company reducer
              const preparedCard = response.data;
              preparedCard.currency = getCurrencyById(getState().company, preparedCard.currency_id);

              const typeId = preparedCard?.type_id;
              const isPhysicalCard = typeId === CARD_TYPES_IDS.PHYSICAL;
              let shippingAddressAvailableForUpdate = null;

              if (isPhysicalCard) {
                shippingAddressAvailableForUpdate = await getShippingAddressAvailableForUpdate({
                  cardId: preparedCard.id
                }).catch(error => error);
              }

              preparedCard.shippingAddressAvailableForUpdate = shippingAddressAvailableForUpdate;

              dispatch({
                type: cardsConstants.SET_ACTIVE_CARD,
                card: preparedCard
              });
              resolve(response.data.limit);
            }
          })
          .catch(error => {
            reject(error);
          });
      } else {
        reject(validationError);
      }
    });
  };
}

function updateCardLimit(actionFlag, payload) {
  return dispatch => {
    return _updateLimit(
      actionFlag,
      payload,
      data => {
        dispatch({
          type: cardsConstants.UPDATE_CARD_LIMIT,
          card: data
        });
      },
      () => {
        dispatch({
          type: cardsConstants.RESET_CARD_LIMIT,
          cardId: payload.cardId,
          prevLimit: payload.prevLimit
        });
      }
    );
  };
}

function updateUserCardLimit(actionFlag, payload) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const newCard = _transformCardForUpdate(actionFlag, payload);
      return cardsService
        .updateCard(newCard)
        .then(response => {
          if (!response.status.is_success) {
            dispatch({
              type: cardsConstants.RESET_USER_CARD_LIMIT,
              cardId: payload.cardId,
              userId: payload.userId,
              prevLimit: payload.prevLimit
            });
            reject(response.status.error_message);
          } else {
            dispatch({
              type: cardsConstants.UPDATE_USER_CARD_LIMIT,
              cardId: payload.cardId,
              userId: payload.userId,
              userCard: response.data
            });
            resolve(response.data.limit);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function updateCardStatus(id, statusId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return cardsService
        .updateCardStatus(id, transformCardStatusForRequest(statusId))
        .then(() => {
          dispatch({
            type: cardsConstants.UPDATE_CARD_STATUS,
            id,
            statusId
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function updateUserCardStatus(id, statusId, userId, cardTypeId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const newStatusId = statusId === CARD_STATUSES.ACTIVE ? 0 : 1;
      return cardsService
        .updateCardStatus(id, newStatusId)
        .then(response => {
          const newStatus =
            newStatusId === 1
              ? CARD_STATUSES.ACTIVE
              : isPhysicalCard(cardTypeId)
              ? CARD_STATUSES.ACTIVE
              : CARD_STATUSES.SUSPENDED;
          dispatch({
            type: userConstants.UPDATE_USER_CARD_STATUS,
            cardId: id,
            userId,
            statusId: newStatus
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getShippingAddressAvailableForUpdate({ cardId }) {
  return new Promise((resolve, reject) => {
    return cardsService
      .getShippingAddressAvailableForUpdate({ cardId })
      .then(({ status, data }) => {
        if (status === 200 && data?.status?.is_success)
          resolve({
            availableDuration: data.AvailableDuration,
            isAvailable: data.IsAvailable
          });
        else reject(cardsConstants.SHIPPING_ADDRESS_REJECT);
      })
      .catch(() => {
        reject();
      });
  });
}

function sortCardsBy(key) {
  return dispatch => {
    dispatch({
      type: cardsConstants.SORT_CARDS_BY_KEY,
      key
    });
  };
}

function getCards(
  { billingId, setNewCardsList, page, cardStatus, sortBy, searchString },
  cancelToken
) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      return cardsService
        .getCards({ billingId, page, cardStatus, sortBy, searchString }, cancelToken)
        .then(response => {
          if (!response.data?.status?.is_success) {
            reject(response.data?.status?.error_code);
          } else {
            // in order to get currency by received from server id
            // we acquiring the data from company reducer
            const cards = response.data.data.map(card => ({
              ...card,
              currency: getCurrencyById(getState().company, card.currency_id)
            }));

            const totalPages = _.get(response, 'data.paging.total_pages');
            const currentPage = _.get(response, 'data.paging.current_page');

            const areActiveCardsData = cardStatus === CARD_REQUEST_STATUS_ID.ACTIVE;

            const activeCardsPaginationInfo = {
              activeCurrentPage: currentPage,
              activeTotalPages: totalPages
            };

            const inactiveCardsPaginationInfo = {
              inactiveCurrentPage: currentPage,
              inactiveTotalPages: totalPages
            };

            const pagination = areActiveCardsData
              ? activeCardsPaginationInfo
              : inactiveCardsPaginationInfo;

            dispatch({
              type: cardsConstants.SET_CARDS,
              cards,
              setNewCardsList,
              pagination,
              cardStatus
            });
            resolve();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function updateShippingAddress({ shippingAddress }) {
  const shippingAddressTransformed = {
    country: shippingAddress?.country,
    city: shippingAddress?.city,
    state: shippingAddress?.state,
    postal_code: shippingAddress?.postalCode,
    CardId: shippingAddress?.cardId,
    AddressHouseNumber: shippingAddress?.addressHouseNumber,
    InteriorNumber: shippingAddress?.interiorNumber,
    Neighborhood: shippingAddress?.neighborhood,
    line1: shippingAddress?.address,
    FirstName: shippingAddress?.firstName,
    LastName: shippingAddress?.lastName,
    SecondLastName: shippingAddress?.secondLastName,
    country_id: shippingAddress?.countryId,
    city_id: shippingAddress?.cityId,
    state_id: shippingAddress?.stateId
  };

  return dispatch => {
    return new Promise((resolve, reject) => {
      return cardsService
        .updateShippingAddress({ shippingAddress: shippingAddressTransformed })
        .then(response => {
          if (response?.status !== 200 || !response?.data?.status?.is_success) return reject();
          dispatch({
            type: cardsConstants.SET_NEW_SHIPPING_ADDRESS,
            shippingAddress: shippingAddressTransformed
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getCardDetails(id, billingId) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      return cardsService
        .getCardBilling(id)
        .then(resp => {
          const cardBillingPeriods = resp.data ? resp.data.data : null;
          return cardsService
            .getCardDetails(id, billingId)
            .then(async response => {
              if (response.data.status.is_success) {
                // in order to get currency by received from server id
                // we acquiring the data from company reducer
                const card = response.data.data;

                const typeId = card?.type_id;
                const isPhysicalCard = typeId === CARD_TYPES_IDS.PHYSICAL;
                let shippingAddressAvailableForUpdate = null;

                if (isPhysicalCard) {
                  shippingAddressAvailableForUpdate = await getShippingAddressAvailableForUpdate({
                    cardId: card.id
                  }).catch(error => error);
                }

                card.shippingAddressAvailableForUpdate = shippingAddressAvailableForUpdate;

                card.currency = getCurrencyById(getState().company, card.currency_id);
                card.billingPeriods = cardBillingPeriods;

                dispatch({
                  type: cardsConstants.SET_ACTIVE_CARD,
                  card
                });
                resolve();
              } else {
                reject(response.data.status.error_message);
              }
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getCardPin(cardId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return cardsService
        .getCardPan(cardId)
        .then(response => response.json())
        .then(({ status, data }) => {
          if (!status?.is_success) reject();
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getCardRate(id, currencyId) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      return cardsService
        .getCardRate(id, currencyId)
        .then(response => {
          if (response.data.status.is_success) {
            resolve(response.data.data);
          } else {
            reject(response.data.status.error_message);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getCardTransactions(id, billingId) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      return cardsService
        .getCardTransactions(id, billingId)
        .then(response => {
          // in order to get currency by received from server id
          // we acquiring the data from company reducer
          const transactions = response.data.data;
          dispatch({
            type: cardsConstants.SET_ACTIVE_CARD_TRANSACTIONS,
            transactions,
            companyState: getState().company
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getPanInfo(id, isVisible) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (isVisible) {
        return cardsService
          .getCardPan(id)
          .then(response => response.json())
          .then(response => {
            dispatch({
              type: cardsConstants.UPDATE_CARD_PAN,
              pan: response.data
            });
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      } else {
        dispatch({
          type: cardsConstants.UPDATE_CARD_PAN,
          pan: null
        });
      }
    });
  };
}

function updateCardVisibility() {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: cardsConstants.UPDATE_CARD_VISIBILITY
      });

      resolve();
    });
}

function getCardsTypes() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return cardsService
        .getCardsTypes()
        .then(response => response.json())
        .then(response => {
          if (response.status.is_success) {
            dispatch({
              type: cardsConstants.SET_CARDS_TYPES,
              cardsTypes: response.data
            });
            resolve();
          } else {
            reject(response.error_mesage);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function reportCard(id, statusId, cardTypeId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return cardsService
        .reportCard(id, transformCardStatusForRequest(statusId))
        .then(response => {
          dispatch({
            type: cardsConstants.UPDATE_CARD_STATUS,
            id,
            statusId
          });
          if (
            cardTypeId === CARD_TYPES_IDS.PHYSICAL &&
            (statusId === CARD_STATUSES.STOLEN || statusId === CARD_STATUSES.LOST)
          ) {
            dispatch({
              type: userConstants.UPDATE_IS_PHYSICAL_CARD_ALLOWED,
              IsPhysicalCardAllowed: true
            });
          }
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function clearActiveCard() {
  return dispatch => {
    dispatch({
      type: cardsConstants.CLEAR_ACTIVE_CARD
    });
  };
}

function _transformCardForCreation(card) {
  const deviceId = getDeviceId();
  let formattedTags = card.tags.map(({ label }) => ({ label }));

  return {
    user_id: card.userId || null,
    device_id: deviceId,
    name: card.name,
    type_id: transformCardTypeForRequest(card.type),
    interval_id: card.usage === CARD_USAGE_OPTIONS.SINGLE_USE ? 0 : 1,
    limit: parseInt(getNumberWithoutCommas(card.limit), 10),
    tags: formattedTags,
    currency_id: card.currencyId,
    is_max: card.isSetToMax,
    address: card.address
  };
}

function _transformCardForUpdate(actionFlag, payload) {
  const preparedCard = {
    card_id: payload.cardId,
    device_id: getDeviceId(),
    new_data: {}
  };

  switch (actionFlag) {
    case UPDATE_FLAGS.UPDATE_NAME:
      preparedCard.new_data = { name: payload.name };
      break;
    case UPDATE_FLAGS.UPDATE_LIMIT:
      preparedCard.new_data = {
        is_max: payload.isSetToMax
      };
      if (payload.limit) {
        preparedCard.new_data.limit = parseInt(payload.limit, 10);
      }
      if (payload.currencyId) {
        preparedCard.currency_id = payload.currencyId;
      }
      break;
    case UPDATE_FLAGS.ADD_TAG:
    case UPDATE_FLAGS.DELETE_TAG:
      preparedCard.new_data = _transformTagsForUpdate(actionFlag, payload);
      break;
  }

  return preparedCard;
}

function _transformTagsForUpdate(actionFlag, payload) {
  let tags = [];

  if (actionFlag === UPDATE_FLAGS.DELETE_TAG) {
    tags = payload.tags.reduce((filtered, tag) => {
      if (tag.id !== payload.tagId) {
        filtered.push({ label: tag.label });
      }
      return filtered;
    }, []);
  } else {
    tags = payload.tags.map(tag => ({ label: tag.label }));
    tags.push({ label: payload.tagLabel });
  }

  return { tags };
}

function _updateLimit(actionFlag, payload, dispatchSuccess, dispatchError) {
  return new Promise((resolve, reject) => {
    const newCard = _transformCardForUpdate(actionFlag, payload);
    return cardsService
      .updateCard(newCard)
      .then(response => {
        const status = response.status;
        if (!status.is_success) {
          dispatchError();
          reject(status.error_message);
        } else {
          dispatchSuccess(response.data);
          resolve(response.data.limit);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

function _constructCardCreationError(
  nameError = null,
  cardHolderNameError = null,
  limitError = null,
  defaultError = null
) {
  return {
    nameError,
    cardHolderNameError,
    limitError,
    defaultError
  };
}

function _getCardCreationValidationError(card) {
  if (
    getCardNameValidationError(card.name) ||
    getCardHolderNameValidationError(card.address?.CardHolderName) ||
    getCardLimitValidationError(card.limit, MINIMAL_CARD_LIMIT)
  ) {
    return _constructCardCreationError(
      getCardNameValidationError(card.name),
      getCardHolderNameValidationError(card.address?.CardHolderName),
      getCardLimitValidationError(card.limit, MINIMAL_CARD_LIMIT),
      null
    );
  } else {
    return false;
  }
}

function _getCardUpdateValidationError(actionFlag, payload) {
  switch (actionFlag) {
    case UPDATE_FLAGS.UPDATE_NAME:
      return getCardNameValidationError(payload.name);
    case UPDATE_FLAGS.UPDATE_LIMIT:
      return false;
    case UPDATE_FLAGS.ADD_TAG:
      return false;
    case UPDATE_FLAGS.DELETE_TAG:
      return false;
  }
}

function hideShippingAddressUpdate() {
  return {
    type: cardsConstants.HIDE_SHIPPING_ADDRESS_UPDATE
  };
}
