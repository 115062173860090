import { Fragment } from 'react';

export const useRenderErrorBlock = (passwordErrors, t) => {
  const { doNotMatch, regularError } = passwordErrors;

  const context = {
    characters: t('error.message.characters'),
    capital: t('error.message.capital'),
    lowercase: t('error.message.lowercase'),
    number: t('error.message.number'),
    specialCharacter: t('error.message.specialCharacter')
  };

  const entriesFromPasswordErrors = Object.entries(passwordErrors);
  const message = doNotMatch || regularError || null;

  const inSpan = (value, idx) => (
    <span className="error-text" key={idx}>
      {` ${value}`}
    </span>
  );

  const inFragment = (value, idx) => <Fragment key={idx}>{` ${value}`}</Fragment>;

  const chooseTagWrapper = (key, value, idx) =>
    value ? inSpan(context[key], idx) : inFragment(context[key], idx);

  const composedString = (key, value, idx) => (
    <Fragment key={idx}>
      {inFragment(` ${t('error.message.and')} `)}
      {chooseTagWrapper(key, value)}
    </Fragment>
  );

  return {
    entriesFromPasswordErrors,
    chooseTagWrapper,
    message,
    composedString
  };
};
