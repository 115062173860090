import classNames from 'classnames';
import PropTypes from 'prop-types';

import { InputInfoContent, Label } from '../index';

import './index.scss';

const Description = ({ description }) =>
  Array.isArray(description) ? (
    <div className="description-block">
      {description.map((d, idx) => (
        <span key={idx} className="description-block__item">
          {d}
        </span>
      ))}
    </div>
  ) : (
    <span className="input-wrapper__description">{description}</span>
  );

//TODO disabled prop will be updated after design is done
const InputWrapper = ({
  autoWidth,
  styles,
  children,
  label,
  infoTooltipDescription,
  error,
  description,
  scrollInstanceId,
  disabled,
  className,
  withDisabledLabelStyles,
  infoContent
}) => (
  <div
    className={classNames('input-wrapper', className, {
      'auto-width': autoWidth
    })}
    style={styles}
  >
    {label ? (
      <Label
        label={label}
        infoTooltipDescription={infoTooltipDescription}
        scrollInstanceId={scrollInstanceId}
        disabled={withDisabledLabelStyles && disabled}
      />
    ) : null}
    {infoContent ? <InputInfoContent infoContent={infoContent} /> : children}
    {description ? <Description description={description} /> : null}
    {error ? <span className="input-wrapper__error">{error}</span> : null}
  </div>
);

export default InputWrapper;

InputWrapper.propTypes = {
  autosize: PropTypes.bool,
  styles: PropTypes.object,
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  infoTooltipDescription: PropTypes.string,
  error: PropTypes.string
};

InputWrapper.defaultProps = {
  styles: null,
  autosize: false,
  label: null,
  infoTooltipDescription: null,
  error: null
};
