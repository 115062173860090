import i18n from '../i18n';

import {
  TWO_FACTOR_CODE_LENGTH,
  MOBILE_VERIFICATION_CODE_LENGTH
} from '../constants/auth.constants';

export const getEmailValidationError = email => {
  let validation =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validation.test(email) ? null : 'sign-in:errors.emailIsInvalid';
};

export const getPasswordValidationError = password => {
  return (password !== null && password.trim().length) >= 7
    ? null
    : i18n.t('sign-in:errors.passwordIsInvalid');
};

export const getPasswordValidationErrors = password => {
  let errors = {
    characters: false,
    capital: false,
    lowercase: false,
    number: false,
    specialCharacter: false
  };
  const handledPassword = password !== null ? password.trim() : '';
  if (handledPassword.length >= 7) {
    // should contain at least one digit
    if (!/\d/.test(handledPassword)) errors.number = true;
    // should contain at least one lower case
    if (!/[a-z]/.test(handledPassword)) errors.lowercase = true;
    // should contain at least one upper case
    if (!/[A-Z]/.test(handledPassword)) errors.capital = true;
    // should contain at least one special character
    if (
      !/[!@#$%^&*(),.?":{}|<>_/~;+=§`]/.test(handledPassword) &&
      !handledPassword.includes('-') &&
      !handledPassword.includes('[') &&
      !handledPassword.includes(']') &&
      !handledPassword.includes("'") &&
      !handledPassword.includes('\\')
    )
      errors.specialCharacter = true;
  } else {
    errors.characters = true;
  }

  return errors;
};

export const getTwoFactorCodeValidationError = code => {
  return code.trim().length === TWO_FACTOR_CODE_LENGTH
    ? null
    : i18n.t('sign-in:twoFactor.codeInvalid');
};

export const getMobileVerificationCodeValidationError = code => {
  return code.trim().length === MOBILE_VERIFICATION_CODE_LENGTH
    ? null
    : i18n.t('sign-in:errors.mobileVerificationInvalidCodeError');
};

export const getMobileNumberValidationError = mobile => {
  let validation = /^\+?[0-9]\d{5,14}$/;
  return validation.test(mobile) ? null : i18n.t('sign-in:errors.mobileValidationError');
};

export const getCardNameValidationError = name => {
  return name.trim() !== '' ? null : 'translation:errors.cardNameIsInvalid';
};

export const getCardHolderNameValidationError = name => {
  let errorMessage;
  const trimmedName = name?.replace(/ /g, '');

  switch (true) {
    case name?.trim().length > 21:
      errorMessage = 'translation:errors.fullNameLength';
      break;
    case name === '':
      errorMessage = 'translation:errors.cardHolderNameIsEmpty';
      break;
    case !isStringContainsCharactersOnly(trimmedName || null):
      errorMessage = 'translation:errors.cardHolderNameIsInvalid';
      break;
    default:
      errorMessage = null;
  }

  return errorMessage;
};

export const getUserNamesValidationError = name => {
  return name.trim() !== '' ? null : 'translation:errors.nameIsInvalid';
};

export const getUserTitleValidationError = title => {
  return title.trim() !== '' ? null : 'translation:errors.titleIsInvalid';
};

export const getAddUserTitleValidationError = title => {
  return title.trim() !== '' && title.trim().length <= 50
    ? null
    : 'translation:errors.titleIsInvalid';
};

export const getCardLimitValidationError = (limit, minLimit) => {
  return parseInt(limit) >= parseInt(minLimit) ? null : 'errors.cardsMinimalLimitError';
};

export const isStringContainsCharactersOnly = value => {
  const regex = /^[a-zA-Z]+$/;
  return regex.test(value);
};

export const isStringContainsNumbersOnly = value => {
  const regex = /^\d+$/;
  return regex.test(value);
};

export const getUserLimitValidationError = (
  limit,
  minLimit,
  companyLimit,
  spent,
  transactionLimit
) => {
  const parsedLimit = parseInt(limit);
  const parsedMinLimit = parseInt(minLimit);
  const parsedCompanyLimit = parseInt(companyLimit);
  const parsedSpent = parseInt(spent);
  let error = null;

  if (parsedLimit < parsedMinLimit) {
    error = 'errors.usersMinimalLimitError';
  } else if (parsedLimit > parsedCompanyLimit) {
    error = 'errors.newUserCompanyLimit';
  } else if (parsedLimit <= parsedSpent) {
    error = 'errors.newUserCurrentSpend';
  } else if (+transactionLimit > +limit) {
    error = 'translation:errors.monthlyLimit';
  }

  return error;
};
