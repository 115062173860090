import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { authActions } from '../../../actions/auth.action';

const resendEmailStatusConstants = {
  PENDING: 'PENDING',
  RESOLVED: 'RESOLVED'
};

export const useActivationResend = ({ userId }) => {
  const { PENDING, RESOLVED } = resendEmailStatusConstants;
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const [resendEmailRequestStatus, setResendEmailRequestStatus] = useState('');

  const isResendEmailPending = resendEmailRequestStatus === PENDING;
  const isResendEmailResolved = resendEmailRequestStatus === RESOLVED;

  const handleClick = async () => {
    if (isResendEmailResolved) return;
    await setResendEmailRequestStatus(PENDING);
    await dispatch(authActions.resendEmailActivation(userId));
    await setResendEmailRequestStatus(RESOLVED);
  };

  const status = {
    pending: isResendEmailPending,
    resolved: isResendEmailResolved
  };

  const getLabel = () =>
    isResendEmailResolved
      ? t('users.waiting.labels.activationEmailSent')
      : t('users.waiting.labels.resend');

  return {
    handleClick,
    status,
    getLabel
  };
};
