import { ScrollableArea } from '../../scrollable-area';

import './index.scss';

const Overlay = ({ children, onClickHandler }) => {
  return (
    <div className="overlay-container" onClick={onClickHandler}>
      <ScrollableArea styles={{ width: '100%', height: '100%' }}>{children}</ScrollableArea>
    </div>
  );
};

export default Overlay;
