import { pendingColors, completeColors, activeColors } from './internal/step/helper';

export const stepStatuses = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  COMPLETE: 'COMPLETE'
};

const { ACTIVE, PENDING, COMPLETE } = stepStatuses;

export const MAP_STEP_COLORS = {
  [ACTIVE]: activeColors,
  [PENDING]: pendingColors,
  [COMPLETE]: completeColors
};
