const getDefaultColWidth = columns => {
  const { valueInPercents, withoutWidthValueCount } = columns.reduce(
    (accumulator, { width: currentValueWidth }, idx) => {
      let { valueInPercents, withoutWidthValueCount } = accumulator;

      if (!currentValueWidth)
        return { ...accumulator, withoutWidthValueCount: ++withoutWidthValueCount };

      if (currentValueWidth.toString().includes('%')) {
        currentValueWidth = +currentValueWidth.replace('%', '');
        return {
          ...accumulator,
          valueInPercents: valueInPercents + currentValueWidth
        };
      }

      return {
        ...accumulator,
        withoutWidthValueCount: ++withoutWidthValueCount
      };
    },
    {
      valueInPercents: 0,
      withoutWidthValueCount: 0
    }
  );

  const tableWidth = 100;
  const freeTableWidth = tableWidth - valueInPercents;
  const defaultValue = (freeTableWidth / withoutWidthValueCount).toFixed(2);

  return `${defaultValue}%`;
};

export const getValidWidth = (width, columns) => {
  const defaultColWidth = getDefaultColWidth(columns);
  let validWidth = '';

  if (!width) validWidth = defaultColWidth;
  else width.includes('%') ? (validWidth = width) : (validWidth = defaultColWidth);

  return { width: validWidth };
};
