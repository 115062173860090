export const configurationConstants = {
  SET_USER_STATUSES: 'SET_USER_STATUSES',
  SET_OVERLAY_VISIBILITY: 'SET_OVERLAY_VISIBILITY'
};

export const PROGRESS_TYPES = {
  DEFAULT: 'DEFAULT_PROGRESS_TYPE',
  USER: 'USER_PROGRESS_TYPE',
  CARD: 'CARD_PROGRESS_TYPE'
};
