import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const languages = ['en', 'es', 'pt'];
const lookupQuerystring = 'lang';

const detectionOptions = {
  order: ['querystring', 'localStorage', 'navigator'],
  lookupQuerystring
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: languages,
    fallbackLng: 'en',
    defaultNS: 'translation',
    ns: ['navigation'],
    load: 'languageOnly',
    interpolation: {
      escapeValue: false
    },
    detection: detectionOptions,
    nonExplicitWhitelist: true
  });

i18n.on('languageChanged', () => {
  let { pathname, search } = window.location;

  const queryLang = new URLSearchParams(search).get(lookupQuerystring);

  if (!!queryLang) {
    const conditionRegex = /[&?]?(lang=\w+?&?)$|(lang=\w+&?)/gi;
    search = search.replace(conditionRegex, '');

    pathname = `${pathname}${search}`;
    window.history.replaceState({}, '', pathname);
  }

  window.zE('webWidget', 'setLocale', i18n.language);
});

export default i18n;
