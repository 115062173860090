import classNames from 'classnames';

export const BoldListItem = ({ marginLeft, children }) => (
  <span
    className={classNames('bold-list-item', {
      'bold-list-item_margin-left': marginLeft
    })}
  >
    {children}
  </span>
);

export const Paragraph = ({ blurred, children }) => (
  <div
    className={classNames('paragraph', {
      blurred: blurred
    })}
  >
    <span>{children}</span>
  </div>
);
