import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import triangle from '../../../assets/images/triangle.svg';

import './index.scss';

const PageSortSection = ({ children }) => {
  const { t } = useTranslation('translation');
  const [isImageActive, setImageActive] = useState(false);

  const handleClick = () => {
    setImageActive(!isImageActive);
  };

  return (
    <section className="page-sort-section">
      <div className="filter-item" onClick={handleClick}>
        <span className="filter-item__label">{t('pageSortSection.active')}</span>
        <img
          src={triangle}
          alt=""
          className={classNames('filter-item__icon', {
            active: isImageActive
          })}
        />
      </div>

      {children}
    </section>
  );
};

export default PageSortSection;

PageSortSection.propTypes = {
  children: PropTypes.node.isRequired
};
