import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { TRANSLATION } from '../../../constants/locales';

import './index.scss';

export const STATUS_MARK_STATUSES = {
  APPROVED: 'approved',
  PENDING: 'pending',
  REJECTED: 'rejected'
};

const { APPROVED, PENDING, REJECTED } = STATUS_MARK_STATUSES;

const StatusMark = ({ status }) => {
  const { t } = useTranslation(TRANSLATION);

  const statusMarkLabel = {
    [APPROVED]: 'statusMark.approved',
    [PENDING]: 'statusMark.pending',
    [REJECTED]: 'statusMark.rejected'
  }[status];

  return <div className={classNames('status-mark', status)}>{t(statusMarkLabel)}</div>;
};

export default StatusMark;
