export const reportsConstants = {
  SET_REPORTS_INFO: 'SET_REPORTS_INFO',

  CHART_TYPES: {
    CUMULATIVE_SUM: 'cumulative-sum',
    TREE_MAP: 'tree-map',
    BAR_CHART: 'bar-chart'
  },

  FILTER_TYPES: {
    CATEGORY: 'category',
    COUNTRY: 'country',
    MERCHANT_NAME: 'merchantName',
    USER: 'user',
    DEPARTMENT: 'department',
    CARD_NAME: 'cardName',
    LAST_4: 'last4',
    CURRENCY: 'currency',
    TAGS: 'tags'
  }
};
