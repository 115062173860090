import React from 'react';
import './index.scss';

const Input = ({
  defaultValue,
  isActive,
  onActivation,
  onChange,
  onSubmit
}) => {
  const handleOnClick = event => {
    if (!isActive) {
      onActivation(event);
    }
  };

  return (
    <div className="departments-input" onClick={handleOnClick}>
      {isActive ? (
        <input
          defaultValue={defaultValue}
          autoFocus={true}
          onChange={onChange}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              onSubmit(event);
            }
          }}
        />
      ) : (
        <span>{defaultValue}</span>
      )}
    </div>
  );
};

export default Input;
