import { depositsConstants } from '../constants/deposits.constants';
import getNumberWithCommas from '../utils/string-service';
import moment from 'moment';
import _ from 'lodash';

import {
  getPreparedTransactions,
  handleDetailsDownload
} from '../containers/wallet/tribal-transactions/utils';

const INITIAL_STATE = {
  details: null,
  isEnabled: false,
  paymentStatuses: [],
  transactions: [],
  transactionsForDownload: [],
  totalPages: 0,
  currentPage: 0,
  cachedBulkTransactions: {}
};

const getFormattedCurrencyLabel = (symbol, value, reverse) => {
  let label = symbol;
  if (symbol !== '$') label += ' ';
  if (value.indexOf('-') !== -1) {
    label = reverse ? label : `-${label}`;
    value = reverse ? `-${value.slice(1)}` : value.slice(1);
  }

  if (reverse) label = value + ' ' + label;
  else label += value;

  return label;
};

const PAYMENT_STATUS_KEYS = {
  PENDING: 'pending',
  RETURNED: 'returned',
  COMPLETE: 'complete',
  FAILED: 'failed'
};
const PAYMENT_STATUS_ID_TO_KEY_MAP = {
  1: PAYMENT_STATUS_KEYS.PENDING,
  2: PAYMENT_STATUS_KEYS.RETURNED,
  3: PAYMENT_STATUS_KEYS.COMPLETE,
  4: PAYMENT_STATUS_KEYS.FAILED
};

const _preparePaymentStatuses = rawStatuses => {
  let statuses = [];
  if (rawStatuses && rawStatuses.length) {
    statuses = rawStatuses.map(status => ({
      id: parseInt(status.id),
      key: PAYMENT_STATUS_ID_TO_KEY_MAP[status.id],
      label: status.name
    }));
  }

  return statuses;
};

export const _prepareTransactionStatus = (transactionStatusId, paymentStatuses) => {
  const status = {
    key: '',
    label: ''
  };

  if (paymentStatuses.length) {
    const preparedStatus = paymentStatuses.find(status => status.id === transactionStatusId);
    if (preparedStatus) {
      status.key = preparedStatus.key;
      status.label = preparedStatus.label;
    }
  }

  return status;
};

const transformResponse = ({
  id,
  user_id,
  company_id,
  bulk_id,
  payer,
  from,
  to,
  amount,
  usd_equivalent,
  currency,
  description,
  created_at,
  date,
  status_id,
  bulk_count
}) => ({
  id,
  userId: user_id,
  companyId: company_id,
  bulkId: bulk_id,
  payer,
  from,
  to,
  amount,
  currency,
  date,
  description,
  createdAt: created_at,
  status: status_id,
  bulkCount: bulk_count,
  usdEquivalent: usd_equivalent
});

export const formatTransactions = ({ paymentInstance, paymentStatuses }) => {
  paymentInstance = transformResponse(paymentInstance);

  return {
    id: paymentInstance.id,
    bulkId: paymentInstance.bulkId,
    bulkCount: paymentInstance.bulkCount,
    createdAt: moment(paymentInstance.createdAt).format('MMM D YYYY') || '-',
    amount:
      paymentInstance?.currency &&
      paymentInstance.amount &&
      getFormattedCurrencyLabel(
        paymentInstance.currency.toUpperCase(),
        getNumberWithCommas(paymentInstance.amount.toFixed(2) || 0),
        true
      ),
    from: paymentInstance?.from,
    currencyCode: paymentInstance?.currency,
    amountValue: paymentInstance?.amount,
    to: paymentInstance?.to,
    payer: paymentInstance?.payer,
    statusId: +paymentInstance?.status,
    status: _prepareTransactionStatus(+paymentInstance.status, paymentStatuses),
    usdEquivalent: paymentInstance?.usdEquivalent,
    description: paymentInstance?.description,
    transactionId: paymentInstance?.id
  };
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case depositsConstants.SET_DEPOSITS_DETAILS:
      return {
        ...state,
        details: action.data.details.hasOwnProperty('total_amount')
          ? {
              amount: getFormattedCurrencyLabel(
                '$',
                getNumberWithCommas(action.data.details.total_amount)
              )
            }
          : null,
        isEnabled: action.data.isEnabled || false
      };

    case depositsConstants.SET_DEPOSITS_PAYMENT_STATUSES:
      const paymentStatuses = _preparePaymentStatuses(action.statuses);
      const newTrans = _.cloneDeep(state.transactions);
      const newTransForDownload = _.cloneDeep(state.transactionsForDownload);

      if (newTrans.length && newTrans.length === newTransForDownload.length) {
        for (let i = 0; i < newTrans.length; i++) {
          newTrans[i].status = _prepareTransactionStatus(newTrans[i].statusId, paymentStatuses);
          newTransForDownload[i].status = _prepareTransactionStatus(
            newTransForDownload[i].statusId,
            paymentStatuses
          );
        }
      }

      return {
        ...state,
        paymentStatuses,
        transactions: newTrans,
        transactionsForDownload: newTransForDownload
      };

    case depositsConstants.SET_DEPOSITS_TRANSACTIONS:
      const { totalPages, currentPage, setNewTransactionsList } = action;
      let transactions = [];

      if (action.transactions)
        action.transactions.forEach(transaction =>
          transactions.push({
            ...formatTransactions({
              paymentInstance: transaction,
              paymentStatuses: state.paymentStatuses
            })
          })
        );

      transactions = getPreparedTransactions({
        transactions,
        handleDetailsDownload
      });

      const transactionsList = setNewTransactionsList
        ? transactions
        : state.transactions.concat(transactions);

      return {
        ...state,
        transactions: transactionsList,
        totalPages,
        currentPage
      };

    case depositsConstants.SET_DEPOSITS_FAILURE:
      return {
        ...state,
        transactions: [],
        currentPage: action.currentPage
      };

    case depositsConstants.UPDATE_TRANSACTIONS:
      return {
        ...state,
        transactions: action.transactions
      };

    case depositsConstants.CACHE_BULK_TRANSACTIONS:
      return {
        ...state,
        cachedBulkTransactions: {
          ...state.cachedBulkTransactions,
          [action.bulkId]: {
            ...state.cachedBulkTransactions?.[action.bulkId],
            [action.page]: action.preparedData
          }
        }
      };

    default:
      return state;
  }
}
