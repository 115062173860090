import { CARD_STATUSES, CARD_TYPES } from './cards.constants';

const _STATUS_TO_SERVER_MAP = {
  [CARD_STATUSES.ACTIVE]: 1,
  [CARD_STATUSES.SUSPENDED]: 0,
  [CARD_STATUSES.LOST]: 3,
  [CARD_STATUSES.STOLEN]: 2,
  [CARD_STATUSES.EXPIRED]: 4,
  [CARD_STATUSES.CANCELED]: 5,
  [CARD_STATUSES.PENDING]: 6
};

const _STATUS_FROM_SERVER_MAP = [
  CARD_STATUSES.SUSPENDED,
  CARD_STATUSES.ACTIVE,
  CARD_STATUSES.STOLEN,
  CARD_STATUSES.LOST,
  CARD_STATUSES.EXPIRED,
  CARD_STATUSES.CANCELED,
  CARD_STATUSES.PENDING
];

export const transformCardStatusForRequest = status => _STATUS_TO_SERVER_MAP[status];

export const transformCardStatusFromServer = statusNumber => _STATUS_FROM_SERVER_MAP[statusNumber];

export const transformCardTypeForRequest = type => (type === CARD_TYPES.PHYSICAL ? 1 : 0);

export const transformCardTypeFromServer = type =>
  type === 1 ? CARD_TYPES.PHYSICAL : CARD_TYPES.VIRTUAL;
