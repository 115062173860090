import { amplitudeInstance } from '../../../analytics/amplitude';

import ErrorBox from '../error-box';
import LimitEdit from '../limit-edit';

import { CARD_STATUSES, UPDATE_FLAGS } from '../../../constants/cards.constants';

import { cardsActions } from '../../../actions/cards.action';

import './index.scss';

const CardLimit = ({
  card,
  isFirstCard,
  currency,
  dispatch,
  limitError,
  userId,
  t,
  displayLimitError,
  bannerMigrationTo20
}) => {
  const cardData = { ...card };
  let exchangeData = null;
  if (card.hasTooltip) {
    exchangeData = {
      rate: `1.00 ${currency.symbol} = ${card.exchangeData.rate} ${card.currency.symbol}`,
      date: card.exchangeData.date
    };
  }

  const handleLimitUpdate = ({
    currencyId,
    updatedLimit,
    baseLimit,
    cardId,
    userId,
    spentValue
  }) => {
    const parsedUpdatedLimit = parseInt(updatedLimit, 10) || 0;

    if (parsedUpdatedLimit !== parseInt(baseLimit, 10)) {
      if (!parsedUpdatedLimit) {
        displayLimitError(cardId, t('errors.cardsMinimalLimitError'));
      } else {
        /**
         * userId adds only in the user page
         */
        const preferredAction = userId
          ? cardsActions.updateUserCardLimit(UPDATE_FLAGS.UPDATE_LIMIT, {
              prevLimit: card.limit,
              limit: updatedLimit,
              cardId,
              spentValue,
              userId
            })
          : cardsActions.updateCardLimit(UPDATE_FLAGS.UPDATE_LIMIT, {
              prevLimit: baseLimit,
              limit: updatedLimit,
              cardId: cardId,
              currencyId
            });

        dispatch(preferredAction)
          .then(() => {
            card.limit = updatedLimit;
            userId && amplitudeInstance.logEvent('Edit_Card_Limit_Button_Clicked');
          })
          .catch(error => {
            displayLimitError(cardId, error);
          });
      }
    }
  };

  return (
    <div className="limit-container">
      {limitError.id === cardData.id ? (
        <ErrorBox
          text={limitError.text}
          style={{ zIndex: 5, marginTop: isFirstCard ? 65 : -55 }}
          bottom={isFirstCard}
        />
      ) : null}

      <LimitEdit
        currencySymbol={currency.symbol}
        data={cardData}
        exchangeData={exchangeData}
        isError={limitError.id !== null}
        disabled={cardData.statusId !== CARD_STATUSES.ACTIVE || bannerMigrationTo20}
        onEdit={(updatedLimit, baseLimit) =>
          handleLimitUpdate({
            currencyId: currency.id,
            updatedLimit,
            baseLimit,
            cardId: cardData.id,
            userId,
            spentValue: card.spent
          })
        }
      />
    </div>
  );
};

export default CardLimit;
