import { useRef, useState } from 'react';

export const useSearchAutocomplete = ({ suggestions, callback, handleChange }) => {
  /**
   * @activeSuggestion - the active selection's index
   * @filteredSuggestions - the suggestions that match the user's input
   * @showSuggestions - whether or not the suggestion list is shown
   * @userInput - what the user has entered
   */
  const [autoCompleteState, setAutoCompleteState] = useState({
    activeSuggestion: 0,
    filteredSuggestions: [],
    showSuggestions: false,
    userInput: ''
  });
  const suggestionsListRef = useRef();

  const { activeSuggestion, filteredSuggestions, showSuggestions, userInput } = autoCompleteState;
  const isSuggestionListVisible = showSuggestions && userInput && filteredSuggestions.length;

  /**
   * event fired when the input value is changed
   * filter  suggestions that don't contain the user's input
   * update the user input and filtered suggestions, reset the active
   * suggestion and make sure the suggestions are shown
   */
  const onChange = e => {
    const userInput = e.currentTarget.value;

    const regex = new RegExp(`${userInput}`, 'gi');

    const filteredSuggestions = suggestions.filter(s => regex.test(s.label));

    setAutoCompleteState({
      ...autoCompleteState,
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput
    });

    if (handleChange) return handleChange(userInput);
  };

  /**
   * event fired when the user clicks on a suggestion
   */
  const onClick = async (e, id) => {
    await setAutoCompleteState({
      ...autoCompleteState,
      showSuggestions: false,
      userInput: ''
    });

    callback(id);
  };

  const onClickOutside = () =>
    setAutoCompleteState({ ...autoCompleteState, showSuggestions: false });

  return {
    isSuggestionListVisible,
    activeSuggestion,
    filteredSuggestions,
    userInput,
    onChange,
    onClick,
    suggestionsListRef,
    onClickOutside
  };
};
