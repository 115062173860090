import { shallowEqual, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

import { BoldListItem, Paragraph } from '../internal';

export { WalletHeaderText, WalletAgreementBodyText };

const WalletHeaderOldUser = ({ t }) => (
  <span className="agreement-form__header">
    <Trans t={t} i18nKey="walletAgreementForm.oldUserTitle">
      We have updated <br /> our Terms & Conditions
    </Trans>
  </span>
);

const WalletHeaderNewUser = ({ t }) => (
  <span className="agreement-form__header">{t('walletAgreementForm.newUserTitle')}</span>
);

const WalletHeaderText = ({ t }) => {
  const { isNewUser } = useSelector(state => state.user.walletAgreement, shallowEqual);

  return isNewUser ? <WalletHeaderNewUser t={t} /> : <WalletHeaderOldUser t={t} />;
};

const WalletAgreementTitle = ({ children }) => (
  <div className="wallet-agreement-title">
    <span>{children}</span>
  </div>
);

const WalletAgreementBodyText = () => (
  <div className="terms-and-conditions">
    <WalletAgreementTitle>Cross-Border Payments & Inventory Financing</WalletAgreementTitle>
    <span>
      These Cross-Border Payments (CBP) & Invoice Financing (IF) Terms & Conditions (collectively
      “CBP&IF TCs”) are entered into between the legal person named in the agreement signed during
      the Tribal application process (“Customer”, “you”, “your”, or “party”) and Tribal Credit
      (“Tribal”, “we”, “our”, “us”, or “party”) (Customer and Tribal are collectively referred to as
      the “parties”) and governs your use of our CBP products and services and IF products and
      services. Agreeing to these CBP&IF TCs constitutes a legal relationship between the parties.
      Tribal Credit is a registered trade name of Aingel Corp., a Delaware USA corporation. Posting
      an updated version of these CBP&IF TCs or a superseding agreement regarding CBP or IF on our{' '}
      <a href="https://tribal.credit" target="_blank">
        website
      </a>{' '}
      has the effect of replacing these CBP&IF TCs with you.
    </span>
    <span>
      Terminology used within these CBP&IF TCs are either defined herein or can be found in{' '}
      <u>Part D – Terminologies Defined</u> of the Tribal Agreement.
    </span>
    <span>
      BY USING ANY OF OUR <BoldListItem>CBP</BoldListItem> PRODUCTS OR SERVICES OR
      <BoldListItem> IF</BoldListItem> PRODUCTS OR SERVICES YOU AND YOUR AGENTS, ASSOCIATES,
      AFFILIATES, AND ANY OTHER THIRD PARTIES THAT HAVE ACCESS TO YOUR TRIBAL ACCOUNT (COLLECTIVELY
      REFERRED TO AS “YOU”) ARE AGREEING TO BE BOUND BY THESE CBP&IF TCs. DO NOT USE ANY OF OUR{' '}
      <BoldListItem>CBP</BoldListItem> PRODUCTS OR SERVICES OR <BoldListItem> IF</BoldListItem>{' '}
      PRODUCTS OR SERVICES IF YOU DO NOT ACCEPT THESE CBP&IF TCs.
    </span>
    <span>
      THESE CBP&IF TCs ARE{' '}
      <BoldListItem>
        A LEGALLY BINDING AGREEMENT BETWEEN THE PARTIES — <u>REVIEW THEM CAREFULLY</u>.
      </BoldListItem>
    </span>

    <WalletAgreementTitle>PART A — SUMMARY</WalletAgreementTitle>

    <span>
      <BoldListItem>CBP</BoldListItem> enables and automates the process of making cross-border
      payments. After agreeing to these CBP&IF TCs, you will be able to request and make legitimate
      business-related cross-border payments to legal persons through your Tribal Account.
    </span>
    <span>
      <BoldListItem>IF</BoldListItem> enables and automates the ability to pay invoices received
      from third-parties for business expenses where — and only if —the third-parties do not accept
      your Tribal issued payment cards. After agreeing to these CBP&IF TCs, you will be able to
      request and make legitimate business-related payments to non-card accepting legal persons
      through your Tribal Account.
    </span>

    <span>
      These CBP&IF TCs, along with your Tribal Agreement and any other relevant agreements between
      the parties, controls your use of Tribal’s CBP products & service and IF products & services,
      which can be accessed via your Tribal Account. In the event there is any discrepancy or
      contradiction between these CBP&IF TCs and the Tribal Agreement or any other agreement between
      the parties, these CBP&IF TCs prevail regarding CBP products & services and IF products &
      services.
    </span>

    <WalletAgreementTitle>PART B — OWNERSHIP & USAGE</WalletAgreementTitle>

    <Paragraph blurred>
      <BoldListItem>
        <u>B.1 Ownership.</u>
      </BoldListItem>{' '}
      Tribal owns all rights, title and interest in all Tribal products and services, including CBP
      and IF and Tribal Accounts, platforms, concepts, names, and our proprietary technology,
      including our software (in source and object forms), algorithms, architecture, documentation,
      experience, knowledge, network designs and processes and process flows, trade secrets, user
      interfaces (UI) and user experiences (UX), including any improvements, modifications,
      subsequent iterations, subsequent versions, and any derivative work derived therefrom
      (collectively referred to as the “Tribal Technology”).
    </Paragraph>
  </div>
);
