import moment from 'moment';

import { useAmountInfoAvailability } from '../../../hooks/send-payment';

import getNumberWithCommas from '../../../utils/string-service';

import './index.scss';

const CurrencyInfo = ({ t, amount, transactionInfo, modalDataFromTable }) => {
  const { isAmountInfoNotAvailable } = useAmountInfoAvailability();

  return (
    <div className="currency-info">
      {transactionInfo?.flagImg ? (
        <img className="flag-img" src={transactionInfo.flagImg} alt="" />
      ) : null}
      <span className="currency-info--main">{amount}</span>
      {isAmountInfoNotAvailable ? null : (
        <>
          <span className="currency-info--sub">
            {!!transactionInfo?.usd_amount || transactionInfo?.usd_amount === 0
              ? `$${getNumberWithCommas(transactionInfo?.usd_amount.toFixed(2))} USD`
              : null}
          </span>

          <span className="currency-info--sub">
            {t('common.fxForUsd', {
              currencyCode: modalDataFromTable?.currencyCode?.toUpperCase(),
              rate: transactionInfo?.rate,
              date: moment(transactionInfo?.created_at).format('D/M/YY')
            })}
          </span>
        </>
      )}
    </div>
  );
};

export default CurrencyInfo;
