import { connectHeap } from './heap';
import { connectTagManager } from './tag-manager';
import { connectSmartlook } from './smartlook';
import { connectAmplitude } from './amplitude';
import { connectChurnZero } from './churnzero';
import { connectHotjar } from './hotjar';

export const connectAnalytics = () => {
  connectHeap();
  connectTagManager();
  connectSmartlook();
  connectAmplitude();
  connectChurnZero();
  connectHotjar();
};
