import axios from 'axios';

import { API_TRIBAL_WALLET_URL, API_URL } from '../constants/common.constants';
import { handleRequestError } from './handlers/error-handlers';
import { _DEFAULT_CONFIG } from './config';

export const depositsService = {
  getDetails,
  getTransactions,
  getPaymentStatuses,
  getPaymentInfo,
  downloadWalletTransactions,
  getPaymentFile,
  downloadPaymentFile
};

async function getDetails() {
  const config = await _DEFAULT_CONFIG();
  return axios
    .get(`${API_TRIBAL_WALLET_URL}/api/deposits/details`, config)
    .catch(handleRequestError);
}

async function downloadWalletTransactions() {
  return axios
    .get(`${API_URL}/api/tribalwallet/download-Wallet-transactions`, {
      ...(await _DEFAULT_CONFIG())
    })
    .catch(handleRequestError);
}

let cancelGetTransactions;
async function getTransactions({ page }, cancelToken) {
  cancelGetTransactions?.cancelToken();
  cancelGetTransactions = cancelToken;

  let params = {
    page: page ? page : 1
  };

  return axios
    .get(`${API_URL}/api/tribalwallet/get-wallet-payments`, {
      ...(await _DEFAULT_CONFIG(cancelToken)),
      params
    })
    .catch(handleRequestError);
}

async function getPaymentStatuses(cancelToken) {
  const config = await _DEFAULT_CONFIG(cancelToken);
  return axios
    .get(`${API_URL}/api/tribalwallet/get-payment-status`, config)
    .catch(handleRequestError);
}

async function getPaymentInfo({ paymentId }) {
  return axios
    .get(`${API_URL}/api/tribalwallet/get-payment-info`, {
      ...(await _DEFAULT_CONFIG()),
      params: { payment_id: paymentId }
    })
    .catch(handleRequestError);
}

async function getPaymentFile({ paymentId }) {
  const params = {
    payment_id: paymentId
  };

  return axios
    .get(`${API_URL}/api/tribalwallet/get-payment-file`, {
      ...(await _DEFAULT_CONFIG()),
      params
    })
    .catch(handleRequestError);
}

async function downloadPaymentFile({ fileName, fileId }) {
  const params = {
    file_name: fileName,
    file_id: fileId
  };

  return axios
    .get(`${API_URL}/api/tribalwallet/download-payment-file`, {
      ...(await _DEFAULT_CONFIG()),
      params
    })
    .catch(handleRequestError);
}
