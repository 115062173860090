import { useState } from 'react';
import classNames from 'classnames';

import AddButton from './add-button';
import SelectionDropdown from './selection-dropdown';
import SimpleBox from './simple-box';
import Input from './input';

import './index.scss';

function TagGroup(props) {
  const [newTagVisibility, setNewTagVisibility] = useState(false);
  const [newTagText, setNewTagText] = useState('');
  const [selectionDropdownVisibility, setSelectionDropdownVisibility] = useState(false);

  const addingByButtonHandler = () => {
    if (!props.predefinedOptions) {
      if (newTagVisibility && newTagText) {
        props.onTagAdding(newTagText);
        setNewTagText('');
      }
      setNewTagVisibility(!newTagVisibility);
    } else {
      setSelectionDropdownVisibility(true);
    }
  };

  const addingByKeyPressHandler = () => {
    if (newTagText) {
      props.onTagAdding(newTagText);
      setNewTagText('');
    }
    setNewTagVisibility(false);
  };

  const optionSelectionHandler = id => {
    if (id) {
      props.onOptionSelection(id);
    }
    setSelectionDropdownVisibility(false);
  };

  const renderSimpleBoxes = () =>
    props.tags.map(tag => (
      <SimpleBox
        readOnly={props.readOnly}
        key={tag.id}
        secondary={props.secondary}
        value={tag.label}
        isValid={tag.valid}
        onDeletion={() => props.onTagDeletion(tag.id)}
        withSelect={props.withSelect}
        dropdownData={props.dropdownData}
        handleTagChange={props.handleTagChange}
        id={tag.id}
        selectType={tag.selectType}
        getSelectLabel={props.getSelectLabel}
        handleTagUpdate={props.handleTagUpdate}
        tag={tag}
        disabled={props.disabled}
        isLoading={tag.id === props.tagLoadingId}
      />
    ));

  const renderAddingWrapper = () =>
    props.readOnly ? null : (
      <AddingWrapper
        predefinedOptions={props.predefinedOptions}
        addTitle={props.addTitle}
        secondary={props.secondary}
        selectionDropdownVisibility={selectionDropdownVisibility}
        newElementVisibility={newTagVisibility}
        newElementText={newTagText}
        onElementTextChange={text => setNewTagText(text)}
        onAddingByKeyPress={() => addingByKeyPressHandler()}
        onAddingByButton={() => addingByButtonHandler()}
        onOptionSelection={id => optionSelectionHandler(id)}
        onCancelSelection={() => optionSelectionHandler(null)}
        brightButton={props.brightButton}
        areTagsDisabled={props.areTagsDisabled}
        isAddButtonLoading={props.isAddButtonLoading}
        isAddButtonDisabled={props.isAddButtonDisabled}
      />
    );

  return (
    <div
      className={classNames('tag-group', {
        disabled: props.areTagsDisabled
      })}
    >
      {renderSimpleBoxes()}
      {props.disabled ? null : renderAddingWrapper()}
    </div>
  );
}

const AddingWrapper = props => (
  <>
    {props.predefinedOptions && props.selectionDropdownVisibility ? (
      <SelectionDropdown
        options={props.predefinedOptions}
        onOptionSelection={id => props.onOptionSelection(id)}
        onCancelSelection={() => props.onCancelSelection()}
      />
    ) : props.newElementVisibility ? (
      <Input
        value={props.newElementText}
        secondary={props.secondary}
        onAdding={() => props.onAddingByKeyPress()}
        onTextChange={text => props.onElementTextChange(text)}
      />
    ) : null}
    {props.selectionDropdownVisibility ? null : (
      <AddButton
        label={props.addTitle}
        secondary={props.secondary}
        bright={props.brightButton}
        onAddClick={() => props.onAddingByButton()}
        areTagsDisabled={props.areTagsDisabled}
        isAddButtonLoading={props.isAddButtonLoading}
        disabled={props.isAddButtonDisabled}
      />
    )}
  </>
);

export default TagGroup;
