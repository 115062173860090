import getNumberWithCommas from './../../../utils/string-service';
import { PROGRESS_TYPES } from './../../../constants/configuration.constants';
import { getFormattedCurrencyLabel } from '../../../utils/currency-util';

const _CURRENCY_SIGNS_MAP = {
  usd: '$',
  eur: '€',
  gbp: '£'
};
// const _PROGRESS_TYPE_TO_INFO_MESSAGE_TEXT_MAP = {
//   [PROGRESS_TYPES.CARD]:
//     'Amount available on card after including your total company spend.',
//   [PROGRESS_TYPES.USER]:
//     'Amount available to you after including your total company spend.'
// };

const _PROGRESS_TYPE_TO_INFO_MESSAGE_TEXT_MAP = {
  [PROGRESS_TYPES.CARD]: 'card',
  [PROGRESS_TYPES.USER]: 'user'
};

export const DEFAULT_CURRENCY = 'usd';

export const isLimitRestricted = (lowerLimit, limit, spent) =>
  lowerLimit && limit && spent >= 0 && spent < limit && lowerLimit < limit;

export const getPercent = (limit, spent) => {
  const onePercentValue = limit / 100;
  return onePercentValue ? spent / onePercentValue : 0;
};

export const getSecondaryPercent = (limit, lowerLimit, spent) =>
  spent < lowerLimit ? getPercent(limit, lowerLimit) : getPercent(limit, spent);

export const getAmount = (currencySymbol, spent) =>
  getFormattedCurrencyLabel(currencySymbol, getNumberWithCommas(spent));

export const getInfoMessageText = progressType =>
  _PROGRESS_TYPE_TO_INFO_MESSAGE_TEXT_MAP[progressType] || '';
