import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Loader from '../../loader';

import PlusIcon from './internal/PlusIcon';
import AddCross from './../../../../assets/images/plus-circle.svg';

import './index.scss';

const AddButton = ({
  onAddClick,
  label,
  secondary,
  bright,
  areTagsDisabled,
  isAddButtonLoading,
  disabled
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames('group-boxes__add-button', {
        secondary,
        bright,
        disabled
      })}
      onClick={() => !disabled && onAddClick()}
    >
      {label ? (
        <>
          {bright ? null : <PlusIcon />}
          <span>{label}</span>
        </>
      ) : (
        <>
          <span>{t('common.add')}</span>
          {isAddButtonLoading && areTagsDisabled ? (
            <div className="loader-container_add-button">
              <Loader small />
            </div>
          ) : (
            <img alt="" src={AddCross} />
          )}
        </>
      )}
    </div>
  );
};

export default AddButton;
