import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { ScrollableArea } from '../../scrollable-area';
import arrow from '../../../assets/images/triangle.svg';

import './index.scss';

const DROPDOWN_ELEMENT_ID = 'AddCardCurrencyDropdown';

const getMenuElementClassName = (currency, selectedCurrency) => {
  let className = 'element';

  if (currency.code === selectedCurrency.code) {
    className += ' selected';
  }

  return className;
};

const CurrencyDropdown = ({ availableCurrencies, selectedCurrency, onCurrencySelection }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const isDropdownVisibleRef = useRef(false);
  const isSingleCurrencyAvailable = availableCurrencies.length <= 1;

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => onCurrencySelection(selectedCurrency), []);

  const setDropdownVisibility = isVisible => {
    isDropdownVisibleRef.current = isVisible;
    setIsDropdownVisible(isVisible);
  };

  const handleCurrencySelection = currency => {
    onCurrencySelection(currency);
    setDropdownVisibility(false);
  };

  const handleClickOutside = event => {
    const dropdownElement = document.getElementById(DROPDOWN_ELEMENT_ID);
    if (!dropdownElement?.contains(event.target) && isDropdownVisibleRef.current) {
      setDropdownVisibility(false);
    }
  };

  const handleHeaderClick = () => {
    if (!isSingleCurrencyAvailable) {
      setDropdownVisibility(!isDropdownVisible);
    }
  };

  return (
    <div id={DROPDOWN_ELEMENT_ID} className="currency-dropdown">
      <div
        className={`header ${isSingleCurrencyAvailable ? 'static' : ''}`.trim()}
        onClick={handleHeaderClick}
      >
        <div className="currency__text--wrapper">
          <img className="flag" src={selectedCurrency.flagImg} alt="" />
          <span className="code">{selectedCurrency.code}</span>
          <span className="description">{selectedCurrency.name}</span>
        </div>
        {!isSingleCurrencyAvailable ? <img className="arrow" src={arrow} alt="" /> : null}
      </div>
      {isDropdownVisible && (
        <DropdownMenu
          selectedCurrency={selectedCurrency}
          availableCurrencies={availableCurrencies}
          onCurrencySelection={currency => handleCurrencySelection(currency)}
        />
      )}
    </div>
  );
};

const DropdownMenu = ({ selectedCurrency, availableCurrencies, onCurrencySelection }) => (
  <div className="menu">
    <ScrollableArea styles={{ width: '100%', maxHeight: 200 }}>
      {availableCurrencies.map(currency => (
        <div
          key={currency.code}
          className={getMenuElementClassName(currency, selectedCurrency)}
          onClick={() => onCurrencySelection(currency)}
        >
          <img className="flag" src={currency.flagImg} alt="" />
          <span className="code">{currency.code}</span>
          <span className="description">{currency.name}</span>
        </div>
      ))}
    </ScrollableArea>
  </div>
);

CurrencyDropdown.propsType = {
  availableCurrencies: PropTypes.array,
  selectedCurrency: PropTypes.object,
  onCurrencySelection: PropTypes.func
};
CurrencyDropdown.defaultProps = {
  availableCurrencies: [],
  selectedCurrency: null,
  onCurrencySelection: function () {}
};

export default CurrencyDropdown;
