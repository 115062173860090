import { CLIService } from '../services';

export const CLIActions = {
  submitCLIData,
  getCLICurrentData
};

function submitCLIData(amount, details, files) {
  return dispatch =>
    new Promise((resolve, reject) =>
      CLIService.submitCLIData(amount, details, files)
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(data.status.is_success);
          } else reject();
        })
        .catch(error => reject(error))
    );
}

function getCLICurrentData() {
  return dispatch =>
    new Promise((resolve, reject) =>
      CLIService.getCLICurrentData()
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(data);
          } else reject();
        })
        .catch(error => {
          reject(error);
        })
    );
}
