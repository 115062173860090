import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CreditLineIncreaseContext } from '../';

import { stepStatuses } from '../../components/shared/stepper/constants';
import { CLI_REQUEST_STATUSES } from '../../constants/cli.constants';

import { CLIActions } from '../../actions/cli.action';

const CreditLineIncreaseState = props => {
  const [CLIRequestedStatus, setCLIRequestedStatus] = useState(null);
  const [currentAmount, setCurrentAmount] = useState(null);
  const [amount, setAmount] = useState(null);
  const [details, setDetails] = useState('');
  const [isConnected, setIsConnected] = useState(null);
  const [files, setFiles] = useState([]);
  const [isSuccessSubmitted, setIsSuccessSubmitted] = useState(null);
  const [isSteperAvailable, setIsSteperAvailable] = useState(false);

  const { ACTIVE, PENDING, COMPLETE } = stepStatuses;

  const defaultSteps = [
    {
      step: 1,
      status: ACTIVE,
      label: 'steps.first'
    },
    {
      step: 2,
      status: PENDING,
      label: 'steps.second'
    },
    {
      step: 3,
      status: PENDING,
      label: 'steps.third'
    }
  ];

  const dropFiles = () => setFiles([]);

  const [steps, setSteps] = useState([...defaultSteps]);

  const prepareSteps = () => {
    if (isConnected) {
      const resultSteps = defaultSteps.filter(elem => elem.step !== 2);
      setSteps(resultSteps);
    }
  };

  const updateSteps = (step, status, nextStepStatus = null) => {
    setSteps(prevState => {
      const targetIndex = prevState.findIndex(elem => elem.step === step);

      const nextIndex = targetIndex + 1;

      return prevState.map((elem, index) => {
        const isTargetIndex = index === targetIndex;
        const isNextIndex = index === nextIndex;

        if (isTargetIndex) {
          return {
            ...elem,
            status
          };
        }

        if (nextStepStatus && isNextIndex) {
          return {
            ...elem,
            status: nextStepStatus
          };
        }

        return {
          ...elem
        };
      });
    });
  };

  const stepsComplete = () =>
    setSteps(prevState =>
      prevState.map(elem => ({
        ...elem,
        status: COMPLETE
      }))
    );

  const clearSteps = () => setSteps([...defaultSteps]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CLIActions.getCLICurrentData()).then(data => {
      setCLIRequestedStatus(data?.NewCLIStatus?.Id || CLI_REQUEST_STATUSES.DISABLE_STATUS);
      setCurrentAmount(data.CurrentCLAmount);
    });
  }, []);

  const clearRequestData = () => {
    setAmount(null);
    setDetails('');
  };

  return (
    <CreditLineIncreaseContext.Provider
      value={{
        CLIRequestedStatus,
        setCLIRequestedStatus,
        currentAmount,
        setCurrentAmount,
        amount,
        setAmount,
        details,
        setDetails,
        clearRequestData,
        updateSteps,
        steps,
        clearSteps,
        stepsComplete,
        isConnected,
        setIsConnected,
        files,
        setFiles,
        isSuccessSubmitted,
        setIsSuccessSubmitted,
        dropFiles,
        prepareSteps,
        isSteperAvailable,
        setIsSteperAvailable
      }}
    >
      {props.children}
    </CreditLineIncreaseContext.Provider>
  );
};

export default CreditLineIncreaseState;
