import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as ExpandIcon } from '../../../../../assets/images/table-row-expand.svg';

import './index.scss';

const CellText = ({
  value,
  className,
  withExpandIcon,
  isExpanded,
  additionalInfo,
  expandable,
  withError,
  index,
  isRowHasErrors,
  setDefaultValue
}) => {
  const { t } = useTranslation('translation');
  const withErroredRowFirstCell = !index && isRowHasErrors && withError;

  const getFormattedValue = value => {
    const isString = typeof value === 'string';

    const renderDefaultValue = () => (
      <span className={classNames(className, 'cell-value')}>
        {!value && setDefaultValue ? (withError ? t('table.cell.empty') : '---') : value}
      </span>
    );

    if (!isString) return renderDefaultValue();

    const [orderedNumber, label] = value?.split('.');

    return !isNaN(orderedNumber) && withErroredRowFirstCell ? (
      <>
        <span className="order-errored">{`${orderedNumber}. `}</span>
        <span>{!label.trim() ? (withError ? t('table.cell.empty') : '---') : label}</span>
      </>
    ) : (
      renderDefaultValue()
    );
  };

  const AdditionalInfo = ({ label }) => (
    <div className="additional-info">
      <span className="additional-info__label">{label}</span>
    </div>
  );

  return (
    <div
      className={classNames('cell-text', {
        'with-additional-info': additionalInfo,
        'with-error': withError,
        centered: !value
      })}
    >
      {withExpandIcon ? (
        <ExpandIcon
          className={classNames('expanded-icon', { expanded: isExpanded })}
          style={{ visibility: expandable ? 'visible' : 'hidden' }}
        />
      ) : null}
      {getFormattedValue(value)}

      {additionalInfo ? <AdditionalInfo label={additionalInfo} /> : null}
    </div>
  );
};

export default CellText;

CellText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
  className: PropTypes.string
};

CellText.defaultProps = {
  className: ''
};
