import SearchModal from '../search-modal';
import Portal from '../portal';

import './index.scss';

const SearchModalPortal = ({ data, closeModal, onItemClick, title }) => {
  const WithOverlayModal = ({ onItemClick, data, closeModal }) => (
    <div className="search-modal__with-overlay">
      <div className="app-overlay" onClick={closeModal} />
      <SearchModal
        data={data}
        onItemClick={onItemClick}
        title={title}
        closeModal={closeModal}
        withPortal
      />
    </div>
  );

  return (
    <Portal
      component={WithOverlayModal}
      nameOfClass="modal-search-js"
      data={data}
      onItemClick={onItemClick}
      closeModal={closeModal}
      title={title}
    />
  );
};

export default SearchModalPortal;
