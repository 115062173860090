import classNames from 'classnames';

import { PaginationItem, Ellipses, Chevron } from './internal';

import { useInnerHook } from './hooks';

import { ELLIPSES } from './constants';

import './index.scss';

const Pagination = ({ totalPages, activePage, setActivePage, onItemClick }) => {
  const {
    t,
    handleClick,
    handleNextClick,
    handlePreviousClick,
    orderedList,
    isPreviousButtonDisabled,
    isNextButtonDisabled
  } = useInnerHook({
    activePage,
    setActivePage,
    onItemClick,
    totalPages
  });

  return (
    <div className="pagination">
      <div
        className={classNames('pagination-nav__previous', {
          disabled: isPreviousButtonDisabled
        })}
        onClick={handlePreviousClick}
      >
        <Chevron
          direction={Chevron.direction.LEFT}
          disabled={isPreviousButtonDisabled}
          className="pagination-nav__previous-icon"
        />
        <div className="pagination-nav__previous-label">{t('previous')}</div>
      </div>
      <div className="pagination__list">
        {orderedList.map((i, idx) =>
          i === ELLIPSES ? (
            <Ellipses key={idx} />
          ) : (
            <PaginationItem
              key={idx}
              value={i}
              active={i === activePage}
              handleClick={handleClick}
            />
          )
        )}
      </div>
      <div
        className={classNames('pagination-nav__next', { disabled: isNextButtonDisabled })}
        onClick={handleNextClick}
      >
        <div className="pagination-nav__next-label">{t('next')}</div>
        <Chevron disabled={isNextButtonDisabled} className="pagination-nav__next-icon" />
      </div>
    </div>
  );
};

export default Pagination;
