import moment from 'moment';

import { ReactComponent as RecipientDetailIcon } from '../../../../assets/images/recipient-details.svg';
import { ReactComponent as MailIcon } from '../../../../assets/images/mail.svg';
import { ReactComponent as BankIcon } from '../../../../assets/images/bank.svg';

import { getIsPaymentConceptMXN } from '../../../send-payment/utils';
import { getAccountNumberKey } from '../../../send-payment/containers/recipient-bank-details/utils';

import { transactionsConstants } from '../../../../constants/transactions.constants';
import { OBJECT } from '../../../../constants/js-types.constants';

const { STATUS } = transactionsConstants;

const messageType = {
  DATE: 'DATE'
};

export const paymentTypes = {
  local: {
    STP: 'stp',
    SPEI: 'spei'
  },
  international: {
    CURRENCY_CLOUD: 'currency_cloud',
    SWAP: 'swap'
  }
};

const {
  international: { CURRENCY_CLOUD }
} = paymentTypes;

const { DATE } = messageType;

export const getIsExpandableValue = ({ message, isDateValue }) =>
  typeof message === OBJECT && message !== null && !Array.isArray(message) && !isDateValue;

export const getIsDateValue = type => type === DATE;

const recipientDetailsData = transactionInfo => [
  transactionInfo?.beneficiary_email,
  transactionInfo?.beneficiary_address,
  transactionInfo?.beneficiary_state
    ? `${transactionInfo?.beneficiary_city}, ${transactionInfo?.beneficiary_state}`
    : transactionInfo?.beneficiary_city,
  transactionInfo?.beneficiary_country,
  transactionInfo?.beneficiary_zip
];

const bankDetailsData = transactionInfo => [
  transactionInfo?.bank_address,
  transactionInfo?.bank_state
    ? `${transactionInfo?.bank_city}, ${transactionInfo?.bank_state}`
    : transactionInfo?.bank_city,
  transactionInfo?.bank_country,
  transactionInfo?.bank_postcode
];

const getIsCompletedOnMessageAvailable = transactionStatus =>
  transactionStatus === STATUS.COMPLETE ? 'transactions.rightModal.completedOn' : null;

const getFormattedDate = date => {
  if (!date) return '';

  const firstPart = moment(date).format('DD-MM-YYYY');
  const secondPart = `${moment(date).format('hh:mm:ss A')} UTC`;

  return {
    firstPart,
    secondPart
  };
};

const getIsCurrencyCloudType = type => type === CURRENCY_CLOUD;

// TODO PAY-997 remove peruanAccountNumber after feature is done
export const getInternationalData = ({
  transactionInfo,
  modalDataFromTable,
  peruanAccountNumber
}) => [
    {
      label: 'transactions.rightModal.payer',
      message: modalDataFromTable?.from
    },
    {
      label: 'transactions.rightModal.paymentType',
      message: transactionInfo?.payment_type,
      messageClass: 'capitalize'
    },
    {
      label: 'transactions.rightModal.deliveryDate',
      message: getFormattedDate(transactionInfo?.delivery_date),
      type: DATE
    },
    {
      label: 'transactions.rightModal.initiatedOn',
      message: getFormattedDate(transactionInfo?.created_at),
      type: DATE
    },
    {
      label: 'transactions.rightModal.completedOn',
      message: getIsCompletedOnMessageAvailable(modalDataFromTable?.status?.key)
        ? getFormattedDate(transactionInfo?.delivery_date)
        : ' ',
      type: DATE
    },
    {
      label: getIsCurrencyCloudType(transactionInfo?.integration_type)
        ? 'transactions.rightModal.paymentReference'
        : 'transactions.rightModal.referenceNumber',
      message: transactionInfo?.reference_number
    },
    {
      label: 'transactions.rightModal.swift',
      message:
        getIsCurrencyCloudType(transactionInfo?.integration_type) && transactionInfo?.swift_code
    },
    {
      label: 'ABA',
      message: transactionInfo?.addtional_attributes?.aba
    },
    {
      label: peruanAccountNumber
        ? getAccountNumberKey(transactionInfo?.bank_country, 'transactions.rightModal.accountNumber')
        : 'transactions.rightModal.accountNumber.common',
      message: transactionInfo?.addtional_attributes?.acctnumber
    },
    {
      label: 'transactions.rightModal.routingNumber',
      message: transactionInfo?.addtional_attributes?.['Routing Number']
    },
    {
      label: 'transactions.rightModal.branchcode',
      message: transactionInfo?.addtional_attributes?.branchcode
    },
    {
      label: 'transactions.rightModal.bankcode',
      message: transactionInfo?.addtional_attributes?.bankcode
    },
    {
      label: 'transactions.rightModal.institutionno',
      message: transactionInfo?.addtional_attributes?.institutionno
    },
    {
      label: 'transactions.rightModal.bsbcode',
      message: transactionInfo?.addtional_attributes?.bsbcode
    },
    {
      label: 'transactions.rightModal.iban',
      message: transactionInfo?.addtional_attributes?.iban
    },
    {
      label: 'transactions.rightModal.sortcode',
      message: transactionInfo?.addtional_attributes?.sortcode
    },
    {
      label: 'transactions.rightModal.cnaps',
      message: transactionInfo?.addtional_attributes?.cnaps
    },
    {
      label: 'transactions.rightModal.ifsc',
      message: transactionInfo?.addtional_attributes?.ifsc
    },
    {
      label:
        transactionInfo?.isLocalPayment || getIsPaymentConceptMXN()
          ? 'transactions.rightModal.paymentConcept'
          : 'transactions.rightModal.messageToRecipient',
      message: transactionInfo?.message
    },
    {
      label: 'transactions.rightModal.wireFee',
      message: transactionInfo?.wire_fee
    },
    {
      label: 'transactions.rightModal.paymentSource',
      message: 'Tribal Credit Line',
      className: 'no-margin-bottom'
    },
    {
      type: 'divider'
    },
    {
      label: 'transactions.rightModal.recipientDetails',
      message: {
        label: transactionInfo?.beneficiary_name,
        items: recipientDetailsData(transactionInfo)
      },
      icon: RecipientDetailIcon
    },
    {
      label: 'transactions.rightModal.bankDetails',
      message: {
        label: transactionInfo?.beneficiary_bank,
        items: bankDetailsData(transactionInfo)
      },
      icon: BankIcon
    },
    {
      label: 'transactions.rightModal.emailPaymentReceipt',
      message: transactionInfo?.emails?.length ? transactionInfo.emails : null,
      icon: MailIcon
    }
  ];

export const getLocalData = ({ cepInfo, transactionInfo, modalDataFromTable }) => [
  {
    label: 'transactions.rightModal.payer',
    message: modalDataFromTable?.from
  },
  {
    label: 'transactions.rightModal.paymentType',
    message: transactionInfo?.payment_type,
    messageClass: 'capitalize'
  },
  {
    label: 'transactions.rightModal.deliveryDate',
    message: getFormattedDate(transactionInfo?.delivery_date),
    type: DATE
  },
  {
    label: 'transactions.rightModal.initiatedOn',
    message: getFormattedDate(transactionInfo?.created_at),
    type: DATE
  },
  {
    label: 'transactions.rightModal.completedOn',
    message: getIsCompletedOnMessageAvailable(modalDataFromTable?.status?.key)
      ? getFormattedDate(transactionInfo?.delivery_date)
      : ' ',
    type: DATE
  },
  {
    label: 'transactions.rightModal.trackingKey',
    message: transactionInfo?.tracking_key
  },
  {
    label: 'transactions.rightModal.cepUrl',
    message: cepInfo?.cepUrl ? cepInfo.message : null,
    link: cepInfo?.cepUrl,
    newTab: true
  },

  {
    label: 'transactions.rightModal.issuingBank',
    message: transactionInfo?.issuing_bank
  },
  {
    label: 'transactions.rightModal.beneficiaryBank',
    message: transactionInfo?.beneficiary_bank
  },
  {
    label: 'transactions.rightModal.clabe',
    message: transactionInfo?.addtional_attributes?.clabe
  },
  {
    label:
      transactionInfo?.isLocalPayment || getIsPaymentConceptMXN()
        ? 'transactions.rightModal.paymentConcept'
        : 'transactions.rightModal.messageToRecipient',
    message: transactionInfo?.message,
    messageClass: 'capitalize'
  },
  {
    label: 'transactions.rightModal.referenceNumber',
    message: transactionInfo?.reference_number
  },
  {
    label: 'transactions.rightModal.wireFee',
    message: transactionInfo?.wire_fee
  },
  {
    label: 'transactions.rightModal.paymentSource',
    message: 'Tribal Credit Line',
    className: 'no-margin-bottom'
  },
  {
    type: 'divider'
  },
  {
    label: 'transactions.rightModal.recipientDetails',
    message: {
      label: transactionInfo?.beneficiary_name,
      items: recipientDetailsData(transactionInfo)
    },
    icon: RecipientDetailIcon
  },
  {
    label: 'transactions.rightModal.emailPaymentReceipt',
    message: transactionInfo?.emails?.length ? transactionInfo.emails : null,
    icon: MailIcon
  }
];
