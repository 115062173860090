import classNames from 'classnames';
import PropTypes from 'prop-types';

import { stepStatuses } from '../../constants';

import './index.scss';

const { PENDING, COMPLETE, ACTIVE } = stepStatuses;

const StepLine = ({ status, width }) => {
  return (
    <div
      className={classNames('step-line', {
        pending: status === PENDING || ACTIVE,
        complete: status === COMPLETE
      })}
      style={{
        width
      }}
    />
  );
};

export default StepLine;

StepLine.propTypes = {
  status: PropTypes.string.isRequired,
  width: PropTypes.number
};

StepLine.defaultProps = {
  width: 0
};
