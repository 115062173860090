import { configurationConstants } from './../constants/configuration.constants';

const INITIAL_STATE = {
  config: {
    userStatuses: null,
    isOverlayVisible: false
  }
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case configurationConstants.SET_USER_STATUSES:
      const statuses = {};
      action.statuses.forEach(status => {
        if (status.label === 'Active') {
          statuses.active = status.id;
        }
        if (status.label === 'Suspend') {
          statuses.suspend = status.id;
        }
        if (status.label === 'Pending') {
          statuses.pending = status.id;
        }
        if (status.label === 'Waiting') {
          statuses.waiting = status.id;
        }
      });
      return { ...state, config: { ...state.config, userStatuses: statuses } };
    case configurationConstants.SET_OVERLAY_VISIBILITY:
      const { isOverlayVisible } = action;
      return { ...state, config: { ...state.config, isOverlayVisible } };
    default:
      return state;
  }
}
