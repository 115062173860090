import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isAuthorized } from '../../../utils/auth-client';

import { getAuthenticatedApp } from '../../../utils/launchdarkly';

const PrivateRoute = ({ component, authComponent: AuthComponent, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const AuthenticatedApp = getAuthenticatedApp(component);
      return isAuthorized() ? <AuthenticatedApp {...props} /> : <AuthComponent />;
    }}
  />
);

export default PrivateRoute;

PrivateRoute.propTypes = {
  component: PropTypes.object,
  authComponent: PropTypes.object
};
