export const notificationsConstants = {
  SET_NOTIFICATIONS_TYPES: 'SET_NOTIFICATIONS_TYPES',
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  SET_ARCHIVED_NOTIFICATIONS: 'SET_ARCHIVED_NOTIFICATIONS',
  HIDE_ARCHIVED_NOTIFICATIONS: 'HIDE_ARCHIVED_NOTIFICATIONS',
  SET_NOTIFICATIONS_SETTINGS: 'SET_NOTIFICATIONS_SETTINGS',
  UPDATE_NOTIFICATIONS_SETTINGS: 'UPDATE_NOTIFICATIONS_SETTINGS',
  UPDATE_NOTIFICATION_SETTING: 'UPDATE_NOTIFICATION_SETTING',
  ARCHIVE_NOTIFICATION: 'ARCHIVE_NOTIFICATION',
  UNARCHIVE_NOTIFICATION: 'UNARCHIVE_NOTIFICATION',
  ARCHIVE_ALL_NOTIFICATIONS: 'ARCHIVE_ALL_NOTIFICATIONS',
  MARK_ALL_AS_READ: 'MARK_ALL_AS_READ',
  SORT_NOTIFICATIONS: 'SORT_NOTIFICATIONS',

  CATEGORIES_IDS: {
    ADVANCE_PAYMENTS: 1,
    MONTHLY_STATEMENTS: 2,
    PAYMENT_REMINDERS: 3,
    PAYMENT_CONFIRMATIONS: 4,
    OVERDUE_PAYMENTS: 5,
    DECLINED_TRANSACTIONS: 6,
    APPROVED_TRANSACTIONS: 7
  }
};
