import { useEffect, useState } from 'react';
import { getIconColors } from './utils';

export const useStepColors = status => {
  const [stepColors, setStepColors] = useState({
    circleStoke: '',
    circleFill: '',
    pathStoke: ''
  });

  useEffect(() => {
    const currentColors = getIconColors(status);
    setStepColors(currentColors);
  }, [status]);

  return { stepColors };
};
