const MIN_PROGRESS = 0;
const MAX_PROGRESS = 100;

// default diameter is equal width or height of parent
// whichever is lesser, or zero, if parent dimensions are not set
const getDefaultDiameter = (parentWidth, parentHeight) => {
  if (parentWidth && parentHeight) {
    return parentHeight >= parentWidth ? parentWidth : parentHeight;
  }
  return parentHeight || parentWidth;
};

// get default diameter/stroke ratio
const getDefaultStroke = diameter => diameter / 25;

// get radius including border
const getRadius = sideLength => (sideLength ? sideLength / 2 : 0);

// get radius excluding border
const getNormalizedRadius = (radius, stroke) => radius - stroke;

// get length of the circle
const getCircumference = normalizedRadius => normalizedRadius * 2 * Math.PI;

// get length of the border area that is not filled
const getStrokeDashoffset = (circumference, progress) =>
  circumference -
  (_getValidatedProgress(progress) / MAX_PROGRESS) * circumference;

const _getValidatedProgress = progress => {
  if (progress >= MIN_PROGRESS && progress <= MAX_PROGRESS) {
    return progress;
  }
  return progress > MAX_PROGRESS ? MAX_PROGRESS : MIN_PROGRESS;
};

export {
  getDefaultDiameter,
  getDefaultStroke,
  getRadius,
  getNormalizedRadius,
  getCircumference,
  getStrokeDashoffset
};
