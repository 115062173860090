import axios from 'axios';

import { getAuthorizationForRequest } from '../utils/network-util';
import { API_TRIBAL_INTERNAL_URL } from './../constants/common.constants';
import { handleRequestError } from './handlers/error-handlers';

export const CLIService = {
  submitCLIData,
  getCLICurrentData
};

const httpCLI = axios.create({ baseURL: API_TRIBAL_INTERNAL_URL });

async function submitCLIData(amount, details, files) {
  const getFormData = () => {
    const formData = new FormData();
    files.forEach((_, idx) => formData.append('files[]', files[idx]));

    return formData;
  };

  return httpCLI
    .post(
      `cli-requests/post-new-cli?NewCLIAmount=${amount}&AdditionalDetails=${details}`,
      getFormData(),
      {
        headers: {
          Accept: 'application/json',
          Authorization: await getAuthorizationForRequest()
        }
      }
    )
    .catch(handleRequestError);
}

async function getCLICurrentData() {
  return httpCLI
    .get(`cli-requests/new-cli-status`, {
      headers: {
        Accept: 'application/json',
        Authorization: await getAuthorizationForRequest()
      }
    })
    .catch(handleRequestError);
}
