import { useEffect, useRef, useState } from 'react';

import infoMarker from '../../../../assets/images/info-circle.svg';

const RingContent = ({
  title,
  description,
  subDesc,
  circumference,
  prevPrevStrokeDashoffset,
  prevStrokeDashoffset,
  strokeDashoffset,
  secondaryStrokeDashoffset,
  onHoverStateChange,
  currencySymbol,
  creditSpent,
  walletSpent,
  remainingBalance,
  ...commonRingParams
}) => {
  const { stroke } = commonRingParams;

  return (
    <>
      <RingLayer stroke={stroke} {...commonRingParams} />
      <RingLayer
        layerModifier="base"
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset: -prevPrevStrokeDashoffset }}
        {...commonRingParams}
      />
      <RingLayer
        layerModifier="bottom"
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset: prevStrokeDashoffset }}
        {...commonRingParams}
      />
      <RingLayer
        layerModifier="intermediate"
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset }}
        {...commonRingParams}
      />
      <RingLayer
        layerModifier="top"
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset: secondaryStrokeDashoffset }}
        {...commonRingParams}
      />
      <InnerContent
        stroke={stroke}
        title={title}
        description={description}
        subDesc={subDesc}
        infoMarker={infoMarker}
        onHoverStateChange={onHoverStateChange}
      />
    </>
  );
};

const RingLayer = ({ layerModifier, diameter, stroke, normalizedRadius, radius, ...rest }) => (
  <>
    <svg className={`ring-svg ${layerModifier || ''}`.trim()} height={diameter} width={diameter}>
      <circle
        className="balance-circle"
        strokeWidth={stroke}
        r={normalizedRadius}
        // TODO fix rounded strokeLinecap
        // strokeLinecap="round"
        cx={radius}
        cy={radius}
        {...rest}
      />
    </svg>
  </>
);

const InnerContent = ({ title, description, subDesc, infoMarker, onHoverStateChange, stroke }) => {
  const innerContentRef = useRef(null);
  const [innerContentWidth, setInnerContentWidth] = useState(null);
  const [innerContentHeight, setInnerContentHeight] = useState(null);

  useEffect(() => {
    if (innerContentRef.current) {
      setInnerContentWidth(innerContentRef.current.offsetWidth - 1.5 * stroke);
      setInnerContentHeight(innerContentRef.current.offsetHeight - 1.5 * stroke);
    }
  }, [innerContentRef.current]);

  return (
    <div
      className="ring-inner-content"
      ref={innerContentRef}
      style={{ width: innerContentWidth, height: innerContentHeight }}
    >
      <span className="ring-title">{title}</span>
      <span className="ring-description">{description}</span>
      {subDesc ? (
        <div className="ring--sub-description__row">
          <span className="ring-description">{subDesc}</span>
          <img
            alt=""
            className="ring-info-marker"
            src={infoMarker}
            onMouseEnter={() => onHoverStateChange(true)}
            onMouseLeave={() => onHoverStateChange(false)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default RingContent;
