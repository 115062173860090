import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { amplitudeInstance } from '../../../analytics/amplitude';

import Portal from '../portal';
import ExchangeDataTooltip from '../exchange-data-tooltip';
import { ReactComponent as InfoIcon } from '../../../assets/images/info-circle.svg';

import { useTooltipCoordinates } from '../../../hooks';

import './index.scss';

const SetToMax = ({ isSelected, hasTooltip, onClick, rateInfo = null, isDisabled }) => {
  const [isExchangeTooltipVisible, setIsExchangeTooltipVisible] = useState(false);
  const { t } = useTranslation();
  const { tooltipCoords, handleHover } = useTooltipCoordinates(setIsExchangeTooltipVisible);

  const handleExchangeDataMouseOut = () => {
    setIsExchangeTooltipVisible(false);
  };

  useEffect(() => {
    isExchangeTooltipVisible && amplitudeInstance.logEvent('Card_Currency_Exchange_Viewed');
  }, [isExchangeTooltipVisible]);

  return (
    <div
      className={classNames('set-to-max', { selected: isSelected, locked: isDisabled })}
      onClick={onClick}
    >
      <span>{t('cardDetails.setToMax')}</span>
      {hasTooltip ? (
        <InfoIcon
          className="set-to-max__icon"
          onMouseEnter={handleHover}
          onMouseLeave={handleExchangeDataMouseOut}
        />
      ) : null}
      {isExchangeTooltipVisible && rateInfo && (
        <Portal
          component={ExchangeDataTooltip}
          nameOfClass="exchange-data-tooltip-js"
          lastUpdate={rateInfo.lastUpdate}
          formattedExchangeRate={rateInfo.formattedExchangeRate}
          tooltipCoords={tooltipCoords}
        />
      )}
    </div>
  );
};

SetToMax.propsType = {
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool
};

SetToMax.defaultProps = {
  isSelected: false,
  isDisabled: false
};

export default memo(SetToMax);
