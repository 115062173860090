import axios from 'axios';

import { API_URL } from '../constants/common.constants';
import { handleRequestError } from './handlers/error-handlers';
import { getDeviceId } from '../utils/auth-client';
import { _DEFAULT_CONFIG } from './config';

export const transactionsService = {
  getCompanyTransactions,
  getTransactionStates,
  downloadUserTransactions,
  getAttachmentContent
};

async function getCompanyTransactions(billingId, page) {
  const deviceId = await getDeviceId();
  let params = {
    device_id: deviceId,
    page: page ? page : 1
  };
  if (billingId) params['billing_id'] = billingId;
  return axios
    .get(`${API_URL}/api/transactions/company-transactions`, {
      ...(await _DEFAULT_CONFIG()),
      params
    })
    .catch(handleRequestError);
}

async function downloadUserTransactions({ billingId }) {
  const deviceId = await getDeviceId();
  let params = {
    device_id: deviceId,
    billing_id: billingId
  };

  return axios
    .get(`${API_URL}/api/transactions/download-User-transactions`, {
      ...(await _DEFAULT_CONFIG()),
      params
    })
    .catch(handleRequestError);
}

async function getAttachmentContent() {
  let params = {
    fileName: 'Transactions on Tribal'
  };

  return axios
    .get(`${API_URL}/api/transactions/get-attachment-content`, {
      ...(await _DEFAULT_CONFIG()),
      params
    })
    .catch(handleRequestError);
}

async function getTransactionStates() {
  const deviceId = await getDeviceId();
  let params = {
    device_id: deviceId
  };
  return axios
    .get(`${API_URL}/api/transactions/transaction-states`, {
      ...(await _DEFAULT_CONFIG()),
      params
    })
    .catch(handleRequestError);
}
