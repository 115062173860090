import Model from './notifications.js';

import addUser from './../../assets/images/notifications/add-user.svg';
import creditLimit from './../../assets/images/notifications/credit-limit.svg';
import reminder from './../../assets/images/notifications/reminder.svg';
import alert from './../../assets/images/notifications/alert.svg';

const icon = {
  [Model.NOTIFICATION_TYPES.ALERT]: [alert],
  [Model.NOTIFICATION_TYPES.ADD_USER]: [addUser],
  [Model.NOTIFICATION_TYPES.CREDIT_LIMIT]: [creditLimit],
  [Model.NOTIFICATION_TYPES.REMINDER]: [reminder]
};

const title = {
  [Model.NOTIFICATION_TYPES.ALERT]: 'Account Alert',
  [Model.NOTIFICATION_TYPES.ADD_USER]: 'Add User Request',
  [Model.NOTIFICATION_TYPES.CREDIT_LIMIT]: 'Credit Limit Request',
  [Model.NOTIFICATION_TYPES.REMINDER]: 'Just so you know...'
};

const mainColor = {
  [Model.NOTIFICATION_TYPES.ALERT]: '#ff5f5f',
  [Model.NOTIFICATION_TYPES.ADD_USER]: '#0078fc',
  [Model.NOTIFICATION_TYPES.CREDIT_LIMIT]: '#0078fc',
  [Model.NOTIFICATION_TYPES.REMINDER]: '#0078fc'
};

const secondaryColor = {
  [Model.NOTIFICATION_TYPES.ALERT]: 'rgba(255, 95, 95, 0.11)',
  [Model.NOTIFICATION_TYPES.ADD_USER]: '#ecf5fe',
  [Model.NOTIFICATION_TYPES.CREDIT_LIMIT]: '#ecf5fe',
  [Model.NOTIFICATION_TYPES.REMINDER]: '#ecf5fe'
};

export default {
  icon,
  title,
  mainColor,
  secondaryColor
};
