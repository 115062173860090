import { useCallback, memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { BOX_SUCCESS, BOX_INFO, BOX_ERROR } from '../../../constants/box-info.constants';

import './index.scss';

const BoxInfo = ({ message, description, type, children }) => {
  const mainClassName = classNames('info-box', {
    info: type === BOX_INFO,
    success: type === BOX_SUCCESS,
    error: type === BOX_ERROR
  });

  const defaultValue = useCallback(
    () =>
      message && (
        <div className={mainClassName}>
          <label className="info-box--label">
            {description && <b> {description}</b>}
            {message}
          </label>
        </div>
      ),
    [message, mainClassName, description]
  );

  const childrenValue = useCallback(
    () => (
      <div className={mainClassName}>
        <label className="info-box--label" children={children} />
      </div>
    ),
    [children, mainClassName]
  );

  return children ? childrenValue() : defaultValue();
};

export default memo(BoxInfo);

BoxInfo.propTypes = {
  message: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.array
};

BoxInfo.defaultProps = {
  message: null,
  description: null,
  type: null,
  children: null
};
