import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowIcon } from './../../../assets/images/back-arrow.svg';

import './index.scss';

const PrevNextButton = ({ next, disabled, onClickHandler }) => {
  return (
    <div
      className={`prev-next-button
          ${next ? 'prev-next-button--next' : ''} 
          ${disabled ? 'prev-next-button--disabled' : ''}`}
      onClick={e => {
        e.preventDefault();
        if (onClickHandler) onClickHandler();
      }}
    >
      <ArrowIcon className="arrow" />
    </div>
  );
};

PrevNextButton.propsType = {
  next: PropTypes.bool,
  disabled: PropTypes.bool,
  onClickHandler: PropTypes.func
};

PrevNextButton.defaultProps = {
  next: false,
  disabled: false
};

export default memo(PrevNextButton);
