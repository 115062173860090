import React from 'react';

import './index.scss';

const ToggleSwitch = function({
  isOn,
  handleToggle,
  labelOff,
  labelOn,
  disabled
}) {
  const classNames = `
    switch 
    ${isOn ? 'switch_is-on' : 'switch_is-off'}
    ${disabled ? 'disabled' : ''}
   `;
  return (
    <div className={classNames} onClick={e => !disabled && handleToggle(e)}>
      <span className="switch-on__label">{labelOff}</span>
      <ToggleButton isOn={isOn} labelOff={labelOff} labelOn={labelOn} />
      <span className="switch-off__label">{labelOn}</span>
    </div>
  );
};

const ToggleButton = function({ isOn, labelOff, labelOn }) {
  const classNames = `toggle-button ${
    isOn ? 'toggle-button_position-right' : 'toggle-button_position-left'
  }`;
  return (
    <div className={classNames}>
      <span>{isOn ? labelOn : labelOff}</span>
    </div>
  );
};

export default ToggleSwitch;
