import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Portal } from '../index';
import { ReactComponent as InfoIcon } from '../../../assets/images/info-circle.svg';

import { useInnerHook } from './hooks';
import { useRemoveTooltipVisibility } from '../../../hooks';

import './index.scss';

const Label = ({ label, infoTooltipDescription, className, disabled, scrollInstanceId }) => {
  const {
    isInfoTooltipVisible,
    handleEmailInfoDataMouseOut,
    handleEmailInfoDataMouseOver,
    tooltipCoords
  } = useInnerHook();

  const ToolTip = ({ infoTooltipDescription, tooltipCoords }) => (
    <div className="label__info-tooltip" style={tooltipCoords}>
      <div>
        <span>{infoTooltipDescription}</span>
      </div>
    </div>
  );

  useRemoveTooltipVisibility({
    dependencies: [isInfoTooltipVisible],
    condition: isInfoTooltipVisible,
    removeTooltipVisibility: handleEmailInfoDataMouseOut,
    instance: scrollInstanceId
  });

  return (
    <span
      className={classNames('label', className, {
        disabled
      })}
    >
      {label}
      {infoTooltipDescription ? (
        <InfoIcon
          onMouseEnter={handleEmailInfoDataMouseOver}
          onMouseLeave={handleEmailInfoDataMouseOut}
        />
      ) : null}
      {isInfoTooltipVisible ? (
        <Portal
          component={ToolTip}
          nameOfClass="label-tooltip-js"
          tooltipCoords={tooltipCoords}
          infoTooltipDescription={infoTooltipDescription}
        />
      ) : null}
    </span>
  );
};

export default Label;

Label.propTypes = {
  label: PropTypes.string,
  infoTooltipDescription: PropTypes.string
};

Label.defaultProps = {
  label: null,
  infoTooltipDescription: null
};
