import { useState, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Loader from '../../../../components/shared/loader';
import { ScrollableArea } from '../../../../components/scrollable-area';
import { ReactComponent as DropdownArrowIcon } from '../../../../assets/images/wallet/dropdown-arrow.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/images/close-icon.svg';
import Divider from '../../../../components/shared/divider';
import CurrencyInfo from '../../../../components/shared/currency-info';
import RoundedButton from '../../../../components/shared/button-rounded';
import { getInternationalData, getLocalData, getIsExpandableValue, getIsDateValue } from './utils';
import { STRING } from '../../../../constants/js-types.constants';
import { useGetPaymentInfo } from './hooks';
import './index.scss';

const RightModal = ({
  closeRightModal,
  isRightModalVisible,
  modalData: modalDataFromTable,
  handleDetailsDownload,
  cepInfo = null
}) => {
  const { transactionId } = modalDataFromTable;
  const { t } = useTranslation('wallet');

  const ExpandableMessage = ({ message: { items, label } }) => {
    const [isItemExpanded, setItemExpandability] = useState();

    return (
      <div
        className="delivering-row__expandable-value"
        onClick={() => setItemExpandability(!isItemExpanded)}
      >
        <div className="header-container">
          <span>{label}</span>
          {items.length ? <DropdownArrowIcon className={isItemExpanded ? 'active' : null} /> : null}
        </div>
        {isItemExpanded ? (
          <div className="menu-container">
            {items.map((i, idx) => (
              <span className="value" key={idx}>
                {i}
              </span>
            ))}
          </div>
        ) : null}
      </div>
    );
  };

  const DateMessage = ({ date }) => (
    <>
      <span>{date.firstPart}</span>
      <span>{date.secondPart}</span>
    </>
  );

  const Message = ({ message, isExpandableValue, messageClass, isDateValue, link, newTab }) => {
    return isExpandableValue ? (
      <ExpandableMessage message={message} />
    ) : (
      <div
        className={classNames('delivering-row__value message', messageClass, {
          'date-value': isDateValue
        })}
      >
        {isDateValue ? <DateMessage date={message} /> :
          link && newTab ? <label style={{ color: "#3366BB", cursor: "pointer" }} onClick={() => window.open(link, "_blank")}>{message}</label> : message}

      </div>
    );
  };

  const BodyItems = ({ data }) => (
    <div className="right-modal__delivering">
      {data.map(({ label, message, icon, className, messageClass, type, link, newTab }, idx) => {
        if (type === 'divider') return <Divider main key={idx} />;
        if (!message || !label) return;
        const Icon = icon;
        let isDateValue = getIsDateValue(type);
        const isExpandableValue = getIsExpandableValue({ message, isDateValue });

        const isNoMarginBottom = className === 'no-margin-bottom';

        if (Array.isArray(message) && typeof message[0] === STRING)
          message = message.map((m, messageIdx) => <span key={messageIdx}>{m}</span>);

        return (
          <div
            className={classNames('delivering-row', {
              'no-margin-bottom': isNoMarginBottom
            })}
            key={idx}
          >
            <div className="delivering-row__label">
              <span>
                {icon && <Icon className="icon" />} {t(label)}
              </span>
            </div>
            <Message
              link={link}
              newTab={newTab}
              messageClass={messageClass}
              message={message}
              isExpandableValue={isExpandableValue}
              isDateValue={isDateValue}
            />
          </div>
        );
      })}
    </div>
  );

  // TODO PAY-997 remove peruanAccountNumber after feature is done
  const International = ({ transactionInfo, peruanAccountNumber }) => (
    <BodyItems
      data={getInternationalData({ transactionInfo, modalDataFromTable, peruanAccountNumber })}
    />
  );

  const Local = ({ transactionInfo }) => (
    <BodyItems data={getLocalData({ cepInfo, transactionInfo, modalDataFromTable })} />
  );

  const ModalContent = ({ transactionInfo }) => {
    // TODO PAY-997 remove after feature is done
    const { peruanAccountNumber } = useFlags();

    const [isFileDownloading, setIsFileDownloading] = useState(false);

    return (
      <>
        <div className="right-modal__header">
          <span className="title">{`${t('transactions.rightModal.header.to')}: ${transactionInfo?.beneficiary_name
            }`}</span>
          <span className="sub-title">
            {t('transactions.rightModal.header.submitted', {
              name: modalDataFromTable.payer,
              date: transactionInfo?.created_at
                ? moment(transactionInfo.created_at).format('D/M/YY')
                : null
            })}
          </span>
        </div>

        <div className="buttons-section">
          {modalDataFromTable.status.key ? (
            <RoundedButton
              className={classNames(
                'buttons-section__status-button',
                modalDataFromTable.status.key
              )}
              disabled
            >
              {modalDataFromTable.status.label}
            </RoundedButton>
          ) : null}

          <RoundedButton
            className={classNames('buttons-section__download-file', {
              loading: isFileDownloading
            })}
            disabled={isFileDownloading}
            onClick={() => {
              setIsFileDownloading(true);
              handleDetailsDownload(transactionId, setIsFileDownloading);
            }}
          >
            {isFileDownloading ? (
              <div className="download-button__loader-wrapper">
                <Loader small style={{ position: 'initial' }} />
              </div>
            ) : null}
            {t('transactions.rightModal.header.download')}
          </RoundedButton>
        </div>
        <div className="right-modal__body">
          <ScrollableArea styles={{ width: '100%', height: '100%' }}>
            <CurrencyInfo
              transactionInfo={transactionInfo}
              amount={modalDataFromTable.amount}
              modalDataFromTable={modalDataFromTable}
              t={t}
            />
            <Divider main />
            {transactionInfo?.isLocalPayment ? (
              <Local transactionInfo={transactionInfo} />
            ) : (
              <International
                transactionInfo={transactionInfo}
                peruanAccountNumber={peruanAccountNumber}
              />
            )}
          </ScrollableArea>
        </div>
      </>
    );
  };

  const ModalError = () => (
    <div className="right-modal__error-container">
      <span className="right-modal__error-message">
        {t('transactions.rightModal.error.errorMessage')}
      </span>
    </div>
  );

  const renderModalContent = ({ transactionInfo, hasPaymentInfoError }) =>
    hasPaymentInfoError ? <ModalError /> : <ModalContent transactionInfo={transactionInfo} />;

  const Modal = () => {
    let { transactionInfo, isGetTransactionInfoLoading, hasPaymentInfoError } = useGetPaymentInfo({
      transactionId,
      currencyCode: modalDataFromTable.currencyCode
    });

    return (
      <div
        className={classNames('right-modal', {
          visible: isRightModalVisible
        })}
      >
        <CloseIcon className="right-modal__close-icon" onClick={closeRightModal} />

        {!isGetTransactionInfoLoading ? (
          renderModalContent({ transactionInfo, hasPaymentInfoError })
        ) : (
          <div className="loader-wrapper">
            <Loader />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="app-overlay" onClick={closeRightModal} />
      <Modal />
    </>
  );
};

export default memo(RightModal);

RightModal.propTypes = {
  closeRightModal: PropTypes.func.isRequired,
  isRightModalVisible: PropTypes.bool.isRequired,
  modalData: PropTypes.object.isRequired
};
