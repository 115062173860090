import { combineReducers } from 'redux';
import configuration from './configuration';
import user from './user';
import cards from './cards';
import transactions from './transactions';
import company from './company';
import reports from './reports';
import notifications from './notifications';
import statements from './statements';
import deposits from './deposits';
import wallet from './wallet';
import invoices from './invoices';

export default combineReducers({
  configuration,
  user,
  cards,
  transactions,
  company,
  reports,
  notifications,
  statements,
  deposits,
  wallet,
  invoices
});
