const _CONTAINER_CLASS_MODIFIERS = {
  DEFAULT: 'default',
  LEFT: 'left',
  RIGHT: 'right',
  NORMAL: ''
};

const DEFAULT_POSITION = {
  containerClassModifier: _CONTAINER_CLASS_MODIFIERS.DEFAULT,
  bodyMargin: 0,
  pointerMargin: 0
};

const getValidatedPercent = percent => {
  let validPercent = percent > 100 ? 100 : 0;
  if (percent >= 0 && percent <= 100) {
    validPercent = percent;
  }
  return validPercent;
};

// get sizes of the tooltip elements in px
const getElementsSizes = (percent, containerWidth = 0, bodyWidth = 0, pointerWidth = 0) => {
  const filledWidth = (containerWidth / 100) * percent;

  return {
    halfBodyWidth: bodyWidth / 2,
    halfPointerWidth: pointerWidth / 2,
    filledWidth,
    unfilledWidth: containerWidth - filledWidth
  };
};

// get position of tooltip elements based on their size
const getPosition = elementsSizes => {
  const position = {};

  const containerClassModifier = _getContainerClassModifier(elementsSizes);
  position.containerClassModifier = containerClassModifier;
  position.bodyMargin = _getBodyMargin(elementsSizes, containerClassModifier);
  position.pointerMargin = _getPointerMargin(elementsSizes);

  return position;
};

const _getContainerClassModifier = ({ filledWidth, unfilledWidth, halfBodyWidth }) => {
  let modifier = _CONTAINER_CLASS_MODIFIERS.NORMAL;

  if (filledWidth <= halfBodyWidth) {
    modifier = _CONTAINER_CLASS_MODIFIERS.LEFT;
  } else if (unfilledWidth <= halfBodyWidth) {
    modifier = _CONTAINER_CLASS_MODIFIERS.RIGHT;
  }

  return modifier;
};

const _getBodyMargin = (elementsSizes, containerClassModifier) =>
  containerClassModifier === _CONTAINER_CLASS_MODIFIERS.NORMAL
    ? elementsSizes.filledWidth - elementsSizes.halfBodyWidth
    : -5;

const _getPointerMargin = ({ filledWidth, halfPointerWidth }) => filledWidth - halfPointerWidth;

export { DEFAULT_POSITION, getElementsSizes, getPosition, getValidatedPercent };
