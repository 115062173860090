export const cardsConstants = {
  INCREMENT_CARDS_COUNT: 'INCREMENT_CARDS_COUNT',
  SET_CARDS: 'SET_CARDS',
  SET_CARDS_TYPES: 'SET_CARDS_TYPES',
  ADD_AND_SET_ACTIVE_CARD: 'ADD_AND_SET_ACTIVE_CARD',
  SET_ACTIVE_CARD: 'SET_ACTIVE_CARD',
  CLEAR_ACTIVE_CARD: 'CLEAR_ACTIVE_CARD',
  UPDATE_CARD_STATUS: 'UPDATE_CARD_STATUS',
  UPDATE_CARD_PAN: 'UPDATE_CARD_PAN',
  UPDATE_CARD_VISIBILITY: 'UPDATE_CARD_VISIBILITY',
  UPDATE_CARD_LIMIT: 'UPDATE_CARD_LIMIT',
  UPDATE_USER_CARD_LIMIT: 'UPDATE_USER_CARD_LIMIT',
  RESET_CARD_LIMIT: 'RESET_CARD_LIMIT',
  RESET_USER_CARD_LIMIT: 'RESET_USER_CARD_LIMIT',
  SET_ACTIVE_CARD_TRANSACTIONS: 'SET_ACTIVE_CARD_TRANSACTIONS',
  SORT_CARDS_BY_KEY: 'SORT_CARDS_BY_KEY',
  SET_NEW_SHIPPING_ADDRESS: 'SET_NEW_SHIPPING_ADDRESS',
  HIDE_SHIPPING_ADDRESS_UPDATE: 'HIDE_SHIPPING_ADDRESS_UPDATE',
  SHIPPING_ADDRESS_REJECT: 'SHIPPING_ADDRESS_REJECT'
};

export const UPDATE_FLAGS = {
  UPDATE_NAME: 'UPDATE_NAME',
  UPDATE_LIMIT: 'UPDATE_LIMIT',
  ADD_TAG: 'ADD_TAG',
  DELETE_TAG: 'DELETE_TAG'
};

export const CARD_TYPES = {
  PHYSICAL: 'CARD_TYPE_PHYSICAL',
  VIRTUAL: 'CARD_TYPE_VIRTUAL'
};

export const CARD_TYPES_IDS = {
  VIRTUAL: 0,
  PHYSICAL: 1
};

export const CARD_USAGE_OPTIONS = {
  SINGLE_USE: 'USAGE_SINGLE_USE',
  RECURRING: 'USAGE_RECURRING'
};

export const CARD_STATUSES = {
  ACTIVE: 'CARD_ACTIVE',
  SUSPENDED: 'CARD_SUSPENDED',
  LOST: 'CARD_LOST',
  STOLEN: 'CARD_STOLEN',
  EXPIRED: 'CARD_EXPIRED',
  CANCELED: 'CARD_CANCELED',
  PENDING: 'PENDING'
};

export const CARD_REQUEST_STATUS_ID = {
  INACTIVE: 0,
  ACTIVE: 1
};

export const MINIMAL_CARD_LIMIT = 1;

export const VGS_SHOW_ID = 'VGSShowScript';
