export const getItemStyles = value => {
  const CHARACTER_WIDTH = 0.86;
  const INITIAL_ITEM_WIDTH = 3.2;
  /** @MAX_NUMBER_LENGTH_WITHOUT_WIDTH_CHANGE - if value is greater than 99 - then item width will be recalculated, otherwise it will be equal to INITIAL_ITEM_WIDTH */
  const MAX_NUMBER_LENGTH_WITHOUT_WIDTH_CHANGE = 3;

  const numberLength = Number.isInteger(value) && value.toString().length;

  const itemWidth =
    numberLength > MAX_NUMBER_LENGTH_WITHOUT_WIDTH_CHANGE
      ? INITIAL_ITEM_WIDTH +
        (numberLength - MAX_NUMBER_LENGTH_WITHOUT_WIDTH_CHANGE) * CHARACTER_WIDTH
      : INITIAL_ITEM_WIDTH;

  return numberLength ? { width: `${itemWidth}rem` } : null;
};
