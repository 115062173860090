import PropTypes from 'prop-types';

import './index.scss';

const SpendingItem = ({ item: { label, value } }) => (
  <div className="spending-item">
    <span className="spending-item__key">{label}:</span>
    <span className="spending-item__value">{value}</span>
  </div>
);

const SpendingSection = ({ data }) => (
  <>
    {data && (
      <div className="spending-section">
        {data.map((item, idx) => (
          <SpendingItem key={idx} item={item} />
        ))}
      </div>
    )}
  </>
);

export default SpendingSection;

SpendingSection.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired
};
