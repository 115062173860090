import { transactionsConstants } from '../constants/transactions.constants';

const INITIAL_STATE = {
  states: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case transactionsConstants.SET_TRANSACTION_STATES:
      return { ...state, states: action.states };
    default:
      return state;
  }
}
