import getNumberWithCommas from './string-service';
import { CURRENCIES } from '../constants/currencies.constants';

export const formatNumberToCurrency = (number = 0, roundToInteger = false, currencyCode = '') => {
  let formattedNumber = '';

  if (roundToInteger) {
    // todo: change rules to round up or round down
    formattedNumber = Math.round(number);
  }
  if (number) {
    formattedNumber = getNumberWithCommas(formattedNumber || number);
  }
  if (currencyCode) {
    formattedNumber = `${currencyCode}${formattedNumber}`;
  }

  return formattedNumber || number;
};

export const getFormattedCurrencyLabel = (symbol, value) => {
  value = typeof value !== 'string' ? String(value) : value;
  const isNegativeValue = value.indexOf('-') === 0;
  isNegativeValue && (value = value.replace('-', ''));
  let label = symbol;
  if (symbol !== '$') label += ' ';
  label += value;

  return isNegativeValue ? `-${label}` : label;
};

export const getAmount = (currencySymbol, limit) =>
  getFormattedCurrencyLabel(currencySymbol, getNumberWithCommas(limit));

export const isUsdCurrency = (currency = '') => currency.toUpperCase() === CURRENCIES.USD;
