import * as _ from 'lodash';

import BANK_CODES from './data/bank-codes.json';

import { depositsConstants } from '../constants/deposits.constants';
import { walletConstants } from '../constants/wallet.constants';

import { getFlagImgByCurrencyCode } from '../services/currency.service';

const INITIAL_STATE = {
  beneficiaries: [],
  beneficiaryDetails: null,
  statuses: [],
  transactions: [],
  companyBalance: null,
  userBalance: null,
  currencies: [],
  features: [],
  banks: [],
  recipientCountries: [],
  recipientStates: [],
  recipientCities: [],
  isCityState: false,
  recurringEmails: [],
  bankCodes: BANK_CODES
};

const _prepareBalance = (rawBalance, creditBalanceField, walletBalanceField, depositField) => {
  const balance = {
    credit: {
      available: null,
      limit: null,
      spent: null
    },
    wallet: {
      available: null,
      limit: null,
      spent: null
    },
    deposit: null
  };
  if (rawBalance) {
    if (rawBalance[creditBalanceField]) {
      balance.credit.available = rawBalance[creditBalanceField].available
        ? rawBalance[creditBalanceField].available
        : null;
      balance.credit.limit =
        rawBalance[creditBalanceField].limit >= 0 ? rawBalance[creditBalanceField].limit : null;
      balance.credit.spent =
        rawBalance[creditBalanceField].spent >= 0 ? rawBalance[creditBalanceField].spent : null;
    }
    if (rawBalance[walletBalanceField]) {
      balance.wallet.available =
        rawBalance[walletBalanceField].available >= 0
          ? rawBalance[walletBalanceField].available
          : null;
      balance.wallet.limit =
        rawBalance[walletBalanceField].limit >= 0 ? rawBalance[walletBalanceField].limit : null;
      balance.wallet.spent =
        rawBalance[walletBalanceField].spent >= 0 ? rawBalance[walletBalanceField].spent : null;
    }
    if (depositField) {
      balance.deposit = rawBalance[depositField] >= 0 ? rawBalance[depositField] : null;
    }
  }
  return balance;
};

const _prepareCurrencies = rawCurrencies => {
  let currencies = [];

  if (rawCurrencies) {
    currencies = Object.entries(rawCurrencies).map(currency => ({
      flagImg: getFlagImgByCurrencyCode(currency[1]),
      code: currency[1],
      id: currency[0]
    }));
  }

  return currencies;
};

const _prepareWalletFeatures = rawFeatures => {
  let features = [];
  if (rawFeatures && rawFeatures.length) {
    features = rawFeatures.map(item => ({
      id: item.id,
      name: item.name
    }));
  }

  return features;
};

const _getIntegrationType = type => {
  const international = ['currency_cloud', 'swap', 'currencycloud'];
  const local = ['spei', 'stp'];

  if (international.indexOf(type) !== -1)
    return { key: 'type.international', label: 'International' };
  if (local.indexOf(type) !== -1) return { key: 'type.local', label: 'Local' };
};

const _getApprovmentStatus = isActive => {
  if (isActive === null) return 'pending';
  if (!isActive) return 'rejected';
  if (isActive) return 'approved';
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case walletConstants.SET_BENEFICIARIES:
      return {
        ...state,
        beneficiaries: Object.keys(action.beneficiaries).map(key => ({
          id: key,
          name: action.beneficiaries[key].name,
          type: _getIntegrationType(action.beneficiaries[key].integration_type.toLowerCase()),
          status: _getApprovmentStatus(action.beneficiaries[key].IsActive)
        }))
      };

    case walletConstants.SET_BENEFICIARY_DETAILS:
      return {
        ...state,
        beneficiaryDetails: action.details
      };

    case walletConstants.SET_BANKS:
      return {
        ...state,
        banks: action.banks
          .sort((a, b) => {
            const bankA = a.name.toLowerCase();
            const bankB = b.name.toLowerCase();
            if (bankA < bankB) return -1;
            if (bankA > bankB) return 1;
            return 0;
          })
          .map(bank => ({
            value: bank.key,
            label: bank.name
          }))
      };

    case walletConstants.SET_RECIPIENT_COUNTRIES:
      let recipientCountries = [];
      if (action.countriesInfo && Object.keys(action.countriesInfo).length) {
        recipientCountries = Object.keys(action.countriesInfo).map(key => ({
          id: key,
          name: action.countriesInfo[key]
        }));
      }
      return { ...state, recipientCountries };

    case walletConstants.SET_RECIPIENT_STATES:
      let recipientStates = [];
      if (action.statesInfo && Object.keys(action.statesInfo).length) {
        recipientStates = Object.keys(action.statesInfo).map(key => ({
          id: key,
          name: action.statesInfo[key]
        }));
      }
      return { ...state, recipientStates };

    case walletConstants.SET_RECIPIENT_CITIES:
      let recipientCities = [];
      if (action.citiesInfo && Object.keys(action.citiesInfo).length) {
        recipientCities = Object.keys(action.citiesInfo).map(key => ({
          value: key,
          label: action.citiesInfo[key]
        }));
      }
      return { ...state, recipientCities, isCityState: action.isCityState };

    case walletConstants.SET_WALLET_FEATURES:
      return { ...state, features: _prepareWalletFeatures(action.features) };

    case walletConstants.SET_BALANCE:
      const companyBalance = _prepareBalance(
        action.companyBalance,
        'company_credit_balance',
        'company_wallet_balance',
        'company_deposit'
      );
      const userBalance = _prepareBalance(
        action.userBalance,
        'user_credit_balance',
        'user_wallet_balance'
      );
      return { ...state, companyBalance, userBalance };

    case walletConstants.SET_WALLET_CURRENCIES:
      let currencies = _.cloneDeep(state.currencies);
      if (action.currencies) {
        currencies = _prepareCurrencies(action.currencies);
      }
      return { ...state, currencies };

    case depositsConstants.SET_DEPOSITS_TRANSACTIONS:
      return {
        ...state,
        transactions: []
      };

    case walletConstants.SET_RECURRING_EMAILS:
      return {
        ...state,
        recurringEmails: action.recurringEmails
      };

    case walletConstants.ADD_BENEFICIARY:
      return {
        ...state,
        beneficiaries: [action.beneficiary, ...state.beneficiaries]
      };

    default:
      return state;
  }
}
