import PropTypes from 'prop-types';

const CloseCircleIcon = ({ color, background, className, ...rest }) => (
  <svg
    className={className}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0002 20.1666C16.0628 20.1666 20.1668 16.0625 20.1668 10.9999C20.1668 5.93731 16.0628 1.83325 11.0002 1.83325C5.93755 1.83325 1.8335 5.93731 1.8335 10.9999C1.8335 16.0625 5.93755 20.1666 11.0002 20.1666Z"
      fill={background}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.75 8.25L8.25 13.75L13.75 8.25Z"
      fill={background}
    />
    <path
      d="M13.75 8.25L8.25 13.75"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 8.25L13.75 13.75L8.25 8.25Z"
      fill="#BCF4E3"
    />
    <path
      d="M8.25 8.25L13.75 13.75"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CloseCircleIcon;

CloseCircleIcon.propTypes = {
  color: PropTypes.string.isRequired,
  background: PropTypes.string,
  className: PropTypes.string
};

CloseCircleIcon.defaultProps = {
  background: 'transparent',
  className: null
};
