import { ReactComponent as File } from '../assets/images/file.svg';
import { ReactComponent as MultiFiles } from '../assets/images/multi-files.svg';

export const recipientInputTypes = {
  RECURRING: 'RECURRING',
  SEND_ONCE: 'SEND_ONCE'
};

const { RECURRING, SEND_ONCE } = recipientInputTypes;

export const RECURRING_LABEL = 'recipientInput.recurring.label';
export const SEND_ONCE_LABEL = 'recipientInput.sendOnce.label';

export const getSelectLabel = selectType =>
  selectType === RECURRING ? RECURRING_LABEL : SEND_ONCE_LABEL;

export const getEmailLabel = key =>
  ({
    RECURRING: [RECURRING_LABEL],
    SEND_ONCE: [SEND_ONCE_LABEL]
  }[key]);

export const DROPDOWN_DATA = [
  {
    label: SEND_ONCE_LABEL,
    description: 'recipientInput.sendOnce.description',
    type: SEND_ONCE,
    Icon: File
  },
  {
    label: RECURRING_LABEL,
    description: 'recipientInput.recurring.description',
    type: RECURRING,
    Icon: MultiFiles
  }
];
