import PropTypes from 'prop-types';

import { useStepColors } from './hooks';

import './index.scss';

const Step = ({ status, label }) => {
  const {
    stepColors: { circleStoke, circleFill, pathStoke }
  } = useStepColors(status);

  const StepIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11" fill={circleFill} stroke={circleStoke} strokeWidth="2" />
      <path d="M6 12L10 16L18 8" stroke={pathStoke} strokeWidth="2" />
    </svg>
  );

  const Label = () => (
    <div className="description-container">
      <span className="description-container__label">{label}</span>
    </div>
  );

  const StepWithLabel = () => (
    <div className="step-container">
      <StepIcon />
      <Label />
    </div>
  );

  return label ? <StepWithLabel /> : <StepIcon />;
};

export default Step;

Step.propTypes = {
  status: PropTypes.string.isRequired
};
