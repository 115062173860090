import React, { memo } from 'react';
import PropTypes from 'prop-types';

import variables from '../../../styles/_variables.scss';
import plusCircleLight from './../../../assets/images/plus-circle-light.svg';
import { NavLink } from 'react-router-dom';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  text: {
    fontFamily: variables.fontRegular,
    color: '#6B6E74'
  },
  title: {
    fontFamily: variables.fontBold,
    fontSize: 18,
    lineHeight: 1.3,
    paddingBottom: 12,
    color: '#6B6E74'
  },
  desc: {
    fontSize: 14,
    lineHeight: 1.5,
    paddingBottom: 18
  },
  submit: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none'
  },
  submitIcon: {
    width: 29,
    height: 29,
    marginRight: 11
  },
  submitLabel: {
    fontSize: 12,
    lineHeight: 2.92,
    textTransform: 'uppercase'
  }
};

const EmptySection = ({ title, description, submitLabel, submitLink }) => {
  return (
    <div style={styles.root}>
      <span style={{ ...styles.text, ...styles.title }}>{title}</span>
      <span style={{ ...styles.text, ...styles.desc }}>{description}</span>
      {submitLabel.length ? (
        <NavLink to={submitLink} style={styles.submit}>
          <img style={styles.submitIcon} src={plusCircleLight} alt="" />
          <span style={{ ...styles.text, ...styles.submitLabel }}>
            {submitLabel}
          </span>
        </NavLink>
      ) : null}
    </div>
  );
};

EmptySection.propsType = {
  title: PropTypes.string,
  description: PropTypes.string,
  submitLabel: PropTypes.string,
  submitLink: PropTypes.string
};

EmptySection.defaultProps = {
  title: '',
  description: '',
  submitLabel: '',
  submitLink: '/'
};

export default memo(EmptySection);
