import axios from 'axios';
import { API_URL } from '../constants/common.constants';
import { getDeviceId } from '../utils/auth-client';
import { getAuthorizationForRequest } from '../utils/network-util';
import { EMAIL_TO_DEVICE_ID_MAP_KEY } from '../constants/auth.constants';
import { handleRequestError } from './handlers/error-handlers';

export const authService = {
  login,
  confirmEmail,
  twoFactorLogin,
  resendTwoFactorCode,
  recoverPassword,
  resetPassword,
  completeRegistration,
  verifyRegistrationToken,
  updateDocuSignStatus,
  updateAgreement,
  cancelEmailActivation,
  resendEmailActivation
};

function login(email, password) {
  let deviceId = getDeviceId();

  if (!deviceId) {
    const rawDeviceIdMap = window.localStorage.getItem(EMAIL_TO_DEVICE_ID_MAP_KEY);

    if (rawDeviceIdMap) {
      const deviceIdMap = JSON.parse(rawDeviceIdMap);
      deviceId = deviceIdMap[email] || null;
    }
  }

  const data = {
    email: email,
    password: password,
    device_id: deviceId
  };

  return axios
    .post(`${API_URL}/api/users/login`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors'
      }
    })
    .then(response => response.data)
    .catch(handleRequestError);
}

function confirmEmail(confirmationToken) {
  const data = {
    confirmation_token: confirmationToken
  };

  return axios
    .post(`${API_URL}/api/users/confirm-email`, data)
    .then(response => response.data)
    .catch(handleRequestError);
}

function twoFactorLogin(data) {
  return axios
    .post(`${API_URL}/api/users/verify-code`, data)
    .then(response => response.data)
    .catch(handleRequestError);
}

function resendTwoFactorCode(tempoToken, deviceId) {
  return axios
    .post(`${API_URL}/api/users/resend-code?device_id=${deviceId}&code_id=${tempoToken}`)
    .then(response => response.data)
    .catch(handleRequestError);
}

function recoverPassword(email) {
  return axios
    .post(`${API_URL}/api/users/forget-password?device_id=null`, {
      email: email
    })
    .then(response => response.data)
    .catch(handleRequestError);
}

function resetPassword(requestId, password) {
  return axios
    .post(`${API_URL}/api/users/reset-password?device_id=null`, {
      request_id: requestId,
      new_password: password
    })
    .then(response => response.data)
    .catch(handleRequestError);
}

function completeRegistration(confirmationToken, email, password, mobileCode, mobileNumber) {
  return axios
    .post(`${API_URL}/api/users/complete-register?device_id=null`, {
      mobile_code: mobileCode,
      mobile_number: mobileNumber,
      password: password,
      confirmation_token: confirmationToken,
      email: email
    })
    .then(response => response.data)
    .catch(handleRequestError);
}

function verifyRegistrationToken(token) {
  return axios
    .get(`${API_URL}/api/users/verify-token`, {
      params: {
        confirmation_token: token
      }
    })
    .then(response => response.data)
    .catch(handleRequestError);
}

function updateDocuSignStatus(status, id) {
  const data = {
    pending_data: {
      id,
      event_type: status
    }
  };
  return axios
    .put(`${API_URL}/api/actions/update?device_id=${getDeviceId()}`, data, {
      headers: {
        Authorization: getAuthorizationForRequest()
      }
    })
    .then(response => response.data)
    .catch(handleRequestError);
}

function updateAgreement(isAccepted, id) {
  const data = {
    pending_data: {
      id,
      event_type: isAccepted ? 'accepted' : 'rejected'
    }
  };
  return axios
    .put(`${API_URL}/api/actions/update-agreement?device_id=${getDeviceId()}`, data, {
      headers: {
        Authorization: getAuthorizationForRequest()
      }
    })
    .then(response => response.data)
    .catch(handleRequestError);
}

function cancelEmailActivation(userId) {
  return axios
    .post(`${API_URL}/api/users/cancel-email-activation?user_id=${userId}`, null, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: getAuthorizationForRequest()
      }
    })
    .then(response => response.data)
    .catch(handleRequestError);
}

function resendEmailActivation(userId) {
  return axios
    .post(`${API_URL}/api/users/resend-email-activation?user_id=${userId}`, null, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: getAuthorizationForRequest()
      }
    })
    .then(response => response.data)
    .catch(handleRequestError);
}
