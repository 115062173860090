import {
  CARD_REQUEST_STATUS_ID,
  CARD_STATUSES,
  cardsConstants
} from '../constants/cards.constants';
import { transformCardStatusFromServer } from '../constants/map.constants';
import * as _ from 'lodash';
import { getCurrencyById } from '../services/currency.service';

const INITIAL_STATE = {
  cards: [],
  activeCards: [],
  inactiveCards: [],
  card: null,
  transactions: [],
  cardsTypes: [],
  pagination: {
    activeCurrentPage: 0,
    activeTotalPages: 0,
    inactiveCurrentPage: 0,
    inactiveTotalPages: 0
  }
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case cardsConstants.SET_CARDS:
      const { setNewCardsList, pagination, cardStatus } = action;
      const areActiveCardsData = cardStatus === CARD_REQUEST_STATUS_ID.ACTIVE;
      const cardsFromState = areActiveCardsData ? state.activeCards : state.inactiveCards;

      const cardsList = setNewCardsList
        ? _prepareCards(action.cards)
        : [...cardsFromState, ..._prepareCards(action.cards)];

      const setActiveCards = {
        activeCards: cardsList
      };

      const setInactiveCards = {
        inactiveCards: cardsList
      };

      const setCards = areActiveCardsData ? setActiveCards : setInactiveCards;

      return { ...state, ...setCards, pagination: { ...state.pagination, ...pagination } };

    case cardsConstants.SET_NEW_SHIPPING_ADDRESS:
      const updatedCard = {
        ...state.card,
        shippingAddress: action.shippingAddress
      };
      return { ...state, card: updatedCard };
    case cardsConstants.HIDE_SHIPPING_ADDRESS_UPDATE:
      return {
        ...state,
        card: {
          ...state.card,
          shippingAddressAvailableForUpdate: { isAvailable: false, availableDuration: 0 }
        }
      };
    case cardsConstants.SORT_CARDS_BY_KEY:
      return {
        ...state,
        activeCards: _.sortBy(state.activeCards, [
          card => {
            const value = card[action.key];
            return typeof value === 'string' ? value.toLowerCase() : value;
          }
        ]),
        inactiveCards: _.sortBy(state.inactiveCards, [
          card => {
            const value = card[action.key];
            return typeof value === 'string' ? value.toLowerCase() : value;
          }
        ])
      };
    case cardsConstants.SET_CARDS_TYPES:
      return { ...state, cardsTypes: action.cardsTypes };
    case cardsConstants.ADD_AND_SET_ACTIVE_CARD:
      return {
        ...state,
        activeCards: [...state.activeCards, _prepareCard(action.card)],
        card: action.card.statusId ? action.card : _prepareActiveCard(action.card)
      };
    case cardsConstants.SET_ACTIVE_CARD:
      return {
        ...state,
        card: action.card.statusId ? action.card : _prepareActiveCard(action.card)
      };
    case cardsConstants.SET_ACTIVE_CARD_TRANSACTIONS:
      return {
        ...state,
        transactions: action.transactions.map(data =>
          prepareCardTransactions(data, action.companyState)
        )
      };
    case cardsConstants.CLEAR_ACTIVE_CARD:
      return { ...state, card: null, transactions: [] };
    case cardsConstants.UPDATE_CARD_STATUS:
      let data = null;
      const isActivationStatus = action.statusId === CARD_STATUSES.ACTIVE;
      const isNotActivationStatus = action.statusId !== CARD_STATUSES.ACTIVE;
      const isLostOrStolenStatus = [CARD_STATUSES.LOST, CARD_STATUSES.STOLEN].includes(
        action.statusId
      );

      if (state.card) {
        let card = { ...state.card };
        card.statusId = action.statusId;
        card.isPending = isActivationStatus || isLostOrStolenStatus ? false : state.card.isPending;
        data = { card };
      } else {
        const activeCards = [...state.activeCards];
        const inactiveCards = [...state.inactiveCards];
        const cards = [...state.activeCards, ...state.inactiveCards];

        const activeCard = activeCards.find(c => c.id === action.id);
        const inactiveCard = inactiveCards.find(c => c.id === action.id);
        let card = activeCard || inactiveCard;

        const isExistingCardPending = card.isPending;
        const isPendingCardUpdatedFromList = card.isPending ? null : isNotActivationStatus;
        card.statusId = action.statusId;
        card.isUpdatedFromList =
          card.isUpdatedFromList !== null ? null : isPendingCardUpdatedFromList;
        card.isPending = isActivationStatus ? false : isExistingCardPending;

        if (isActivationStatus && isExistingCardPending) {
          const cardsWithoutElement = cards.filter(c => c.id !== action.id);
          data = !!activeCards
            ? { activeCards: [card, ...cardsWithoutElement] }
            : { inactiveCards: [card, ...cardsWithoutElement] };
        } else {
          data = { activeCards, inactiveCards };
        }
      }

      return { ...state, ...data };

    case cardsConstants.UPDATE_CARD_LIMIT:
      const stateActiveCards = [...state.activeCards];
      const stateInactiveCards = [...state.inactiveCards];
      let activeCard = stateActiveCards.find(c => c.id === action.card.id);
      let inactiveCard = stateInactiveCards.find(c => c.id === action.card.id);
      const card = activeCard || inactiveCard;
      card.limit = action.card.limit;
      card.available_limit = action.card.available_limit;
      return { ...state, ...{ stateActiveCards, stateInactiveCards } };

    case cardsConstants.RESET_CARD_LIMIT:
      // const stateCards = [...state.cards];
      const activeCards = [...state.activeCards];
      const inactiveCards = [...state.inactiveCards];
      let activeCardInfo = activeCards.find(c => c.id === action.cardId);
      let inactiveCardInfo = inactiveCards.find(c => c.id === action.cardId);
      const cardInfo = activeCardInfo || inactiveCardInfo;
      cardInfo.limit = action.prevLimit;
      return { ...state, activeCards, inactiveCards };

    case cardsConstants.UPDATE_CARD_PAN:
      let cardUpdate = { ...state.card };
      cardUpdate.panVisible = !cardUpdate.panVisible;
      cardUpdate.panInfo = action.pan;
      return { ...state, card: cardUpdate };

    case cardsConstants.UPDATE_CARD_VISIBILITY:
      let newCard = { ...state.card };
      newCard.panVisible = !newCard.panVisible;
      return { ...state, card: newCard };

    default:
      return state;
  }
}

function _prepareCards(rawCards) {
  return rawCards.map(card => _prepareCard(card));
}
function _prepareCard(card) {
  const statusId = transformCardStatusFromServer(card.status_id);
  const isPending = statusId === CARD_STATUSES.PENDING;

  return {
    address: card.address,
    shippingAddress: card?.shippingaddress,
    available_limit: card.available_limit,
    id: card.id,
    interval_id: card.interval_id,
    issuer_id: card.issuer_id,
    last4: card.last4,
    limit: card.limit,
    name: card.name,
    spent: card.spent,
    statusId,
    isPending,
    tags: card.tags,
    type_id: card.type_id,
    editable: false,
    userName: card.user_name,
    isUpdatedFromList: null,
    currency: card.currency,
    isMax: card.is_max,
    hasTooltip: card.tooltip && card.tooltip.has_tooltip,
    exchangeData: {
      rate: card.tooltip ? card.tooltip.exchange_rate : null,
      date: card.tooltip ? card.tooltip.updated_at : null
    }
  };
}

function _prepareActiveCard(rawCard) {
  const statusId = transformCardStatusFromServer(rawCard.status_id);
  const isPending = statusId === CARD_STATUSES.PENDING;

  return {
    address: rawCard.address,
    shippingAddress: rawCard?.shippingaddress,
    available_limit: rawCard.available_limit,
    default_available_limit: rawCard.default_available_limit,
    enablePay: rawCard.enable_pay,
    id: rawCard.id,
    interval_id: rawCard.interval_id,
    issuer_id: rawCard.issuer_id,
    last4: rawCard.last4,
    limit: rawCard.limit,
    name: rawCard.name,
    spent: rawCard.spent,
    statusId,
    tags: rawCard.tags,
    isPending,
    type_id: rawCard.type_id,
    panInfo: rawCard?.panInfo,
    panVisible: false,
    user: rawCard.user,
    currency: rawCard.currency,
    billingPeriods: rawCard.billingPeriods,
    isMax: rawCard.is_max,
    shippingAddressAvailableForUpdate: rawCard.shippingAddressAvailableForUpdate,
    card_token: rawCard.card_token
  };
}

function prepareCardTransactions(rawData, companyState = {}) {
  return {
    user: {
      name: `${rawData.first_name} ${rawData.last_name}`,
      department: rawData.department_name
    },
    card: {
      id: rawData.card_id,
      name: rawData.card_name,
      last4: rawData.last4_digits,
      tags: rawData.tags,
      currency: getCurrencyById(companyState, rawData.currency_id)
    },
    merchant: {
      name: rawData.cleaned_merchant_name,
      baseName: rawData.merchant_name,
      country: rawData.merchant_country,
      category: rawData.merchant_category,
      amount: rawData.merchant_amount,
      currency: rawData.merchant_currency
    },
    transaction: {
      id: rawData.id,
      state: rawData.transaction_state,
      type: rawData.type,
      amount: rawData.amount,
      currency: companyState.defaultCurrency,
      cardCurrencyAmount: rawData.card_currency_amount || null,
      cardCurrency: getCurrencyById(companyState, rawData.currency_id),
      createdAt: rawData.created_at,
      description: rawData.description,
      cardAmount: rawData.card_amount || ''
    }
  };
}
