import { useMemo } from 'react';

import { TOOLTIP_MARKER_TYPE } from './constants';

import { getAmount } from '../../../shared/progress-tooltip/helper';

export const useTooltipContent = ({
  t,
  walletSpent,
  creditSpent,
  currencySymbol,
  remainingBalance
}) => {
  const TOOLTIP_DATA = useMemo(() => {
    const { GREEN, BLUE, ORANGE } = TOOLTIP_MARKER_TYPE;

    const tooltipData = [
      {
        markerClass: GREEN,
        label: t('spentResourcesTooltip.labels.wirePayments'),
        amount: getAmount(currencySymbol, walletSpent)
      },
      {
        markerClass: BLUE,
        label: t('spentResourcesTooltip.labels.cardPayments'),
        amount: getAmount(currencySymbol, creditSpent)
      },
      {
        markerClass: ORANGE,
        label: t('spentResourcesTooltip.labels.remainingBalance'),
        amount: getAmount(currencySymbol, remainingBalance)
      }
    ];

    return remainingBalance
      ? tooltipData
      : tooltipData.filter(({ markerClass }) => markerClass !== 'circle-orange');
  }, [walletSpent, creditSpent, remainingBalance]);

  return { TOOLTIP_DATA };
};
