import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';

import { BoldListItem, Paragraph } from './internal';
import { WalletAgreementBodyText, WalletHeaderText } from './wallet';
import AuthCheckbox from '../../authorization/checkbox';
import { ScrollableArea } from '../../scrollable-area';
import Loader from '../loader';
import Divider from '../divider';

import { useAcceptSection } from './hooks';
import { usePreventTab, useTargetDevice } from '../../../hooks';

import './index.scss';
import {PSClickWrap} from "@pactsafe/pactsafe-react-sdk";
import {authActions} from "../../../actions/auth.action";
import {useState} from "react";

const ACCEPT_BUTTON_ID = 'agreement-update-form-accept';
const DECLINE_BUTTON_ID = 'agreement-update-form-decline';

const walletAgreementDocLink =
  'https://drive.google.com/file/d/1NBfhWAMwr3tBNrE0e7YIyyDDe3pKsHDG/view';
const betaUserAgreementDocLink =
  'https://drive.google.com/file/d/1LeL1hKIXw7oLzS_TlXNMmR2hxFyU_mC7/view'; // njsscan-ignore: node_username

const TermsAndConditionsUpdate = () => (
  <div className="terms-and-conditions">
    <Paragraph>
      <BoldListItem>
        <u>IDENTIFICATION AS A CUSTOMER</u>: &nbsp;
      </BoldListItem>
      Once Beta Tester is provided access to the Platform, Company may publicly reference Beta
      Tester as a customer on its websites or in communications. Company will not express any false
      endorsement or partnerships. Beta Tester grants Company a limited license to use Customer
      trademarks or service marks for this purpose.
    </Paragraph>
  </div>
);

const CommonHeaderText = ({ t }) => (
  <span className="agreement-form__header">
    <Trans t={t} i18nKey="walletAgreementForm.oldUserTitle">
      We have updated <br /> our Terms & Conditions
    </Trans>
  </span>
);

const AgreementFormHeader = ({ isWallet, t, isClickwrap }) => {
  if (isClickwrap) {
    return (
      <span className="agreement-form__header">Tribal Beta Tester Agreement</span>
    );
  }
  return !isWallet ? <CommonHeaderText t={t} /> : <WalletHeaderText t={t} />;
};

const AgreementCheckboxSection = ({
  onAgreementCheckboxCheck,
  isAgreementCheckboxChecked,
  t,
  docLink
}) => {
  const AgreementCheckboxLink = (
    <span className="agreement-checkbox-section__link-wrapper">
      <Trans t={t} i18nKey={'walletAgreementForm.clickToViewAgreement'}>
        Click
        <a className="agreement-checkbox-section__link" href={docLink} target="_blank">
          here
        </a>
        to view the full agreement
      </Trans>
    </span>
  );

  return (
    <div className="agreement-checkbox-section">
      <AuthCheckbox
        isChecked={isAgreementCheckboxChecked}
        onCheck={onAgreementCheckboxCheck}
        label={AgreementCheckboxLink}
        disabled
      />
    </div>
  );
};

const AgreementFormBody = ({
  isButtonsDisabled,
  onAgreementCheckboxCheck,
  isAgreementCheckboxChecked,
  isWallet
}) => {
  const { isTargetDevice } = useTargetDevice();

  return (
    <div
      className={classNames('agreement-form__body', {
        'common-scroll': !isTargetDevice
      })}
    >
      <ScrollableArea styles={{ width: '100%' }}>
        {isButtonsDisabled && (
          <div
            className={classNames('agreement-loader', {
              centered: !isWallet
            })}
          >
            <Loader />
          </div>
        )}
        <div
          className={classNames('content', {
            disabled: isButtonsDisabled
          })}
        >
          {!isWallet ? <TermsAndConditionsUpdate /> : <WalletAgreementBodyText />}
        </div>
      </ScrollableArea>
    </div>
  );
};

const AgreementFormFooter = ({
  isButtonsDisabled,
  onDecline,
  onAccept,
  isAgreementCheckboxChecked,
  isWallet,
  t,
  hasAgreed,
  isClickWrap
}) => (
  <div className="agreement-form__footer">
    <button
      id={DECLINE_BUTTON_ID}
      tabIndex={0}
      className="secondary"
      disabled={isButtonsDisabled}
      onClick={onDecline}
    >
      {isClickWrap ? t('walletAgreementForm.close') : t('walletAgreementForm.decline')}
    </button>
    <button
      id={ACCEPT_BUTTON_ID}
      tabIndex={0}
      className="primary"
      disabled={isClickWrap ? !hasAgreed : !isWallet ? isButtonsDisabled : isButtonsDisabled || !isAgreementCheckboxChecked}
      onClick={onAccept}
    >
      {isClickWrap ? t('walletAgreementForm.submit') : t('walletAgreementForm.accept')}
    </button>
  </div>
);

const AgreementForm = ({ isButtonsDisabled, onAccept, onDecline, isWallet, isClickWrap, user }) => {
  usePreventTab([DECLINE_BUTTON_ID, ACCEPT_BUTTON_ID]);
  const { isAgreementCheckboxChecked, onAgreementCheckboxCheck } = useAcceptSection();
  const { t, i18n } = useTranslation('translation');

  const [hasAgreed, setHasAgreed] = useState(false);

  if (isClickWrap) {
    const siteAccessId = process.env.REACT_APP_CLICKWRAP_ACCESS_ID; // A PactSafe Site Access ID
    const testMode = process.env.REACT_APP_CLICKWRAP_TEST_MODE === 'true';
    const groupKey = user.pending_actions[0].data.key; //"group-br-access"; // A PactSafe Group Key.
    // const groupKey = "group-br-access";

    const fullLegalName = `${user.first_name} ${user.last_name}`.trim();
    const { address, company_legal_name: companyLegalName, company_id: companyId } = user.company;
    const { line1, line2, city, state, country, postal_code: postalCode } = address;
    const primaryAddress = `${line1}, ${line2}, ${city}, ${state}, ${country} ${postalCode}`.replaceAll('null, ', '');
    const customerId = companyId;

    const renderData = {
      date: moment().locale(i18n.language).format('MMMM Do, YYYY'),
      full_legal_name: fullLegalName,
      title: user.title,
      entity_full_legal_name: companyLegalName,
      primary_physical_business_address: primaryAddress
    };

    // let disableSending = true;

    return (
      <div
        className={classNames('agreement-form')}
        style={{minWidth: '60rem', maxWidth: '64rem'}}
      >
        <AgreementFormHeader isWallet={isWallet} t={t} isClickwrap={true} />
        <Divider />

        <PSClickWrap
          dynamic
          accessId={siteAccessId}
          groupKey={groupKey}
          signerId={user.email}
          testMode={testMode}
          renderData={{ ...renderData, customerId }}
          displayImmediately={false}
          // disableSending={disableSending}
          allowDisagreed={true}
          onValid={() => setHasAgreed(true)}
          onInvalid={() => setHasAgreed(false)}
        />

        <Divider />

        <AgreementFormFooter
          onDecline={onDecline}
          onAccept={onAccept}
          isAgreementCheckboxChecked={isAgreementCheckboxChecked}
          isWallet={isWallet}
          t={t}
          isClickWrap={true}
          hasAgreed={hasAgreed}
        />
      </div>
    );
  } else {
    return (
      <div
        className={classNames('agreement-form', {
          small: !isWallet
        })}
      >
        <AgreementFormHeader isWallet={isWallet} t={t} />
        <Divider />
        <AgreementFormBody
          isButtonsDisabled={isButtonsDisabled}
          onAgreementCheckboxCheck={onAgreementCheckboxCheck}
          isAgreementCheckboxChecked={isAgreementCheckboxChecked}
          isWallet={isWallet}
        />

        <AgreementCheckboxSection
          onAgreementCheckboxCheck={onAgreementCheckboxCheck}
          isAgreementCheckboxChecked={isAgreementCheckboxChecked}
          t={t}
          docLink={isWallet ? walletAgreementDocLink : betaUserAgreementDocLink}
        />

        <Divider />

        <AgreementFormFooter
          isButtonsDisabled={isButtonsDisabled}
          onDecline={onDecline}
          onAccept={onAccept}
          isAgreementCheckboxChecked={isAgreementCheckboxChecked}
          isWallet={isWallet}
          t={t}
        />
      </div>
    );
  }
};

AgreementForm.propsType = {
  isButtonsDisabled: PropTypes.bool,
  onAccept: PropTypes.func,
  onDecline: PropTypes.func,
  isWallet: PropTypes.bool
};
AgreementForm.defaultProps = {
  isButtonsDisabled: false,
  onAccept: function () {},
  onDecline: function () {},
  isWallet: false
};

export default AgreementForm;
