export const getValidString = value => {
  const invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g;
  return value.replace(invalid, '');
};

export const isKeyExistsInItem = ({ enteredText, item: [label, description] }) => {
  enteredText = getValidString(enteredText);
  label = getValidString(label);
  description = getValidString(description);

  const regex = new RegExp(enteredText, 'i');

  return regex.test(label) || regex.test(description);
};

const MODAL_DATA_MAP = {
  'UNITED ARAB EMIRATES': import('./modal-data/uae-data.json')
};

export const getSearchModalData = ({ bankCountry }) => MODAL_DATA_MAP[bankCountry];
