export function prepareSelectedUser(rawUser, physCard) {
  return !rawUser
    ? null
    : physCard !== undefined && physCard !== null
    ? { ...rawUser, ...{ enablePhysicalCard: physCard } }
    : {
        id: rawUser.user_id,
        department: {
          id: rawUser.department_id,
          label: rawUser.department
        },
        firstName: rawUser.first_name,
        lastName: rawUser.last_name,
        mobile: {
          code: rawUser.mobile_code,
          number: rawUser.mobile_number
        },
        pendingData: _getSelectedUserPendingData(rawUser.pending_contacts),
        type: rawUser.user_type_id,
        title: rawUser.title,
        limit: rawUser.limit,
        spent: rawUser.spent,
        availableLimit: rawUser.avaliable_limit,
        email: rawUser.email,
        status: rawUser.status_id,
        enableWallets: rawUser.enable_wallets,
        enablePhysicalCard: rawUser.enablePhysicalCard,
        transactionLimit: rawUser.TransactionLimit,
        restrictedMerchants: rawUser?.BlockedMerchants
      };
}

export function getSelectedUserAfterPendingEmailReset(oldSelectedUser) {
  return {
    ...oldSelectedUser,
    pendingData: { ...oldSelectedUser.pendingData, email: null }
  };
}

function _getSelectedUserPendingData(rawPendingData) {
  const pendingData = {
    email: null,
    mobile: null
  };

  if (rawPendingData) {
    if (rawPendingData.pending_email) {
      pendingData.email = rawPendingData.pending_email.email;
    }

    if (rawPendingData.pending_phone_number) {
      pendingData.mobile = {
        code: rawPendingData.pending_phone_number.code,
        codeId: rawPendingData.pending_phone_number.code_id,
        number: rawPendingData.pending_phone_number.number
      };
    }
  }

  return pendingData;
}
