import React, { useRef, useEffect } from 'react';

import './index.scss';

const SelectionDropdown = props => {
  const dropdownRef = useRef(null);
  useEffect(() => {
    dropdownRef.current.focus();
  });

  return (
    <div className="group-boxes__selection-dropdown__wrapper">
      <div
        ref={dropdownRef}
        className="dropdown"
        tabIndex={0}
        onBlur={() => props.onCancelSelection()}
      >
        {props.options.map(option => (
          <span
            key={option.id}
            onClick={() => props.onOptionSelection(option.id)}
          >
            {option.label}
          </span>
        ))}
      </div>
    </div>
  );
};

export default SelectionDropdown;
