import { memo } from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const BaseLoader = ({ style }) => (
  <div className="lds-css" style={style}>
    <div style={{ width: '100%', height: '100%' }} className="lds-eclipse">
      <div />
    </div>
  </div>
);

const Loader = ({ centered, small, style }) => {
  const isBaseLoaderVisible = !centered && !small;

  return (
    <>
      {centered && (
        <div className="centered-loader">
          <BaseLoader style={style} />
        </div>
      )}
      {small && <div className="loader-small" style={style} />}
      {isBaseLoaderVisible && <BaseLoader style={style} />}
    </>
  );
};

export default memo(Loader);

Loader.propTypes = {
  centered: PropTypes.bool,
  small: PropTypes.bool
};

Loader.defaultProps = {
  centered: false,
  small: false
};
