import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ErrorImage from './../../../assets/images/validation-error.svg';

import './index.scss';

const ErrorBox = ({ text, right, bottom, ...rest }) => {
  const { t } = useTranslation(['translation', 'sign-in']);
  return (
    <div
      className={`error-box ${right ? 'right-box' : ''} ${bottom ? 'bottom-box' : ''}`}
      {...rest}
    >
      <img alt="" src={ErrorImage} />
      <span>{t(text)}</span>
    </div>
  );
};

ErrorBox.propsType = {
  text: PropTypes.string,
  right: PropTypes.bool,
  bottom: PropTypes.bool
};

ErrorBox.defaultProps = {
  text: '',
  right: false,
  bottom: false
};

export default ErrorBox;
