import moment from 'moment';
import { formatNumberToCurrency, getFormattedCurrencyLabel } from '../../utils/currency-util';

const _formatStatementValueToCurrency = value => formatNumberToCurrency(value);

const _prepareStatementCredits = (rawCredits, creditsTypes) => {
  const credits = [];

  if (rawCredits && Array.isArray(rawCredits) && rawCredits.length) {
    rawCredits.forEach((item, index) => {
      if (item.id) {
        const creditType = creditsTypes.find(el => el.id === item.id);
        if (creditType) {
          credits.push({
            label: creditType.label,
            value: _formatStatementValueToCurrency(item.amount.toFixed(2) || 0),
            tooltip: creditType.tooltip
          });
        }
      }
    });
  }

  return credits;
};

const _prepareStatementBalance = rawStatement => {
  const balance = rawStatement.balance.toFixed(2);
  return balance ? _formatStatementValueToCurrency(balance) : '0.00';
};

const _prepareStatementBillingPeriod = rawStatement => {
  let billingPeriod = null;

  if (rawStatement.period) {
    const startDate = moment(rawStatement.period.start_date).format('MMMM D');
    const endDate = moment(rawStatement.period.end_date).format('MMMM D');
    billingPeriod = `${startDate} - ${endDate}`;
  }

  return billingPeriod;
};

const _prepareStatementInCredit = rawStatement =>
  rawStatement.details ? rawStatement.details.total_incredits_amount || 0 : 0;

const _prepareStatementDetails = (
  rawStatement,
  creditsTypes,
  isCurrentStatement,
  rawDetails,
  totalDetailsAmount
) => {
  let details = {
    spend: 0,
    baseSpend: isCurrentStatement ? rawStatement.details.spend : rawDetails.spend,
    credits: [],
    total: 0
  };

  if (rawStatement && rawStatement.details && typeof rawStatement.details === 'object') {
    if (isCurrentStatement) {
      details.credits = _prepareStatementCredits(rawStatement.details.credits, creditsTypes);
      details.spend = _formatStatementValueToCurrency(rawStatement.details.spend.toFixed(2));
      details.total = rawStatement.total_details_amount
        ? _formatStatementValueToCurrency(rawStatement.total_details_amount.toFixed(2))
        : '0.00';
    }
  }
  if (!isCurrentStatement && rawStatement && rawDetails) {
    details.credits = _prepareStatementCredits(rawDetails.credits, creditsTypes);
    details.spend = _formatStatementValueToCurrency(rawDetails.spend.toFixed(2));
    details.total = totalDetailsAmount
      ? _formatStatementValueToCurrency(totalDetailsAmount.toFixed(2))
      : '0.00';
    details['spendByCurrency'] = [];
  }

  return details;
};

export const prepareCreditsTypes = rawCreditsTypes => {
  let creditsTypes = [];

  if (rawCreditsTypes && Array.isArray(rawCreditsTypes) && rawCreditsTypes.length) {
    creditsTypes = rawCreditsTypes.map(type => ({
      id: type.id,
      label: type.name,
      key: type.type,
      tooltip:
        type.tooltip && type.tooltip.has_tooltip && type.tooltip.text ? type.tooltip.text : null
    }));
  }

  return creditsTypes;
};

export const prepareStatementStatuses = rawStatuses => {
  let statuses = [];

  if (rawStatuses && Array.isArray(rawStatuses) && rawStatuses.length) {
    statuses = rawStatuses.map(status => ({
      id: status.id,
      label: status.name,
      key: status.type
    }));
  }

  return statuses;
};

export const attachmentsTypes = types => {
  let attachmentsTypes = [];

  if (types && Array.isArray(types) && types.length) {
    attachmentsTypes = types.map(type => ({
      id: type.id,
      label: type.name,
      key: type.type
    }));
  }

  return attachmentsTypes;
};

export const prepareCurrentStatement = (rawStatement, creditsTypes) => {
  let statement = null;

  if (rawStatement) {
    const inCredit = _prepareStatementInCredit(rawStatement);
    statement = {
      currency: rawStatement.currency,
      balance: _prepareStatementBalance(rawStatement),
      billingPeriod: _prepareStatementBillingPeriod(rawStatement),
      billingMonth: moment(rawStatement.period.start_date).format('MMMM yyyy'),
      dueBy: moment(rawStatement.due_by).format('MMMM Do') || null,
      dueByDate: moment(rawStatement.due_by) || null,
      inCredit,
      isIncredit: rawStatement.total_details_amount >= inCredit,
      details: _prepareStatementDetails(rawStatement, creditsTypes, true)
    };
  }

  return statement;
};

export const prepareStatement = (
  rawStatement,
  creditsTypes,
  statuses,
  currencies,
  statementCurrency,
  defaultCurrency
) => {
  let statement = null;

  if (rawStatement) {
    const statementCurrencySymbol = statementCurrency.symbol;
    statement = {
      id: rawStatement.id,
      amount: getFormattedCurrencyLabel(
        statementCurrencySymbol,
        _formatStatementValueToCurrency(rawStatement.amount.toFixed(2))
      ),
      remaining: getFormattedCurrencyLabel(
        statementCurrencySymbol,
        _formatStatementValueToCurrency(rawStatement.remaining.toFixed(2))
      ),
      isPaid: rawStatement.isPaid,
      status: _getStatus(rawStatement.invoice_status_id, statuses),
      paidOn: rawStatement.paid_on.length
        ? moment(rawStatement.paid_on).format('MMMM Do, YYYY')
        : '',
      shortPaidOn: rawStatement.paid_on.length
        ? moment(rawStatement.paid_on).format('MMMM Do')
        : '',
      paidOnDate: rawStatement.paid_on.length ? moment(rawStatement.paid_on) : '',
      period: moment(rawStatement.period.start_date).format('MMMM YYYY') || null,
      statementDetails: {
        baseSpend: rawStatement.details.spend,
        spend: _formatStatementValueToCurrency(rawStatement.details.spend.toFixed(2)) || 0,
        credits: _prepareStatementCredits(rawStatement.details.credits, creditsTypes) || [],
        total: rawStatement.amount
          ? _formatStatementValueToCurrency(rawStatement.amount.toFixed(2))
          : '0.00',
        spendByCurrency: rawStatement.currencies_spent.map(currencySpent => {
          const currency = currencies.length
            ? currencies.find(item => item.id === currencySpent.currency_id) || defaultCurrency
            : defaultCurrency;
          return {
            code: currency.code,
            symbol: currency.symbol,
            value: _formatStatementValueToCurrency(currencySpent.spend.toFixed(2))
          };
        }),
        currencySymbol: statementCurrencySymbol
      }
    };
  }

  return statement;
};

const _getStatus = (invoiceStatusId, statuses) => {
  return statuses.find(status => status.id === invoiceStatusId) || null;
};
