export const BASE_CLASSES = {
  PAN: 'card-pan__number',
  CVV: 'card-cvv__number',
  DATE: 'card-date__number'
};

export const SHOW_JS_ENTITY_IDS = {
  COPY_BUTTON_NUMBER: 'copy-button_number',
  COPY_BUTTON_CVV: 'copy-button_cvv',
  COPY_BUTTON_DATE: 'copy-button_date'
};
