const _ALIGNMENTS = {
  DEFAULT: 'default',
  LEFT: 'left',
  RIGHT: 'right',
  NORMAL: ''
};

const MARKER_WIDTH = 3;

const _MAX_PERCENT = 100;
export const MIN_PERCENT = 0;

export const DEFAULT_POSITION = {
  alignment: _ALIGNMENTS.DEFAULT,
  margin: 0
};

export const getValidatedPercent = percent =>
  percent >= MIN_PERCENT && percent <= _MAX_PERCENT ? percent : MIN_PERCENT;

export const getElementsSizes = (imageWidth, containerWidth, percent) => {
  if (imageWidth && containerWidth) {
    const filledWidth = (containerWidth / 100) * percent;

    return {
      halfImageWidth: imageWidth / 2,
      filledWidth,
      unfilledWidth: containerWidth - filledWidth
    };
  }
};

export const getPosition = ({ filledWidth, halfImageWidth }) => {
  const margin = filledWidth - halfImageWidth + MARKER_WIDTH / 2;

  return {
    alignment: _ALIGNMENTS.NORMAL,
    margin
  };
};
