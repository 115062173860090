import React from 'react';
import googlePayIcon from '../../../assets/images/google-pay/google-pay.png';
import googlePayIcon2x from '../../../assets/images/google-pay/google-pay@2x.png';
import googlePayIcon3x from '../../../assets/images/google-pay/google-pay@3x.png';

const GooglePayIcon = ({ className }) => (
  <img
    className={className}
    src={googlePayIcon}
    srcSet={`
      ${googlePayIcon2x} 2x,
      ${googlePayIcon3x} 3x
    `}
    alt=""
  />
);

export default GooglePayIcon;
