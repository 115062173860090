import { Component } from 'react';
import { withTranslation } from 'react-i18next';

import LimitInfo from '../../dashboard/limit-info';

import { getNumberWithoutCommas } from '../../../utils/string-service';

import './index.scss';

class LimitEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditable: false,
      data: null,
      baseLimit: 0,
      disabled: false,
      isEmpty: false
    };

    this.handleLimitChange = this.handleLimitChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      data: { ...this.props.data },
      baseLimit: this.props.data.limit,
      disabled: this.props.disabled,
      isEmpty: this.props.isEmpty,
      updateLimitBlocked: this.props.updateLimitBlocked
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.data.id === this.props.data.id) {
      if (this.props.disabled !== this.state.disabled) {
        this.setState({
          disabled: this.props.disabled
        });
      }
      if (this.props.isEmpty !== this.state.isEmpty) {
        this.setState({
          isEmpty: this.props.isEmpty
        });
      }
      if (this.props.updateLimitBlocked !== this.state.updateLimitBlocked) {
        this.setState({
          updateLimitBlocked: this.props.updateLimitBlocked
        });
      }
      if (this.props.data.limit !== this.state.data.limit) {
        const hasError = !this.state.isEditable && this.props.isError;
        const isBasedLimitChanged =
          !this.state.isEditable && this.props.data.limit !== this.state.baseLimit;
        if (hasError || isBasedLimitChanged) {
          this.setState({
            baseLimit: this.props.data.limit,
            data: { ...this.state.data, ...{ limit: this.props.data.limit } }
          });
        }
      }
    }
  }

  handleLimitChange = limit => {
    let data = this.state.data;
    data.limit = getNumberWithoutCommas(limit);

    this.setState({
      data
    });
  };

  getLimitLabel = (typeId, updateLimitBlocked, isMax = false) => {
    let label = this.props.t('limitEdit.labels.limit');
    if (updateLimitBlocked) {
      label = this.props.t('limitEdit.labels.companyLimit');
    } else if (typeId) {
      label = this.props.t('limitEdit.labels.monthlyLimit');
    } else if (isMax) {
      label = this.props.t('limitEdit.labels.maximumLimit');
    }
    return label;
  };

  render() {
    const isEditPen = !this.state.updateLimitBlocked && !this.state.isEmpty;

    return (
      <div
        className={`limit-edit ${this.state.isEditable ? 'active' : ''}`}
        onClick={e => {
          e.preventDefault();
          if (!this.state.disabled) {
            this.setState({
              isEditable: true
            });
          }
        }}
      >
        {this.state.data ? (
          <LimitInfo
            currencySymbol={this.props.currencySymbol}
            limit={this.state.data.limit}
            totalLimit={this.state.data.limit}
            title={this.getLimitLabel(
              this.state.data.interval_id,
              this.state.updateLimitBlocked,
              this.state.data.isMax
            )}
            exchangeData={this.props.exchangeData}
            large
            isEmpty={this.state.isEmpty}
            editable={this.state.isEditable}
            handleLimitChange={this.handleLimitChange}
            handleLimitUpdate={limit => {
              this.props.onEdit(limit, this.state.baseLimit);
              this.setState({ isEditable: false, baseLimit: limit });
            }}
            isEditPen={isEditPen}
          />
        ) : null}
      </div>
    );
  }
}

export default withTranslation(['translation'])(LimitEdit);
