import React from 'react';

import SimpleBox from './simple-box';

import './index.scss';

function BoxGroup(props) {
  return (
    <div className="box-group">
      {props.elements.map(el => (
        <SimpleBox
          readOnly={props.isReadOnly}
          key={el.id}
          value={el.label}
          onBoxClick={() => props.onElementClick(el.id)}
        />
      ))}
    </div>
  );
}

export default BoxGroup;
