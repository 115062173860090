import { useRef, memo, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Loader, Divider } from '../';
import { ScrollableArea } from '../../scrollable-area';
import { CellText, LoadMore } from './internal';

import { useHasScrollbar } from '../../../hooks';

import { getValidWidth } from './utils';
import { prefix } from '../../../utils/classnames-utils';

import { tableConstants } from '../../../constants/table.constants';

import './index.scss';

const {
  type: { WALLET }
} = tableConstants;

const renderLoader = ({ isOrderedPagination, medium } = {}) => {
  const baseClass = 'table-pagination';
  const addPrefix = prefix(baseClass);

  return (
    <div
      className={classNames({
        [addPrefix('__ordered-pagination-loader')]: isOrderedPagination,
        [addPrefix('__infinite-pagination-loader')]: !isOrderedPagination,
        'medium-loader': medium
      })}
    >
      <Loader />
    </div>
  );
};

const Table = ({
  data,
  columns,
  styles,
  styleDetector,
  cellValueMiddleware,
  onCellClick,
  lastRowRef,
  areAdditionalItemsLoading,
  expandableKey,
  type,
  updateTableData,
  DefaultComponent,
  tableStyles,
  getItems,
  isNewPageDataLoading,
  id
}) => {
  let tableRowRef = useRef();
  const tableBodyRef = useRef();
  const isWalletType = type === WALLET;

  const { scrollbarWidth, hasScrollbar } = useHasScrollbar(tableRowRef, tableBodyRef);

  const getCellValue = useCallback(
    ({ key, value }) => (cellValueMiddleware ? cellValueMiddleware(key, value) : value),
    []
  );

  /** @idx - is using for pagination */
  const WalletExpandRow = ({
    idx,
    item,
    expandable,
    handleExpandabilityToggle,
    bulkItem,
    isExpanded,
    withoutDivider
  }) => (
    <div
      className={classNames('table-body__row', {
        'no-divider': withoutDivider
      })}
      key={idx}
      onClick={handleExpandabilityToggle ? handleExpandabilityToggle : null}
      ref={
        lastRowRef && expandable && data.length === idx + 1
          ? node => {
              tableRowRef = node;
              lastRowRef({ node });
            }
          : tableRowRef
      }
    >
      {Object.entries(item).map(([key, value], idx) => (
        <div
          key={idx}
          className={classNames('table-body__item', columns[idx].className)}
          style={getValidWidth(columns[idx].width, columns)}
          onClick={!expandable ? onCellClick.bind(null, { key, item }) : null}
        >
          {([5, 6].includes(idx) && expandable) || ([0, 2, 4].includes(idx) && bulkItem) ? null : (
            <CellText
              className={classNames(
                styleDetector && styleDetector([key, value], item?.status?.key)
              )}
              withExpandIcon={!idx && expandable}
              expandable={expandable}
              isExpanded={isExpanded}
              value={getCellValue({ key, value })}
              additionalInfo={idx === 3 ? item.numberOfBulkPayments : null}
            />
          )}
        </div>
      ))}
    </div>
  );

  const ExpandableRow = ({ idx, item }) => {
    const isDownloadMoreButtonAvailable =
      item.isExpanded && item?.pagination?.totalPages >= item?.pagination?.currentPage;

    return isWalletType ? (
      <>
        <WalletExpandRow
          idx={idx}
          item={item}
          handleExpandabilityToggle={() => updateTableData({ idx, bulkId: item.bulkId })}
          expandable
          isExpanded={item.isExpanded}
          withoutDivider={item.isExpanded}
        />
        {item.isFirstBulkPageLoading ? renderLoader({ medium: true }) : null}
        {item.isExpanded
          ? item[expandableKey].map((i, index) => (
              <WalletExpandRow
                key={index}
                idx={idx}
                item={i}
                bulkItem
                withoutDivider={
                  item[expandableKey]?.length !== index + 1 || isDownloadMoreButtonAvailable
                }
              />
            ))
          : null}
        {isDownloadMoreButtonAvailable ? (
          <>
            <LoadMore item={item} getItems={getItems} />
            <Divider style={{ margin: '1.6rem 0 0 0' }} />
          </>
        ) : null}
      </>
    ) : null;
  };

  const Row = memo(({ idx, item, expandable }) => (
    <div
      className="table-body__row"
      key={idx}
      ref={
        lastRowRef && data.length === idx + 1
          ? node => {
              tableRowRef = node;
              lastRowRef({ node });
            }
          : tableRowRef
      }
    >
      {Object.entries(item).map(([key, value], idx) => {
        const cellError = item?.cellsErrors?.find(e => e.index === idx);
        const hasErrors = !!item?.cellsErrors?.length;

        return (
          <div
            key={idx}
            className={classNames('table-body__item', columns[idx].className)}
            style={getValidWidth(columns[idx].width, columns)}
            onClick={onCellClick.bind(null, { key, item })}
          >
            <CellText
              withExpandIcon={!idx && isWalletType}
              expandable={expandable}
              className={classNames(
                styleDetector && styleDetector([key, value], item?.status?.key)
              )}
              value={getCellValue({ key, value })}
              withError={hasErrors && cellError}
              index={idx}
              isRowHasErrors={hasErrors}
              setDefaultValue={item?.cellsErrors}
            />
          </div>
        );
      })}
    </div>
  ));

  return (
    <div
      id={id}
      className={classNames('table', { loading: isNewPageDataLoading })}
      style={tableStyles}
    >
      <div className="table-header" style={{ width: `calc(100% - ${scrollbarWidth}px)` }}>
        {columns.map(({ header, width, className }, idx) => {
          const validWidth = getValidWidth(width, columns, idx);
          const styles = isWalletType
            ? idx
              ? validWidth
              : { ...validWidth, textIndent: 24 }
            : validWidth;
          return (
            <span className={classNames('table-header__item', className)} style={styles} key={idx}>
              {header}
            </span>
          );
        })}
      </div>

      <div
        className={classNames('table-body', { 'has-scrolls': hasScrollbar })}
        style={styles}
        ref={tableBodyRef}
      >
        {data?.length ? (
          <ScrollableArea styles={{ width: '100%', maxHeight: styles?.maxHeight || 350 }}>
            <>
              {data.map((item, idx) =>
                item[expandableKey] ? (
                  <ExpandableRow key={idx} idx={idx} item={item} />
                ) : (
                  <Row key={idx} idx={idx} item={item} expandable={false} />
                )
              )}
              {areAdditionalItemsLoading ? renderLoader() : null}
            </>
          </ScrollableArea>
        ) : DefaultComponent ? (
          <DefaultComponent />
        ) : null}
      </div>
      {isNewPageDataLoading ? renderLoader({ isOrderedPagination: true }) : null}
    </div>
  );
};

export default memo(Table);

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  styles: PropTypes.object,
  styleDetector: PropTypes.func,
  onCellClick: PropTypes.func,
  cellValueMiddleware: PropTypes.func
};

Table.defaultProps = {
  styles: null,
  styleDetector: null,
  onCellClick: () => {},
  cellValueMiddleware: null
};
