import axios from 'axios';

import { API_URL, API_URL_V2, INTEGRATIONS_API_URL } from '../constants/common.constants';
import { handleRequestError } from './handlers/error-handlers';
import { getDeviceId } from '../utils/auth-client';
import { _DEFAULT_CONFIG } from './config';
import { getAuthorizationForRequest } from '../utils/network-util';

async function getBeneficiaries() {
  return axios
    .get(`${API_URL}/api/tribalwallet/get-beneficiaries`, await _DEFAULT_CONFIG())
    .catch(handleRequestError);
}

async function getBeneficiaryDetails(id, cancelToken) {
  return axios
    .get(
      `${API_URL}/api/tribalwallet/get-beneficiary-details?BeneficiaryId=${id}`,
      await _DEFAULT_CONFIG(cancelToken)
    )
    .catch(error => handleRequestError(error));
}

async function getPaymentDetails(id, cancelToken) {
  return axios
    .get(
      `${API_URL}/api/tribalwallet/get-payment-details?beneficiary_id=${id}`,
      await _DEFAULT_CONFIG(cancelToken)
    )
    .catch(error => handleRequestError(error));
}

async function createBeneficiary(beneficiary, companyId) {
  const defaultConfigAxios = await _DEFAULT_CONFIG()
  return axios
    .post(`${API_URL_V2}/beneficiaries`, beneficiary, {
      ...defaultConfigAxios,
      headers: {
        ...defaultConfigAxios.headers,
        "X-Company-Id": companyId,
        "Content-Type": "Application/json",
        Authorization: defaultConfigAxios.headers.Authorization
      }
    })
    .catch(error => handleRequestError(error));
}

async function updateBeneficiary(beneficiary, companyId) {
  const defaultConfigAxios = await _DEFAULT_CONFIG()
  return axios
    .put(`${API_URL_V2}/beneficiaries`, beneficiary,
      {
        ...defaultConfigAxios,
        headers: {
          ...defaultConfigAxios.headers,
          "X-Company-Id": companyId,
          "Content-Type": "Application/json",
          Authorization: defaultConfigAxios.headers.Authorization
        }
      }
    )
    .catch(error => handleRequestError(error));
}

async function approvedDeclinedRecipient(id, isActive) {
  const defaultConfigAxios = await _DEFAULT_CONFIG()
  return axios
    .post(`${API_URL}/api/tribalwallet/approved-declined-recipient`, {
        beneficiary_Id: id,
        IsActive: isActive
      },
      {
        ...defaultConfigAxios, headers:
          { ...defaultConfigAxios.headers }
      })
    .catch(error => handleRequestError(error));
}


async function createPayment(data, companyId, isSatPayment) {

  const defaultConfigAxios = await _DEFAULT_CONFIG()

  return axios
    .post(isSatPayment ? `${API_URL}/api/tribalwallet/create-payment` : `${API_URL_V2}/payments`, data,
      {
        ...defaultConfigAxios, headers:
          { ...defaultConfigAxios.headers, "X-Company-Id": companyId }
      })
    .catch(error => handleRequestError(error));
}

async function createBulkPayments(data, arcusPayment) {
  return axios
    .post(arcusPayment ?
      `${INTEGRATIONS_API_URL}/v1/bulk-payouts` : `${API_URL}/api/tribalwallet/create-bulk-payments`, data, await _DEFAULT_CONFIG())
    .catch(error => handleRequestError(error));
}

async function getBankDetails(id, currencyId, countryId) {
  return axios
    .get(
      `${API_URL}/api/tribalwallet/get-bank-details?identifier_value=${id}&default_account_currency_id=${currencyId}&beneficiary_country_id=${countryId}`,
      await _DEFAULT_CONFIG()
    )
    .catch(error => handleRequestError(error));
}

async function getTransactions() {
  return axios
    .get(`${API_URL}/api/deposits/transactions`, await _DEFAULT_CONFIG())
    .catch(handleRequestError);
}

async function getCountries() {
  return axios
    .get(`${API_URL}/api/tribalwallet/get-countries`, await _DEFAULT_CONFIG())
    .catch(handleRequestError);
}

async function getBanks() {
  return axios
    .get(`${API_URL}/api/tribalwallet/get-banks`, await _DEFAULT_CONFIG())
    .catch(handleRequestError);
}

async function getCities(countryId, stateId) {
  let url = `${API_URL}/api/tribalwallet/get-cities?country_id=${countryId}`;
  url = stateId ? `${url}&state_id=${stateId}` : url;
  return axios.get(url, await _DEFAULT_CONFIG()).catch(handleRequestError);
}

async function getStates(countryId) {
  return axios
    .get(`${API_URL}/api/tribalwallet/get-states?country_id=${countryId}`, await _DEFAULT_CONFIG())
    .catch(handleRequestError);
}

async function getRate(beneficiaryId) {
  return axios
    .get(
      `${API_URL}/api/tribalwallet/get-rate?beneficiary_id=${beneficiaryId}`,
      await _DEFAULT_CONFIG()
    )
    .catch(handleRequestError);
}

async function getBalanceInfo(billingId) {
  const deviceId = await getDeviceId();
  let params = {
    device_id: deviceId
  };
  return axios
    .get(`${API_URL}/api/tribalwallet/get-balance-info?billing_id=${billingId}`, {
      ...(await _DEFAULT_CONFIG()),
      params
    })
    .catch(handleRequestError);
}

const getCurrencies = async cancelToken => {
  const config = await _DEFAULT_CONFIG(cancelToken);

  return axios.get(`${API_URL}/api/tribalwallet/get-currencies`, config).catch(handleRequestError);
};

const getWalletFeatures = async () => {
  const config = await _DEFAULT_CONFIG();
  return axios
    .get(`${API_URL}/api/tribalwallet/get-all-features`, config)
    .catch(handleRequestError);
};

const getWalletAgreement = async () =>
  axios
    .get(`${API_URL}/api/tribalwallet/get-wallet-agreement`, await _DEFAULT_CONFIG())
    .catch(handleRequestError);

const acceptPaymentAgreement = async () => {
  const data = {
    is_accepted: true
  };

  return axios
    .post(`${API_URL}/api/tribalwallet/accept-payment-agreement`, data, await _DEFAULT_CONFIG())
    .catch(error => handleRequestError(error));
};

const getPurposeCodes = async ({ currency, bankAccountCountry }) => {
  const params = {
    currency,
    bank_account_country: bankAccountCountry
  };

  return axios
    .get(`${API_URL}/api/tribalwallet/get-purpose-codes`, {
      ...(await _DEFAULT_CONFIG()),
      params
    })
    .catch(handleRequestError);
};

async function uploadBulkPaymentFile({ file }, arcusPayment) {

  return axios
    .post(
      arcusPayment ?
        `${INTEGRATIONS_API_URL}/v1/bulk-payouts/file` :
        `${API_URL}/api/tribalwallet/upload-bulk-payments`,
      file, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: await getAuthorizationForRequest()
      }
    })
    .catch(error => handleRequestError(error));
}

async function downloadPaymentFile({ fileId, fileName }) {
  const params = {
    file_name: fileName,
    file_id: fileId
  };

  return axios
    .get(`${API_URL}/api/tribalwallet/get-bulkpayment-file`, {
      ...(await _DEFAULT_CONFIG()),
      params
    })
    .catch(handleRequestError);
}

async function getRecurringEmails() {
  return axios
    .get(`${API_URL}/api/tribalwallet/get-recurring-emails`, await _DEFAULT_CONFIG())
    .catch(handleRequestError);
}

async function postRecurringEmails({ recurringEmails }) {
  return axios
    .post(
      `${API_URL}/api/tribalwallet/add-recurring-emails`,
      {
        recurring_emails: recurringEmails
      },
      await _DEFAULT_CONFIG()
    )
    .catch(error => handleRequestError(error));
}

const getConversion = async (data, companyId) => {
  const defaultConfigAxios = await _DEFAULT_CONFIG()

  try {
    const response = await axios.get(`${API_URL_V2}/rates`, {
      ...defaultConfigAxios,
      params: data,

      headers: {
        ...defaultConfigAxios.headers,
        "X-Company-Id": companyId,
        "Content-Type": "Application/json",
        Authorization: defaultConfigAxios.headers.Authorization
      }
    });
    return response.data;
  }
  catch (e) {
    throw Error(e);
  }
}


async function getBulkPayments({ bulkId, page }) {
  const params = {
    bulk_id: bulkId,
    page
  };

  return axios
    .get(`${API_URL}/api/tribalwallet/get-bulk-payments`, {
      ...(await _DEFAULT_CONFIG()),
      params
    })
    .catch(handleRequestError);
}

export const walletService = {
  getPurposeCodes,
  getBeneficiaries,
  getBeneficiaryDetails,
  getPaymentDetails,
  createBeneficiary,
  updateBeneficiary,
  createPayment,
  createBulkPayments,
  getBankDetails,
  getTransactions,
  getCountries,
  getBanks,
  getCities,
  getStates,
  getRate,
  getConversion,
  getBalanceInfo,
  getCurrencies,
  getWalletFeatures,
  getWalletAgreement,
  acceptPaymentAgreement,
  uploadBulkPaymentFile,
  downloadPaymentFile,
  getRecurringEmails,
  postRecurringEmails,
  getBulkPayments,
  approvedDeclinedRecipient
};
