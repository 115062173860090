import { useLayoutEffect, useRef, useState } from 'react';
import * as _ from 'lodash';

export const useMarkerPosition = () => {
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    if (containerRef.current !== null) {
      const getContainerWidth = () => {
        const clientRect = containerRef?.current?.getBoundingClientRect();
        if (clientRect && clientRect?.width && clientRect?.width !== containerWidth)
          setContainerWidth(clientRect.width);
      };

      window.addEventListener('resize', _.throttle(getContainerWidth, 500));
      !containerWidth && getContainerWidth();

      return () => window.removeEventListener('resize', getContainerWidth);
    }
  }, [containerRef]);

  return { containerWidth, containerRef };
};
