import classNames from 'classnames';

import InputWithSelect from '../../input-with-select';
import Loader from '../../loader';

import CloseIcon from './internal/CloseIcon';
import CloseCross from '../../../../assets/images/cross.svg';

import './index.scss';

const SimpleBox = ({
  value,
  readOnly,
  onDeletion,
  secondary,
  isValid,
  withSelect,
  id,
  dropdownData,
  handleTagChange,
  selectType,
  getSelectLabel,
  handleTagUpdate,
  disabled,
  isLoading
}) =>
  withSelect ? (
    <InputWithSelect
      handleChange={handleTagChange}
      value={value}
      handleClose={onDeletion}
      dropDownData={dropdownData}
      isValid={isValid}
      id={id}
      selectType={selectType}
      getSelectLabel={getSelectLabel}
      handleTagUpdate={handleTagUpdate}
      disabled={disabled}
    />
  ) : (
    <div
      className={classNames('group-boxes__simple-box', {
        secondary: secondary,
        'not-valid': !isValid,
        loading: isLoading
      })}
    >
      <span>{value}</span>
      {readOnly ? null : (
        <>
          {isLoading ? (
            <div className="loader-container_simple-box">
              <Loader small />
            </div>
          ) : secondary ? (
            <div className="icon-wrapper" onClick={onDeletion}>
              <CloseIcon />
            </div>
          ) : (
            <img alt="" src={CloseCross} onClick={onDeletion} />
          )}
        </>
      )}
    </div>
  );

export default SimpleBox;
