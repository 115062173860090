import { useState } from 'react';

import { useTooltipCoordinates } from '../../../hooks';

export const useInnerHook = () => {
  const [isInfoTooltipVisible, setInfoTooltipVisibility] = useState(false);
  const { tooltipCoords, handleHover: handleEmailInfoDataMouseOver } = useTooltipCoordinates(
    setInfoTooltipVisibility
  );

  const handleEmailInfoDataMouseOut = () => setInfoTooltipVisibility(false);

  return {
    isInfoTooltipVisible,
    handleEmailInfoDataMouseOut,
    handleEmailInfoDataMouseOver,
    tooltipCoords
  };
};
