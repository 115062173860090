import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Avatar = ({ initials, profile, color, big }) => (
  <div className={`${profile ? 'box__container' : ''}`}>
    <div
      className={`avatar ${big ? 'avatar--big' : ''}`}
      style={{ backgroundColor: color }}
    >
      <span>{initials}</span>
    </div>
  </div>
);

Avatar.propsType = {
  initials: PropTypes.string,
  color: PropTypes.string,
  profile: PropTypes.bool
};

Avatar.defaultProps = {
  initials: '',
  profile: false,
  color: '#d9e7fd'
};

export default Avatar;
