import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Loader from '../loader';
import Divider from '../divider';
import Input from '../input';
import RoundedButton from '../button-rounded';

import { ReactComponent as WarnIcon } from '../../../assets/images/warn.svg';
import { ReactComponent as CheckmarkIcon } from '../../../assets/images/checkmark.svg';
import satLogo from '../../../assets/images/logo/logo-sat.svg';
import { ReactComponent as RepeatIcon } from '../../../assets/images/repeat.svg';
import tribalLogo from '../../../assets/images/logo/logo-tribal.svg';

import { useSATform } from './hooks';

import { checkIsValidRFC } from './utils';

import './index.scss';

//TODO will be translated in the parent component
const ConnectSatForm = ({
  title,
  description,
  handleDoneClick,
  isDisplayedInModal,
  onSatConnectedCallback
}) => {
  const { t } = useTranslation('translation');
  const [isConnectionComplete, setConnectionComplete] = useState(false);
  const { companyId } = useSelector(state => state.company);
  const { connectToSat } = useSATform(isDisplayedInModal);

  const handlePasswordRestore = () =>
    window.open('https://www.sat.gob.mx/empresas/iniciar-sesion', '_blank');

  const ModalSatConnectionSuccess = () => (
    <div className="modal-sat-connection-success">
      <CheckmarkIcon className="modal-sat-connection-success__icon" />
      <div className="modal-sat-connection-success-description">
        <span className="modal-sat-connection-success-description__text">
          {t('connectSatForm.successComponent.modalSuccess')}
        </span>
      </div>
      <div className="modal-sat-connection-success-info">
        <span className="modal-sat-connection-success-info__text">
          {t('connectSatForm.successComponent.modalRequestSubmitted')}
        </span>
      </div>
      <RoundedButton onClick={handleDoneClick} className="modal-sat-connection-success__button">
        {t('connectSatForm.done')}
      </RoundedButton>
    </div>
  );

  const SatConnectionFailure = () => (
    <div className="sat-connection-failure">
      <WarnIcon />
      <span className="sat-connection-failure__message">
        {t('connectSatForm.errors.connection')}
      </span>
    </div>
  );

  const ConnectSat = () => {
    const INITIAL_ERROR_STATE = {
      rfc: '',
      satPassword: '',
      connection: ''
    };

    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState(INITIAL_ERROR_STATE);
    const [formData, setFormData] = useState({
      rfc: '',
      satPassword: ''
    });

    const handleConnect = () => {
      error.connection && setError(INITIAL_ERROR_STATE);
      setIsProcessing(true);
      const { rfc, satPassword } = formData;
      connectToSat({ rfc, satPassword, companyId })
        .then(() => {
          setIsProcessing(false);
          setConnectionComplete(true);
          onSatConnectedCallback && onSatConnectedCallback();
        })
        .catch(error => {
          setIsProcessing(false);
          setError({ ...error, connection: error.response?.data?.Message || error?.message });
        });
    };

    const handleChange = event => {
      const { value, name } = event.target;
      error[name] && setError({ ...error, [name]: '' });
      setFormData({ ...formData, [name]: value });
    };

    const { rfc, satPassword } = formData;

    const isButtonDisabled = !satPassword || !rfc || error.rfc || error.satPassword;

    const handleRfcValidation = rfc => {
      const isRFCvalid = checkIsValidRFC(rfc);
      return isRFCvalid ? null : setError({ ...error, rfc: 'connectSatForm.errors.rfc' });
    };

    const handlePasswordValidation = password => {
      const MIN_PASSWORD_LENGTH = 8;
      return password.length >= MIN_PASSWORD_LENGTH
        ? null
        : setError({ ...error, satPassword: 'connectSatForm.errors.rfcPassword' });
    };

    return (
      <section className="connect-sat">
        <span className="connect-sat__title">{t(title)}</span>
        {description ? <span className="connect-sat__description">{description}</span> : null}
        <div className="logos-section">
          <img src={satLogo} alt="sat-logo" />
          <RepeatIcon className="logos-section__repeat-icon" />
          <img src={tribalLogo} alt="tribal-logo" />
        </div>
        <Divider className="first-divider" />

        {isConnectionComplete && isDisplayedInModal ? null : (
          <>
            <div
              className={classNames('connect-sat-form', {
                processing: isProcessing
              })}
            >
              <div className="input-container">
                <label className="input-container__label">
                  {t('connectSatForm.inputLabels.rfc')}
                </label>
                <Input
                  onChange={handleChange}
                  onBlur={() => handleRfcValidation(rfc)}
                  name="rfc"
                  value={rfc}
                  invalid={!!error.rfc}
                />
                {error.rfc ? (
                  <span className="input-container__error">{t('connectSatForm.errors.rfc')}</span>
                ) : null}
              </div>
              <div className="input-container">
                <label className="input-container__label">
                  {t('connectSatForm.inputLabels.password')}
                </label>
                <Input
                  onChange={handleChange}
                  name="satPassword"
                  value={satPassword}
                  invalid={!!error.satPassword}
                  onBlur={() => handlePasswordValidation(satPassword)}
                  autoComplete="new-password"
                  type="password"
                />
                {error.satPassword ? (
                  <span className="input-container__error">Password error</span>
                ) : null}
                <span className="input-container__restore-password" onClick={handlePasswordRestore}>
                  {t('connectSatForm.forgotPassword')}
                </span>
              </div>
            </div>
            <Divider className="last-divider" />
          </>
        )}

        {isDisplayedInModal && isConnectionComplete ? (
          <ModalSatConnectionSuccess />
        ) : (
          <>
            {error.connection ? <SatConnectionFailure /> : null}
            <div className="connect-sat-action-container">
              {isProcessing ? (
                <div className="loader-box">
                  <Loader />
                  <span className="loader-box__message">{t('connectSatForm.connectingSat')}</span>
                </div>
              ) : (
                <RoundedButton
                  className="connect-sat__button"
                  onClick={handleConnect}
                  disabled={isButtonDisabled}
                >
                  {t('connectSatForm.button')}
                </RoundedButton>
              )}
            </div>
          </>
        )}

        <div className="connect-sat-info">
          <span className="connect-sat-info__text">{t('connectSatForm.info.first')}</span>
          <span className="connect-sat-info__text">{t('connectSatForm.info.second')}</span>
          <span className="connect-sat-info__text">{t('connectSatForm.info.third')}</span>
        </div>
      </section>
    );
  };

  return <ConnectSat />;
};

export default ConnectSatForm;

ConnectSatForm.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  handleDoneClick: PropTypes.func,
  isDisplayedInModal: PropTypes.bool,
  onSatConnectedCallback: PropTypes.func
};

ConnectSatForm.defaultProps = {
  title: 'connectSatForm.title',
  description: null,
  handleDoneClick: () => {},
  isDisplayedInModal: false,
  onSatConnectedCallback: null
};
