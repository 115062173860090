import classNames from 'classnames';

import PropTypes from 'prop-types';

import './index.scss';

const LinkedText = ({ src, className, children }) => (
  <a className={classNames('linked-text', className)} href={src}>
    {children}
  </a>
);

export default LinkedText;

LinkedText.propTypes = {
  src: PropTypes.string
};
