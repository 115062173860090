import React from 'react';
import { useTranslation } from 'react-i18next';

import AddCross from './../../../assets/images/plus-circle.svg';

import './index.scss';

const AddButton = ({ label, onAddClick }) => {
  const { t } = useTranslation();
  return (
    <div className="group-boxes__add-button" onClick={() => onAddClick()}>
      <span>{label || t('common.add')}</span>
      <img alt="" src={AddCross} />
    </div>
  );
};

export default AddButton;
