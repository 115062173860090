import { authService } from '../services';
import {
  getEmailValidationError,
  getPasswordValidationErrors,
  getTwoFactorCodeValidationError
} from '../services/validation-service';
import { getQueryParameter } from '../utils/network-util';
import { cleanMobileNumber } from '../utils/string-service';

import {
  TOKEN_TYPES,
  authConstants,
  TEMPO_DEVICE_ID_KEY,
  TEMPO_TOKEN_KEY
} from '../constants/auth.constants';

import {
  getLoginValidationError,
  getRegistrationValidationError,
  setAuthDataToLocalStorage,
  setTemporaryAuthData,
  cleanTemporaryAuthData
} from './helpers/auth.helper';
import { userConstants } from '../constants/user.constants';

export const authActions = {
  login,
  twoFactorLogin,
  resendTwoFactorCode,
  recoverPassword,
  resetPassword,
  setPassword,
  completeRegistration,
  verifyRegistrationToken,
  logout,
  setUser,
  updateDocuSignStatus,
  updateAgreement,
  cancelEmailActivation,
  resendEmailActivation
};

function login(email, password) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      _callProperLoginRequest(email, password)
        .then(response => {
          if (response.status) {
            if (response.status.is_success) {
              if (response.data.token_type === TOKEN_TYPES.BEARER) {
                setAuthDataToLocalStorage(response.data);
                dispatch({
                  type: authConstants.LOGIN_USER,
                  user: response.data.profile
                });
                resolve(false);
              } else {
                setTemporaryAuthData(response.data);
                resolve(true);
              }
            } else reject(response.status.error_message);
          } else reject();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function _callProperLoginRequest(email, password) {
  const confirmationToken = getQueryParameter('token');
  if (confirmationToken) {
    return authService.confirmEmail(confirmationToken);
  }

  return new Promise((resolve, reject) => {
    const validationError = getLoginValidationError(email, password);
    validationError ? reject(validationError) : resolve(authService.login(email, password));
  });
}

function twoFactorLogin(code, rememberMe) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!getTwoFactorCodeValidationError(code)) {
        const tempoToken = window.sessionStorage.getItem(TEMPO_TOKEN_KEY);
        const deviceId = window.sessionStorage.getItem(TEMPO_DEVICE_ID_KEY);
        return authService
          .twoFactorLogin({
            code: code,
            code_id: tempoToken,
            device_id: deviceId,
            save_device: rememberMe
          })
          .then(response => {
            if (response.status.is_success) {
              setAuthDataToLocalStorage(response.data, rememberMe);
              cleanTemporaryAuthData();
              dispatch({
                type: authConstants.LOGIN_USER,
                user: response.data.profile
              });
              resolve();
            } else reject(response.status.error_message);
          })
          .catch(error => {
            reject(error);
          });
      } else reject(getTwoFactorCodeValidationError(code));
    });
  };
}

function resendTwoFactorCode() {
  return () => {
    return new Promise((resolve, reject) => {
      const tempoToken = window.sessionStorage.getItem(TEMPO_TOKEN_KEY);
      const deviceId = window.sessionStorage.getItem(TEMPO_DEVICE_ID_KEY);
      return authService
        .resendTwoFactorCode(tempoToken, deviceId)
        .then(response => {
          if (response.status.is_success) {
            setTemporaryAuthData(response.data);
            resolve(response.data);
          } else {
            reject(response.status.error_message);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function recoverPassword(email) {
  return () => {
    return new Promise((resolve, reject) => {
      if (!getEmailValidationError(email)) {
        return authService
          .recoverPassword(email)
          .then(response => {
            if (response.status.is_success) {
              resolve();
            } else {
              reject(response.status.error_message);
            }
          })
          .catch(error => {
            reject(error);
          });
      } else {
        reject(getEmailValidationError(email));
      }
    });
  };
}

function resetPassword(password) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const passwordErrors = getPasswordValidationErrors(password);
      let hasPasswordsErrors = false;
      for (let [key, value] of Object.entries(passwordErrors)) {
        if (value) {
          hasPasswordsErrors = true;
          break;
        }
      }
      if (!hasPasswordsErrors) {
        const requestId = getQueryParameter('request_id');
        return authService
          .resetPassword(requestId, password)
          .then(response => {
            if (response.status.is_success) {
              setAuthDataToLocalStorage(response.data);
              cleanTemporaryAuthData();
              dispatch({
                type: authConstants.LOGIN_USER,
                user: response.data.profile
              });
              resolve();
            } else {
              reject({
                isDirty: true,
                passwordError: null,
                commonError: response.status.error_message
              });
            }
          })
          .catch(error => {
            reject({
              isDirty: true,
              passwordError: null,
              commonError: error
            });
          });
      } else {
        reject({
          isDirty: true,
          passwordError: passwordErrors,
          commonError: ''
        });
      }
    });
  };
}

function setPassword(password) {
  return resetPassword(password);
}

function completeRegistration(payload) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const cleanedMobileNumber = cleanMobileNumber(payload.mobileNumber);
      const validationErrors = getRegistrationValidationError(
        payload.password,
        cleanedMobileNumber
      );
      if (
        !validationErrors.passwordError &&
        !validationErrors.isDirty &&
        !validationErrors.mobileError
      ) {
        const email = getQueryParameter('email');
        const confirmationToken = getQueryParameter('token');
        return authService
          .completeRegistration(
            confirmationToken,
            email,
            payload.password,
            payload.country.mobileCountryCode,
            cleanedMobileNumber
          )
          .then(response => {
            if (response.status.is_success) {
              setTemporaryAuthData(response.data);
              resolve();
            } else {
              reject({
                isDirty: true,
                passwordError: null,
                mobileError: response.status.error_message
              });
            }
          })
          .catch(error => {
            reject({ passwordError: null, mobileError: error });
          });
      } else {
        reject(validationErrors);
      }
    });
  };
}

function verifyRegistrationToken() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return authService
        .verifyRegistrationToken(getQueryParameter('token'))
        .then(response => {
          resolve(response.status.is_success);
        })
        .catch(error => reject(error));
    });
  };
}

function logout() {
  return {};
}

function setUser(user) {
  return dispatch => {
    dispatch({
      type: authConstants.LOGIN_USER,
      user
    });
  };
}

function updateDocuSignStatus(status, id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return authService
        .updateDocuSignStatus(status, id)
        .then((data) => {
          resolve(data?.status?.is_success);
        })
        .catch(error => reject(error));
    });
  };
}

function updateAgreement(isAccepted, id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return authService
        .updateAgreement(isAccepted, id)
        .then(response => {
          if (response.status.is_success) {
            dispatch({ type: authConstants.UPDATE_AGREEMENT });
            resolve();
          } else {
            reject(response.status.error_message);
          }
        })
        .catch(error => reject(error));
    });
  };
}

function cancelEmailActivation(userId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return authService
        .cancelEmailActivation(userId)
        .then(response => {
          dispatch({
            type: userConstants.CANCEL_USER_ACTIVATION,
            userId
          });
          resolve();
        })
        .catch(error => reject(error));
    });
  };
}

function resendEmailActivation(userId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return authService
        .resendEmailActivation(userId)
        .then(response => {
          resolve();
        })
        .catch(error => reject(error));
    });
  };
}
