import React, { memo } from 'react';
import PropTypes from 'prop-types';
import PrevNextButton from '../prev-next-button';

import './index.scss';

const PrevNext = ({ prevDisabled, nextDisabled, onPrevClick, onNextClick }) => {
  return (
    <div className="prev-next-container">
      <PrevNextButton disabled={nextDisabled} onClickHandler={onNextClick} />
      <PrevNextButton
        disabled={prevDisabled}
        onClickHandler={onPrevClick}
        next
      />
    </div>
  );
};

PrevNext.propsType = {
  nextDisabled: PropTypes.bool,
  prevDisabled: PropTypes.bool,
  onPrevClick: PropTypes.func,
  onNextClick: PropTypes.func
};

PrevNext.defaultProps = {
  nextDisabled: false,
  prevDisabled: false
};

export default memo(PrevNext);
