export const walletConstants = {
  SET_BENEFICIARIES: 'SET_BENEFICIARIES',
  SET_BENEFICIARY_DETAILS: 'SET_BENEFICIARY_DETAILS',
  SET_BANK_DETAILS: 'SET_BANK_DETAILS',
  SET_BALANCE: 'SET_BALANCE',
  SET_WALLET_CURRENCIES: 'SET_WALLET_CURRENCIES',
  SET_WALLET_FEATURES: 'SET_WALLET_FEATURES',
  SET_RECIPIENT_COUNTRIES: 'SET_RECIPIENT_COUNTRIES',
  SET_BANKS: 'SET_BANKS',
  SET_RECIPIENT_STATES: 'SET_RECIPIENT_STATES',
  SET_RECIPIENT_CITIES: 'SET_RECIPIENT_CITIES',
  SET_RECURRING_EMAILS: 'SET_RECURRING_EMAILS',

  ADD_BENEFICIARY: 'ADD_BENEFICIARY',

  SET_SCHEDULED_PAYMENTS: 'SET_SCHEDULED_PAYMENTS',
  SET_CANCELED_SCHEDULE_PAYMENTS: 'SET_CANCELED_SCHEDULE_PAYMENTS',
  CANCEL_SCHEDULE_PAYMENT: 'CANCEL_SCHEDULE_PAYMENT',

  GET_WALLET_AGREEMENT: 'GET_WALLET_AGREEMENT',
  ACCEPT_PAYMENT_AGREEMENT: 'ACCEPT_PAYMENT_AGREEMENT',
  WALLET_AGREEMENT_STATUS: {
    PENDING: 'Pending',
    DONE: 'Done'
  }
};
