const _MAX_PERCENT = 100;
export const MIN_PERCENT = 0;

export const getValidatedPercent = percent => {
  let validPercent = percent;
  if (validPercent > _MAX_PERCENT) {
    validPercent = _MAX_PERCENT;
  } else if (validPercent < MIN_PERCENT) {
    validPercent = MIN_PERCENT;
  }
  return validPercent;
};

export const getValidatedSecondaryPercent = percent =>
  percent > MIN_PERCENT && percent < _MAX_PERCENT ? percent : null;

export const getFilledBarStyle = percent => ({ width: `${percent}%` });

// make right border of the filled area rounded
// if filled by at least 99.5%
export const getFilledBarClass = percent =>
  `progress-bar--filled ${percent < 99.5 ? '' : 'rounded'}`;

export const getReversedBarStyle = secondaryPercent => ({
  width: `${_MAX_PERCENT - secondaryPercent}%`
});
