import axios from 'axios';
import { API_URL } from '../constants/common.constants';
import { handleRequestError } from './handlers/error-handlers';
import { getDeviceId } from '../utils/auth-client';
import { getAuthorizationForRequest } from '../utils/network-util';
import { _DEFAULT_CONFIG } from './config';

export const cardsService = {
  createCard,
  getCards,
  getCardDetails,
  getCardRate,
  getCardBilling,
  getCardTransactions,
  getCardsTypes,
  getCardPan,
  updateCardStatus,
  updateCard,
  reportCard,
  getShippingAddressAvailableForUpdate,
  updateShippingAddress,
  getUserCards
};

async function getShippingAddressAvailableForUpdate({ cardId }) {
  const deviceId = await getDeviceId();
  let params = {
    device_id: deviceId,
    card_id: cardId
  };
  return axios
    .get(`${API_URL}/api/cards/get-shipping-address-available-slots`, {
      ...(await _DEFAULT_CONFIG()),
      params
    })
    .catch(handleRequestError);
}

async function updateShippingAddress({ shippingAddress }) {
  const deviceId = await getDeviceId();
  let params = {
    device_id: deviceId
  };

  return axios
    .post(`${API_URL}/api/cards/update-shipping-address?device_id=${deviceId}`, shippingAddress, {
      ...(await _DEFAULT_CONFIG())
    })
    .catch(handleRequestError);
}

function createCard(card) {
  return axios
    .post(`${API_URL}/api/cards/create`, card, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: getAuthorizationForRequest()
      }
    })
    .then(response => response.data)
    .catch(handleRequestError);
}

let cancelGetCards;
async function getCards({ billingId, page, cardStatus, sortBy, searchString }, cancelToken) {
  (searchString || searchString === '') && cancelGetCards?.cancelToken?.();
  cancelGetCards = cancelToken;

  let params = {
    device_id: getDeviceId(),
    page: page ? page : 1,
    CardStatus: cardStatus,
    SortBy: sortBy ? sortBy : 0,
    search_str: searchString ? searchString : null
  };

  if (billingId) params['billing_id'] = billingId;

  return axios
    .get(`${API_URL}/api/cards/my-cards`, {
      ...(await _DEFAULT_CONFIG(cancelToken)),
      params
    })
    .catch(handleRequestError);
}

async function getUserCards({ page, userId }, cancelToken) {
  // 1st page always exists in users object
  const defaultPage = 2;

  let params = {
    device_id: getDeviceId(),
    cards_page: page ? page : defaultPage,
    user_id: userId
  };

  return axios
    .get(`${API_URL}/api/cards/cards`, {
      ...(await _DEFAULT_CONFIG(cancelToken)),
      params
    })
    .catch(handleRequestError);
}

function getCardDetails(id, billingId) {
  let params = {
    device_id: getDeviceId(),
    card_id: id
  };
  if (billingId) params['billing_id'] = billingId;
  return axios
    .get(`${API_URL}/api/cards/card`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: getAuthorizationForRequest()
      },
      params
    })
    .catch(handleRequestError);
}

function getCardRate(id, currencyId) {
  let params = {
    device_id: getDeviceId(),
    card_id: id
  };
  if (currencyId) params['currency_id'] = currencyId;
  return axios
    .get(`${API_URL}/api/cards/get-rate`, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: getAuthorizationForRequest()
      },
      params
    })
    .catch(handleRequestError);
}

function getCardBilling(id) {
  let params = {
    device_id: getDeviceId(),
    card_id: id
  };
  return axios
    .get(`${API_URL}/api/billing/card-billing`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: getAuthorizationForRequest()
      },
      params
    })
    .catch(handleRequestError);
}

function getCardTransactions(id, billingId) {
  let params = {
    device_id: getDeviceId(),
    filter: 'Last30Days',
    card_id: id
  };
  if (billingId) params['billing_id'] = billingId;
  return axios
    .get(`${API_URL}/api/transactions/card-transactions`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: getAuthorizationForRequest()
      },
      params
    })
    .catch(handleRequestError);
}

function getCardsTypes() {
  return fetch(`${API_URL}/api/cards/types?&device_id=${getDeviceId()}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: getAuthorizationForRequest()
    }
  }).then(response => {
    if (response.status === 401) {
      window.sessionStorage.clear();
      window.location.href = '/login';
    }

    return response;
  });
}

function getCardPan(id) {
  return fetch(`${API_URL}/api/cards/pan?card_id=${id}&device_id=${getDeviceId()}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: getAuthorizationForRequest()
    }
  }).then(response => {
    if (response.status === 401) {
      window.sessionStorage.clear();
      window.location.href = '/login';
    }

    return response;
  });
}

function updateCardStatus(id, statusId) {
  return fetch(
    `${API_URL}/api/cards/update-status?card_id=${id}&status_id=${statusId}&device_id=${getDeviceId()}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: getAuthorizationForRequest()
      }
    }
  ).then(response => {
    if (response.status === 401) {
      window.sessionStorage.clear();
      window.location.href = '/login';
    }

    if (!response.ok) throw new Error('Something went wrong');

    return response;
  });
}

function reportCard(id, statusId) {
  return fetch(
    `${API_URL}/api/cards/report-card?card_id=${id}&device_id=${getDeviceId()}&status_id=${statusId}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: getAuthorizationForRequest()
      }
    }
  ).then(response => {
    if (response.status === 401) {
      window.sessionStorage.clear();
      window.location.href = '/login';
    }

    return response;
  });
}

function updateCard(card) {
  return axios
    .post(`${API_URL}/api/cards/update-card`, card, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: getAuthorizationForRequest()
      }
    })
    .then(response => response.data)
    .catch(handleRequestError);
}
