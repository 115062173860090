import classNames from 'classnames';

import { getItemStyles } from './utils';

import './index.scss';

const PaginationItem = ({ value, active, handleClick, disabled }) => (
  <div
    className={classNames('pagination-item', { active, disabled })}
    onClick={() => !active && handleClick?.(value)}
    style={getItemStyles(value)}
  >
    {value}
  </div>
);

export default PaginationItem;
