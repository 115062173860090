import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';

import { userActions } from '../../../actions/user.action';

import { ReactComponent as Lighting } from '../../../assets/images/notifications/lightning.svg';
import { CloseCircleIcon, NotificationButton } from './internal';

import { notificationBannerConstants } from '../../../constants/notification-banner.constants';

import './index.scss';

const {
  DUE_TOMORROW,
  OVERDUE_EXCEPTION,
  OVERDUE_PARTIALLY_SUSPENDED,
  OVERDUE_FULLY_SUSPENDED,
  PRODUCT_BANNER,
  POLICY_BANNER
} = notificationBannerConstants;

const NotificationBanner = ({ notification: { id, type, content, link }, dispatch }) => {
  const { t, i18n } = useTranslation('translation');

  const isDueTomorrow = type === DUE_TOMORROW;
  const isProductBanner = type === PRODUCT_BANNER;
  const isMigrationBanner = type === 'MIGRATION_BANNER';
  const isBeforeMigrationBanner = type === 'BEFORE_MIGRATION_BANNER';
  const isOverdue = [
    OVERDUE_EXCEPTION,
    OVERDUE_PARTIALLY_SUSPENDED,
    OVERDUE_FULLY_SUSPENDED
  ].includes(type);
  const isPolicyBanner = type === POLICY_BANNER;

  const BoldText = ({ children }) => <b>{children}</b>;
  const LinkText = ({ children, link }) => <a href={link} target="_blank">{children}</a>;

  const MigrationBanner = ({before = false}) => {
    const key = before ? 'beforeMigrationBanner' : 'migrationBanner';
    let faqLink = 'https://support.tribal.mx/hc/en-us/sections/9738038003604-Tribal-2-0-';

    if (i18n.language === 'es') faqLink = 'https://support.tribal.mx/hc/es/sections/9738038003604-Tribal-2-0';
    if (i18n.language === 'pt') faqLink = 'https://support.tribal.mx/hc/pt-br/sections/9738038003604-Tribal-2-0-';

    return (
      <div className="notification-banner__info-container">
        <div className="notification-banner__content-wrapper">
          <div className="notification-banner__title notification-banner__title--migration">
            {before ? <Lighting className="lighting-icon"/> : null}
            <span>
            <Trans
              i18nKey={key}
              t={t}
              components={[
                <BoldText/>,
                <LinkText link={'https://app.tribalcredit.io/'}/>,
                <LinkText link={faqLink}/>
              ]}
            />
          </span>
          </div>
        </div>
      </div>
    );
  };

  const ProductBanner = () => (
    <div className="notification-banner__info-container">
      <div className="notification-banner__content-wrapper">
        <div className="notification-banner__title">
          <Lighting className="lighting-icon" />
          <span>{content}</span>
        </div>

        <NotificationButton
          type="product_banner"
          label={t('notificationBanners.link.readNow')}
          src={link}
        />
      </div>

      <CloseCircleIcon
        className="close-icon"
        color="#256659"
        background="#BCF4E3"
        onClick={() => dispatch(userActions.hidePendingNotifications(id, type))}
      />
    </div>
  );

  const DueTomorrowBanner = () => (
    <div className="notification-banner__info-container">
      <span className="notification-banner__title">{content}</span>

      <CloseCircleIcon
        className="close-icon"
        color="#B56C00"
        background="#FFCA7A"
        onClick={() => {
          dispatch(userActions.hidePendingNotifications(id));
        }}
      />
    </div>
  );

  const PolicyBanner = () => (
    <div className="notification-banner__info-container">
      <span className="notification-banner__title">{content}</span>

      <CloseCircleIcon
        className="close-icon"
        color="#1A1A1A"
        background="#EAEAEA"
        onClick={() => {
          dispatch(userActions.hidePendingNotifications(id));
        }}
      />
    </div>
  );

  const OverdueBanner = () => (
    <div className="notification-banner__info-container">
      <span className="notification-banner__title">{content}</span>
    </div>
  );

  return (
    <div
      className={classNames('notification-banner', {
        'notification-banner--overdue': isOverdue,
        'notification-banner--due_tomorrow': (isDueTomorrow ||isMigrationBanner),
        'notification-banner--special': (isProductBanner || isBeforeMigrationBanner),
        'notification-banner--policy': isPolicyBanner
      })}
    >
      {isOverdue && <OverdueBanner />}
      {isDueTomorrow && <DueTomorrowBanner />}
      {isProductBanner && <ProductBanner />}
      {isMigrationBanner && <MigrationBanner before={false}/>}
      {isBeforeMigrationBanner && <MigrationBanner before={true}/>}
      {isPolicyBanner && <PolicyBanner />}
    </div>
  );
};

export default NotificationBanner;

NotificationBanner.propTypes = {
  notifications: PropTypes.shape({
    type: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    dispatch: PropTypes.func,
    link: PropTypes.string,
    id: PropTypes.string
  })
};

NotificationBanner.defaultProps = {
  dispatch: null,
  link: null,
  id: null
};
