import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Loader } from '../index';

import './index.scss';

const RoundedButton = ({ children, type, className, disabled, isLoading, ...rest }) => {
  const buttonClass = classNames('rounded-button', className, {
    secondary: type === 'secondary',
    ghost: type === 'ghost',
    reload: type === 'reload',
    white: type === 'white', //    TODO will be replaced after "global button refactoring"
    disabled: disabled && type !== 'bright' && type !== 'reload',
    bright: type === 'bright',
    'bright-disabled': disabled && type === 'bright'
  });

  const childrenWithLoader = (
    <div className="rounded-button__with-loader-content">
      <Loader small style={{ position: 'inherit' }} />
      {children}
    </div>
  );

  return (
    <button className={buttonClass} disabled={disabled} {...rest}>
      {isLoading ? childrenWithLoader : children}
    </button>
  );
};

RoundedButton.propsType = {
  children: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool
};

RoundedButton.defaultProps = {
  children: '',
  type: '',
  className: '',
  disabled: false,
  isLoading: false
};

export default RoundedButton;
