import { Component } from 'react';

import Input from './input';
import Dropdown from './dropdown';

import './index.scss';

class DepartmentsDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownVisible: props.activeByDefault,
      isAddDepartmentVisible: false,
      enteredDepartmentText: ''
    };
    this.rootDocument = document.getElementById('root');
    if (props.activeByDefault) {
      this.addDropdownListener();
    }
  }

  componentWillUnmount() {
    this.resetToDefaultState();
  }

  addDropdownListener =  () =>
    this.rootDocument.addEventListener('click', this.handleClickOutside);


  removeDropdownListener =  () =>
    this.rootDocument.removeEventListener('click', this.handleClickOutside);


  handleClickOutside = event => {
    if (!this.dropdownContainer.contains(event.target)) {
      this.resetToDefaultState();

      if (this.props.hasOwnProperty('onClickOutside')) {
        this.props.onClickOutside();
      }
    }
  };

  resetToDefaultState = () => {
    this.removeDropdownListener();
    this.setState({
      isDropdownVisible: false,
      isAddDepartmentVisible: false
    });
  };

  handleInputActivation = () => {
    this.addDropdownListener();
    this.setState({ isDropdownVisible: true });
  };

  handleInputTextChange = text => {
    const formattedText = text.trim();
    const existingDepartment = this.props.data.find(
      el => el.label === formattedText
    );
    this.setState({
      enteredDepartmentText: formattedText,
      isAddDepartmentVisible: !existingDepartment && formattedText
    });
  };

  handleInputSubmit = text => {
    const formattedText = text.trim();
    if (formattedText) {
      if (this.state.isAddDepartmentVisible) {
        this.handleDepartmentCreation(formattedText);
      } else {
        this.handleDepartmentSelection(
          this.props.data.find(el => el.label === formattedText)
        );
      }
    }
  };

  handleDepartmentCreation = name => {
    this.props.onCreation(name);
    this.resetToDefaultState();
  };

  handleDepartmentSelection = department => {
    this.props.onSelection(department);
    this.resetToDefaultState();
  };

  render() {
    return (
      <div
        className="departments-dropdown"
        ref={element => {
          this.dropdownContainer = element;
        }}
      >
        <Input
          defaultValue={this.props.selectedDepartment.label}
          isActive={this.state.isDropdownVisible}
          onActivation={this.handleInputActivation}
          onChange={event => this.handleInputTextChange(event.target.value)}
          onSubmit={event => this.handleInputSubmit(event.target.value)}
        />
        {this.state.isDropdownVisible ? (
          <Dropdown
            isAddDepartmentVisible={this.state.isAddDepartmentVisible}
            enteredDepartmentText={this.state.enteredDepartmentText}
            data={this.props.data}
            selectedDepartment={this.props.selectedDepartment}
            onCreation={name => this.handleDepartmentCreation(name)}
            onSelection={department =>
              this.handleDepartmentSelection(department)
            }
          />
        ) : null}
      </div>
    );
  }
}

export default DepartmentsDropdown;
