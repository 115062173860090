const CURRENCY_IMAGE_BASE_URL = 'https://lipis.github.io/flag-icons/flags/4x3/';

const _prepareCurrency = rawCurrency => {
  let currency = null;

  if (rawCurrency && typeof rawCurrency === 'object' && !Array.isArray(rawCurrency)) {
    currency = {
      id: rawCurrency.id || null,
      code: rawCurrency.code || null,
      symbol: rawCurrency.symbol || null,
      name: rawCurrency.name || null,
      shortName: rawCurrency.short_name || null,
      longName: rawCurrency.long_name || null,
      flagImg: getFlagImgByCurrencyCode(rawCurrency.code)
    };

    if (rawCurrency.is_default) {
      currency.isDefault = true;
    }
  }
  return currency;
};

export const getFlagImgByCurrencyCode = currencyCode => {
  let flagImgUrl = null;
  if (currencyCode) {
    const formattedCountryCode = currencyCode.toLowerCase().slice(0, -1);
    flagImgUrl = `${CURRENCY_IMAGE_BASE_URL}${formattedCountryCode}.svg`;
  }
  return flagImgUrl;
};

export const prepareCurrencies = rawCurrencies => {
  let currencies = [];

  if (rawCurrencies && Array.isArray(rawCurrencies) && rawCurrencies.length) {
    currencies = rawCurrencies
      .map(currency => _prepareCurrency(currency))
      .filter(currency => currency !== null);
  }

  return currencies;
};

export const getDefaultCurrency = preparedCurrencies =>
  preparedCurrencies.find(currency => currency.isDefault) || null;

export const getAvailableCurrencies = (preparedCurrencies, supportedCurrenciesIds) => {
  let currencies = [];
  getAvailableCurrencies.preloadedImages = {};

  if (
    preparedCurrencies.length &&
    supportedCurrenciesIds &&
    Array.isArray(supportedCurrenciesIds) &&
    supportedCurrenciesIds.length
  ) {
    for (const currencyId of supportedCurrenciesIds) {
      const currency = preparedCurrencies.find(item => item.id === currencyId);

      if (currency) {
        /** images were preloaded */
        const img = new Image();
        img.src = currency.flagImg;
        getAvailableCurrencies.preloadedImages[currency.code] = img;

        currencies.push(currency);
      }
    }
  }

  return currencies;
};

export const getCurrencyById = (companyState, currencyId) =>
  companyState.currencies.length
    ? companyState.currencies.find(item => item.id === currencyId) || companyState.defaultCurrency
    : companyState.defaultCurrency;

export const getUnsupportedCurrencyByCode = code =>
  _prepareCurrency({
    code,
    symbol: code
  });
