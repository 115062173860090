import React from 'react';

import editPencil from '../../../../assets/images/edit-pen.svg';

import './index.scss';

const SimpleBox = ({ value, readOnly, onBoxClick }) => (
  <div
    className={`simple-box ${readOnly ? '' : 'clickable'}`}
    onClick={event => (readOnly ? null : onBoxClick())}
  >
    <span>{value}</span>
    {readOnly ? null : <img alt="" src={editPencil} />}
  </div>
);

export default SimpleBox;
