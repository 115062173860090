import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './index.scss';

function PendingEmail({ email, onResend, onReset, isButtonsDisabled, ...rest }) {
  const { t } = useTranslation('translation');

  return (
    <div className="pending-email" {...rest}>
      <span className="title">{t('pendingEmail.awaitingUserConfirmation')}</span>
      <br />
      <span className="description">{t('pendingEmail.confirmationEmailSentTo', { email })}</span>
      <div className="footer">
        <button onClick={onResend} disabled={isButtonsDisabled}>
          {t('pendingEmail.resendEmail')}
        </button>
        <button onClick={onReset} disabled={isButtonsDisabled}>
          {t('pendingEmail.cancel')}
        </button>
      </div>
    </div>
  );
}

PendingEmail.propsType = {
  email: PropTypes.string,
  onResend: PropTypes.func,
  onReset: PropTypes.func
};

export default memo(PendingEmail);
