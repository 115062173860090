import _ from 'lodash';

import { depositsService } from '../services';

import { downloadFromLink } from '../utils/download-from-link';

import { depositsConstants } from '../constants/deposits.constants';
import { CANCEL_TOKEN } from '../constants/axios.constants';

export const depositsActions = {
  getDetails,
  getTransactionsByPage,
  getPaymentStatuses,
  getPaymentInfo,
  getTransactionsForDownload,
  downloadPaymentFile,
  updateTransactions
};

function getPaymentInfo({ paymentId }) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return depositsService
        .getPaymentInfo({ paymentId })
        .then(response => {
          if (response.status !== 200) return reject();
          return resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getDetails() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return depositsService
        .getDetails()
        .then(response => {
          if (response.data?.status.is_success) {
            dispatch({
              type: depositsConstants.SET_DEPOSITS_DETAILS,
              data: {
                details: response.data.data.deposit_details,
                isEnabled: response.data.data.is_enabled
              }
            });
            resolve();
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

const _setDepositsFailure = (reject, page, dispatch, error) => {
  dispatch({
    type: depositsConstants.SET_DEPOSITS_FAILURE,
    currentPage: page
  });

  reject(error);
};

function getTransactionsByPage({ setNewTransactionsList, page = 1 }, cancelToken) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return depositsService
        .getTransactions({ page }, cancelToken)
        .then(async response => {
          if (response?.__CANCEL__) return reject(CANCEL_TOKEN);
          if (response.data?.status?.is_success) {
            const totalPages = _.get(response, 'data.paging.total_pages');
            const currentPage = _.get(response, 'data.paging.current_page');
            const transactions = response.data.data;

            dispatch({
              type: depositsConstants.SET_DEPOSITS_TRANSACTIONS,
              transactions,
              setNewTransactionsList,
              totalPages,
              currentPage
            });

            resolve();
          } else _setDepositsFailure(reject, page, dispatch);
        })
        .catch(e => _setDepositsFailure(reject, page, dispatch, e));
    });
  };
}

function getTransactionsForDownload() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return depositsService
        .downloadWalletTransactions()
        .then(async response => {
          const status = _.get(response, 'status', 400);
          if (status !== 200 && !response.data?.status) return reject();
          let data = _.get(response, 'data', []);
          return resolve(data.url);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function downloadPaymentFile({ paymentId }) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return depositsService
        .getPaymentFile({ paymentId })
        .then(async response => {
          const status = _.get(response, 'status', 400);
          if (status !== 200 && !response.data?.status) return reject();
          let data = _.get(response, 'data', []);
          if (!data?.status?.is_success) return reject();
          if (data?.file_name) {
            downloadFromLink({ url: data.file_name });
            return resolve();
          } else return reject();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getPaymentStatuses(setNewTransactionsList, cancelToken) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return depositsService
        .getPaymentStatuses(cancelToken)
        .then(response => {
          if (
            response &&
            response.data &&
            response.data.status &&
            response.data.status.is_success
          ) {
            dispatch({
              type: depositsConstants.SET_DEPOSITS_PAYMENT_STATUSES,
              statuses: response.data.payment_states,
              setNewTransactionsList
            });
            resolve();
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function updateTransactions(transactions) {
  return dispatch => {
    dispatch({
      type: depositsConstants.UPDATE_TRANSACTIONS,
      transactions
    });
  };
}
