import { CARD_STATUSES, CARD_TYPES_IDS } from '../constants/cards.constants';

import pCardIcon from '../assets/images/cards/physical-card-icon.svg';
import vCardIcon from '../assets/images/cards/virtual-card-icon.svg';
import { getAvailableLimitByUserType, getLowerLimitByUserType } from './limits-helper';
import { cardsActions } from '../actions/cards.action';

export const isPhysicalCard = type => type === CARD_TYPES_IDS.PHYSICAL;

export const getCardStatusLabel = (status, t) => {
  const CARD_STATUS_TO_LABEL_MAP = {
    [CARD_STATUSES.ACTIVE]: t('card.statuses.active'),
    [CARD_STATUSES.SUSPENDED]: t('card.statuses.suspended'),
    [CARD_STATUSES.LOST]: t('card.statuses.lost'),
    [CARD_STATUSES.STOLEN]: t('card.statuses.stolen'),
    [CARD_STATUSES.EXPIRED]: t('card.statuses.expired'),
    [CARD_STATUSES.CANCELED]: t('card.statuses.canceled')
  };

  return CARD_STATUS_TO_LABEL_MAP[status] || '';
};

export const getCardTypeLabel = (type, t) => {
  const CARD_TYPE_TO_LABEL_MAP = {
    [CARD_TYPES_IDS.PHYSICAL]: t('card.types.physical'),
    [CARD_TYPES_IDS.VIRTUAL]: t('card.types.virtual')
  };

  return CARD_TYPE_TO_LABEL_MAP[type] || '';
};

export const getCardTypeIcon = type => (type === CARD_TYPES_IDS.PHYSICAL ? pCardIcon : vCardIcon);

export const getCardAvailableLimit = (card, profile) => {
  const { available, userType } = profile;
  const companyAvailable = available ? available : 0;
  return getAvailableLimitByUserType(null, card.available_limit, companyAvailable, userType);
};

export const getCardLowerLimit = (card, profile) => {
  const { available, userType } = profile;
  const companyAvailable = available ? available : 0;
  return getLowerLimitByUserType(
    null,
    card.available_limit,
    card.spent,
    companyAvailable,
    userType
  );
};
