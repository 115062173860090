import { useTranslation } from 'react-i18next';

import UploadingProgressBar from '../uploading-progress-bar';

import { ReactComponent as CrossIcon } from '../../../assets/images/cross-light.svg';
import { ReactComponent as FileIcon } from '../../../assets/images/file.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/images/file-upload-success.svg';
import { ReactComponent as WarningIcon } from '../../../assets/images/file-upload-failure.svg';

import { useFileUploader } from '../../../hooks/index';

import './index.scss';

export const ChosenFile = ({ file: { fileName, id, error }, handleFileDelete }) => {
  const { t } = useTranslation();

  const handleCloseClick = () => handleFileDelete(id);

  return (
    <div className="file-uploader__file" title={fileName}>
      <div className="file-uploader__icon">{error ? <WarningIcon /> : <SuccessIcon />}</div>

      <div className="file-uploader__header">
        <span className="file-uploader__name">{fileName}</span>

        {error && (
          <span className="file-uploader__error">{t('uploader.error.upload', { fileName })}</span>
        )}
      </div>

      <div className="file-uploader__actions">
        <CrossIcon className="file-uploader__close-icon" onClick={handleCloseClick} />
      </div>
    </div>
  );
};

const FileUploader = ({ file, uploadApiEndpoint, onFileDelete, onUploadFinish }) => {
  const { t } = useTranslation();

  const { upload, progress, isUploadError } = useFileUploader({
    file,
    uploadApiEndpoint,
    onUploadFinish
  });

  const handleCloseClick = () => onFileDelete?.(file.id);

  return (
    <div className="file-uploader__file" title={file.fileName}>
      <div className="file-uploader__icon">
        {!file.isUploaded && !isUploadError && <UploadingProgressBar progress={progress} />}
        {isUploadError ? <WarningIcon /> : file.isUploaded ? <SuccessIcon /> : <FileIcon />}
      </div>

      <div className="file-uploader__header">
        <span className="file-uploader__name">{file.fileName}</span>

        {isUploadError && (
          <span className="file-uploader__error">
            {t('uploader.error.upload', { fileName: file.fileName })}
          </span>
        )}
      </div>

      <div className="file-uploader__actions">
        <CrossIcon className="file-uploader__close-icon" onClick={handleCloseClick} />
      </div>
    </div>
  );
};

export default FileUploader;
