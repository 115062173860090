import axios from 'axios';

import { SAT_URL, API_URL } from '../constants/common.constants';
import { handleRequestError } from './handlers/error-handlers';
import { _DEFAULT_CONFIG } from './config';

export const invoicesService = {
  getUnpaidInvoices,
  downloadInvoice,
  getInvoiceBeneficiary
};

async function getUnpaidInvoices({ companyId, page }) {
  //TODO mock params
  const params = {
    CompanyId: companyId,
    Page: page ? page : 1
  };

  return axios
    .get(`${SAT_URL}/sat/get-invoices?applicationId=2`, {
      ...(await _DEFAULT_CONFIG()),
      params
    })
    .catch(handleRequestError);
}

async function downloadInvoice({ invoiceId }) {
  const params = {
    invoice_id: invoiceId,
    download_options: 1
  };

  return axios
    .get(`${SAT_URL}/sat/get-invoice-file?applicationId=2`, {
      ...(await _DEFAULT_CONFIG()),
      params
    })
    .catch(handleRequestError);
}

let cancelGetInvoices;
async function getInvoiceBeneficiary({ amount, beneficiaryId }, cancelToken) {
  cancelGetInvoices?.cancelToken();
  cancelGetInvoices = cancelToken;

  const params = {
    amount,
    beneficiary_id: beneficiaryId || null
  };

  return axios
    .get(`${API_URL}/api/tribalwallet/invoice-beneficiary`, {
      ...(await _DEFAULT_CONFIG(cancelToken)),
      params
    })
    .catch(handleRequestError);
}
