import Axios, { CancelToken } from 'axios';

export const getCancelToken = source => {
  const cancelToken = new CancelToken.source(source);
  const token = cancelToken.token;
  const handleCancel = () => cancelToken.cancel('Canceled by user');
  const handleCancelError = error => {
    if (Axios.isCancel(error)) {
      return true;
    }
  };

  return {
    token,
    handleCancel,
    handleCancelError
  };
};

export function successCallback(response) {
  return response.data;
}
