import { recipientInputTypes } from '../constants/send-emails.constants';

const { RECURRING } = recipientInputTypes;

export const getTagEmails = ({ emails }) =>
  emails.map((email, idx) => ({
    id: idx,
    label: email,
    valid: true,
    selectType: RECURRING
  }));

export const areTagsInvalid = (tags, optional) =>
  (optional ? false : !tags.length) || tags.some(t => !t.valid);
