import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';

import edit from './../../../assets/images/edit-pen.svg';

import './index.scss';

function LabelWrapper({
  label,
  isSelected,
  isReadOnly,
  children,
  onSelection,
  style
}) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      {isSelected ? (
        <div>{children}</div>
      ) : (
        <div
          className={
            'label-wrapper ' + (isHovered && !isReadOnly ? 'hovered' : '')
          }
          style={{ ...style }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => {
            if (!isReadOnly) {
              setIsHovered(false);
              onSelection(true);
            }
          }}
        >
          <span>{label}</span>
          {isHovered && !isReadOnly ? <img alt="" src={edit} /> : null}
        </div>
      )}
    </>
  );
}

LabelWrapper.propsType = {
  label: PropTypes.string,
  isSelected: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  children: PropTypes.element,
  onSelection: PropTypes.func,
  style: PropTypes.object
};

export default memo(LabelWrapper);
