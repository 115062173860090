import { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { amplitudeInstance } from '../../../analytics/amplitude';

import Content from './content';
import Header from './header';

import { useClickOutsideElement } from '../../../hooks';

import { PAYMENT_STEPS } from '../../../containers/send-payment/constants';
import { dropdownSearchConstants } from '../../../constants/dropdown-search.constants';

import './index.scss';

const {
  TYPE: { NEW_RECIPIENT, SELECT_PAYMENT_TYPE }
} = dropdownSearchConstants;

const { PAYMENT_SETTINGS } = PAYMENT_STEPS;

const DropdownSearch = ({
  selectedItem,
  items,
  isActiveByDefault,
  listTitle,
  isLoading,
  isDisabled,
  isCreateNewItemDisabled,
  onItemSelection,
  onItemCreation,
  onClickOutside,
  contentType,
  onHeaderKeyDown,
  withChevron,
  placeholder,
  setVisibleContent,
  withFullMatchMark,
  clearFieldDependencies = [],
  setSettingsError
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(isActiveByDefault);
  const [isNewItemVisible, setIsNewItemVisible] = useState(false);
  const [enteredText, setEnteredText] = useState('');
  const [isCreateNewItemEnabled, setIsNewItemEnabled] = useState(true);
  const dropdownRef = useRef();

  const formattedEnteredText = enteredText.trim();

  useEffect(() => {
    selectedItem && setEnteredText(selectedItem.label);
  }, [selectedItem, ...clearFieldDependencies]);

  useEffect(() => {
    if (clearFieldDependencies.length) setEnteredText('');
  }, [...clearFieldDependencies]);

  const getSelectedItem = () =>
    items.find(i => i.label.toLowerCase() === formattedEnteredText.toLowerCase());

  const handleNewRecipientButtonState = () => {
    if (contentType === SELECT_PAYMENT_TYPE) return;

    setIsNewItemVisible(true);
    const isCreateNewItemEnabled = !getSelectedItem() && formattedEnteredText !== '';
    setIsNewItemEnabled(isCreateNewItemEnabled);
  };

  useEffect(() => {
    handleNewRecipientButtonState();
  }, [enteredText, items, isDropdownVisible]);

  const resetToDefaultState = () => {
    setIsDropdownVisible(false);

    contentType === NEW_RECIPIENT &&
      selectedItem?.label !== enteredText &&
      setIsNewItemVisible(true);
  };

  const handleClickOutside = () => {
    if (isDropdownVisible) {
      resetToDefaultState();
      onClickOutside();
    }
  };

  useClickOutsideElement(dropdownRef, handleClickOutside);

  const handleItemCreation = () => {
    if (!isCreateNewItemEnabled) return;

    amplitudeInstance.logEvent('Create_New_Recipient_Button_Clicked');

    onItemCreation(formattedEnteredText);

    resetToDefaultState();
  };

  const handleItemSelection = selectedItemId => {
    onItemSelection(selectedItemId);
    resetToDefaultState();
  };

  const handleInputKeyPress = event => {
    if (event.key === 'Enter') {
      if (isNewItemVisible) handleItemCreation();
      else {
        const selectedItem = getSelectedItem();
        handleItemSelection(selectedItem ? selectedItem.id : null);
      }
    }
  };

  const handleDropdownHeaderClick = () =>
    !isDropdownVisible && !isDisabled && setIsDropdownVisible(true);

  return (
    <div ref={dropdownRef} className={classNames('dropdown-search', { disabled: isDisabled })}>
      <Header
        isDropdownVisible={isDropdownVisible}
        isDisabled={isCreateNewItemDisabled}
        selectedItemLabel={selectedItem ? selectedItem.label : ''}
        onHeaderClick={handleDropdownHeaderClick}
        onInputChange={event => {
          setSettingsError(null);
          setEnteredText(event.target.value);
          setVisibleContent && setVisibleContent([PAYMENT_SETTINGS]);
          onItemSelection(null);
        }}
        onInputKeyPress={handleInputKeyPress}
        onKeyDown={onHeaderKeyDown}
        enteredText={enteredText}
        withChevron={withChevron}
        placeholder={placeholder}
        withFullMatchMark={withFullMatchMark}
      />
      {isDropdownVisible ? (
        <Content
          items={items}
          contentType={contentType}
          isNewItemVisible={isNewItemVisible && !isCreateNewItemDisabled}
          isCreateNewItemEnabled={isCreateNewItemEnabled}
          enteredText={formattedEnteredText}
          listTitle={listTitle}
          onItemSelection={handleItemSelection}
          onItemCreation={handleItemCreation}
          isLoading={isLoading}
          withFullMatchMark={withFullMatchMark}
          selectedItemLabel={selectedItem ? selectedItem.label : ''}
        />
      ) : null}
    </div>
  );
};

export default DropdownSearch;

DropdownSearch.propsType = {
  selectedItem: PropTypes.object,
  items: PropTypes.array,
  listTitle: PropTypes.string,
  isActiveByDefault: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isCreateNewItemDisabled: PropTypes.bool,
  onItemSelection: PropTypes.func,
  onItemCreation: PropTypes.func,
  onClickOutside: PropTypes.func,
  contentType: PropTypes.string,
  onHeaderKeyDown: PropTypes.func
};

DropdownSearch.defaultProps = {
  selectedItem: null,
  items: [],
  listTitle: '',
  isActiveByDefault: false,
  isLoading: false,
  isDisabled: false,
  isCreateNewItemDisabled: false,
  onItemSelection: () => {},
  onItemCreation: () => {},
  onClickOutside: () => {},
  contentType: null,
  onHeaderKeyDown: () => {}
};
