import axios from 'axios';

import { API_URL } from './../constants/common.constants';
import { handleRequestError } from './handlers/error-handlers';
import { getDeviceId } from '../utils/auth-client';
import { _DEFAULT_CONFIG } from './config';

export const companyService = {
  getCompanyInfo,
  getBillingPeriods,
  getCurrencies,
  getDepartments,
  createDepartment,
  getSuperAdmins,
  getAdmins,
  getBillingContacts,
  addBillingContact,
  deleteBillingContact
};

async function getCompanyInfo(billingId) {
  const deviceId = await getDeviceId();
  let params = {
    device_id: deviceId
  };
  if (billingId) params['billing_id'] = billingId;
  return axios
    .get(`${API_URL}/api/company/get-company-info`, {
      ...(await _DEFAULT_CONFIG()),
      params
    })
    .catch(handleRequestError);
}

async function getBillingPeriods() {
  const config = await _DEFAULT_CONFIG();
  return axios
    .get(`${API_URL}/api/billing/company-billing`, config)
    .catch(error => handleRequestError(error));
}

async function getCurrencies() {
  const deviceId = await getDeviceId();
  let params = {
    device_id: deviceId
  };

  return axios
    .get(`${API_URL}/api/company/get-currencies`, {
      ...(await _DEFAULT_CONFIG()),
      params
    })
    .catch(handleRequestError);
}

async function getDepartments() {
  const deviceId = await getDeviceId();
  return axios
    .get(`${API_URL}/api/departments/departments`, {
      ...(await _DEFAULT_CONFIG()),
      params: { device_id: deviceId }
    })
    .catch(handleRequestError);
}

async function createDepartment(name) {
  const deviceId = await getDeviceId();
  const config = await _DEFAULT_CONFIG();

  return axios
    .post(`${API_URL}/api/departments/create?name=${name}&device_id=${deviceId}`, null, config)
    .then(response => response.data)
    .catch(handleRequestError);
}

async function getSuperAdmins() {
  const deviceId = await getDeviceId();

  return axios
    .get(`${API_URL}/api/settings/super-admins`, {
      ...(await _DEFAULT_CONFIG()),
      params: { device_id: deviceId }
    })
    .catch(handleRequestError);
}

async function getAdmins() {
  const deviceId = await getDeviceId();
  return axios
    .get(`${API_URL}/api/settings/admins`, {
      ...(await _DEFAULT_CONFIG()),
      params: { device_id: deviceId }
    })
    .catch(handleRequestError);
}

async function getBillingContacts() {
  const deviceId = await getDeviceId();
  return axios
    .get(`${API_URL}/api/settings/billing-contacts`, {
      ...(await _DEFAULT_CONFIG()),
      params: { device_id: deviceId }
    })
    .catch(handleRequestError);
}

async function addBillingContact(email) {
  const deviceId = await getDeviceId();
  const config = await _DEFAULT_CONFIG();

  const data = {
    email,
    device_id: deviceId
  };

  return axios
    .post(`${API_URL}/api/settings/add-billing-contact`, data, config)
    .then(response => response.data)
    .catch(handleRequestError);
}

async function deleteBillingContact(id) {
  const deviceId = await getDeviceId();
  const config = await _DEFAULT_CONFIG();

  return axios
    .delete(
      `${API_URL}/api/settings/remove-billing-contact?contact_id=${id}&device_id=${deviceId}`,
      config
    )
    .then(response => response.data)
    .catch(handleRequestError);
}
