export const dropdownSearchConstants = {
  TYPE: {
    NEW_RECIPIENT: 'new-recipient',
    SELECT_PAYMENT_TYPE: 'SELECT_PAYMENT_TYPE'
  },
  PAYMENT_TYPE: {
    SINGLE: 'SINGLE',
    BULK: 'BULK'
  }
};
