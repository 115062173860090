import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreditLineIncreaseContext } from '../../../context';

import { Step, StepLine } from './internal';

import './index.scss';

const Stepper = () => {
  const { t } = useTranslation('credit-line-increase');
  const [lineWidth, setLineWidth] = useState();
  const ref = useRef();
  const { steps } = useContext(CreditLineIncreaseContext);

  useEffect(() => {
    const stepsCount = steps.length;
    if (stepsCount > 1) {
      const stepperContainerWidth = ref.current?.offsetWidth;
      const stepIconWidth = 24;
      const totalStepsWidth = stepIconWidth * stepsCount;
      const totalLinesWidth = stepperContainerWidth - totalStepsWidth;
      const singleLineWidth = totalLinesWidth / (stepsCount - 1);

      setLineWidth(singleLineWidth);
    }
  }, [ref.current]);

  return (
    <div className="stepper-wrapper">
      <div className="stepper-container" ref={ref}>
        {steps.map(({ status, label }, idx) => {
          const isLastStep = idx + 1 === steps.length;

          return (
            <Fragment key={idx}>
              <Step status={status} label={t(label)} />
              {isLastStep ? null : <StepLine width={lineWidth} status={status} />}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Stepper;
