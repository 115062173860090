import Hotjar from '@hotjar/browser';

const connectHotjar = () => {
  const siteId = 3071368;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);
};

const identifyHotjarEvent = (userId, userInfo) => {
  Hotjar.identify(userId, userInfo);
}

export { connectHotjar, identifyHotjarEvent };
