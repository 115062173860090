import { useEffect, useState } from 'react';
import { isKeyExistsInItem } from './utils';

export const useModalSearch = ({ dropdownData }) => {
  const [enteredText, setEnteredText] = useState('');
  const [data, setData] = useState(dropdownData);

  useEffect(() => {
    if (!enteredText) {
      setData(dropdownData);
    } else {
      const newList = dropdownData.filter(item => isKeyExistsInItem({ enteredText, item }));
      setData(newList);
    }
  }, [enteredText]);

  return { enteredText, setEnteredText, data };
};
