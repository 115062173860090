import { isAuthorized } from './auth-client'


export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const getToken = () => {
  if (isAuthorized()) {
    return parseJwt(sessionStorage.getItem('token'));
  }
  return null
}