import { Component, Fragment, createRef } from 'react';

import { getNumbersFromString } from '../../../utils/string-service';

import './index.scss';

class TwoFactorInput extends Component {
  state = {
    refs: []
  };

  async componentDidMount() {
    await this.initRefs();
    this.state.refs[0]?.current.focus();
  }

  initRefs = () => {
    let refs = [];
    for (let i = 0; i < this.props.codeLength; i++) {
      refs.push(createRef());
    }
    this.setState({ refs });
  };

  handleCodeInput(index, value) {
    const codeLength = this.props.codeLength;

    if (value.length < codeLength) {
      const lastIndex = codeLength - 1;
      this.props.onCodeChange(assembleCode(this.props.code, index, value));
      if (value && index !== lastIndex) {
        //focus the next cell but not the last one
        this.state.refs[index + 1].current.focus();
      }
    } else {
      this.state.refs[index].current.blur();
      // cut the code if it was pasted into the filled cell
      const newCode = value.length === codeLength ? value : value.slice(value.length - codeLength);
      this.props.onCodeChange(newCode.split(''));
    }
  }

  render() {
    return (
      <div className="two-factor-input--container">
        {this.props.code.map((codeSymbol, index) => (
          <Fragment key={index}>
            <input
              className="two-factor-input"
              ref={this.state.refs[index]}
              value={codeSymbol}
              type="text"
              onChange={event =>
                this.handleCodeInput(index, getNumbersFromString(event.target.value))
              }
            />
            {index === Math.floor((this.props.codeLength - 1) / 2) && (
              <div className="two-factor-input--separator" />
            )}
          </Fragment>
        ))}
      </div>
    );
  }
}

function assembleCode(code, cellIndex, value) {
  code[cellIndex] = value.slice(-1);
  return code;
}

export default TwoFactorInput;
