import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ReactComponent as CreditCardHeaderIcon } from './../../../assets/images/wallet-overlay-icon.svg';

import './index.scss';

const WalletOverlay = ({ enabled = false, disabledText, forwardEmail = '' }) => {
  const { t } = useTranslation();

  return (
    <div className="wallet-overlay">
      <div className="wallet-overlay__header">
        <CreditCardHeaderIcon />
      </div>
      <ul className={classNames('wallet-overlay__list-options', { disabled: !enabled })}>
        {enabled ? (
          <>
            <li>{t('cardDetails.preview.wallet.tooltip.line_1')}</li>
            <li>{t('cardDetails.preview.wallet.tooltip.line_2')}</li>
            <li>
              <div className="wallet-emails">
                <div>
                  <span>{t('cardDetails.preview.wallet.tooltip.line_3_1')}</span>
                  <span className="wallet-emails__title">{forwardEmail}</span>
                </div>
              </div>
            </li>
            <li>{t('cardDetails.preview.wallet.tooltip.line_4')}</li>
          </>
        ) : (
          <>
            <li>{disabledText}</li>
          </>
        )}
      </ul>
    </div>
  );
};

export default memo(WalletOverlay);
