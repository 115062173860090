import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const Button = ({ children, className, onClick, ...rest }) => (
  <button className={className} onClick={onClick} {...rest}>
    {children}
  </button>
);

Button.propsType = {
  children: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

Button.defaultProps = {
  children: '',
  className: 'button',
  onClick: () => {}
};

export default Button;
