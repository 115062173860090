import { useState } from 'react';

export const useAcceptSection = () => {
  /**
   * To switch for checkbox version turn isAgreementCheckboxChecked to false
   */
  const [isAgreementCheckboxChecked, setAgreementCheckboxStatus] = useState(
    true
  );

  const onAgreementCheckboxCheck = () =>
    setAgreementCheckboxStatus(!isAgreementCheckboxChecked);

  return { onAgreementCheckboxCheck, isAgreementCheckboxChecked };
};
