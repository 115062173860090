import { BANK_COUNTRIES_CONSTANTS } from '../../../../constants/bank-countries.constants';

export const getIsRecipientValid = recipient =>
  !!Object.values(recipient).filter(v => v === null || v.trim() === '').length;

export const getAccountNumberKey = (countryName, basePath = 'bankDetails.acctnumber') => {
  const getKey = key => `${basePath}.${key}`;

  // prettier-ignore
  return ({
      [BANK_COUNTRIES_CONSTANTS.PERU]: getKey('PE')
    }[countryName] || getKey('common'));
};
