import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Dropdown } from './internal';
import { ReactComponent as Chevron } from '../../../assets/images/chevron.svg';

import './index.scss';

const DropdownButton = ({
  label,
  isActive,
  dropdownData,
  handleClick,
  handleTypeChange,
  selectedType,
  handleDropdownClose,
  disabled
}) => (
  <div
    className={classNames('dropdown-button', { active: isActive, disabled })}
    onClick={handleClick}
  >
    <span className="dropdown-button__label">{label}</span>
    {disabled ? null : <Chevron className="dropdown-button__icon" />}
    {isActive ? (
      <Dropdown
        data={dropdownData}
        handleClick={handleTypeChange}
        selectedType={selectedType}
        handleDropdownClose={handleDropdownClose}
      />
    ) : null}
  </div>
);

export default DropdownButton;

DropdownButton.propTypes = {
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  dropdownData: PropTypes.array.isRequired,
  handleTypeChange: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  handleDropdownClose: PropTypes.func.isRequired
};

DropdownButton.defaultProps = {
  isActive: false
};
