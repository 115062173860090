import { MERCHANT_CATEGORIES_DATA } from './data/user';

import { userConstants } from '../constants/user.constants';
import { authConstants } from '../constants/auth.constants';
import { cardsConstants } from '../constants/cards.constants';
import { walletConstants } from '../constants/wallet.constants';
import { transformCardStatusFromServer } from '../constants/map.constants';

import { getSelectedUserAfterPendingEmailReset, prepareSelectedUser } from './helpers/user';

const { WALLET_AGREEMENT_STATUS } = walletConstants;

const INITIAL_STATE = {
  user: null,
  selectedUser: null,
  topMerchants: null,
  topItems: null,
  users: [],
  trustedDevices: [],
  languages: null,
  selectedLangId: null,
  totalPages: 0,
  currentPage: 0,
  walletAgreement: {
    isNewUser: false,
    status: WALLET_AGREEMENT_STATUS.PENDING
  },
  merchantCategories: MERCHANT_CATEGORIES_DATA
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'TEMP_SET_COMPANY_INFO_TO_USER':
      //todo: delete this set_company later
      // todo: after refactoring components
      // todo: to use company reducer instead
      return {
        ...state,
        user: state.user ? { ...state.user, company: action.company } : { company: action.company }
      };

    case authConstants.LOGIN_USER:
      // todo: refactor set user after refactoring
      // todo: components to use company reducer instead
      let langId = state.selectedLangId;

      if (state.languages) {
        const currentLang = state.languages.find(lang => lang.id === action.user.user_language);
        if (currentLang) langId = currentLang.id;
      }

      return {
        ...state,
        user: state.user
          ? {
              ...action.user,
              company: state.user.company
            }
          : action.user,

        selectedLangId: langId
      };
    case userConstants.CANCEL_USER_ACTIVATION:
      const users = state.users.filter(user => user.id !== action.userId);
      return { ...state, ...{ users } };
    case userConstants.UPDATE_USER_PROFILE_FIELD:
      const updatedUser = Object.assign({}, state.user);
      const newPendingData = Object.assign({}, state.user.pending_contacts);
      switch (action.flag) {
        case userConstants.USER_PROFILE_FIELD_FLAGS.FIRST_NAME:
          updatedUser.first_name = action.value;
          break;
        case userConstants.USER_PROFILE_FIELD_FLAGS.LAST_NAME:
          updatedUser.last_name = action.value;
          break;
        case userConstants.USER_PROFILE_FIELD_FLAGS.SECOND_LAST_NAME:
          updatedUser.second_last_name = action.value;
          break;
        case userConstants.USER_PROFILE_FIELD_FLAGS.EMAIL:
          newPendingData.pending_email = {
            email: action.value
          };
          break;
        case userConstants.USER_PROFILE_FIELD_FLAGS.MOBILE:
          newPendingData.pending_phone_number = {
            code: action.value.code,
            code_id: action.value.codeId,
            number: action.value.number
          };
          break;
        case userConstants.USER_PROFILE_FIELD_FLAGS.TITLE:
          updatedUser.title = action.value;
          break;
        case userConstants.USER_PROFILE_FIELD_FLAGS.DEPARTMENT:
          updatedUser.department_id = action.value.id;
          updatedUser.department = action.value.label;
          break;
        case userConstants.USER_PROFILE_FIELD_FLAGS.WALLET:
          updatedUser.enable_wallets = !updatedUser.enable_wallets;
          break;
        case userConstants.USER_PROFILE_FIELD_FLAGS.PHYS_CARDS:
          updatedUser.enablePhysicalCard = !updatedUser.enablePhysicalCard;
          break;
      }
      updatedUser.pending_contacts = Object.assign({}, newPendingData);
      return { ...state, user: updatedUser };
    case userConstants.VERIFY_USER_MOBILE:
      const verifiedUser = { ...state.user };
      verifiedUser.mobile_code = action.pendingCode;
      verifiedUser.mobile_number = action.pendingNumber;
      verifiedUser.pending_contacts.pending_phone_number = null;
      return { ...state, user: verifiedUser };
    case userConstants.SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: prepareSelectedUser(
          action.user.hasOwnProperty('physCard') ? state.selectedUser : action.user,
          action.user?.physCard
        )
      };
    case userConstants.SET_LANGUAGES:
      let newLangId = state.selectedLangId;
      if (state.user) {
        const currentLang = action.languages.find(lang => lang.id === state.user.user_language);
        if (currentLang) newLangId = currentLang.id;
      }
      return {
        ...state,
        languages: action.languages,
        selectedLangId: newLangId
      };
    case userConstants.SET_SELECTED_LANGUAGE:
      return { ...state, selectedLangId: action.langId };
    case userConstants.RESET_SELECTED_USER:
      return { ...state, selectedUser: null };
    case userConstants.SET_TOP_MERCHANTS:
      return {
        ...state,
        topMerchants: action.merchants ? action.merchants.merchant_data : null
      };
    case userConstants.SET_TOP_ITEMS:
      return { ...state, topItems: action.items };
    case userConstants.SET_TRUSTED_DEVICES:
      return { ...state, trustedDevices: action.devices || [] };
    case userConstants.REMOVE_TRUSTED_DEVICE:
      const devices = state.trustedDevices.filter(device => device.device_id !== action.targetId);
      return { ...state, trustedDevices: devices };
    case userConstants.REMOVE_TRUSTED_DEVICES:
      return { ...state, trustedDevices: [] };

    case userConstants.SET_USERS:
      const { setNewUsersList, totalPages, currentPage } = action;
      action.users.forEach(user => {
        user['expanded'] = false;
        user.user_cards.cards.forEach(card => {
          card.status_id = transformCardStatusFromServer(card.status_id);
        });
      });

      const usersList = setNewUsersList ? action.users : [...state.users, ...action.users];
      return { ...state, users: usersList, totalPages, currentPage };

    case cardsConstants.UPDATE_USER_CARD_LIMIT: {
      const users = [...state.users];
      let userInfo = users.find(user => user.id === action.userId);
      let cardInfo = userInfo.user_cards.cards.find(c => c.id === action.cardId);
      cardInfo.limit = action.userCard.limit;
      cardInfo.available_limit = action.userCard.available_limit;

      return { ...state, ...{ users } };
    }
    case cardsConstants.RESET_USER_CARD_LIMIT: {
      const users = [...state.users];
      let userInfo = users.find(user => user.id === action.userId);
      let cardInfo = userInfo.user_cards.cards.find(c => c.id === action.cardId);
      cardInfo.limit = action.prevLimit;
      return { ...state, ...{ users } };
    }
    case userConstants.UPDATE_USER_CARD_STATUS: {
      const users = [...state.users];
      let userInfo = users.find(user => user.id === action.userId);
      let cardInfo = userInfo.user_cards.cards.find(c => c.id === action.cardId);
      cardInfo.status_id = action.statusId;
      return { ...state, ...{ users } };
    }
    case userConstants.UPDATE_USER_LIMIT: {
      if (action.userId) {
        const users = [...state.users];
        let user = users.find(u => u.id === action.userId);
        user.limit = action.limit;
        user.avaliable_limit = user.limit - user.spent;
        return { ...state, ...{ users } };
      } else {
        const user = { ...state.user };
        user.limit = action.limit;
        return { ...state, ...{ user } };
      }
    }
    case userConstants.RESET_USER_LIMIT: {
      const users = [...state.users];
      let userInfo = users.find(u => u.id === action.userId);
      userInfo.limit = action.prevLimit;
      return { ...state, ...{ users } };
    }
    case userConstants.UPDATE_USER_STATUS: {
      const users = [...state.users];
      let user = users.find(u => u.id === action.id);
      user.status_id = action.statusId;
      return { ...state, ...{ users } };
    }
    case userConstants.RESET_USER_EMAIL:
      return {
        ...state,
        user: {
          ...state.user,
          pending_contacts: {
            ...state.user.pending_contacts,
            pending_email: null
          }
        }
      };
    case userConstants.RESET_SELECTED_USER_EMAIL:
      return {
        ...state,
        selectedUser: getSelectedUserAfterPendingEmailReset(state.selectedUser)
      };
    case userConstants.RESEND_USER_MOBILE:
      return {
        ...state,
        user: {
          ...state.user,
          pending_contacts: {
            ...state.user.pending_contacts,
            pending_phone_number: {
              code_id: action.codeId,
              code: action.code,
              number: action.number
            }
          }
        }
      };
    case userConstants.RESET_USER_MOBILE:
      return {
        ...state,
        user: {
          ...state.user,
          pending_contacts: {
            ...state.user.pending_contacts,
            pending_phone_number: null
          }
        }
      };
    case userConstants.INCREMENT_USERS_COUNT:
      return {
        ...state,
        user: {
          ...state.user,
          users_count: state.user.users_count + 1
        }
      };
    case cardsConstants.INCREMENT_CARDS_COUNT:
      return {
        ...state,
        user: {
          ...state.user,
          cards_count: state.user.cards_count + 1
        }
      };
    case authConstants.UPDATE_AGREEMENT:
      const pendingActionsArray = state.user.pending_actions;
      return {
        ...state,
        user: {
          ...state.user,
          pending_actions: pendingActionsArray.length
            ? pendingActionsArray.slice(1, pendingActionsArray.length)
            : []
        }
      };
    case userConstants.HIDE_PENDING_NOTIFICATIONS:
      const { id } = action.payload;

      return {
        ...state,
        user: {
          ...state.user,
          pending_notifications: state.user.pending_notifications.filter(
            notification =>
              (notification.type !== 'due_tomorrow' && !notification.id) || notification.id !== id
          )
        }
      };
    case walletConstants.GET_WALLET_AGREEMENT:
      let { is_new: isNewUser, state: status } = action.payload;

      if (!status) status = WALLET_AGREEMENT_STATUS.PENDING;

      return {
        ...state,
        walletAgreement: {
          isNewUser,
          status
        }
      };

    case walletConstants.ACCEPT_PAYMENT_AGREEMENT:
      return {
        ...state,
        walletAgreement: {
          isNewUser: false,
          status: WALLET_AGREEMENT_STATUS.DONE
        }
      };
    case userConstants.UPDATE_IS_PHYSICAL_CARD_ALLOWED:
      const { IsPhysicalCardAllowed } = action;
      return {
        ...state,
        user: { ...state.user, IsPhysicalCardAllowed }
      };

    default:
      return state;
  }
}
