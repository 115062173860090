import axios from 'axios';

import { API_URL } from '../constants/common.constants';

import { handleRequestError } from './handlers/error-handlers';

import { _DEFAULT_CONFIG } from './config';

import { getDeviceId } from '../utils/auth-client';
import { getAuthorizationForRequest } from '../utils/network-util';

export const notificationsService = {
  getNotifications,
  getNotificationsTypes,
  getNotificationSettings,
  archiveNotification,
  unarchiveNotification,
  archiveAllNotifications,
  markAllAsRead,
  updateNotificationVisibility,
  updateNotificationSetting,
  cancelBanner
};

function getNotificationsTypes() {
  let params = {
    device_id: getDeviceId()
  };

  return axios
    .get(`${API_URL}/api/notifications/all-notification-types`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: `bearer ${window.sessionStorage.getItem('token')}`
      },
      params
    })
    .catch(handleRequestError);
}

function getNotifications({ archived = false, sortBy, page, categories }) {
  let params = {
    device_id: window.sessionStorage.getItem('device_id'),
    archive: archived,
    NotificationCategories: categories,
    SortBy: sortBy,
    page
  };

  return axios
    .get(`${API_URL}/api/notifications/notifications`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: getAuthorizationForRequest()
      },
      params
    })
    .catch(handleRequestError);
}

function getNotificationSettings() {
  let params = {
    device_id: window.sessionStorage.getItem('device_id')
  };
  return axios
    .get(`${API_URL}/api/notifications/user-notification-setting`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
        crossDomain: true,
        mode: 'no-cors',
        Authorization: `bearer ${window.sessionStorage.getItem('token')}`
      },
      params
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        window.sessionStorage.clear();
        window.location.href = '/login';
      }
      return error;
    });
}

function archiveNotification(id) {
  const deviceId = window.sessionStorage.getItem('device_id');
  return axios
    .post(
      `${API_URL}/api/notifications/archive-notification?device_id=${deviceId}&notification_id=${id}`,
      {},
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
          crossDomain: true,
          mode: 'no-cors',
          Authorization: `bearer ${window.sessionStorage.getItem('token')}`
        }
      }
    )
    .catch(error => {
      if (error.response && error.response.status === 401) {
        window.sessionStorage.clear();
        window.location.href = '/login';
      }
      return error;
    });
}

function unarchiveNotification(id) {
  const deviceId = window.sessionStorage.getItem('device_id');
  return axios
    .post(
      `${API_URL}/api/notifications/unarchive-notification?device_id=${deviceId}&notification_id=${id}`,
      {},
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
          crossDomain: true,
          mode: 'no-cors',
          Authorization: `bearer ${window.sessionStorage.getItem('token')}`
        }
      }
    )
    .catch(error => {
      if (error.response && error.response.status === 401) {
        window.sessionStorage.clear();
        window.location.href = '/login';
      }
      return error;
    });
}

function archiveAllNotifications() {
  const deviceId = window.sessionStorage.getItem('device_id');
  return axios
    .post(
      `${API_URL}/api/notifications/archive-all?device_id=${deviceId}`,
      {},
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
          crossDomain: true,
          mode: 'no-cors',
          Authorization: `bearer ${window.sessionStorage.getItem('token')}`
        }
      }
    )
    .catch(error => {
      if (error.response && error.response.status === 401) {
        window.sessionStorage.clear();
        window.location.href = '/login';
      }
      return error;
    });
}

function markAllAsRead() {
  const deviceId = window.sessionStorage.getItem('device_id');
  return axios
    .post(
      `${API_URL}/api/notifications/mark-all-as-read?device_id=${deviceId}`,
      {},
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
          crossDomain: true,
          mode: 'no-cors',
          Authorization: `bearer ${window.sessionStorage.getItem('token')}`
        }
      }
    )
    .catch(error => {
      if (error.response && error.response.status === 401) {
        window.sessionStorage.clear();
        window.location.href = '/login';
      }
      return error;
    });
}

function updateNotificationVisibility(id) {
  const deviceId = window.sessionStorage.getItem('device_id');
  return axios
    .put(
      `${API_URL}/api/notifications/update-visibility?device_id=${deviceId}&notification_type_id=${id}`,
      {},
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
          crossDomain: true,
          mode: 'no-cors',
          Authorization: `bearer ${window.sessionStorage.getItem('token')}`
        }
      }
    )
    .catch(error => {
      if (error.response && error.response.status === 401) {
        window.sessionStorage.clear();
        window.location.href = '/login';
      }
      return error;
    });
}

function updateNotificationSetting(id, setting) {
  const deviceId = window.sessionStorage.getItem('device_id');
  return axios
    .put(
      `${API_URL}/api/notifications/update-user-notification-setting?device_id=${deviceId}`,
      JSON.stringify({
        notification_type_id: id,
        new_setting: setting
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          crossDomain: true,
          mode: 'no-cors',
          Authorization: `bearer ${window.sessionStorage.getItem('token')}`
        }
      }
    )
    .catch(error => {
      if (error.response && error.response.status === 401) {
        window.sessionStorage.clear();
        window.location.href = '/login';
      }
      return error;
    });
}

async function cancelBanner(banner_id) {
  const device_id = await getDeviceId();
  let params = {
    device_id,
    banner_id
  };

  return axios
    .put(
      `${API_URL}/api/notifications/hide-banner`,
      {},
      {
        ...(await _DEFAULT_CONFIG()),
        params
      }
    )
    .catch(handleRequestError);
}
