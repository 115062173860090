import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import TwoFactorInput from './../../authorization/two-factor-input';

import { MOBILE_VERIFICATION_CODE_LENGTH } from '../../../constants/auth.constants';

import './index.scss';

function PendingMobile({
  mobile,
  isReadOnly,
  onCodeChange,
  onResend,
  onReset,
  isButtonsDisabled,
  ...rest
}) {
  const { t } = useTranslation('translation');

  const [code, setCode] = useState(Array(MOBILE_VERIFICATION_CODE_LENGTH).fill(''));

  const handleCodeChange = newCode => {
    if (isCodeValid(newCode)) {
      onCodeChange(newCode.join(''));
    }
  };

  const isCodeValid = code => {
    for (let value of code) {
      if (!value) {
        return false;
      }
    }
    return true;
  };

  return (
    <div className="pending-mobile" {...rest}>
      <span className="title">{t('pendingMobile.enterConfirmationCode')}</span>
      <br />
      <span className="description">{t('pendingMobile.confirmationSMSsentTo', { mobile })}</span>
      {isReadOnly ? null : (
        <>
          <div className="code-input__wrapper">
            <TwoFactorInput
              code={code}
              codeLength={MOBILE_VERIFICATION_CODE_LENGTH}
              onCodeChange={newCode => {
                setCode([...newCode]);
                handleCodeChange(newCode);
              }}
            />
            <Footer
              isDisabled={isButtonsDisabled}
              onResend={() => {
                const defaultCode = Array(MOBILE_VERIFICATION_CODE_LENGTH).fill('');
                setCode([...defaultCode]);
                onResend();
              }}
              onReset={onReset}
            />
          </div>
        </>
      )}
    </div>
  );
}

function Footer({ isDisabled, onResend, onReset }) {
  const { t } = useTranslation('translation');

  return (
    <div className="footer">
      <button disabled={isDisabled} onClick={onResend}>
        {t('pendingMobile.resendSMS')}
      </button>
      <button disabled={isDisabled} onClick={onReset}>
        {t('pendingMobile.cancel')}
      </button>
    </div>
  );
}

export default PendingMobile;
