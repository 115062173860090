import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useExchangeTooltip } from '../../../hooks';

import { SEND_PAYMENT_SCROLL_INSTANCE_ID } from '../../../containers/send-payment/constants';

import './index.scss';

const AmountInput = ({
  flagImg,
  currencyCode,
  value,
  valueDescription,
  onChange,
  rateInfo,
  autoFocus,
  name,
  invalid,
  disabled,
  withoutDisabledStyles
}) => {
  rateInfo && (rateInfo.currency = currencyCode);
  const { RateInfoIcon, ExchangeTooltip } = useExchangeTooltip({
    rateInfo,
    scrollInstanceId: SEND_PAYMENT_SCROLL_INSTANCE_ID
  });

  return (
    <div
      className={classNames('amount-input', { disabled: withoutDisabledStyles ? null : disabled })}
    >
      {/** ExchangeTooltip from Portal */}
      <ExchangeTooltip />
      {rateInfo ? <RateInfoIcon className="amount-input__info-icon" /> : null}
      <div
        className={classNames('amount-input__content', {
          invalid
        })}
      >
        {flagImg ? <img src={flagImg} alt="" /> : null}
        <span className="currency-code">{currencyCode}</span>
        <input
          value={value}
          onChange={onChange}
          name={name}
          autoFocus={autoFocus}
          disabled={disabled}
        />
        {valueDescription ? <span className="value-description">{valueDescription}</span> : null}
      </div>
    </div>
  );
};

AmountInput.propsType = {
  flagImg: PropTypes.node.isRequired,
  currencyCode: PropTypes.string,
  value: PropTypes.string,
  valueDescription: PropTypes.string,
  onChange: PropTypes.func,
  rateInfo: PropTypes.shape({
    date: PropTypes.string.isRequired,
    rate: PropTypes.string.isRequired
  }),
  invalid: PropTypes.bool,
  utoFocus: PropTypes.bool
};

AmountInput.defaultProps = {
  flagImg: null,
  currencyCode: '',
  value: '',
  valueDescription: '',
  onChange: () => {},
  invalid: false,
  autoFocus: false
};

export default AmountInput;
