export const PAYMENT_STEPS = {
  PAYMENT_SETTINGS: 'paymentSettings',
  CONFIRM_PAGE: 'confirmPage',
  RECIPIENT_DETAILS: 'recipientDetails',
  BANK_DETAILS: 'bankDetails',
  PAYMENT_DETAILS: 'paymentDetails'
};

export const BULK_PAYMENT_STEPS = {
  UPLOAD_DOCUMENT: 'uploadDocument',
  CONFIRM_BULK_PAYMENT: 'confirmPayment'
};

export const SEND_PAYMENT_SCROLL_INSTANCE_ID = 'send-payment-container-js';

export const reasonFeatureFlag = true;

export const RESTRICTED_RECIPIENTS_LIST = [
  'CF TECH',
  'CF Tech SA de CV',
  'JEEVES INC',
  'Jeeves Inc.',
  'JVS INC MEXICO SA DE C',
  'JVS INC MEXICO SA DE CV',
  'RED AMIGO DAL, S.A.P.I. DE C.V',
  'CF TECH SA DE cv',
  'Clara',
  'jeeves',
  'Jeeves Belvo',
  'JEEVES INC',
  'JVS INC MEXICO SA DE CV',
  'KONFIO'
];

export const RESTRICTED_CLABES_LIST = [
  '646180242300001100',
  '646180242300004424',
  '646180242300006587',
  '646180242300008048',
  '646180245100000007',
  '646420120701359385'
];
