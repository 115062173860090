import { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import DropdownButton from '../dropdown-button';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-icon.svg';

import './index.scss';

const InputWithSelect = ({
  handleChange,
  value,
  handleClose,
  dropDownData,
  isValid,
  id,
  selectType,
  getSelectLabel,
  handleTagUpdate,
  disabled
}) => {
  const { t } = useTranslation('translation');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleDropdownClose = () => setIsDropdownVisible(false);

  const toggleModalVisibility = () => setIsDropdownVisible(!isDropdownVisible);

  const handleTypeChange = type => {
    handleTagUpdate({ id, type });
    setIsDropdownVisible(false);
  };

  return (
    <div className={classNames('input-with-select', { 'not-valid': !isValid })}>
      <div className="input-with-select-content">
        <input
          value={value}
          onChange={e => handleChange({ label: e.target.value, id })}
          disabled={disabled}
        />
        {dropDownData ? (
          <DropdownButton
            label={t(getSelectLabel(selectType))}
            handleClick={toggleModalVisibility}
            isActive={isDropdownVisible && !disabled}
            handleTypeChange={handleTypeChange}
            dropdownData={dropDownData}
            selectedType={selectType}
            handleDropdownClose={handleDropdownClose}
            disabled={disabled}
          />
        ) : null}
        {disabled ? null : (
          <CloseIcon className="input-with-select-content__close-icon" onClick={handleClose} />
        )}
      </div>
    </div>
  );
};

export default InputWithSelect;

InputWithSelect.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
  dropDownData: PropTypes.array,
  handleClose: PropTypes.func
};

InputWithSelect.defaultProps = {
  handleChange: () => {},
  handleClose: () => {}
};
