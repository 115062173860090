import { memo, useState, forwardRef, useMemo } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ShowJsEntity from './show-js-entity';

import { ReactComponent as Logo } from '../../../assets/images/logo.svg';
import { ReactComponent as Visa } from '../../../assets/images/cards/visa-corporate-logo.svg';
import { ReactComponent as CopyIcon } from '../../../assets/images/copy.svg';

import { BASE_CLASSES, SHOW_JS_ENTITY_IDS } from './show-js-entity/constants';

import './index.scss';

const onClickAnimation = ({ animated, setAnimated }) => {
  if (animated) return;

  setAnimated(true);
  setTimeout(() => setAnimated(false), 700);
};

const CopyInfoButton = ({ info, style, animated, setAnimated }) => (
  <>
    <CopyIcon
      style={{
        ...{
          width: '1.2rem',
          height: '1.4rem',
          position: 'absolute',
          cursor: 'pointer'
        },
        ...style
      }}
      className={classNames('copy-icon', {
        animated
      })}
      onClick={() => {
        onClickAnimation({ animated, setAnimated });
        const cb = navigator.clipboard;
        cb.writeText(info).then(() => {});
      }}
    />
  </>
);

const renderCVV = ({
  securedFieldDynamicClasses,
  isPhysicalCard,
  cardCVV,
  animatedCvv,
  setAnimatedCVV,
  isShowJs,
  showJsCvvAnimated,
  isCopyButtonVisible
}) => (
  <div className={classNames('card-cvv', securedFieldDynamicClasses)}>
    <div className="card-date--label">
      <span>Secure</span>
      <span>Code</span>
    </div>
    <div className="card-cvv--value">
      {isShowJs ? (
        <ShowJsEntity
          baseClass={BASE_CLASSES.CVV}
          animated={showJsCvvAnimated}
          buttonId={SHOW_JS_ENTITY_IDS.COPY_BUTTON_CVV}
        >
          {cardCVV || '###'}
        </ShowJsEntity>
      ) : (
        <span className={classNames(BASE_CLASSES.CVV, { animated: animatedCvv })}>
          {cardCVV || '###'}
        </span>
      )}
      {isPhysicalCard ? null : isCopyButtonVisible ? (
        <CopyInfoButton
          animated={isShowJs ? showJsCvvAnimated : animatedCvv}
          setAnimated={setAnimatedCVV}
          info={cardCVV}
          style={{ top: '.2rem', right: 0 }}
        />
      ) : null}
    </div>
  </div>
);

const renderPan = ({
  securedFieldDynamicClasses,
  numbers,
  pan,
  animatedPan,
  setAnimatedPan,
  isShowJs,
  showJsNumberAnimated,
  isCopyButtonVisible
}) => {
  const CardNumber = () =>
    numbers.map((n, idx) => (
      <span className={classNames({ animated: animatedPan })} key={idx}>
        {n}
      </span>
    ));

  return (
    <div className={classNames('card-pan', securedFieldDynamicClasses)}>
      <div>
        {isShowJs ? (
          <ShowJsEntity
            baseClass={BASE_CLASSES.PAN}
            animated={showJsNumberAnimated}
            buttonId={SHOW_JS_ENTITY_IDS.COPY_BUTTON_NUMBER}
          >
            <CardNumber />
          </ShowJsEntity>
        ) : (
          <CardNumber />
        )}
      </div>
      {isCopyButtonVisible ? (
        <CopyInfoButton
          animated={isShowJs ? showJsNumberAnimated : animatedPan}
          setAnimated={setAnimatedPan}
          info={pan}
          style={{ top: '-1.6rem', right: '-.8rem' }}
        />
      ) : null}
    </div>
  );
};

const renderSecureInfo = ({
  securedFieldDynamicClasses,
  isPhysicalCard,
  dates,
  cardCVV,
  animatedSecureInfo,
  setAnimatedSecureInfo,
  animatedCvv,
  setAnimatedCVV,
  isShowJs,
  showJsCvvAnimated,
  showJsDateAnimated,
  isCopyButtonVisible
}) => (
  <div className={classNames('card-secure-info', isPhysicalCard ? 'phys' : 'virt')}>
    {renderCVV({
      securedFieldDynamicClasses,
      isPhysicalCard,
      cardCVV,
      animatedCvv,
      setAnimatedCVV,
      isShowJs,
      showJsCvvAnimated,
      isCopyButtonVisible
    })}
    <div className={classNames('card-date', securedFieldDynamicClasses)}>
      <div className="card-date--label">
        <span>Valid</span>
        <span>Thru</span>
      </div>
      <div className="card-date--value">
        {isShowJs ? (
          <ShowJsEntity
            baseClass={BASE_CLASSES.DATE}
            animated={showJsDateAnimated}
            buttonId={SHOW_JS_ENTITY_IDS.COPY_BUTTON_DATE}
          >
            {dates?.end}
          </ShowJsEntity>
        ) : (
          <span className={classNames(BASE_CLASSES.DATE, { animated: animatedSecureInfo })}>
            {dates?.end}
          </span>
        )}
        {isPhysicalCard ? null : isCopyButtonVisible ? (
          <CopyInfoButton
            animated={isShowJs ? showJsDateAnimated : animatedSecureInfo}
            setAnimated={setAnimatedSecureInfo}
            info={dates?.end}
            style={{ top: '0.2rem', right: 0 }}
          />
        ) : null}
      </div>
    </div>
  </div>
);

const renderCardBrand = () => (
  <div className="card-brand">
    <Visa />
  </div>
);

const renderCardMarqetaInfo = t => (
  <div className="card-marqeta-info">
    <span className="card-marqeta-info__title">{t('cardPreview.limitedUse')}</span>
    <span className="card-marqeta-info__desk">
      <Trans t={t} i18nKey={'cardPreview.marqetaInfoText'}>
        Sutton Bank®️ Visa®️ Prepaid Card is issued by Sutton Bank,
        <br />
        Member FDIC, pursuant to a license from Visa U.S.A. Inc.
      </Trans>
    </span>
  </div>
);

const renderCardBrandInfo = (isMarqetaCard, t) => (
  <div className={classNames('card-brand-info', { 'marqeta-card': isMarqetaCard })}>
    {isMarqetaCard ? renderCardMarqetaInfo(t) : null}
    {renderCardBrand()}
  </div>
);

const CardPreview = forwardRef(
  (
    {
      isPhysicalCard,
      userName,
      isMarqetaCard,
      isNewCard,
      panInfo,
      panVisible,
      last4,
      isShowJs,
      showJsNumberAnimated,
      showJsCvvAnimated,
      showJsDateAnimated,
      isShowJsLoaded
    },
    ref
  ) => {
    const { t } = useTranslation('translation');
    const [animatedPan, setAnimatedPan] = useState(false);
    const [animatedSecureInfo, setAnimatedSecureInfo] = useState(false);
    const [animatedCvv, setAnimatedCVV] = useState(false);

    const condition = (panVisible && panInfo) || (isPhysicalCard && panInfo);
    const cardPanNumbers = condition
      ? transformCardPan(panInfo.pan)
      : ['####', '####', '####', last4];
    const cardCVV = panVisible && panInfo ? panInfo.cvv : '###';
    const date = condition ? { end: panInfo.exp_date } : { end: '##/##' };

    const isCopyButtonVisible = ((isShowJs && isShowJsLoaded) || !isShowJs) && panVisible;

    const securedFieldDynamicClasses = useMemo(
      () => ({
        phys: isPhysicalCard,
        virt: !isPhysicalCard,
        visible: !isPhysicalCard && panVisible
      }),
      [isPhysicalCard, panVisible]
    );

    return (
      <div className={classNames('card-preview', { physical: isPhysicalCard })} ref={ref}>
        <Logo className="card-logo" />
        <span className="card-title">{userName}</span>

        {isNewCard
          ? renderPan({
              securedFieldDynamicClasses,
              numbers: ['0000', '0000', '0000', '0000'],
              animatedPan,
              setAnimatedPan
            })
          : renderPan({
              securedFieldDynamicClasses,
              numbers: cardPanNumbers,
              pan: panInfo?.pan,
              animatedPan,
              setAnimatedPan,
              isShowJs,
              showJsNumberAnimated,
              isCopyButtonVisible
            })}
        {isNewCard
          ? renderSecureInfo({
              isPhysicalCard,
              securedFieldDynamicClasses,
              dates: { end: '-- --' },
              animatedSecureInfo,
              setAnimatedSecureInfo,
              animatedCvv,
              setAnimatedCVV
            })
          : renderSecureInfo({
              isPhysicalCard,
              securedFieldDynamicClasses,
              dates: date,
              cardCVV,
              animatedSecureInfo,
              setAnimatedSecureInfo,
              animatedCvv,
              setAnimatedCVV,
              isShowJs,
              showJsCvvAnimated,
              showJsDateAnimated,
              isCopyButtonVisible
            })}
        {renderCardBrandInfo(isMarqetaCard, t)}
      </div>
    );
  }
);

const transformCardPan = panString => {
  let pan = [];
  [0, 4, 8, 12].forEach(index => {
    pan.push(panString.slice(index, index + 4));
  });
  return pan;
};

CardPreview.propsType = {
  isPhysicalCard: PropTypes.bool,
  isNewCard: PropTypes.bool,
  isMarqetaCard: PropTypes.bool,
  userName: PropTypes.string,
  panVisible: PropTypes.bool,
  panInfo: PropTypes.object,
  last4: PropTypes.string,
  isShowJs: PropTypes.bool
};

CardPreview.defaultProps = {
  isPhysicalCard: false,
  userName: '',
  last4: '####',
  isNewCard: false,
  isMarqetaCard: false,
  panVisible: false,
  panInfo: null,
  isShowJs: false
};

export default memo(CardPreview);
