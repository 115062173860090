import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from '../../../assets/images/close-modal-icon.svg';

import './index.scss';

const Modal = ({ children, closeModal, styles, className, withOverlay }) => {
  const renderModal = () => (
    <div className={classNames('modal', className)} style={styles}>
      <CloseIcon className="modal__close-icon" onClick={closeModal} />
      {children}
    </div>
  );

  const renderModalWithOverlay = () => (
    <div className="modal-wrapper">
      {renderModal()}
      <div className="app-overlay" onClick={closeModal} />
    </div>
  );

  return withOverlay ? renderModalWithOverlay() : renderModal();
};

export default Modal;

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  closeModal: PropTypes.func.isRequired,
  styles: PropTypes.object,
  className: PropTypes.string,
  withOverlay: PropTypes.bool
};

Modal.defaultProps = {
  styles: null,
  className: '',
  withOverlay: true
};
