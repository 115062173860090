import axios from 'axios';
import { SAT_URL } from '../constants/common.constants';
import { _DEFAULT_CONFIG } from './config';
import { handleRequestError } from './handlers/error-handlers';

export const satService = {
  createTaxPayer,
  checkTaxPayerStatus,
  isSATConnected
};

const httpSatEngine = axios.create({ baseURL: SAT_URL });

export async function createTaxPayer({ rfc, satPassword, companyId }) {
  return httpSatEngine
    .post(
      `/sat/create-taxpayer?applicationId=2`,
      {
        type: 'ciec',
        rfc,
        password: satPassword,
        companyId
      },
      {
        ...(await _DEFAULT_CONFIG())
      }
    )
    .then(({ data }) => data);
}

export async function checkTaxPayerStatus(id) {
  return httpSatEngine
    .get(`/sat/get-status?applicationId=2`, {
      ...(await _DEFAULT_CONFIG()),
      params: { id }
    })
    .then(({ data: { taxpayer_status: status, rfc } }) => ({ status, rfc }));
}

async function isSATConnected({ companyId }) {
  const params = {
    companyId
  };

  return axios
    .get(`${SAT_URL}/sat/is-connected-sat?applicationId=2`, {
      ...(await _DEFAULT_CONFIG()),
      params
    })
    .catch(handleRequestError);
}
