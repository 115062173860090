import classNames from 'classnames';

import { ReactComponent as ChevronRight } from '../../../../../assets/images/chevron-right.svg';

import './index.scss';

const CHEVRON_DIRECTION = {
  RIGHT: 'RIGHT',
  LEFT: 'LEFT'
};

const { LEFT } = CHEVRON_DIRECTION;

const Chevron = ({ direction, disabled, className }) => (
  <ChevronRight
    className={classNames('chevron', className, {
      disabled,
      left: direction === LEFT
    })}
  />
);

Chevron.direction = CHEVRON_DIRECTION;

export default Chevron;
