import React from 'react';
import './index.scss';

import Model from './../../../model/notifications/notifications';
import constants from './../../../model/notifications/constants.js';

function InfoMessage(props) {
  return (
    <div key={props.id} className={props.className}>
      <div
        className={`info-message ` + (props.wasRead ? '' : 'expanded')}
        style={mainContainerColor(props)}
      >
        <div
          className="info-message-icon--container"
          style={iconContainerColor(props)}
        >
          <img alt="" src={constants.icon[props.type]} />
        </div>
        <div className="info-message-text--container">
          <div className="info-message-text--title">
            {constants.title[props.type]}
          </div>
          <div className="info-message-text--separator"> - </div>
          <div className="info-message-text">{props.text}</div>
        </div>
        <div className="info-message-actions-container">
          {props.hasActions ? actionButtons(props) : readButton(props)}
        </div>
      </div>
    </div>
  );
}

function actionButtons(props) {
  return (
    <div className="info-message-actions-container">
      <button
        className="info-message-button"
        onClick={() => {
          props.onInteraction(Model.ACTION_TYPES.ACCEPT);
        }}
      >
        ACCEPT
      </button>
      <button
        className="info-message-button"
        onClick={() => {
          props.onInteraction(Model.ACTION_TYPES.REJECT);
        }}
      >
        REJECT
      </button>
    </div>
  );
}

const readButton = props => (
  <button
    className="info-message-button read"
    onClick={() => {
      props.onInteraction(Model.ACTION_TYPES.READ);
    }}
  >
    Mark as Read
  </button>
);

const mainContainerColor = ({ type }) => ({
  backgroundColor: constants.secondaryColor[type]
});

const iconContainerColor = ({ type }) => ({
  backgroundColor: constants.mainColor[type]
});

export default InfoMessage;
