import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import classNames from 'classnames';

import { StatusMark } from '../../index';

import { SEND_PAYMENT_LOCALE } from '../../../../constants/locales';
import { userConstants } from '../../../../constants/user.constants';
import { STATUS_MARK_STATUSES } from '../../status-mark';

const ItemsList = ({
  items,
  enteredText,
  onItemSelection,
  withFullMatchMark,
  selectedItemLabel
}) => {
  const { t } = useTranslation(SEND_PAYMENT_LOCALE);

  const [matchingItems, setMatchingItems] = useState([]);
  const [matchingSubstrings, setMatchingSubstrings] = useState([]);
  const [notMatchingItems, setNotMatchingItems] = useState([]);

  const {
    user: { user_type_id: userTypeId }
  } = useSelector(({ user }) => user);

  const isSuperAdmin = userTypeId === userConstants.USER_TYPES.SUPER_ADMIN;

  useEffect(() => {
    const matchingItemsArray = [];
    const substringsArray = [];
    let restArray = [];

    for (let i = 0; i < items.length; i++) {
      const listItem = items[i];

      if (
        listItem.label.toLowerCase().includes(enteredText.toLowerCase()) &&
        !withFullMatchMark &&
        enteredText
      ) {
        const letters = [];

        for (let a = 0; a < listItem.label.length; a++) {
          letters.push(false);
        }

        for (let y = 0; y < enteredText.length; y++) {
          const highlightedSymbolIndex =
            listItem.label.toLowerCase().indexOf(enteredText.toLowerCase()) + y;
          letters[highlightedSymbolIndex] = true;
        }

        substringsArray.push(letters);
        matchingItemsArray.push(listItem);
      } else {
        restArray.push(listItem);
      }
    }

    if (withFullMatchMark) restArray = _.sortBy(restArray, [i => i.label !== selectedItemLabel]);

    setMatchingItems(matchingItemsArray);
    setMatchingSubstrings(substringsArray);
    setNotMatchingItems(restArray);
  }, [items, enteredText]);

  const isApprovalPolicyAvailable = process.env.REACT_APP_APPROVAL_POLICY === 'true';

  const handleItemSelection = item => {
    if (
      isApprovalPolicyAvailable &&
      !isSuperAdmin &&
      (item.status === STATUS_MARK_STATUSES.PENDING ||
        item.status === STATUS_MARK_STATUSES.REJECTED)
    )
      return;

    onItemSelection(item.id);
  };

  return (
    <>
      {matchingItems.map((item, index) => (
        <div key={item.id} className="search-item__row" onClick={() => handleItemSelection(item)}>
          {item.label.split('').map((letter, i) => (
            <span
              key={i}
              className={classNames({
                highlighted: matchingSubstrings[index][i]
              })}
            >
              {letter}
            </span>
          ))}{' '}
          {isApprovalPolicyAvailable && item.status && !isSuperAdmin ? (
            <StatusMark status={item.status} />
          ) : null}
        </div>
      ))}
      {notMatchingItems.map(item => (
        <div key={item.id} className="search-item__row" onClick={() => handleItemSelection(item)}>
          <span
            className={classNames({
              highlighted:
                item.label === enteredText ||
                (withFullMatchMark && item.label === selectedItemLabel)
            })}
          >
            {withFullMatchMark ? t(item.label) : item.label}
          </span>
          {isApprovalPolicyAvailable && item.status && !isSuperAdmin ? (
            <StatusMark status={item.status} />
          ) : null}
        </div>
      ))}
    </>
  );
};

export default ItemsList;

// todo: items are bojects
// todo: onItemSelection should send id
