import classNames from 'classnames';
import PropTypes from 'prop-types';

import Loader from '../loader';
import RoundedButton from '../button-rounded';

import './index.scss';

const DownloadButton = ({ label, handleClick, isProcessing }) => (
  <div className="download-button-container">
    {isProcessing ? <Loader centered /> : null}
    <RoundedButton
      className={classNames({ loading: isProcessing })}
      disabled={isProcessing}
      onClick={handleClick}
      type="bright"
    >
      {label}
    </RoundedButton>
  </div>
);

export default DownloadButton;

DownloadButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  isProcessing: PropTypes.bool
};
