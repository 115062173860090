import classNames from 'classnames';
import PropTypes from 'prop-types';

import './index.scss';

const ItemSelect = ({ Icon, label, isSelected, ...rest }) => (
  <div
    className={classNames('item-select', {
      selected: isSelected
    })}
    {...rest}
  >
    <Icon className="item-select__icon" />
    <span className="item-select__label">{label}</span>
  </div>
);

export default ItemSelect;

ItemSelect.defaultProps = {
  Icon: PropTypes.node,
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool
};

ItemSelect.defaultProps = {
  isSelected: false
};
