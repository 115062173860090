import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ELLIPSES } from './constants';

export const useInnerHook = ({ setActivePage, onItemClick, totalPages, activePage }) => {
  const { t } = useTranslation('translation');

  const initialList = Array.from({ length: totalPages }, (_, i) => i + 1);

  const [orderedList, setOrderedList] = useState(initialList);

  useEffect(() => {
    updateOrderedList();
  }, [activePage]);

  const handleClick = activePage => {
    setActivePage?.(activePage);
    onItemClick({ page: activePage });
  };

  /**
   * @MAX_PAGES_WITHOUT_ELLIPSES - max visible pages without ellipses
   * @MAX_PAGES_BEFORE_ELLIPSES - max pages from both sides with single ellipses
   * @MAX_ACTIVE_PAGE_WITHOUT_ELLIPSES - after this page will be rendered centralPart with ellipses on the sides
   * */
  const MAX_PAGES_WITHOUT_ELLIPSES = 9;
  const MAX_PAGES_BEFORE_ELLIPSES = 7;
  const MAX_ACTIVE_PAGE_WITHOUT_ELLIPSES = 5;
  const isPaginationNotAvailable = totalPages === 1;
  const isNextButtonDisabled = activePage === totalPages || isPaginationNotAvailable;
  const isPreviousButtonDisabled = activePage === 1 || isPaginationNotAvailable;
  const itemsAfterActivePageInCentralGroup = 2;
  const itemsBeforeActivePageInCentralGroup = 2;
  const centralPositioning = [
    activePage - itemsBeforeActivePageInCentralGroup - 1,
    activePage + itemsAfterActivePageInCentralGroup
  ];

  const handleNextClick = () => !isNextButtonDisabled && handleClick(activePage + 1);
  const handlePreviousClick = () => !isPreviousButtonDisabled && handleClick(activePage - 1);

  /**
   * @isLastPagesGroup - determining whether lastPages group is available
   * @isGTMaxPagesWithoutLeftEllipses - determining whether active page is grater then MAX_ACTIVE_PAGE_WITHOUT_ELLIPSES
   * */
  const updateOrderedList = () => {
    if (totalPages <= MAX_PAGES_WITHOUT_ELLIPSES) return;
    if (totalPages > MAX_PAGES_BEFORE_ELLIPSES) {
      const isLastPagesGroup = activePage > totalPages - MAX_ACTIVE_PAGE_WITHOUT_ELLIPSES;
      const isGTMaxPagesWithoutLeftEllipses = activePage > MAX_ACTIVE_PAGE_WITHOUT_ELLIPSES;
      const firstPart = initialList.slice(0, MAX_PAGES_BEFORE_ELLIPSES);
      const lastPart = isLastPagesGroup
        ? initialList.slice(-MAX_PAGES_BEFORE_ELLIPSES)
        : initialList.slice(-1);

      const getCentralPart = () => initialList.slice(...centralPositioning);

      const getOrderedList = () => {
        if (isLastPagesGroup) return [1, ELLIPSES, ...lastPart];
        if (isGTMaxPagesWithoutLeftEllipses)
          return [1, ELLIPSES, ...getCentralPart(), ELLIPSES, ...lastPart];

        return [...firstPart, ELLIPSES, ...lastPart];
      };

      setOrderedList(getOrderedList());
    }
  };

  return {
    t,
    orderedList,
    handleClick,
    handleNextClick,
    handlePreviousClick,
    isPreviousButtonDisabled,
    isNextButtonDisabled
  };
};
