import classNames from 'classnames';

import { ReactComponent as CloseIcon } from '../../../assets/images/close-icon.svg';
import { ReactComponent as InfoIcon } from '../../../assets/images/info.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/images/success-circle.svg';

import { ALERT_TYPES } from './constants';

import './index.scss';

const { SUCCESS, INFO } = ALERT_TYPES;

const Alert = ({ description, type = SUCCESS, handleCloseClick, style }) => {
  const isCloseIconAvailable = () => type === SUCCESS;

  const Icon = type === SUCCESS ? SuccessIcon : InfoIcon;

  return (
    <div
      className={classNames('alert', {
        success: type === SUCCESS,
        info: type === INFO
      })}
      style={style}
    >
      <Icon className="alert__status-icon" />
      <span className="alert__description">{description}</span>
      {isCloseIconAvailable() ? (
        <CloseIcon className="alert__close-icon" onClick={handleCloseClick} />
      ) : null}
    </div>
  );
};

export default Alert;
