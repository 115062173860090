import { notificationsConstants } from '../constants/notifications.constants';
import * as _ from 'lodash';
import moment from 'moment';

const INITIAL_STATE = {
  notificationsTypes: [],
  notifications: [],
  archivedNotifications: [],
  settings: null,
  hasUnreadNotifications: false,
  totalPages: 0,
  currentPage: 0,
  activeCategories: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case notificationsConstants.SET_NOTIFICATIONS_TYPES:
      return { ...state, notificationsTypes: action.types };
    case notificationsConstants.SET_NOTIFICATIONS:
      const notifications = _prepareNotifications(state.notificationsTypes, action.notifications);
      return {
        ...state,
        notifications: action.setNewNotificationsList
          ? notifications
          : [...state.notifications, ...notifications],
        totalPages: action.totalPages,
        currentPage: action.currentPage,
        hasUnreadNotifications: !!notifications.find(notification => !notification.isRead)
      };
    case notificationsConstants.SET_ARCHIVED_NOTIFICATIONS:
      const archivedNotifications = _prepareNotifications(
        state.notificationsTypes,
        action.notifications,
        true
      );
      return {
        ...state,
        archivedNotifications: action.setNewNotificationsList
          ? archivedNotifications
          : [...state.archivedNotifications, ...archivedNotifications],
        totalPages: action.totalPages,
        currentPage: action.currentPage
      };
    case notificationsConstants.HIDE_ARCHIVED_NOTIFICATIONS:
      return {
        ...state,
        notifications: state.notifications.filter(notification => !notification.archived)
      };
    case notificationsConstants.ARCHIVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.id !== action.notificationId
        )
      };
    case notificationsConstants.UNARCHIVE_NOTIFICATION:
      action.notification.archived = false;
      return {
        ...state,
        notifications: [...state.notifications, action.notification],
        archivedNotifications: state.archivedNotifications.filter(
          notification => notification.id !== action.notification.id
        )
      };
    case notificationsConstants.ARCHIVE_ALL_NOTIFICATIONS:
      const updatedNotifications = state.notifications.filter(
        notification => action.ids.indexOf(notification.id) === -1
      );
      return { ...state, notifications: [...updatedNotifications] };
    case notificationsConstants.MARK_ALL_AS_READ:
      return {
        ...state,
        hasUnreadNotifications: false
      };
    case notificationsConstants.SET_NOTIFICATIONS_SETTINGS:
      const notificationsSettings = _prepareNotificationsSettings(
        state.notificationsTypes,
        action.settings
      );

      const activeCategories = notificationsSettings.reduce((accumulator, nextValue) => {
        if (nextValue.isVisible) {
          accumulator.push(nextValue.id);
        }
        return accumulator;
      }, []);

      return { ...state, settings: notificationsSettings, activeCategories };
    case notificationsConstants.UPDATE_NOTIFICATIONS_SETTINGS:
      return {
        ...state,
        settings: state.settings.map(notificationSetting => {
          if (notificationSetting.id === action.notificationSettingId) {
            notificationSetting.isVisible = !notificationSetting.isVisible;
          }
          return notificationSetting;
        })
      };
    case notificationsConstants.UPDATE_NOTIFICATION_SETTING:
      return {
        ...state,
        settings: state.settings.map(notificationSetting => {
          if (notificationSetting.id === action.notificationSettingId) {
            notificationSetting.setting = action.notificationSetting;
          }
          return notificationSetting;
        })
      };
    case notificationsConstants.SORT_NOTIFICATIONS:
      let sortedItems = [];
      const { key, archived } = action.data;
      const notificationItems = archived ? state.archivedNotifications : state.notifications;
      if (key === 'newest') {
        sortedItems = notificationItems.sort((a, b) => new Date(b.date) - new Date(a.date));
      } else if (key === 'oldest') {
        sortedItems = notificationItems
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .reverse();
      } else if (key === 'category') {
        sortedItems = notificationItems.sort((a, b) => {
          if (a.typeKey.toLowerCase() > b.typeKey.toLowerCase()) {
            return 1;
          }
          if (a.typeKey.toLowerCase() < b.typeKey.toLowerCase()) {
            return -1;
          }
          return 0;
        });
      }
      let updatedState = { ...state };
      if (archived) {
        updatedState['archivedNotifications'] = [...sortedItems];
      } else {
        updatedState['notifications'] = [...sortedItems];
      }
      return updatedState;
    default:
      return state;
  }
}

const _prepareNotifications = (types, notifications, archived) =>
  notifications.map(notification => _prepareNotification(types, notification, archived));

const _prepareNotification = (types, notification, archived = false) => {
  const typeInfo = types.find(notificationType => notification.type_id === notificationType.id);
  return {
    id: notification.id,
    typeKey: typeInfo.type,
    typeName: typeInfo.name,
    notificationName: typeInfo.notification_name,
    settingName: typeInfo.setting_name,
    content: notification.content,
    createdAt: notification.created_at,
    date: notification.created_at,
    isRead: notification.isRead,
    archived: archived
  };
};

const _prepareNotificationsSettings = (types, settings) => {
  return settings.map(notificationTypeSettings => {
    const typeInfo = types.find(
      notificationType => notificationTypeSettings.type_id === notificationType.id
    );
    const setting = notificationTypeSettings.setting;
    return {
      id: typeInfo.id,
      key: typeInfo.type,
      name: typeInfo.setting_name,
      isVisible: notificationTypeSettings.isVisible,
      setting:
        typeInfo.supported_setting !== null
          ? {
              useEmail: setting ? setting.useEmail || false : false,
              useSMS: setting ? setting.useSMS || false : false
            }
          : null
    };
  });
};

export const _formatNotificationDate = (date, isES, t) => {
  let formattedDate = date;
  const dateFormat = 'MM-DD-YYYY';
  const isToday = moment(date).format(dateFormat) === moment().format(dateFormat);
  if (isToday) {
    formattedDate = t('common.today');
  } else {
    const d1 = _getDate();
    const d2 = _getDate(date);
    const diffDays = d1.diff(d2, 'days');
    if (diffDays >= 7) {
      const format = isES ? 'DD [de] MMMM, YYYY' : 'MMM Do, YYYY';
      formattedDate = moment(date).format(format);
    } else {
      if (isES) {
        formattedDate = t(diffDays === 1 ? 'common.dayAgo' : 'common.daysAgo', {
          daysCount: diffDays
        });
      } else {
        formattedDate = `${diffDays} ${diffDays === 1 ? 'day' : 'days'} ago`;
      }
    }
  }

  return formattedDate;
};

const _getDate = d => {
  const date = d ? moment(d) : moment();
  date.set('hour', 0);
  date.set('minute', 0);
  date.set('second', 0);
  date.set('millisecond', 0);
  return date;
};
