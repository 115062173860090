import { FixedSizeList } from 'react-window';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ScrollableArea } from '../../scrollable-area';

import { useTargetDevice } from '../../../hooks';

import './index.scss';

const ScrollableTable = ({
  height,
  initialScrollOffset,
  width,
  rows,
  renderRow: RenderRow,
  itemSize,
  simplebarMarginRight,
  reportStyles,
  styles
}) => {
  const { isTargetDevice } = useTargetDevice();

  const List = ({ contentNodeRef, scrollableNodeRef }) => (
    <FixedSizeList
      height={height}
      itemCount={rows.length}
      itemSize={itemSize}
      initialScrollOffset={initialScrollOffset}
      width={width}
      className={classNames({ 'overflow-invisible': isTargetDevice })}
      innerRef={contentNodeRef}
      outerRef={scrollableNodeRef}
    >
      {RenderRow}
    </FixedSizeList>
  );

  return (
    <ScrollableArea
      styles={{ height, width, overflowX: 'hidden', ...styles }}
      className={classNames({
        'simplebar-table': simplebarMarginRight,
        'report-styles': reportStyles
      })}
    >
      {!isTargetDevice ? (
        <List />
      ) : (
        ({ scrollableNodeRef, contentNodeRef }) => (
          <List scrollableNodeRef={scrollableNodeRef} contentNodeRef={contentNodeRef} />
        )
      )}
    </ScrollableArea>
  );
};

export default ScrollableTable;

ScrollableTable.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  rows: PropTypes.array,
  renderRow: PropTypes.func,
  itemSize: PropTypes.number,
  initialScrollOffset: PropTypes.number,
  simplebarMarginRight: PropTypes.bool,
  reportStyles: PropTypes.bool,
  styles: PropTypes.object
};

ScrollableTable.defaultProps = {
  height: 350,
  width: null,
  rows: null,
  renderRow: null,
  itemSize: 52,
  initialScrollOffset: 0,
  simplebarMarginRight: true,
  reportStyles: false,
  styles: null
};
