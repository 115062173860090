export const userConstants = {
  INCREMENT_USERS_COUNT: 'INCREMENT_USERS_COUNT',

  SET_MERCHANT_CATEGORIES: 'SET_MERCHANT_CATEGORIES',

  SET_TOP_MERCHANTS: 'SET_TOP_MERCHANTS',
  SET_TOP_ITEMS: 'SET_TOP_ITEMS',
  UPDATE_IS_PHYSICAL_CARD_ALLOWED: 'UPDATE_IS_PHYSICAL_CARD_ALLOWED',

  UPDATE_USER_PROFILE_FIELD: 'UPDATE_USER_PROFILE_FIELD',
  USER_PROFILE_FIELD_FLAGS: {
    FIRST_NAME: 'FIRST_NAME',
    LAST_NAME: 'LAST_NAME',
    SECOND_LAST_NAME: 'SECOND_LAST_NAME',
    EMAIL: 'EMAIL',
    MOBILE: 'MOBILE',
    TITLE: 'TITLE',
    DEPARTMENT: 'DEPARTMENT',
    WALLET: 'WALLET',
    PHYS_CARDS: 'SELECTED_USER_PHYS_CARDS'
  },

  UPDATE_SELECTED_USER_FLAGS: {
    FIRST_NAME: 'SELECTED_USER_FIRST_NAME',
    LAST_NAME: 'SELECTED_USER_LAST_NAME',
    DEPARTMENT: 'SELECTED_USER_DEPARTMENT',
    TITLE: 'SELECTED_USER_TITLE',
    EMAIL: 'SELECTED_USER_EMAIL',
    MOBILE: 'SELECTED_USER_MOBILE',
    TYPE: 'SELECTED_USER_TYPE',
    WALLET: 'SELECTED_USER_WALLET',
    PHYS_CARDS: 'SELECTED_USER_PHYS_CARDS',
    LIMIT: 'SELECTED_USER_LIMIT',
    STATE: 'SELECTED_USER_STATE',
    DEFAULT: 'SELECTED_USER_DEFAULT',
    ADVANCED_CONTROLS: 'ADVANCED_CONTROLS'
  },

  VERIFY_USER_MOBILE: 'VERIFY_USER_MOBILE',

  RESET_USER_EMAIL: 'RESET_USER_EMAIL',
  RESET_SELECTED_USER_EMAIL: 'RESET_SELECTED_USER_EMAIL',

  RESEND_USER_MOBILE: 'RESEND_USER_MOBILE',
  RESET_USER_MOBILE: 'RESET_USER_MOBILE',

  SET_USERS: 'SET_USERS',

  SET_LANGUAGES: 'SET_LANGUAGES',

  SET_TRUSTED_DEVICES: 'SET_TRUSTED_DEVICES',
  REMOVE_TRUSTED_DEVICE: 'REMOVE_TRUSTED_DEVICE',
  REMOVE_TRUSTED_DEVICES: 'REMOVE_TRUSTED_DEVICES',

  RESET_USER_LIMIT: 'RESET_USER_LIMIT',
  UPDATE_USER_LIMIT: 'UPDATE_USER_LIMIT',

  UPDATE_USER_STATUS: 'UPDATE_USER_STATUS',
  UPDATE_USER_CARD_STATUS: 'UPDATE_USER_CARD_STATUS',

  SET_SELECTED_USER: 'SET_SELECTED_USER',
  RESET_SELECTED_USER: 'RESET_SELECTED_USER',

  UPDATE_SHIPPING_ADDRESS: 'UPDATE_SHIPPING_ADDRESS',
  SET_SELECTED_LANGUAGE: 'SET_SELECTED_LANGUAGE',

  CANCEL_USER_ACTIVATION: 'CANCEL_USER_ACTIVATION',
  RESEND_USER_ACTIVATION: 'RESEND_USER_ACTIVATION',

  MAX_USER_NAME_LENGTH: 24,

  // if user type values will change,
  // then change arrays in constants below
  USER_TYPES: {
    SUPER_ADMIN: 0,
    ADMIN: 1,
    USER: 4,
    ACCOUNTANT: 6
  },

  USER_ACTIVATION_STATUSES: {
    ACTIVE: 0,
    SUSPENDED: 1
  },

  // refactor these arrays if user types values will change
  // and they usage across the app
  USER_TYPES_LABELS: ['Super Admin', 'Admin', '', '', 'User', '', 'Accountant'],
  USER_TYPES_FORMATTED: ['super_admin', 'admin', '', '', 'user', '', 'accountant'],

  HIDE_PENDING_NOTIFICATIONS: 'HIDE_PENDING_NOTIFICATIONS'
};

export const MINIMAL_USER_LIMIT = 0;

export const getDefaultUserTableTooltipVisibilityMatrix = () => {
  // UPDATE this function rows and cols count
  // if needed in case of USER_TYPES_TABLE_DATA changing

  const UserTypeTableColCount = 5;
  const UserTypeTableRowCount = 11;

  const array = [];
  for (let i = 0; i < UserTypeTableColCount; i++) {
    array.push([]);
    for (let y = 0; y < UserTypeTableRowCount; y++) {
      array[i].push(false);
    }
  }
  return array;
};

export const USER_TYPES_TABLE_STATIC_COLUMN_TYPE = 'USER_TYPES_TABLE_STATIC_COLUMN_TYPE'; // njsscan-ignore: node_username

// update getDefaultUserTableTooltipVisibilityMatrix in case
// of changing table columns or rows count
export const USER_TYPES_TABLE_DATA = [
  {
    type: USER_TYPES_TABLE_STATIC_COLUMN_TYPE,
    header: 'User Type',
    items: [
      '',
      'Create users',
      'Assign user profile',
      'Suspend users',
      'Set user spend limits',
      // 'Create physical cards',
      'Create virtual cards',
      'Download and access statements',
      'Receive Notifications',
      'Reporting',
      "See other users' card details"
    ],
    tooltipData: null
  },
  {
    type: userConstants.USER_TYPES.SUPER_ADMIN,
    header: userConstants.USER_TYPES_LABELS[userConstants.USER_TYPES.SUPER_ADMIN],
    items: [
      '',
      'Auto-approved',
      'All roles',
      'Auto-approved',
      'Auto-approved',
      // 'For self and others',
      'Yes',
      'Yes',
      'Yes',
      'All Users',
      'Yes'
    ],
    tooltipData: [
      null,
      null,
      null,
      null,
      null,
      // null,
      null,
      null,
      [
        'Advance Payments',
        'Monthly Statements',
        'Payment Reminders',
        'Payment Confirmations',
        'Overdue Payments',
        'Approved Transactions from self and others',
        'Declined Transactions from self and others'
      ],
      null,
      null
    ]
  },
  {
    type: userConstants.USER_TYPES.ADMIN,
    header: userConstants.USER_TYPES_LABELS[userConstants.USER_TYPES.ADMIN],
    items: [
      '',
      'Auto-approved',
      'All roles',
      'Auto-approved',
      'Auto-approved',
      // 'For self and others',
      'Yes',
      'No',
      'Yes',
      'All Users',
      'No'
    ],
    tooltipData: [
      null,
      null,
      null,
      null,
      null,
      // null,
      null,
      null,
      ['Approved Transactions from self and others', 'Declined Transactions from self and others'],
      null,
      null
    ]
  },
  {
    type: userConstants.USER_TYPES.USER,
    header: userConstants.USER_TYPES_LABELS[userConstants.USER_TYPES.USER],
    items: [
      '',
      'No',
      'No',
      'No',
      'No',
      // 'No',
      'Yes',
      'No',
      'Yes',
      'Self',
      'No'
    ],
    tooltipData: [
      null,
      null,
      null,
      null,
      null,
      // null,
      null,
      null,
      ['Approved Transactions', 'Declined Transactions'],
      null,
      null
    ]
  },
  {
    type: userConstants.USER_TYPES.ACCOUNTANT,
    header: userConstants.USER_TYPES_LABELS[userConstants.USER_TYPES.ACCOUNTANT],
    items: [
      '',
      'No',
      'No',
      'No',
      'No',
      // 'No',
      'No',
      'Yes',
      'No',
      'All Users',
      'No'
    ],
    tooltipData: null
  }
];
