import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { walletActions } from '../../actions/wallet.action';

import { getTagEmails } from '../../utils/wallet-utils';
import { EMAIL_REGEX } from '../../utils/regex';
import { isUsdCurrency } from '../../utils/currency-util';

import {
  RECURRING_LABEL,
  SEND_ONCE_LABEL,
  recipientInputTypes
} from '../../constants/send-emails.constants';

const { RECURRING } = recipientInputTypes;

export const enteredStringComparator = (firstString, secondString, enteredString) => {
  const a = firstString.label.toLowerCase(),
    b = secondString.label.toLowerCase(),
    str = enteredString.toLowerCase();

  return a.indexOf(str) > b.indexOf(str) ? 1 : a.indexOf(str) < b.indexOf(str) ? -1 : 0;
};

const getIsEmailDuplicated = (tags, label) => tags.some(t => t.label === label);

export const useFindCountries = () => {
  const dispatch = useDispatch();
  const recipientCountries = useSelector(state => state.wallet.recipientCountries);

  useEffect(() => {
    dispatch(walletActions.getCountries());
  }, []);

  const findCountries = async string => {
    if (!string || string.length < 1) {
      return [];
    }

    return (
      recipientCountries
        .filter(country => country.name.toLowerCase().includes(string.toLowerCase()))
        // .slice(0, 5)
        .map(country => ({
          value: country.id,
          label: country.name
        }))
        .sort((a, b) => enteredStringComparator(a, b, string))
    );
  };

  return { findCountries };
};

export const useFindBanks = () => {
  const dispatch = useDispatch();
  const banks = useSelector(state => state.wallet.banks);

  useEffect(() => {
    dispatch(walletActions.getBanks());
  }, []);

  const findBanks = async string => {
    if (!string || string.length < 1) {
      return [];
    }

    return banks
      .filter(bank => bank.name.toLowerCase().includes(string.toLowerCase()))
      .map(bank => ({
        value: bank.key,
        label: bank.name
      }))
      .sort((a, b) => enteredStringComparator(a, b, string));
  };

  return { findBanks };
};

export const useEmails = ({ initialTags }) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    initialTags.length && setTags(initialTags);
  }, [initialTags]);

  const handleTagAdding = useCallback(
    text => {
      /** Check for duplicated email */
      const isEmailDuplicated = getIsEmailDuplicated(tags, text);
      if (isEmailDuplicated) return;

      let updTags = [
        ...tags,
        {
          id: text + tags.length,
          label: text,
          valid: EMAIL_REGEX.test(text),
          selectType: RECURRING
        }
      ];

      setTags(updTags);
    },
    [tags]
  );

  const handleTagDeletion = useCallback(
    id => {
      setTags(prevState => prevState.filter(t => t.id !== id));
    },
    [tags]
  );

  const handleTagChange = ({ id, label }) => {
    const isEmailDuplicated = getIsEmailDuplicated(tags, label);
    const isEmailValid = isEmailDuplicated ? false : EMAIL_REGEX.test(label);

    const rawTags = [...tags];
    let tag = tags.find(t => t.id === id);
    tag.label = label;
    tag.valid = isEmailValid;

    setTags(rawTags);
  };

  const getSelectLabel = selectType =>
    selectType === RECURRING ? RECURRING_LABEL : SEND_ONCE_LABEL;

  const handleReceivingPeriodUpdate = ({ id, type }) => {
    const rawTags = [...tags];
    let tag = tags.find(t => t.id === id);

    tag.selectType = type;
    setTags(rawTags);
  };

  return {
    handleTagAdding,
    handleTagDeletion,
    tags,
    handleTagChange,
    getSelectLabel,
    handleReceivingPeriodUpdate
  };
};

export const useAmountInfoAvailability = () => {
  const { features: existingWalletFeatures } = useSelector(({ wallet }) => wallet);

  const {
    user: { user_features: userFeatures }
  } = useSelector(state => state.user);

  // TODO will be uncommented when api is ready
  // const { companyCurrency } = useSelector(({ company }) => company);

  const isLocalPaymentAvailable =
    existingWalletFeatures.find(f => f.id === '3') && userFeatures.includes(3);

  return {
    isAmountInfoNotAvailable: isLocalPaymentAvailable
    // && isUsdCurrency(companyCurrency)
  };
};
