import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as Chevron } from '../../../../assets/images/input-chevron.svg';

import { SEND_PAYMENT_LOCALE } from '../../../../constants/locales';

import './index.scss';

const Header = ({
  isDropdownVisible,
  isDisabled,
  selectedItemLabel,
  onHeaderClick,
  onInputChange,
  onInputKeyPress,
  onKeyDown,
  enteredText,
  withChevron,
  placeholder,
  withFullMatchMark
}) => {
  const { t } = useTranslation(SEND_PAYMENT_LOCALE);

  return (
    <div className="dropdown-search__input" onClick={onHeaderClick}>
      {isDropdownVisible ? (
        <input
          disabled={isDisabled}
          value={t ? t(enteredText || selectedItemLabel) : enteredText || selectedItemLabel}
          autoFocus={true}
          onKeyDown={onKeyDown}
          onChange={onInputChange}
          onKeyPress={onInputKeyPress}
          placeholder={placeholder}
        />
      ) : (
        <span>
          {withFullMatchMark
            ? t(enteredText || selectedItemLabel || placeholder)
            : enteredText || selectedItemLabel || placeholder}
        </span>
      )}
      {withChevron ? (
        <Chevron
          className={classNames('dropdown-search__input-chevron', {
            expanded: isDropdownVisible
          })}
        />
      ) : null}
    </div>
  );
};

export default Header;
