import PropTypes from 'prop-types';

import './index.scss';

const ExchangeDataTooltip = ({ formattedExchangeRate, lastUpdate, tooltipCoords }) => (
  <div className="exchange-data__tooltip" style={tooltipCoords}>
    <div>
      <span className="rate">{formattedExchangeRate}</span>
      <span className="date">{lastUpdate}</span>
    </div>
  </div>
);

export default ExchangeDataTooltip;

ExchangeDataTooltip.propTypes = {
  formattedExchangeRate: PropTypes.string.isRequired,
  lastUpdate: PropTypes.string.isRequired,
  tooltipCoords: PropTypes.object
};

ExchangeDataTooltip.defaultProps = {
  tooltipCoords: null
};
