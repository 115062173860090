import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Loader, RoundedButton } from '../index';

import './index.scss';

const ReloadButton = ({ handleReloadClick, isLoading, children }) => (
  <RoundedButton
    type="reload"
    className={isLoading && 'reload-loading'}
    onClick={handleReloadClick}
    disabled={isLoading}
  >
    {children}
  </RoundedButton>
);

const TransactionErrorSection = ({
  handleReloadClick,
  style,
  isButtonLoading,
  info,
  buttonLabel,
  isLoading
}) => {
  const { t } = useTranslation('translation');

  return (
    <div className="error-transactions__body" style={style}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <span>{info || t('charts.transactions.error.title')}</span>
          <div className="error-transactions__button-wrapper">
            <ReloadButton handleReloadClick={handleReloadClick} isLoading={isButtonLoading}>
              {buttonLabel || t('charts.transactions.error.reload')}
            </ReloadButton>
          </div>
        </>
      )}
    </div>
  );
};

export default TransactionErrorSection;

TransactionErrorSection.propTypes = {
  props: PropTypes.shape({
    handleReloadClick: PropTypes.func.isRequired,
    info: PropTypes.string,
    buttonLabel: PropTypes.string
  })
};

TransactionErrorSection.defaultProps = {
  info: '',
  buttonLabel: ''
};
