import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useClickOutsideElement } from '../../../../../hooks';

import './index.scss';

const DropDown = ({ data, handleClick, selectedType, handleDropdownClose }) => {
  const { t } = useTranslation('translation');

  const dropdownRef = useRef();
  useClickOutsideElement(dropdownRef, handleDropdownClose, true);

  return (
    <div ref={dropdownRef} className="dropdown-box">
      {data.map(({ label, description, type, Icon }) => (
        <div
          className={classNames('dropdown-box-container', {
            highlighted: type === selectedType
          })}
          key={type}
          onClick={() => handleClick(type)}
        >
          <Icon className="dropdown-box-container__icon" />
          <div className="input-info-section">
            <span className="input-info-section__label">{t(label)}</span>
            <span className="input-info-section__description">{t(description)}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DropDown;

DropDown.propTypes = {
  data: PropTypes.array.isRequired,
  handleClick: PropTypes.object,
  selectedType: PropTypes.string.isRequired
};

DropDown.defaultProps = {
  handleClick: () => {}
};
