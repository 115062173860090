import * as _ from 'lodash';
import i18n from '../i18n';

import { cardsService, userService, walletService } from '../services';

import * as Smartlook from '../analytics/smartlook';
import {
  getCardLimitValidationError,
  getEmailValidationError,
  getUserNamesValidationError,
  getPasswordValidationErrors,
  getMobileNumberValidationError,
  getUserTitleValidationError,
  getAddUserTitleValidationError,
  getMobileVerificationCodeValidationError
} from '../services/validation-service';
import { cleanMobileNumber } from '../utils/string-service';
import { getCurrencyById } from '../services/currency.service';
import { walletConstants } from '../constants/wallet.constants';
import { notificationsService } from '../services/notifications.service';

import { authConstants, EMAIL_TO_DEVICE_ID_MAP_KEY } from '../constants/auth.constants';
import { userConstants, MINIMAL_USER_LIMIT } from '../constants/user.constants';

import { getDeviceId } from '../utils/auth-client';
import { transformCardStatusFromServer } from '../constants/map.constants';

export const userActions = {
  getUserInfo,
  getSystemLanguages,
  getSelectedUserInfo,
  updateSelectedUserInfo,
  resetSelectedUser,
  getTopMerchants,
  getMostActiveUsers,
  getUsers,
  updateUserProfileField,
  resendPendingEmail,
  resetPendingEmail,
  resendPendingMobile,
  resetPendingMobile,
  verifyUserMobile,
  updateUserLanguage,
  updateUserLimit,
  updateUserStatus,
  addNewUser,
  getTrustedDevices,
  changePassword,
  removeTrustedDevice,
  removeAllTrustedDevices,
  hidePendingNotifications,
  getWalletAgreement,
  getUserCards
};

// TODO - remove second param after BE implementation
function getUserInfo(billingId, newLangId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return userService
        .getUserInfo(billingId)
        .then(response => {
          if (response.data.status.is_success) {
            // TODO - remove condition after BE implementation
            if (newLangId === 3) {
              dispatch({
                type: authConstants.LOGIN_USER,
                user: { ...response.data.data, user_language: newLangId }
              });
            } else {
              dispatch({
                type: authConstants.LOGIN_USER,
                user: response.data.data
              });
            }

            const {
              first_name,
              last_name,
              email,
              user_id: id,
              mobile_code,
              mobile
            } = response.data.data;
            const preparedName = `${first_name} ${last_name}`;
            const preparedPhoneNumber = `${mobile_code}${mobile}`;
            Smartlook.identify(id, {
              email,
              phone: preparedPhoneNumber,
              name: preparedName,
              language: navigator.language,
              languages: JSON.stringify(window.navigator.languages, null, 2),
              i18nLanguages: JSON.stringify(i18n.languages, null, 2),
              i18nLanguage: i18n.language,
              i18nTranslator: i18n.translator.language
            });
            resolve(response.data.data);
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getUserCards({ page, userId, company }, cancelToken) {
  return () => {
    return new Promise((resolve, reject) => {
      return cardsService
        .getUserCards({ page, userId }, cancelToken)
        .then(({ data, status }) => {
          if (status === 200 || !data.status.is_success) {
            let { cards, paging } = data.data[0].user_cards;

            cards = cards.map(card => ({
              ...card,
              status_id: transformCardStatusFromServer(card.status_id),
              currency: getCurrencyById(company, card.currency_id)
            }));

            resolve({
              cards,
              currentPage: paging.current_page,
              totalPages: paging.total_pages
            });
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getSystemLanguages() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return userService
        .getSystemLanguages()
        .then(response => {
          if (response.data.status.is_success) {
            dispatch({
              type: userConstants.SET_LANGUAGES,
              languages: response.data.data
            });
            resolve();
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function updateUserLanguage(languageId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return userService
        .updateUserLanguage(languageId)
        .then(response => {
          if (response.status.is_success) {
            dispatch({
              type: userConstants.SET_SELECTED_LANGUAGE,
              langId: languageId
            });
            resolve();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getSelectedUserInfo(userId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (userId) {
        return userService
          .getUserInfo(null, userId)
          .then(response => {
            if (response.data.status.is_success) {
              dispatch({
                type: userConstants.SET_SELECTED_USER,
                user: response.data.data
              });
              resolve();
            } else {
              reject(response.data.status.error_message);
            }
          })
          .catch(error => {
            reject(error);
          });
      } else {
        reject();
      }
    });
  };
}

function updateSelectedUserInfo(payload, userId, physCard) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const handleError = error => {
        // todo: change array construction to differentiate errors
        // todo: when it will be done on backend
        reject([
          {
            flag: userConstants.UPDATE_SELECTED_USER_FLAGS.DEFAULT,
            error
          }
        ]);
      };

      userService
        .updateUserProfile(payload, userId)
        .then(response => {
          if (response.status.is_success) {
            dispatch({
              type: userConstants.SET_SELECTED_USER,
              user: response.data || { physCard }
            });
            resolve();
          } else handleError(response.status.error_message);
        })
        .catch(error => {
          handleError(error);
        });
    });
  };
}

function resetSelectedUser() {
  return dispatch => {
    dispatch({
      type: userConstants.RESET_SELECTED_USER
    });
  };
}

function getTopMerchants(billingId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return userService
        .getTopMerchants(billingId)
        .then(response => {
          if (response.data.status.is_success) {
            dispatch({
              type: userConstants.SET_TOP_ITEMS,
              items: response.data.merchant_data.map(({ name, transaction_sum }) => ({
                name: _.capitalize(name),
                amount: transaction_sum
              }))
            });
            resolve();
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getMostActiveUsers(billingId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return userService
        .getMostActiveUsers(billingId)
        .then(response => {
          if (response.data.status.is_success) {
            dispatch({
              type: userConstants.SET_TOP_ITEMS,
              items: response.data.data.map(user => ({
                name: `${user.first_name} ${user.last_name}`,
                amount: user.spent
              }))
            });
            resolve();
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getUsers({ billingId, setNewUsersList, page, sortIndex, searchString }, cancelToken) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      return userService
        .getUsers({ billingId, page, sortIndex, searchString }, cancelToken)
        .then(response => {
          if (response.data?.status?.is_success) {
            // in order to get currency by received from server id
            // we acquiring the data from company reducer
            const users = response.data.data;

            const totalPages = _.get(response, 'data.paging.total_pages');
            const currentPage = _.get(response, 'data.paging.current_page');

            users.forEach(user => {
              user.user_cards.cards = user.user_cards.cards.map(card => ({
                ...card,
                currency: getCurrencyById(getState().company, card.currency_id)
              }));
            });

            dispatch({
              type: userConstants.SET_USERS,
              users,
              setNewUsersList,
              totalPages,
              currentPage
            });
            resolve();
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function updateUserLimit(actionFlag, payload) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return userService
        .updateUserLimit(payload.userId, payload.limit)
        .then(response => response.json())
        .then(response => {
          if (!response.status.is_success) {
            let data = {
              type: userConstants.RESET_USER_LIMIT,
              prevLimit: payload.prevLimit
            };
            if (payload.userId) data['userId'] = payload.userId;
            dispatch(data);
            reject(response.status.error_message);
          } else {
            let data = {
              type: userConstants.UPDATE_USER_LIMIT,
              limit: payload.limit
            };
            if (payload.userId) data['userId'] = payload.userId;
            dispatch(data);
            resolve(payload.limit);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function updateUserStatus(id, statusId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const newStatus = statusId ? 0 : 1;
      return userService
        .updateUserStatus(id, newStatus)
        .then(response => response.json())
        .then(response => {
          if (response.status.is_success) {
            dispatch({
              type: userConstants.UPDATE_USER_STATUS,
              id,
              statusId: newStatus
            });
            resolve();
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function addNewUser(userInfo) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const limitError =
        userInfo.type === 6
          ? null
          : getCardLimitValidationError(userInfo.limit, MINIMAL_USER_LIMIT);
      const emailError = getEmailValidationError(userInfo.email);
      const firstNameError = getUserNamesValidationError(userInfo.firstName);
      const lastNameError = getUserNamesValidationError(userInfo.lastName);
      const titleError = getAddUserTitleValidationError(userInfo.title);
      if (!limitError && !emailError && !firstNameError && !lastNameError && !titleError) {
        const formattedUser = _transformUserForCreation(userInfo, getState().user.user);
        return userService
          .addNewUser(formattedUser)
          .then(response => {
            if (!response.status.is_success) {
              reject(
                _constructUserCreationValidationError(
                  null,
                  null,
                  null,
                  null,
                  null,
                  response.status.error_message
                )
              );
            } else {
              dispatch({ type: userConstants.INCREMENT_USERS_COUNT });
              resolve(response.data);
            }
          })
          .catch(error => {
            reject(_constructUserCreationValidationError(null, null, null, null, null, error));
          });
      } else {
        reject(
          _constructUserCreationValidationError(
            limitError,
            emailError,
            firstNameError,
            lastNameError,
            titleError
          )
        );
      }
    });
  };
}

function getTrustedDevices() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return userService
        .getTrustedDevices()
        .then(response => {
          if (response.data.status.is_success) {
            dispatch({
              type: userConstants.SET_TRUSTED_DEVICES,
              devices: response.data.data
            });
            resolve();
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function removeTrustedDevice(targetId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return userService
        .removeTrustedDevice(targetId)
        .then(response => {
          if (response.status.is_success) {
            _removeTrustedDeviceFromStorage(targetId);
            dispatch({
              type: userConstants.REMOVE_TRUSTED_DEVICE,
              targetId: targetId
            });
            resolve();
          } else {
            reject(response.status.error_message);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function removeAllTrustedDevices() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return userService
        .removeAllTrustedDevices()
        .then(response => {
          if (response.status.is_success) {
            window.localStorage.removeItem('email-to-device_id-map');
            dispatch({
              type: userConstants.REMOVE_TRUSTED_DEVICES
            });
            resolve();
          } else {
            reject(response.status.error_message);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function changePassword(oldPassword, newPassword) {
  return () => {
    return new Promise((resolve, reject) => {
      const validationError = _getChangePasswordErrors(newPassword);

      if (!validationError.hasPasswordsErrors) {
        const deviceId = getDeviceId();
        userService
          .changePassword(oldPassword, newPassword, deviceId)
          .then(response => {
            if (response.status.is_success) {
              resolve();
            } else {
              reject({
                hasPasswordErrors: true,
                passwordError: {
                  regularError: response.status.error_message
                }
              });
            }
          })
          .catch(error => {
            reject({
              hasPasswordsErrors: true,
              passwordError: {
                regularError: error
              }
            });
          });
      } else {
        reject(validationError);
      }
    });
  };
}

function updateUserProfileField(flag, value, userId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const validationError = _getUpdateUserProfileFieldValidationError(flag, value);
      if (!validationError) {
        if (flag !== userConstants.USER_PROFILE_FIELD_FLAGS.MOBILE) {
          _callSpecificProfileUpdateApi(flag, value, userId)
            .then(response => {
              handleResponse(response, value);
            })
            .catch(error => {
              reject(error);
            });
        } else {
          const cleanedNumber = cleanMobileNumber(value.number);
          userService
            .updateUserMobile(value.code, cleanedNumber)
            .then(response => {
              const updatedValue = {
                code: value.code,
                codeId: response.data ? response.data.code_id : null,
                number: cleanedNumber
              };
              handleResponse(response, updatedValue);
            })
            .catch(error => {
              reject(error);
            });
        }

        const handleResponse = (response, newValue) => {
          if (response.status.is_success) {
            dispatch({
              type: userConstants.UPDATE_USER_PROFILE_FIELD,
              flag,
              value: newValue
            });
            resolve(response);
          } else {
            reject(response.status.error_message);
          }
        };
      } else {
        reject(validationError);
      }
    });
  };
}

function resendPendingEmail(userId, email) {
  return () => {
    return new Promise((resolve, reject) => {
      return userService
        .resendPendingEmail(email, userId)
        .then(response => {
          if (response.status.is_success) {
            resolve();
          } else {
            reject(response.status.error_message);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function resetPendingEmail(userId, isSelectedUser) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return userService
        .resetPendingEmail(userId)
        .then(response => {
          if (response.status.is_success) {
            dispatch({
              type: isSelectedUser
                ? userConstants.RESET_SELECTED_USER_EMAIL
                : userConstants.RESET_USER_EMAIL
            });
            resolve();
          } else {
            reject(response.status.error_message);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function resendPendingMobile(userId, codeId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return userService
        .resendPendingMobile(codeId, userId)
        .then(response => {
          if (response.status.is_success) {
            dispatch({
              type: userConstants.RESEND_USER_MOBILE,
              codeId: response.data.code_id,
              code: response.data.code,
              number: response.data.number
            });
            resolve();
          } else {
            reject(response.status.error_message);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function resetPendingMobile(userId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return userService
        .resetPendingMobile(userId)
        .then(response => {
          if (response.status.is_success) {
            dispatch({
              type: userConstants.RESET_USER_MOBILE
            });
            resolve();
          } else {
            reject(response.status.error_message);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function verifyUserMobile(payload) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const { codeId, code, pendingMobileCode, pendingMobileNumber } = payload;
      const codeError = getMobileVerificationCodeValidationError(code);
      if (!codeError) {
        userService
          .verifyUserMobile(codeId, code)
          .then(response => {
            if (response.status.is_success) {
              dispatch({
                type: userConstants.VERIFY_USER_MOBILE,
                pendingCode: pendingMobileCode,
                pendingNumber: pendingMobileNumber
              });
              resolve();
            } else {
              reject(response.status.error_message);
            }
          })
          .catch(error => {
            reject(error);
          });
      } else {
        reject(codeError);
      }
    });
  };
}

function hidePendingNotifications(id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: userConstants.HIDE_PENDING_NOTIFICATIONS,
        payload: { id }
      });
      if (!id) return resolve();

      return notificationsService
        .cancelBanner(id)
        .then(response => (response.data?.status?.is_success ? resolve() : reject()))
        .catch(() => {
          reject();
        });
    });
  };
}

function _callSpecificProfileUpdateApi(flag, value, userId) {
  switch (flag) {
    case userConstants.USER_PROFILE_FIELD_FLAGS.FIRST_NAME:
      return userService.updateUserFirstName(value);
    case userConstants.USER_PROFILE_FIELD_FLAGS.LAST_NAME:
      return userService.updateUserLastName(value);
    case userConstants.USER_PROFILE_FIELD_FLAGS.SECOND_LAST_NAME:
      return userService.updateUserSecondLastName(value);
    case userConstants.USER_PROFILE_FIELD_FLAGS.EMAIL:
      return userService.updateUserEmail(value);
    case userConstants.USER_PROFILE_FIELD_FLAGS.TITLE:
      return userService.updateUserTitle(value);
    case userConstants.USER_PROFILE_FIELD_FLAGS.DEPARTMENT:
      return userService.updateUserDepartment(value.id);
    case userConstants.USER_PROFILE_FIELD_FLAGS.WALLET:
      return userService.updateUserWalletAvailability(value, userId);
  }
}

function _getUpdateUserProfileFieldValidationError(flag, value) {
  let error = null;

  switch (flag) {
    case userConstants.USER_PROFILE_FIELD_FLAGS.FIRST_NAME:
    case userConstants.USER_PROFILE_FIELD_FLAGS.LAST_NAME:
      error = getUserNamesValidationError(value);
      break;
    case userConstants.USER_PROFILE_FIELD_FLAGS.EMAIL:
      error = getEmailValidationError(value);
      break;
    case userConstants.USER_PROFILE_FIELD_FLAGS.MOBILE:
      const cleanedMobileNumber = cleanMobileNumber(value.number);
      error = getMobileNumberValidationError(cleanedMobileNumber);
      break;
    case userConstants.USER_PROFILE_FIELD_FLAGS.TITLE:
      error = getUserTitleValidationError(value);
      break;
    default:
      error = null;
  }

  return error;
}

function _getChangePasswordErrors(password) {
  const passwordErrors = getPasswordValidationErrors(password);
  let hasPasswordsErrors = false;
  for (let [key, value] of Object.entries(passwordErrors)) {
    if (value) {
      hasPasswordsErrors = true;
      break;
    }
  }
  return hasPasswordsErrors
    ? {
        hasPasswordsErrors: true,
        passwordError: passwordErrors
      }
    : {
        hasPasswordsErrors: false,
        passwordError: null
      };
}

function _constructUserCreationValidationError(
  limit,
  email,
  firstName,
  lastName,
  title,
  defaultError = null,
  department = null
) {
  return {
    limit: limit || null,
    email: email || null,
    firstName: firstName || null,
    lastName: lastName || null,
    title: title || null,
    defaultError,
    department
  };
}

function _transformUserForCreation(userInfo, user) {
  return {
    device_id: getDeviceId(),
    first_name: userInfo.firstName,
    last_name: userInfo.lastName,
    department_id: userInfo.departmentId,
    title: userInfo.title,
    limit: userInfo.limit,
    email: userInfo.email,
    user_type_id: userInfo.type,
    enable_wallets: userInfo.isWalletActive,
    enablePhysicalCard: userInfo.isCreatePhysicalCardsEnable,
    BlockedMerchants: userInfo.restrictedMerchantCategoriesKeys,
    TransactionLimit: userInfo.transactionLimit
  };
}

function _removeTrustedDeviceFromStorage(deviceId) {
  const rawDeviceIdMap = window.localStorage.getItem(EMAIL_TO_DEVICE_ID_MAP_KEY);

  const deviceIdMap = rawDeviceIdMap ? JSON.parse(rawDeviceIdMap) : {};
  const entryForRemoval = Object.entries(deviceIdMap).find(item => item[1] === deviceId);
  if (entryForRemoval) {
    if (Object.keys(deviceIdMap).length > 1) {
      delete deviceIdMap[entryForRemoval[0]];
      window.localStorage.setItem(EMAIL_TO_DEVICE_ID_MAP_KEY, JSON.stringify(deviceIdMap));
    } else {
      window.localStorage.removeItem(EMAIL_TO_DEVICE_ID_MAP_KEY);
    }
  }
}

function getWalletAgreement() {
  return dispatch =>
    new Promise((resolve, reject) =>
      walletService
        .getWalletAgreement()
        .then(({ data }) => {
          const isSuccess = _.get(data, 'status.is_success', false);
          if (isSuccess) {
            dispatch({
              type: walletConstants.GET_WALLET_AGREEMENT,
              payload: data
            });
            resolve();
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        })
    );
}
