import i18n from '../../i18n';

import { logout } from '../../utils/auth-client';

import { MAINTENANCE_ROUTE } from '../../constants/routes.constants';

const _handleUnauthorized = ({ response }) => {
  response && response.status === 401 && logout();
};

const redirectToMaintenance = () => {
  sessionStorage.setItem('currentLanguage', i18n.language);
  window.location.href = MAINTENANCE_ROUTE;
};

const _handleServerError = ({ response }) =>
  response &&
  response.status === 503 &&
  window.location.pathname !== MAINTENANCE_ROUTE &&
  redirectToMaintenance();

export const handleRequestError = error => {
  _handleServerError(error);

  _handleUnauthorized(error);
  return error;
};
